import React from 'react';
import { useNavigate } from 'react-router-dom';

// Common components
import { Breadcrumbs, ModalCenter } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import { BULK_DELETE_ASSESSMENTS } from 'src/api/endpoints';

// hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useAssessmentIndex from 'src/hooks/Pages/LearningManagement/Assessment/useAssessmentIndex';

import BulkDeleteAssessmentModal from './components/BulkDeleteAssessmentModal';
import DeleteAssessmentModal from './components/DeleteAssessmentModal';

const Index = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    assessmentListMemo,
    hasMore,
    assessmentColumns,
    showModal,
    showDeleteModal,
    form,
    submitFilter,
    handleModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs,
    modalAssessmentId,
    assessmentTitle
  } = useAssessmentIndex(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Learning Management' }, { name: 'Assessments' }]}
      />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            buttonName="New Assessment"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => navigate('/assessments/add')}
            bulkDeleteClick={() =>
              handleBulkDeleteModal('BulkDeleteAssessmentModal')
            }
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={assessmentListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {assessmentListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={assessmentListMemo || []}
              clickableRows={false}
              headingColumns={assessmentColumns}
              title="Assessments"
              actions={['view', 'custom', 'delete']}
              actionLinks={{
                view: '/assessments/view',
                custom: '/assessments/users/{id}'
              }}
              actionLabels={{
                custom: 'Users'
              }}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="Delete"
              deleteModal="DeleteAssessmentModal"
              keyField="id"
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <ModalCenter
        showModal={showDeleteModal}
        modalName="DeleteAssessmentModal"
      >
        {showDeleteModal && (
          <DeleteAssessmentModal
            handleDeleteModal={handleDeleteModal}
            id={modalAssessmentId}
            name={assessmentTitle}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter
        showModal={showBulkDeleteModal}
        modalName="BulkDeleteAssessmentModal"
      >
        {showBulkDeleteModal && (
          <BulkDeleteAssessmentModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={BULK_DELETE_ASSESSMENTS}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
