/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Formik, Form, Field, ErrorMessage, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import defaultPicture from 'src/assets/icons/defaultProfile2.png';
import { useAddEditModal } from 'src/hooks/components/Parts/Employee/useAddEditModal';
import Select from 'src/components/Common/Select';
import { useUserAccess } from 'src/hooks/useUserAccess';

import {
  STATUS_OPTIONS,
  EMPLOYEE_RELATION_OPTIONS
} from 'src/helpers/constants';
import FormGridCard from 'src/components/Common/FormGridCard';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import Loading from 'src/components/Common/loading';

const AddEditModal = ({ handleModal, id, menuCode, userData }) => {
  const {
    formData,
    initialDummyData,
    image,
    previewImage,
    modalTitle,
    handleChange,
    handleImageChange,
    handleStatusChange,
    setIsEdit,
    isEdit,
    handleSubmit,
    salaryGradeOptions,
    positionOptions,
    teamOptions,
    gender,
    civilStatus,
    employmentStatus,
    validationSchema,
    costCenterOptions,
    formLoading,
    dateYesterday
  } = useAddEditModal({ handleModal, id, userData });
  const { access } = useUserAccess(menuCode);

  return (
    <Formik
      enableReinitialize={!!id}
      validateOnMount={!!id}
      initialValues={id ? initialDummyData : formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="employeeForm">
          {/* Modal Head Section | Start */}
          <h4 className="text-[22px] font-StolzlMedium leading-[27px] pb-4 mx-[40px]">
            {modalTitle}
          </h4>
          {/* Modal Head Section | End */}
          {formLoading && <Loading />}
          <div className="h-[calc(100vh-112px)] overflow-auto">
            <div className="mx-[40px] pb-11 flex flex-row gap-5 border-solid border-b-[1px] border-[#eaeaea]">
              <FormField
                type="file"
                name="employee_input.image"
                preview={previewImage}
                image={image}
                defaultImage={defaultPicture}
                id={id}
                isEdit={isEdit}
                idNo={formData.employee_input?.id_no}
                firstName={formData?.employee_info_input?.first_name}
                middleName={formData?.employee_info_input?.middle_name}
                lastName={formData?.employee_info_input?.last_name}
                suffix={formData?.employee_info_input?.suffix}
                email={formData.employee_input?.email}
                onChange={e =>
                  handleImageChange(e, {
                    setFieldValue,
                    setFieldTouched,
                    setFieldError
                  })
                }
              />
              <div className="flex-1 text-right">
                {!isEdit && id ? (
                  <button
                    type="button"
                    disabled={!access.can_edit}
                    onClick={e => {
                      e.preventDefault();
                      setIsEdit(!isEdit);
                    }}
                    className={`text-[12px] text-white ${
                      !access.can_edit
                        ? 'bg-gray-400/20'
                        : 'bg-gray-400 hover:bg-gray-500'
                    } font-normal leading-[100%] h-10 w-20  border-none rounded`}
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                      Edit
                    </span>
                  </button>
                ) : (
                  isEdit &&
                  id && (
                    <button
                      form="employeeForm"
                      type="submit"
                      disabled={formLoading}
                      className={` ${
                        formLoading && 'opacity-50'
                      } text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_40px] rounded`}
                    >
                      <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                        Save
                      </span>
                    </button>
                  )
                )}
              </div>
            </div>

            <div
              className={` ${
                formLoading ? 'opacity-0' : 'opacity-100'
              } my-5 mx-[40px] text-[12px] font-stolzlBook`}
            >
              <div className="border-solid border-b-[1px] border-[#eaeaea] mb-[21px]">
                <h6 className="text-[14px] text-input font-stolzlBold leading-[14px] mb-[22px]">
                  Personal Information
                </h6>
                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="First Name"
                      required
                      name="employee_info_input.first_name"
                      type="text"
                      placeholder=""
                      errorMessage="Field Required"
                      className="employee_info_input"
                      error={errors?.employee_info_input?.first_name}
                      value={formData.employee_info_input?.first_name ?? ''}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />

                    {errors.employee_info_input?.first_name !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.first_name}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Middle Name"
                      name="employee_info_input.middle_name"
                      type="text"
                      className="employee_info_input"
                      error={errors?.employee_info_input?.middle_name}
                      value={formData.employee_info_input?.middle_name ?? ''}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.middle_name !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.middle_name}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>

                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Family Name"
                      required
                      name="employee_info_input.last_name"
                      type="text"
                      placeholder=""
                      className="employee_info_input"
                      errorMessage="Field Required"
                      error={errors.employee_info_input?.last_name}
                      value={formData.employee_info_input?.last_name ?? ''}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.last_name !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.last_name}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Suffix"
                      name="employee_info_input.suffix"
                      type="text"
                      placeholder=""
                      className="employee_info_input"
                      error={errors.employee_info_input?.suffix}
                      value={formData.employee_info_input?.suffix ?? ''}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.suffix !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.suffix}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>
                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Gender"
                      type="select"
                      options={gender}
                      onChangeValue={(value, name) =>
                        handleStatusChange(
                          value,
                          name,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      placeholder="Select Gender"
                      currentValue={formData.employee_info_input.gender ?? ''}
                      selectedValue={formData.employee_info_input.gender ?? ''}
                      selectName="employee_info_input.gender"
                      disabled={id && !isEdit}
                    />
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Date of Birth"
                      name="employee_info_input.birth_date"
                      type="date"
                      className="employee_info_input"
                      placeholder=""
                      value={formData.employee_info_input?.birth_date ?? ''}
                      max={dateYesterday}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                  </FormCard>
                </FormGridCard>

                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Phone 1"
                      name="employee_info_input.phone_no"
                      type="tel"
                      className="employee_info_input"
                      placeholder=""
                      value={formData.employee_info_input?.phone_no ?? ''}
                      error={errors.employee_info_input?.phone_no}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />

                    {errors.employee_info_input?.phone_no !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.phone_no}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Phone 2"
                      name="employee_info_input.landline_no"
                      type="tel"
                      className="employee_info_input"
                      placeholder=""
                      value={formData.employee_info_input?.landline_no ?? ''}
                      error={errors.employee_info_input?.landline_no}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.landline_no !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.landline_no}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>

                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Personal Email"
                      name="employee_info_input.personal_email"
                      type="text"
                      placeholder=""
                      className="employee_info_input normal-case"
                      value={formData.employee_info_input?.personal_email ?? ''}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      error={errors?.employee_info_input?.personal_email}
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.personal_email !==
                      'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.personal_email}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Civil Status"
                      type="select"
                      options={civilStatus}
                      placeholder="Select Civil Status"
                      onChangeValue={(value, name) =>
                        handleStatusChange(
                          value,
                          name,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      currentValue={
                        formData.employee_info_input.civil_status ?? ''
                      }
                      selectedValue={
                        formData.employee_info_input.civil_status ?? ''
                      }
                      selectName="employee_info_input.civil_status"
                      disabled={id && !isEdit}
                    />
                  </FormCard>
                </FormGridCard>
              </div>

              <div className="border-solid border-b-[1px] border-[#eaeaea] mb-[21px]">
                <h6 className="text-[14px] text-input font-stolzlBold leading-[14px] mb-[21px]">
                  Emergency Contact
                </h6>

                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Name"
                      name="employee_info_input.emergency_contact"
                      type="text"
                      className="employee_info_input"
                      placeholder=""
                      error={errors.employee_info_input?.emergency_contact}
                      value={
                        formData.employee_info_input?.emergency_contact ?? ''
                      }
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.emergency_contact !==
                      'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.emergency_contact}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Relation"
                      type="select"
                      placeholder="Select Relation"
                      options={EMPLOYEE_RELATION_OPTIONS}
                      onChangeValue={(value, name) =>
                        handleStatusChange(
                          value,
                          name,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      selectedValue={
                        formData.employee_info_input.emergency_contact_relation
                      }
                      currentValue={
                        formData.employee_info_input.emergency_contact_relation
                      }
                      selectName="employee_info_input.emergency_contact_relation"
                      disabled={id && !isEdit}
                    />
                  </FormCard>
                </FormGridCard>

                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Phone 1"
                      name="employee_info_input.emergency_contact_no_1"
                      type="tel"
                      className="employee_info_input"
                      placeholder=""
                      value={
                        formData.employee_info_input?.emergency_contact_no_1 ??
                        ''
                      }
                      error={errors.employee_info_input?.emergency_contact_no_1}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.emergency_contact_no_1 !==
                      'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.emergency_contact_no_1}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Phone 2"
                      name="employee_info_input.emergency_contact_no_2"
                      type="tel"
                      className="employee_info_input"
                      placeholder=""
                      value={
                        formData.employee_info_input?.emergency_contact_no_2 ??
                        ''
                      }
                      error={errors.employee_info_input?.emergency_contact_no_2}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      readOnly={id && !isEdit}
                    />
                    {errors.employee_info_input?.emergency_contact_no_2 !==
                      'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.employee_info_input?.emergency_contact_no_2}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>
              </div>

              <h6 className="text-[14px] text-input font-stolzlBold leading-[14px] mb-[22px]">
                Work
              </h6>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Status"
                    type="select"
                    required
                    placeholder="Select Status"
                    options={STATUS_OPTIONS}
                    onChangeValue={(value, name) =>
                      handleStatusChange(
                        value,
                        name,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    selectedValue={formData.employee_input.status}
                    selectName="employee_input.status"
                    errors={errors?.employee_input?.status}
                    disabled={(id && !isEdit) || !id}
                  />
                </FormCard>
                <FormCard>
                  <FormField
                    label="Employment Status"
                    type="select"
                    required
                    placeholder="Select Employment Status"
                    errorMessage="Field Required"
                    options={employmentStatus}
                    onChangeValue={(value, name) =>
                      handleStatusChange(
                        value,
                        name,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    selectedValue={formData.employee_input.employment_status}
                    currentValue={formData.employee_input.employment_status}
                    selectName="employee_input.employment_status"
                    errors={errors?.employee_input?.employment_status}
                    disabled={id && !isEdit}
                  />
                </FormCard>
              </FormGridCard>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Work Email"
                    required
                    className="employee_input normal-case"
                    name="employee_input.email"
                    type="text"
                    placeholder=""
                    errorMessage="Field Required"
                    error={errors.employee_input?.email}
                    value={formData.employee_input?.email ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={isEdit || id > 0}
                  />

                  {errors.employee_input?.email !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.employee_input?.email}
                    </div>
                  )}
                </FormCard>
                <FormCard>
                  <FormField
                    label="ID Number"
                    required
                    className="employee_input"
                    name="employee_input.id_no"
                    value={formData.employee_input?.id_no ?? ''}
                    type="tel"
                    placeholder=""
                    errorMessage="Field Required"
                    error={errors.employee_input?.id_no}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={id && !isEdit}
                  />
                  {errors.employee_input?.id_no !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.employee_input?.id_no}
                    </div>
                  )}
                </FormCard>
              </FormGridCard>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Salary Grade"
                    type="select"
                    required
                    placeholder="Select Salary Grade"
                    errorMessage="Field Required"
                    options={salaryGradeOptions}
                    onChangeValue={(value, name) =>
                      handleStatusChange(
                        value,
                        name,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    selectedValue={formData.employee_input.salary_grade}
                    errors={errors?.employee_input?.salary_grade}
                    selectName="employee_input.salary_grade"
                    disabled={id && !isEdit}
                  />
                </FormCard>
                <FormCard>
                  {id ? (
                    <FormField
                      label="Cost Center"
                      type={
                        formData.employee_input.cost_center_code
                          ? 'combobox'
                          : 'select'
                      }
                      listType="costcenter"
                      required
                      placeholder="Select Cost Center"
                      errorMessage="Field Required"
                      options={costCenterOptions}
                      onChangeValue={value => {
                        handleStatusChange(
                          value,
                          'employee_input.cost_center_code',
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        );
                      }}
                      selectedValue={
                        formData.employee_input.cost_center_code ?? ''
                      }
                      errors={errors?.employee_input?.cost_center_code}
                      selectName="employee_input.cost_center_code"
                      disabled={id && !isEdit}
                    />
                  ) : (
                    <FormField
                      label="Cost Center"
                      type="combobox"
                      listType="costcenter"
                      required
                      placeholder="Select Cost Center"
                      errorMessage="Field Required"
                      options={costCenterOptions}
                      onChangeValue={value => {
                        handleStatusChange(
                          value,
                          'employee_input.cost_center_code',
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        );
                      }}
                      selectedValue={
                        formData.employee_input.cost_center_code ?? ''
                      }
                      errors={errors?.employee_input?.cost_center_code}
                      selectName="employee_input.cost_center_code"
                      disabled={id && !isEdit}
                    />
                  )}
                </FormCard>
              </FormGridCard>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Team"
                    type="select"
                    placeholder="Select Team"
                    options={teamOptions}
                    onChangeValue={(value, name) =>
                      handleStatusChange(
                        value,
                        name,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    selectedValue={formData.employee_input?.team_id}
                    selectName="employee_input.team_id"
                    disabled={id && !isEdit}
                  />
                </FormCard>
                <FormCard>
                  <FormField
                    label="Position"
                    type="select"
                    placeholder="Select Position"
                    options={positionOptions}
                    onChangeValue={(value, name) =>
                      handleStatusChange(
                        value,
                        name,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    selectedValue={formData.employee_input?.position_id}
                    selectName="employee_input.position_id"
                    errors={errors?.employee_input?.position}
                    disabled={id && !isEdit}
                  />
                </FormCard>
              </FormGridCard>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Immediate Superior"
                    name="employee_input.immediate_superior"
                    type="text"
                    className="employee_input"
                    placeholder=""
                    error={errors.employee_input?.immediate_superior}
                    value={formData.employee_input?.immediate_superior ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={id && !isEdit}
                  />
                  {errors.employee_input?.immediate_superior !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.employee_input?.immediate_superior}
                    </div>
                  )}
                </FormCard>
                <FormCard>
                  <FormField
                    label="Date Hired"
                    name="employee_input.date_hired"
                    value={formData.employee_input?.date_hired ?? ''}
                    type="date"
                    className="employee_input"
                    placeholder=""
                    // required
                    // errorMessage="Field Required"
                    max="9999-12-31"
                    error={errors.employee_input?.date_hired}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={id && !isEdit}
                  />
                </FormCard>
              </FormGridCard>

              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    label="Date of Regularization"
                    className="employee_input"
                    placeholder=""
                    name="employee_input.date_regularized"
                    error={errors.employee_input?.date_regularized}
                    // errorMessage="Field Required"
                    // required
                    type="date"
                    max="9999-12-31"
                    value={formData.employee_input?.date_regularized ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={id && !isEdit}
                  />
                </FormCard>
                <FormCard>
                  <FormField
                    label="Resigned Date"
                    name="employee_input.date_resigned"
                    value={formData.employee_input?.date_resigned ?? ''}
                    type="date"
                    className="employee_input"
                    placeholder=""
                    max="9999-12-31"
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    readOnly={id && !isEdit}
                  />
                </FormCard>
              </FormGridCard>
            </div>

            <div className="flex mx-[40px] pb-[39px] mb-[21px]">
              {!isEdit && !id && (
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_40px] rounded"
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                      Save
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddEditModal.propTypes = {
  handleModal: PropTypes.func,
  id: PropTypes.number,
  menuCode: PropTypes.string,
  userData: PropTypes.shape({})
};

export default AddEditModal;
