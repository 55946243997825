import React from 'react';
import { SlideModal, ModalCenter, Breadcrumbs } from 'src/components/Common';
import PropTypes from 'prop-types';
import useCompanyEvents from 'src/hooks/Pages/CalendarSettings/CompanyEvents/useCompanyEvents';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Datatable from 'src/components/Common/Datatable';
import { useUserAccess } from 'src/hooks/useUserAccess';
import AddEditModal from './AddEditModal';
import DeleteModal from './DeleteModal';
import BulkDeleteModal from '../BulkDeleteModal';

const Index = ({ menuCode }) => {
  const {
    inputs,
    eventsListMemo,
    fetchData,
    eventsColumn,
    eventsId,
    isOpen,
    hasMore,
    handleModal,
    detectDateOnChange,
    handleBulkDeleteModal,
    form,
    showModal,
    showBulkDeleteModal,
    submitFilter,
    showDeleteModal,
    eventsList,
    handleDeleteModal
  } = useCompanyEvents();

  const { menus } = useUserAccess();

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'System' }, { name: 'Company Events' }]} />

      <div className="pt-2 holiday__container">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Events Name"
            buttonName="Events"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
            dateOnChange={detectDateOnChange}
          />

          <InfiniteScroll
            dataLength={eventsListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {eventsListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
            style={{ overflow: 'visible' }}
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={eventsListMemo || []}
              clickableRows={false}
              headingColumns={eventsColumn}
              title="Company Events"
              actions={['edit', 'delete']}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>

      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            eventsId={eventsId}
            dataList={eventsList}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            id={eventsId}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            // endpoint={BULK_DELETE_EVENTS}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
