import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { getHolidaysService } from 'src/api/modules/holidays';
import {
  getHolidays,
  getAllHolidays
} from 'src/redux/modules/calendar/holidayActions';
import { getDaycodes } from 'src/api/modules/daycode';
import {
  getDayCode,
  getAllDayCodes
} from 'src/redux/modules/dayCodes/dayCodeActions';

export const useBulkDeleteModal = ({
  handleBulkDeleteModal,
  apiService,
  endpoint,
  codeType
}) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);

  const fetchUpdateHoliday = async () => {
    getHolidaysService(1)
      .then(() => {
        dispatch(getHolidays());
        dispatch(getAllHolidays());
      })
      .catch(err => {
        return err;
      });
  };

  const fetchUpdateDayCode = async () => {
    getDaycodes(1)
      .then(() => {
        dispatch(getDayCode());
        dispatch(getAllDayCodes());
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await apiService(ids, endpoint);
      if (res.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
        dispatch(setIdSelection([]));
        fetchUpdateHoliday();
        fetchUpdateDayCode();
      }
      if (res.status === 405) {
        toast.error('Unable to delete!', {
          icon: <MdOutlineCancel />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete,
    ids
  };
};
