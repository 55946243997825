/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import useAddEditModal from 'src/hooks/components/Parts/CalendarSettings/CompanyEvents/useAddEditModal';
import ColorPicker from 'src/components/Common/ColorPicker';
import {
  Button,
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard
} from 'src/components/Common';
import { useUserAccess } from 'src/hooks/useUserAccess';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, eventsId, dataList, menuCode }) => {
  const {
    uniqueOccurrences,
    isFixedCheckboxDisabled,
    formData,
    initialDummyData,
    validationSchema,
    showDeleteModal,
    isDoesNotRepeat,
    editModal,
    dayChecked,
    endsType,
    isDateEnabled,
    isOccurrencesEnabled,
    handSelectChange,
    handleDeleteModal,
    handleColorChange,
    handleChange,
    handleTrimSpaces,
    handleSubmit,
    handleSelectChange,
    handleDayChange,
    handleEndsTypeChange
  } = useAddEditModal({ handleModal, eventsId, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <>
      <Formik
        enableReinitialize={!!eventsId}
        validateOnMount={!!eventsId}
        initialValues={eventsId ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {eventsId ? 'Edit' : 'Add'} Event
              </h4>
              {eventsId ? (
                <Button
                  type="button"
                  disabled={!access.can_delete}
                  modifier="w-[44px] h-[40px] bg-white hover:bg-gray-700 border border-[#DEDEDE] border-solid border-1 text-white p-[10px_14px] text-[12px] leading-unset rounded-default mr-[10px]"
                  spanModifier="!p-0 w-[43px] h-[43px] inset-x-0 inset-y-0 !absolute flex justify-center items-center before:w-[15px] before:h-auto before:relative before:left-0 before:content-[url('/src/assets/icons/trash.svg')]"
                  onClick={() => {
                    handleDeleteModal('DeleteModal', 'AddEdit', eventsId);
                  }}
                />
              ) : (
                ''
              )}{' '}
            </div>

            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!eventsId ? (
                  <>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Event Name"
                          required
                          name="event_name"
                          type="text"
                          placeholder=""
                          value={formData.event_name ?? ''}
                          error={errors.event_name && touched.event_name}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.event_name !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.event_name}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Event Occurence"
                          type="select"
                          // options={EVENTS_OCCURRENCE}
                          onChangeValue={(value, name) =>
                            handleSelectChange(
                              value,
                              name,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          // currentValue={formData.occurrence ?? ''}
                          selectedValue={formData.occurrence}
                          selectName="occurrence"
                        />
                      </FormCard>
                      <div className="mt-[18px]">
                        <ColorPicker
                          name="color"
                          value={formData.color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </FormGridCard>
                    <FormGridCard>
                      <div className="mb-8 flex flex-row gap-4 items-center">
                        <div className="flex-1">
                          <FormField
                            label="Start Date"
                            required
                            name="start_date"
                            value={formData.start_date ?? ''}
                            type="date"
                            error={errors?.start_date && touched.start_date}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </div>
                        <div className="mt-[14px]"> - </div>
                        <div className="flex-1">
                          <FormField
                            label="End Date"
                            required
                            name="end_date"
                            value={formData.end_date ?? ''}
                            type="date"
                            error={errors?.end_date && touched.end_date}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_date !== 'Required' && (
                            <ErrorMessage
                              name="end_date"
                              component="div"
                              className="text-[10px] text-[#E43B26] font-stolzlBook mt-1"
                            />
                          )}
                        </div>
                      </div>
                    </FormGridCard>

                    <hr className="border-solid border-b-[1px] border-[#eaeaea] mb-8" />

                    <FormGridCard>
                      <div className="flex flex-col">
                        <label
                          htmlFor="repeat_every"
                          className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]"
                        >
                          Repeat Every
                        </label>
                        <div className="flex items-end gap-2 w-[50%]">
                          <FormCard className="w-[20%]">
                            <FormField
                              type="select"
                              name="repeat_every_count"
                              options={Array.from({ length: 10 }, (_, i) => ({
                                value: i + 1,
                                label: `${i + 1}`
                              }))}
                              onChangeValue={(value, name) =>
                                handleSelectChange(
                                  value,
                                  name,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              currentValue={formData.repeat_every_count ?? ''}
                              selectedValue={formData.repeat_every_count ?? 1}
                              selectName="repeat_every_count"
                              disabled={isDoesNotRepeat}
                            />
                          </FormCard>
                          <FormCard>
                            <FormField
                              type="select"
                              name="repeat_every_unit"
                              options={[
                                { value: 'week', label: 'Week' },
                                { value: 'month', label: 'Month' },
                                { value: 'year', label: 'Year' }
                              ]}
                              onChangeValue={(value, name) =>
                                handleSelectChange(
                                  value,
                                  name,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              currentValue={formData.repeat_every_unit ?? ''}
                              selectedValue={
                                formData.repeat_every_unit ?? 'week'
                              }
                              selectName="repeat_every_unit"
                              disabled={isDoesNotRepeat}
                            />
                          </FormCard>
                        </div>
                      </div>
                    </FormGridCard>
                    <FormGridCard cols="1">
                      <FormCard>
                        <div className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                          Repeat On
                        </div>
                        <div className="flex flex-wrap gap-4 items-end">
                          {[
                            'Sun',
                            'Mon',
                            'Tue',
                            'Wed',
                            'Thu',
                            'Fri',
                            'Sat'
                          ].map((day, index) => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className="flex gap-1 items-center"
                            >
                              <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                                <input
                                  type="checkbox"
                                  id={day.toLowerCase()}
                                  name={day.toLowerCase()}
                                  checked={dayChecked[day.toLowerCase()]} //
                                  onChange={() =>
                                    handleDayChange(day.toLowerCase())
                                  }
                                  hidden
                                  disabled={isDoesNotRepeat}
                                />
                                <span
                                  className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease  duration-200 relative ${
                                    isDoesNotRepeat
                                      ? '!border-disabled !bg-[#f7f7f7]'
                                      : ''
                                  } `}
                                >
                                  <em
                                    className={`absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded ${
                                      isDoesNotRepeat ? 'hidden' : ''
                                    }`}
                                  />
                                  <em
                                    className={`absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded ${
                                      isDoesNotRepeat ? 'hidden' : ''
                                    }`}
                                  />
                                </span>
                                <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                                  {day}
                                </i>
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="1">
                      <FormCard>
                        <div className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                          Ends
                        </div>
                        <div className="flex gap-5 items-center">
                          <div className="flex gap-1 items-center">
                            <label className="flex gap-1 items-center text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              <input
                                type="radio"
                                value="on"
                                name="ends_on"
                                checked={endsType === 'on'}
                                onChange={() => handleEndsTypeChange('on')}
                                disabled={isDoesNotRepeat}
                              />
                              On
                            </label>
                            <FormField
                              label=""
                              name="on_date"
                              value={formData.on_date ?? ''}
                              type="date"
                              // error={errors?.start_date && touched.start_date}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              disabled={!isDateEnabled && isDoesNotRepeat}
                            />
                          </div>
                          <div className="flex gap-2 items-center">
                            <label className="flex gap-1 items-center text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              <input
                                type="radio"
                                value="after"
                                name="ends_after"
                                checked={endsType === 'after'}
                                onChange={() => handleEndsTypeChange('after')}
                                disabled={isDoesNotRepeat}
                              />
                              After
                            </label>
                            <div className="w-[57px]">
                              <FormField
                                type="select"
                                name="ends_occurrences"
                                options={Array.from({ length: 10 }, (_, i) => ({
                                  value: i + 1,
                                  label: `${i + 1}`
                                }))}
                                onChangeValue={(value, name) =>
                                  handleSelectChange(
                                    value,
                                    name,
                                    setFieldValue,
                                    setFieldTouched,
                                    setFieldError
                                  )
                                }
                                currentValue={formData.ends_occurrences ?? ''}
                                selectedValue={formData.ends_occurrences ?? 1}
                                selectName="ends_occurrences"
                                disabled={
                                  !isOccurrencesEnabled && isDoesNotRepeat
                                }
                              />
                            </div>
                            <span className="inline-block text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              Occurrences
                            </span>
                          </div>

                          {/* <select disabled={!isOccurrencesEnabled}>
                            {Array.from({ length: 10 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select> */}
                        </div>
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Event Name"
                          required
                          name="event_name"
                          type="text"
                          placeholder=""
                          value={formData.event_name ?? ''}
                          error={errors.event_name && touched.event_name}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.event_name !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.event_name}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Event Occurence"
                          type="select"
                          // options={[
                          //   ...uniqueOccurrences.map(occurrence => ({
                          //     value: occurrence,
                          //     label: occurrence
                          //   }))
                          // ]}
                          onChangeValue={(value, name) =>
                            handleSelectChange(
                              value,
                              name,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          placeholder="Select Event Occurence"
                          currentValue={formData.occurrence ?? ''}
                          selectedValue={formData.occurrence ?? ''}
                          selectName="occurrence"
                        />
                      </FormCard>
                      <div className="mt-[18px]">
                        <ColorPicker
                          name="color"
                          value={formData.color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </FormGridCard>
                    <FormGridCard>
                      <div className="mb-8 flex flex-row gap-4 items-center">
                        <div className="flex-1">
                          <FormField
                            label="Start Date"
                            required
                            name="start_date"
                            value={formData.start_date ?? ''}
                            type="date"
                            error={errors?.start_date && touched.start_date}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </div>
                        <div className="mt-[14px]"> - </div>
                        <div className="flex-1">
                          <FormField
                            label="End Date"
                            required
                            name="end_date"
                            value={formData.end_date ?? ''}
                            type="date"
                            error={errors?.end_date && touched.end_date}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_date !== 'Required' && (
                            <ErrorMessage
                              name="end_date"
                              component="div"
                              className="text-[10px] text-[#E43B26] font-stolzlBook mt-1"
                            />
                          )}
                        </div>
                      </div>
                    </FormGridCard>

                    <hr className="border-solid border-b-[1px] border-[#eaeaea] mb-8" />

                    <FormGridCard>
                      <div className="flex flex-col">
                        <label
                          htmlFor="repeat_every"
                          className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]"
                        >
                          Repeat Every
                        </label>
                        <div className="flex items-end gap-2 w-[50%]">
                          <FormCard className="w-[20%]">
                            <FormField
                              type="select"
                              name="repeat_every_count"
                              options={Array.from({ length: 10 }, (_, i) => ({
                                value: i + 1,
                                label: `${i + 1}`
                              }))}
                              onChangeValue={(value, name) =>
                                handleSelectChange(
                                  value,
                                  name,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              currentValue={formData.repeat_every_count ?? ''}
                              selectedValue={formData.repeat_every_count ?? ''}
                              selectName="repeat_every_count"
                              disabled={isDoesNotRepeat}
                            />
                          </FormCard>
                          <FormCard>
                            <FormField
                              type="select"
                              name="repeat_every_unit"
                              options={[
                                { value: 'week', label: 'Week' },
                                { value: 'month', label: 'Month' },
                                { value: 'year', label: 'Year' }
                              ]}
                              onChangeValue={(value, name) =>
                                handleSelectChange(
                                  value,
                                  name,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              currentValue={formData.repeat_every_unit ?? ''}
                              selectedValue={formData.repeat_every_unit ?? ''}
                              selectName="repeat_every_unit"
                              disabled={isDoesNotRepeat}
                            />
                          </FormCard>
                        </div>
                      </div>
                    </FormGridCard>
                    <FormGridCard cols="1">
                      <FormCard>
                        <div className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                          Repeat On
                        </div>
                        <div className="flex flex-wrap gap-4 items-end">
                          {[
                            'Sun',
                            'Mon',
                            'Tue',
                            'Wed',
                            'Thu',
                            'Fri',
                            'Sat'
                          ].map((day, index) => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className="flex gap-1 items-center"
                            >
                              <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                                <input
                                  type="checkbox"
                                  id={day.toLowerCase()}
                                  name={day.toLowerCase()}
                                  checked={dayChecked[day.toLowerCase()]} //
                                  onChange={() =>
                                    handleDayChange(day.toLowerCase())
                                  }
                                  hidden
                                  disabled={isDoesNotRepeat}
                                />
                                <span
                                  className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease  duration-200 relative ${
                                    isDoesNotRepeat
                                      ? '!border-disabled !bg-[#f7f7f7]'
                                      : ''
                                  } `}
                                >
                                  <em
                                    className={`absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded ${
                                      isDoesNotRepeat ? 'hidden' : ''
                                    }`}
                                  />
                                  <em
                                    className={`absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded ${
                                      isDoesNotRepeat ? 'hidden' : ''
                                    }`}
                                  />
                                </span>
                                <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                                  {day}
                                </i>
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="1">
                      <FormCard>
                        <div className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                          Ends
                        </div>
                        <div className="flex gap-5 items-center">
                          <div className="flex gap-1 items-center">
                            <label className="flex gap-1 items-center text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              <input
                                type="radio"
                                value="on"
                                name="ends_on"
                                checked={endsType === 'on'}
                                onChange={() => handleEndsTypeChange('on')}
                                disabled={isDoesNotRepeat}
                              />
                              On
                            </label>
                            <FormField
                              label=""
                              name="on_date"
                              value={formData.on_date ?? ''}
                              type="date"
                              // error={errors?.start_date && touched.start_date}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              disabled={!isDateEnabled}
                            />
                          </div>
                          <div className="flex gap-2 items-center">
                            <label className="flex gap-1 items-center text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              <input
                                type="radio"
                                value="after"
                                name="ends_after"
                                checked={endsType === 'after'}
                                onChange={() => handleEndsTypeChange('after')}
                                disabled={isDoesNotRepeat}
                              />
                              After
                            </label>
                            <div className="w-[57px]">
                              <FormField
                                type="select"
                                name="ends_occurrences"
                                options={Array.from({ length: 10 }, (_, i) => ({
                                  value: i + 1,
                                  label: `${i + 1}`
                                }))}
                                onChangeValue={(value, name) =>
                                  handleSelectChange(
                                    value,
                                    name,
                                    setFieldValue,
                                    setFieldTouched,
                                    setFieldError
                                  )
                                }
                                currentValue={formData.ends_occurrences ?? ''}
                                selectedValue={formData.ends_occurrences ?? ''}
                                selectName="ends_occurrences"
                                disabled={!isOccurrencesEnabled}
                              />
                            </div>
                            <span className="inline-block text-[#414141] font-stolzlBook text-[14px] leading-[14px]">
                              Occurrences
                            </span>
                          </div>

                          {/* <select disabled={!isOccurrencesEnabled}>
                            {Array.from({ length: 10 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select> */}
                        </div>
                      </FormCard>
                    </FormGridCard>
                  </>
                )}
              </div>
            </div>

            <div className="flex mx-[35px] mt-[30px]">
              <div className="text-right mr-[10px]">
                <button
                  type="submit"
                  className="text-[12px] text-white leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                  disabled={isSubmitting}
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Save
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            id={eventsId}
          />
        )}
      </ModalCenter>
    </>
  );
};

export default AddEditModal;
