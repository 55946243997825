import { useCallback, useEffect, useState } from 'react';
import Checkbox from 'src/components/Common/Filter/Checkbox';
import Date from 'src/components/Common/Filter/Date';
// eslint-disable-next-line import/no-named-as-default
import CheckboxMultiSelect from 'src/components/Common/Filter/CheckboxMultiSelect';
import Dropdown from 'src/components/Common/Filter/Dropdown';
import DateYear from 'src/components/Common/Filter/DateYear';
import Select from 'src/components/Common/Select';
import ComboBox from 'src/components/Common/ComboBox';

const useFilter = ({
  form,
  submitFilter,
  isOpen,
  dateOnChange,
  onChangeValue,
  menuCode,
  localQuery
}) => {
  const [searchValue, setSearchValue] = useState('');
  const searchParams = new URLSearchParams(localStorage.getItem(localQuery));

  useEffect(() => {
    setSearchValue(searchParams.get('search'));
  }, []);

  const handleFiltersDisplay = useCallback(
    input => {
      switch (input.type) {
        case 'checkbox': {
          return (
            <Checkbox
              form={form}
              name={input?.name}
              item={input?.options}
              submitFilter={submitFilter}
            />
          );
        }
        case 'multiselect': {
          const statusParams = searchParams.get('status')
            ? searchParams.get('status').split(',')
            : [];

          const statusValue =
            input?.defaultValue && input?.defaultValue.length
              ? input?.defaultValue
              : statusParams || [];

          return (
            <CheckboxMultiSelect
              form={form}
              name={input?.name}
              placeholder={input?.label}
              item={input.multiOptions}
              submitFilter={submitFilter}
              selected={statusValue}
              isOpen={isOpen}
              size={input?.size || ''}
            />
          );
        }
        case 'date-range': {
          const inputOptions = [
            {
              ...input.options[0],
              queryValue: searchParams.get('hired_date_from') || ''
            },
            {
              ...input.options[1],
              queryValue: searchParams.get('hired_date_to') || ''
            }
          ];

          return (
            <Date
              form={form}
              item={inputOptions}
              submitFilter={submitFilter}
              dateOnChange={dateOnChange}
            />
          );
        }
        case 'dropdown': {
          return (
            <Dropdown
              form={form}
              name={input?.name}
              item={input?.options}
              submitFilter={submitFilter}
            />
          );
        }
        case 'select': {
          return (
            <Select
              form={form}
              selectName={input?.name}
              options={input?.options}
              onChangeValue={onChangeValue}
              submitFilter={submitFilter}
            />
          );
        }
        case 'combobox': {
          const defaultOption =
            input?.options.find(
              option => option.value === input?.defaultValue
            ) || input?.options[0];
          return (
            <ComboBox
              form={form}
              name={input?.name}
              datasource={input?.options}
              onChangeValue={onChangeValue}
              defaultValue={defaultOption}
              submitFilter={submitFilter}
              menuCode={menuCode}
              placeholder={input.placeholder}
            />
          );
        }

        default: {
          return null;
        }
      }
    },
    [form, isOpen]
  );

  return {
    handleFiltersDisplay,
    searchValue
  };
};

export default useFilter;
