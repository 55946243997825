import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getDayCode } from 'src/redux/modules/dayCodes/dayCodeActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import {
  deleteDayCodeService,
  getDayCodeByIDService,
  getDayCodeItemsService
} from 'src/api/modules/daycode';

export const useDeleteModal = ({
  handleDeleteModal,
  editModal,
  dayCodeId,
  modal
}) => {
  const dispatch = useDispatch();
  const [dayCodeDetail, setDayCodeDetail] = useState(null);

  const fetchNewUserList = async () => {
    getDayCodeItemsService(1)
      .then(res => {
        dispatch(getDayCode(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (dayCodeId) {
      getDayCodeByIDService(dayCodeId).then(res => {
        setDayCodeDetail(dayCodeDetail);
      });
    }
  }, []);

  const handleDelete = async codeId => {
    if (codeId) {
      const res = await deleteDayCodeService(codeId, 'DELETE');
      if (res.success) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: codeId
        });
        fetchNewUserList();
      } else if (res.response.status === 405)
        toast.error('Unable to delete!', {
          icon: <CgCloseO />,
          toastId: codeId
        });
      handleDeleteModal(modal);
      if (editModal) {
        editModal(null);
      }
    }
  };
  return {
    dayCodeDetail,
    handleDelete
  };
};
