/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link
} from 'react-router-dom';
import defaultPicture from 'src/assets/icons/defaultProfile2.png';
import Input from 'src/components/Common/Input';
import { cn, dateFormat, employeeName, sortData } from 'src/helpers/utils';
import SortingArrows from 'src/components/Common/SortingArrows';
import _ from 'lodash';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { useDispatch } from 'react-redux';
import Pencil from 'src/assets/icons/edit.svg';
import CheckSolid from 'src/assets/icons/check-solid.svg';
import CloseSolid from 'src/assets/icons/xmark-solid.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const useDatatable = (
  datasource,
  headingColumns,
  actions,
  modalName,
  deleteModal,
  createModal,
  keyField,
  pageSize,
  isTemplate,
  shouldDisplayEditable,
  isSelectedValue,
  shouldEllipsis,
  ellipsisModifier,
  noPadding,
  clickableRows,
  link,
  openNewTab,
  breakOn,
  handleModal,
  handleViewModal,
  handleDeleteModal,
  handleCreateModal,
  access,
  isCostCenter,
  codeField,
  actionLinks,
  isSchedule,
  isAnnouncement,
  isHoliday,
  handleUpdateRow,
  handleResetScheduleItem,
  scheduleItem,
  actionLabels,
  disableActions,
  columnFormatter,
  editCurrentId,
  updateSortedData,
  isEvaluationList,
  ellipsisTooltip,
  isEvaluatorsTooltip,
  setSortType,
  scheduleIds,
  viewOnly
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const queryParamsList = Object.fromEntries([...queryParams]);
  const [columns, setColumns] = useState(headingColumns);
  const [editing, setEditing] = useState({ state: false, row: null });
  const [selectedRow, setSelectedRow] = useState(null);
  const [multipleSelectedItems, setMultipleSelectedItems] = useState([]);
  const [multiSelectLabel, setMultiSelectLabel] = useState('');
  const [data, setData] = useState();
  const [paginationKey, setPaginationKey] = useState(1);
  const [order, setOrder] = useState('ASC');
  const [sortColumn, setSortColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState();
  const pageCount = data ? Math.ceil(data.length / pageSize) : 0;
  const pages = _.range(1, pageCount + 1);
  const [isCurrentValue, setIsCurrentValue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const location = useLocation();

  let tableClass = 'text-sm';
  const statusClass = '';
  const status = '';

  switch (breakOn) {
    case 'small':
      tableClass += ' text-sm ';
      break;
    case 'medium':
      tableClass += 'text-base ';
      break;
    default:
      tableClass += 'text-lg ';
      break;
  }

  useEffect(() => {
    setOnLoadColumns();
    const searchParamsObject = { ...queryParamsList };

    if (searchParamsObject.page) {
      setCurrentPage(Number(searchParamsObject.page));
    }

    if (!editCurrentId) handleCloseRow();
    else handleEditRow(editCurrentId);
  }, []);

  useEffect(() => {
    setSelectedIds([]);
  }, [location.search]);

  useEffect(() => {
    setPaginationKey(paginationKey + 1);
    setData(datasource);
    setPaginatedData(_(data).slice(0).take(pageSize).value());

    if (!!datasource?.length || datasource?.length === 0) setLoading(false);
  }, [datasource]);

  useEffect(() => {
    dispatch(setIdSelection(selectedIds));
    if (isSchedule && !selectedIds.length) setSelectedIds(scheduleIds);
  }, [selectedIds]);

  function setOnLoadColumns() {
    if (actions.length) {
      setColumns([...columns, 'actions']);
    }
  }

  if (actions.length) {
    datasource?.map(cell => {
      const actionItems = actions.map((action, i) => {
        // view action
        if (action === 'view') {
          const buttonView = (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              type="button"
              className={cn(
                tableClass,
                'font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5  last:pr-0 last:border-r-0 last:mr-0',
                !access.can_view || disableActions?.view(cell.status)
                  ? '!text-disabled'
                  : ''
              )}
              onClick={() => {
                if (actionLinks) {
                  navigate(`${actionLinks.view}/${cell.id}`);
                } else {
                  handleViewModal(
                    modalName,
                    isCostCenter ? cell[codeField] : cell.id,
                    cell
                  );
                }
              }}
              disabled={!access.can_view || disableActions?.view(cell.status)}
            >
              {action}
            </button>
          );

          const currentLink = actionLinks
            ? `${actionLinks?.view}/${cell.id}`
            : `${location.pathname}/${cell.id}`;

          const linkView = (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              to={currentLink}
              className={cn(
                tableClass,
                'text-gray-400 active:text-gray-400 visited:text-gray-400 hover:text-gray-100 font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5  last:pr-0 last:border-r-0 last:mr-0',
                !access.can_view || disableActions?.view(cell.status)
                  ? '!text-disabled'
                  : ''
              )}
              disabled={!access.can_view || disableActions?.view(cell.status)}
            >
              {action}
            </Link>
          );
          const renderAction = isEvaluationList ? linkView : buttonView;
          return renderAction;
        }
        // edit action
        if (action === 'edit') {
          return (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              type="button"
              className={`${tableClass} font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5  last:pr-0 last:border-r-0 last:mr-0 ${
                !access.can_edit ? '!text-disabled' : ''
              }`}
              onClick={() => {
                if (modalName) {
                  handleModal(
                    modalName,
                    isCostCenter ? cell[codeField] : cell.id
                  );
                }
              }}
              disabled={!access.can_edit}
            >
              {action}
            </button>
          );
        }
        // delete action
        if (action === 'delete') {
          return (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              type="button"
              className={`${tableClass} font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5 last:pr-0 last:border-r-0 last:mr-0 text-[#E56367] hover:text-opacity-50 ${
                !access.can_delete ? '!text-disabled' : ''
              }`}
              onClick={() => {
                if (
                  deleteModal &&
                  !isSchedule &&
                  !isAnnouncement &&
                  !isHoliday
                ) {
                  handleDeleteModal({
                    modal: deleteModal,
                    id: isCostCenter ? cell[codeField] : cell.id,
                    first_name: cell.first_name || null,
                    last_name: cell.last_name || null,
                    suffix: cell.suffix || null,
                    user_group_name: cell.user_group_name || null,
                    cost_center_code: cell.cost_center_code || null,
                    division_code: cell.division_code || null,
                    department_code: cell.department_code || null,
                    section_code: cell.section_code || null,
                    sub_section_code: cell.sub_section_code || null,
                    project_name: cell.project_name || null,
                    name: cell.name || null,
                    day_code: cell.day_code || null,
                    leave_code: cell.leave_code || null
                  });
                }

                if (isSchedule || isAnnouncement || isHoliday) {
                  const { actions: notNeeded, ...cellData } = cell;
                  handleDeleteModal({ modal: deleteModal, ...cellData });
                }
              }}
              disabled={!access.can_delete}
            >
              {action}
            </button>
          );
        }
        // edit row/bulk update action
        if (action === 'editRow' && isSchedule) {
          const key = i;
          const isNew = cell.status === 'N';

          if (editing.state && cell.id === editing.row)
            scheduleItem(cell, handleCloseRow);

          return (
            <div key={key} className="flex items-center justify-end">
              {editing.state && cell.id === editing.row ? (
                <div className="flex items-center gap-x-1">
                  <button
                    type="button"
                    onClick={() => handleUpdateRow(cell, handleCloseRow)}
                    className={cn(
                      'flex items-center justify-center rounded-full h-6 w-6 bg-[#57B14C]'
                      // !someLogicToFalse && 'opacity-50 cursor-not-allowed',
                    )}
                    // disabled={!someLogicToFalse}
                  >
                    <img src={CheckSolid} alt="Update" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleCloseRow();
                      handleResetScheduleItem('onCancel', cell.id);
                    }}
                    className="flex items-center justify-center rounded-full h-6 w-6 bg-[#7F7F80]"
                  >
                    <img src={CloseSolid} alt="Close" />
                  </button>
                </div>
              ) : (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  type="button"
                  onClick={() => handleEditRow(cell.id)}
                  className="ml-auto w-[20px]"
                  disabled={cell.notSelected || viewOnly} // {!isNew || cell.notSelected}
                >
                  <div className="border rounded border-gray-50 p-1">
                    <img
                      src={Pencil}
                      alt="edit"
                      className={cn(
                        cell.notSelected && 'opacity-50 cursor-not-allowed',
                        viewOnly && 'cursor-auto opacity-[.3]' // (!isNew || cell.notSelected)
                      )}
                    />
                  </div>
                </button>
              )}
            </div>
          );
        }
        // custom action
        if (action === 'custom') {
          return (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              type="button"
              className={`${tableClass} hover:opacity-50 font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5  last:pr-0 last:border-r-0 last:mr-0 ${
                !access.can_view ? '!text-disabled' : ''
              }`}
              onClick={() => {
                if (actionLinks && actionLinks.custom) {
                  const customLink = actionLinks.custom
                    .toString()
                    .replace('{id}', cell.id);
                  navigate(customLink);
                }
              }}
              disabled={!access.can_view}
            >
              {actionLabels.custom}
            </button>
          );
        }
        // announcement_custom action
        if (action === 'announcement_custom') {
          if (isAnnouncement && cell.status === 'F') {
            return (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                type="button"
                className={`${tableClass} hover:opacity-50 font-stolzlBook capitalize border-solid border-r-[1px] border-[#232932] pr-1.5 mr-1.5  last:pr-0 last:border-r-0 last:mr-0 text-[#468EFF] ${
                  !access?.can_edit ? '!text-disabled' : ''
                }`}
                onClick={() => {
                  const { actions: notNeeded, ...cellData } = cell;
                  handleModal({ modal: 'RepostModal', ...cellData });
                }}
                disabled={!access?.can_edit}
              >
                {actionLabels.custom}
              </button>
            );
          }
        }

        return undefined;
      });

      // eslint-disable-next-line no-param-reassign, react/jsx-no-useless-fragment
      cell.actions = <>{actionItems}</>;
      return cell;
    });
  }

  const pagination = (pageNumber, source) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * pageSize;
    const paginatedPost = _(source).slice(startIndex).take(pageSize).value();
    setPaginatedData(paginatedPost);
  };

  const sortIcon = (column, key) => {
    if (column === key) {
      return (
        <div className="mx-1 py-1">
          <SortingArrows order={order} />
        </div>
      );
    }
    if (column !== key) {
      return (
        <div>
          <img
            alt="Sorting Icon"
            src="../icons/up-down-arrow.svg"
            className="mx-1 py-1 transition-opacity hover:opacity-50"
          />
        </div>
      );
    }
    return undefined;
  };

  const sorting = (column, pageNumber) => {
    setLoading(true);
    const isDate = column.includes('date');
    if (order === 'ASC') {
      const asc = sortData(data, column, isDate, order);
      if (isSchedule) updateSortedData(asc);
      pagination(pageNumber, asc);
      setOrder('DSC');
    }
    if (order === 'DSC') {
      const desc = sortData(data, column, isDate, order);
      if (isSchedule) updateSortedData(desc);
      pagination(pageNumber, desc);
      setOrder('ASC');
    }
    setSortColumn(column);
    if (isSchedule) {
      setSortType({
        column,
        isDate,
        order
      });
    }
  };

  const cellData = (row, column) => {
    const value = row[column];
    if (column === 'actions' || column === 'delete-actions') {
      return value;
    }
    const isEditing = editing.state && row[keyField] === editing.row;
    const [
      {
        key,
        type,
        readOnly,
        onSelect,
        selectOptions,
        placeholder,
        userIconKey,
        onChangeFn,
        sectionKey,
        lastNameKey,
        deleteKey,
        listType
      }
    ] = headingColumns.filter(col => col.key === column);
    let currentValue;
    const keyId = type ? `${row.id}-${column}-${type}` : `${row.id}-${column}`;

    if (column.startsWith('evaluator_') && row.sectionCount) {
      const [, number] = column.split('_');
      const evalStatus = row[`eval_entry_status_${number}`] || 'N';
      const colorMap = {
        A: '#23B53A',
        F: '#468EFF',
        N: '#e8f1ff'
      };
      const color = colorMap[evalStatus];
      return (
        <div>
          {row[column] !== '-' && (
            <>
              <span
                className="inline-block w-2 h-2 rounded-full"
                style={{ backgroundColor: color }}
              />{' '}
            </>
          )}
          {row[column]}
        </div>
      );
    }

    if (column === 'division_list') {
      return (
        <div className="flex items-center whitespace-nowrap">
          <div>{row.division_list?.division_code || '-'}</div>
        </div>
      );
    }

    if (column === 'department_list') {
      return (
        <div className="flex items-center whitespace-nowrap">
          <div>{row.department_list?.department_code || '-'}</div>
        </div>
      );
    }

    if (column === 'section_list') {
      return (
        <div className="flex items-center whitespace-nowrap">
          <div>{row.section_list?.section_code || '-'}</div>
        </div>
      );
    }

    if (column === 'sub_section_list') {
      return (
        <div className="flex items-center whitespace-nowrap">
          <div>{row.sub_section_list?.sub_section_code || '-'}</div>
        </div>
      );
    }

    if (column === 'cost_center_code') {
      const sectionName = row.cost_center_tree
        ? row.cost_center_tree[row.cost_center_tree.length - 1]
        : null;
      return (
        <div className="flex items-center whitespace-nowrap">
          <div>
            {value ? `${value} ${sectionName ? `/ ${sectionName}` : ''}` : '-'}
          </div>
        </div>
      );
    }

    if (column === 'shift_code') {
      const shiftStatus = row.is_default ? 'D' : 'N';
      const colorMap = {
        D: '#468EFF',
        N: '#e8f1ff'
      };
      const color = colorMap[shiftStatus];
      return (
        <>
          <span
            className="inline-block w-3 h-3 rounded-full mr-[5px]"
            style={{ backgroundColor: color }}
          />
          {row[column]}
        </>
      );
    }

    if (column === 'isUser') {
      const userStatus = row.user?.status;
      const isUser = row.user?.id;
      let displayText = 'Pending';
      if (userStatus === 'N') {
        displayText = 'Yes';
      }

      return (
        <div className="flex items-center whitespace-nowrap">
          <div
            className={`${!access.can_add ? 'text-disabled' : 'text-input'}`}
          >
            {(() => {
              if (isUser) {
                return userStatus === 'N' ? 'Pending' : 'Yes';
              }
              return (
                <>
                  <span>No </span>
                  {createModal && (
                    <button
                      type="button"
                      onClick={() => {
                        if (createModal) {
                          handleCreateModal(
                            createModal,
                            row.id,
                            row.first_name || null,
                            row.last_name || null,
                            row.suffix || null
                          );
                        }
                      }}
                      disabled={!access.can_add}
                    >
                      <span
                        className={`${
                          !access.can_add ? 'text-disabled' : 'text-[#458FFF]'
                        } ${
                          access.can_add &&
                          'hover:underline hover:text-opacity-50'
                        }`}
                      >
                        (Create User)
                      </span>
                    </button>
                  )}
                </>
              );
            })()}
          </div>
        </div>
      );
    }

    if ((isEditing && !readOnly) || (shouldDisplayEditable && !readOnly)) {
      const isTemplateActive =
        isSchedule && 'is_template_active' in row && row.is_template_active;

      return (
        <div
          className={cn(
            'sort-table__container-edit',
            isSchedule && 'w-[180px]'
          )}
        >
          {userIconKey ? (
            <div className="sort-table__container-image">
              <img
                className="sort-table__container-image-data"
                src={row[userIconKey] || defaultPicture}
                alt="User Icon"
              />
            </div>
          ) : null}

          <Input
            id={keyId}
            value={value}
            currentValue={value}
            type={type}
            listType={listType}
            placeholder={placeholder}
            selectOptions={selectOptions}
            isTemplate={isTemplate}
            onSelect={selectData => {
              if (type === 'templateMultiSelect') {
                if (selectData.selected) {
                  setMultipleSelectedItems(item => {
                    return [...item, selectData];
                  });
                  return;
                }

                if (!selectData.selected) {
                  const selectedItemIndex = multipleSelectedItems.findIndex(
                    item => item.value === selectData.value
                  );
                  setMultipleSelectedItems(
                    multipleSelectedItems.splice(1, selectedItemIndex)
                  );
                  return;
                }
                return;
              }

              setSelectedRow(selectRow => {
                return {
                  ...selectRow,
                  [column]: selectData
                };
              });
            }}
            onChange={onChangeValue => {
              if (!['select', 'combobox'].includes(type)) return;
              const dataIndex = datasource.findIndex(
                dataFind => dataFind.id === row.id
              );
              const updatedDatasource = { ...datasource };
              if (updatedDatasource[dataIndex]) {
                updatedDatasource[dataIndex][column] = onChangeValue;
              }

              onChangeFn({
                data: updatedDatasource[dataIndex],
                selectOptions,
                key
              });

              if (isSelectedValue) {
                setIsCurrentValue(true);
              }
            }}
            getLabel={setMultiSelectLabel}
            isTemplateActive={isTemplateActive}
          />
        </div>
      );
    }

    switch (type) {
      case 'date':
        return dateFormat(value);
      case 'multiselect':
        return multiSelectLabel || value;
      case 'select':
        return selectOptions.find(x => x.value === value)?.label || value;
      default: {
        const { first_name, middle_name, last_name, suffix, name } = row;
        const fullName = { first_name, middle_name, last_name, suffix };
        const hasFirstLastName = Boolean(first_name || last_name);
        const employee_name = hasFirstLastName ? employeeName(fullName) : name;
        if (!userIconKey) {
          if (!value || !value.length) return '-';
          return value.toString();
        }
        return (
          <div
            className={`${
              !shouldEllipsis ? 'whitespace-nowrap' : ''
            } flex items-center py-[11.5px]`}
          >
            <div>
              <img
                className="w-11 h-11 rounded-full object-cover max-w-none"
                src={row[userIconKey] || defaultPicture}
                alt="User Icon"
              />
            </div>
            <div
              className={`${
                shouldEllipsis
                  ? 'overflow-hidden text-ellipsis line-clamp-1'
                  : ''
              } ${
                column === 'first_name'
                  ? 'overflow-hidden text-ellipsis max-w-[180px]'
                  : ''
              } ml-[15px] capitalize`}
            >
              {employee_name}
            </div>
          </div>
        );
      }
    }
  };

  const rowClick = useCallback(
    key => {
      if (clickableRows && !_.isEmpty(key.toString())) {
        if (openNewTab) window.open(`${link}/${key}`, '_blank');
        else navigate(`${link}/${key}`);
      }
    },
    [clickableRows, link, openNewTab, navigate]
  );

  const handleCheckAll = event => {
    const isChecked = event.target.checked;
    // if (isChecked) {
    const allUserIds = isChecked ? datasource.map(row => row.id) : [];
    setSelectedIds(allUserIds);
    // } else {
    //   setSelectedIds([]);
    // }
  };

  const handleSingleCheck = idParam => {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(idParam)) {
        return prevSelectedIds.filter(id => id !== idParam);
      }
      return [...prevSelectedIds, idParam];
    });
  };

  const handleEditRow = id => {
    setEditing(prevState => ({
      ...prevState,
      state: true,
      row: Number(id)
    }));
  };

  function handleCloseRow() {
    setEditing(prevState => ({
      ...prevState,
      state: false,
      row: null
    }));
  }

  const statusData = useCallback(statusValue => {
    let badgeClassName = '';
    let badgeText = '';

    switch (statusValue) {
      case 'A':
        badgeClassName = 'bg-[#23B53A] text-white';
        badgeText = 'Active';
        break;
      case 'C':
        badgeClassName = 'bg-[#DE5858] text-white';
        badgeText = 'Cancelled';
        break;
      case 'F':
        badgeClassName = 'bg-[#468EFF] text-white';
        badgeText = 'Fulfilled';
        break;
      case 'N':
        badgeClassName = 'bg-[#e8f1ff] text-[#232932]';
        badgeText = 'New';
        break;
      case 'O':
        badgeClassName = 'bg-[#F2CC62] text-[#232932]';
        badgeText = 'Onhold';
        break;
      default:
        badgeClassName = 'bg-[#E8EAED] text-white';
        badgeText = '';
    }

    return (
      <div
        className={`rounded-full text-center text-[12px] leading-[100%] h-[26px] w-[84px] inline-flex items-center justify-center ${badgeClassName}`}
      >
        {badgeText}
      </div>
    );
  }, []);

  const generateUserType = useCallback(rowUserType => {
    let roleString = '';

    if (rowUserType.is_system_admin) {
      roleString += 'System Admin';
    } else {
      roleString = '-';
    }

    return (
      <div className="overflow-hidden text-ellipsis line-clamp-1 font-stolzlBook">
        {roleString}
      </div>
    );
  }, []);

  const renderColorCell = useCallback(
    color => (
      <div
        style={{
          backgroundColor: color,
          width: '20px',
          height: '20px',
          borderRadius: '4px'
        }}
      />
    ),
    []
  );

  const renderCol = useCallback(
    (rowInput, col) => {
      const value = cellData(rowInput, col); // Assuming this is a function that gets the cell data

      // Check for custom column formatting
      if (columnFormatter && typeof columnFormatter[col] !== 'undefined') {
        const formatter = columnFormatter[col];
        return formatter(rowInput[col]);
      }

      // Handle ellipsis and tooltip display
      if (col !== 'actions') {
        if (shouldEllipsis && !isSchedule) {
          return (
            <div
              className={cn(
                'text-ellipsis line-clamp-1 font-stolzlBook -mt-[3px]',
                ellipsisModifier && `${ellipsisModifier}`,
                ellipsisTooltip && `id-${rowInput.id}-${col}`
              )}
            >
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(!!ellipsisTooltip &&
                  ([...ellipsisTooltip].includes(col) ||
                    (isEvaluatorsTooltip && col.startsWith('evaluator_'))) && {
                    'data-tooltip-id': `tooltip-${rowInput.id}`,
                    'data-tooltip-content': value,
                    'data-id': `${col}-${rowInput.id}`
                  })}
              >
                {value}
              </span>
              {!!ellipsisTooltip && (
                <ReactTooltip
                  className="max-w-[450px] !bg-white !text-[#222222] !text-[12px] border-[1px] border-gray-600 !font-stolzlBook drop-shadow-sm"
                  id={`tooltip-${rowInput.id}`}
                />
              )}
            </div>
          );
        }

        // Check if the schedule is active and handle editable states
        if (isSchedule) {
          if (editing.state) {
            return <div className="font-stolzlBook">{value}</div>;
          }

          if (shouldDisplayEditable) {
            if (col === 'name') {
              return (
                <div
                  className={cn(
                    'text-ellipsis line-clamp-1 font-stolzlBook -mt-[3px]',
                    ellipsisModifier && `${ellipsisModifier}`
                  )}
                >
                  {value}
                </div>
              );
            }

            return <div className="font-stolzlBook max-w-[180px]">{value}</div>;
          }

          return (
            <div
              className={cn(
                'overflow-hidden text-ellipsis line-clamp-1 font-stolzlBook -mt-[3px]',
                ellipsisModifier && `${ellipsisModifier}`
              )}
            >
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(!!ellipsisTooltip &&
                  ([...ellipsisTooltip].includes(col) ||
                    (isEvaluatorsTooltip && col.startsWith('evaluator_'))) && {
                    'data-tooltip-id': `tooltip-${rowInput.id}`,
                    'data-tooltip-content': value,
                    'data-id': `${col}-${rowInput.id}`
                  })}
              >
                {value}
              </span>
              {!!ellipsisTooltip && (
                <ReactTooltip
                  className="max-w-[450px] !bg-white !text-[#222222] !text-[12px] border-[1px] border-gray-600 !font-stolzlBook drop-shadow-sm"
                  id={`tooltip-${rowInput.id}`}
                />
              )}
            </div>
          );
        }

        return <div className="font-stolzlBook">{value}</div>;
      }

      return <div className="whitespace-nowrap text-right">{value}</div>;
    },
    [
      columnFormatter,
      shouldEllipsis,
      isSchedule,
      editing.state,
      shouldDisplayEditable,
      ellipsisModifier,
      ellipsisTooltip,
      isEvaluatorsTooltip,
      selectedIds,
      viewOnly
    ]
  );

  const rowData = useMemo(() => {
    if (data?.length === 0) {
      return (
        <tr>
          <td colSpan="100%" />
        </tr>
      );
    }

    if (loading && isSchedule) {
      return (
        <tr>
          <td
            colSpan="100%"
            style={{
              textAlign: 'center'
            }}
          >
            <p>Loading...</p>
          </td>
        </tr>
      );
    }

    const columnSequence = columns.map(column => column.key);

    const res = data?.map((row, srcIndex) => {
      const cols = [];
      const isEditing = editing.state && row[keyField] === editing.row;
      Object.entries(row).forEach(([key]) => {
        if (columns.find(e => (e.key || e) === key)) {
          if (columns.includes(key)) {
            columnSequence.push(key);
          }
          cols.push(key);
        }
      });

      cols.sort(
        (a, b) => columnSequence.indexOf(a) - columnSequence.indexOf(b)
      );

      const isChecked = selectedIds.includes(row.id);

      return (
        <Fragment key={`${row.id}}`}>
          <tr
            className={cn(
              'border border-t-0 border-l-2 border-r-2 border-l-[transparent] border-r-[transparent] border-b-[#DEDEDE] rounded-[4px] border-separate last:border-b-0',
              (isChecked || isEditing) && 'bg-[#F8F8F8]'
            )}
            onClick={event => {
              const tagname = event.target.tagName.toLowerCase();
              if (shouldDisplayEditable && clickableRows && tagname === 'td')
                rowClick(row[keyField] ?? srcIndex);
              if (!shouldDisplayEditable && clickableRows)
                rowClick(row[keyField] ?? srcIndex);
            }}
          >
            {cols.map((col, colIndex) => {
              if (col === 'id')
                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                    className="pl-[15px] text-[14px] w-[0px]"
                  >
                    <label
                      className={cn(
                        'custom__checkbox inline-block align-middle text-[0] cursor-pointer',
                        access.can_delete && 'hover:opacity-50',
                        viewOnly && 'cursor-default opacity-[.5]'
                      )}
                      htmlFor={row.id}
                    >
                      <input
                        checked={isChecked}
                        type="checkbox"
                        id={row.id}
                        disabled={
                          (!access.can_delete &&
                            !access.can_print &&
                            !access.can_generate) ||
                          viewOnly
                        }
                        hidden
                        onChange={() => handleSingleCheck(row.id)}
                      />
                      <span
                        className={cn(
                          'inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded-[4px] ease duration-200 relative transition-opacity',
                          !access.can_delete &&
                            !access.can_print &&
                            !access.can_generate &&
                            'cursor-not-allowed'
                        )}
                      >
                        <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded-md" />
                        <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded-md" />
                      </span>
                    </label>
                  </td>
                );
              if (col === 'status')
                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                    className={`${
                      !noPadding ? 'pt-[5px] pb-[5px]' : ''
                    } px-[15px] first:pl-[29px] last:pr-[29px] text-center h-[35px]`}
                  >
                    {statusData(row.status)}
                  </td>
                );

              if (col === 'is_system_admin') {
                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                    className={`${
                      !noPadding ? 'pt-[5px] pb-[5px]' : ''
                    } pr-[15px] first:pl-[29px]  last:pr-[29px] text-[14px] h-[35px]`}
                  >
                    {generateUserType(row)}
                  </td>
                );
              }
              if (col === 'color') {
                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                  >
                    {renderColorCell(row.color)}
                  </td>
                );
              }
              return (
                <td
                  // eslint-disable-next-line react/no-array-index-key
                  key={colIndex}
                  className={`${
                    !noPadding ? 'pt-[5px] pb-[5px]' : ''
                  } px-[0px] first:pl-[29px] last:pr-[13px] text-[14px] h-[35px]`}
                >
                  {renderCol(row, col)}
                </td>
              );
            })}
          </tr>
        </Fragment>
      );
    });
    return res;
  }, [data, editing, order, selectedIds, viewOnly]);

  return {
    columns,
    editing,
    selectedRow,
    multipleSelectedItems,
    multiSelectLabel,
    data,
    paginationKey,
    order,
    currentPage,
    paginatedData,
    pageCount,
    pages,
    isCurrentValue,
    loading,
    tableClass,
    statusClass,
    status,
    sortColumn,
    selectedIds,
    sortIcon,
    setEditing,
    setSelectedRow,
    setMultipleSelectedItems,
    setMultiSelectLabel,
    setData,
    setPaginationKey,
    setOrder,
    setCurrentPage,
    setPaginatedData,
    setIsCurrentValue,
    setOnLoadColumns,
    pagination,
    sorting,
    cellData,
    rowData,
    rowClick,
    handleCheckAll
  };
};
