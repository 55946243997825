import PageTitle from 'src/components/Common/PageTitle';
import Filter from 'src/components/Common/Filter/Filter';
import SlideModal from 'src/components/Common/SlideModal';
import Datatable from 'src/components/Common/Datatable';
import AddEditModal from 'src/components/Parts/Employee/AddEditModal';
import ViewModal from 'src/components/Parts/Employee/ViewModal';
import ModalCenter from 'src/components/Common/ModalCenter';
import DeleteModal from 'src/components/Parts/Employee/DeleteModal';
import BulkDeleteModal from 'src/components/Parts/User/BulkDeleteModal';
import CreateModal from 'src/components/Parts/Employee/CreateModal';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import { EMPLOYEE_BULK } from 'src/api/endpoints';

import InfiniteScroll from 'react-infinite-scroll-component';
import useEmployeeInformation from 'src/hooks/Pages/admin/employee/useEmployeeInformation';
import PropTypes from 'prop-types';

const EmployeeInformation = ({ menuCode }) => {
  const {
    employeeListMemo,
    showModal,
    showDeleteModal,
    showBulkDeleteModal,
    showViewModal,
    showCreateModal,
    employeeId,
    userData,
    employeeName,
    inputs,
    form,
    hasMore,
    employeeColumns,
    isOpen,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    handleCreateModal,
    fetchEmployee,
    submitFilter
  } = useEmployeeInformation();
  const { menus } = useUserAccess();

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Resource' }, { name: 'Employee Information' }]}
      />
      <div className="pt-2">
        <div className="filter__content--search capitalize">
          <Filter
            searchInputPlaceholder="ID Number, Name, Cost Center, Project"
            buttonName="New Employee"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleViewModal('View')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
          />
          <InfiniteScroll
            dataLength={employeeListMemo?.length ?? 0}
            next={fetchEmployee}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {employeeListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
            style={{ overflow: 'visible' }}
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={employeeListMemo || []}
              clickableRows={false}
              headingColumns={employeeColumns}
              title="Employee Information"
              actions={['view', 'delete']}
              showModal={showModal}
              showViewModal={showViewModal}
              handleModal={handleModal}
              handleViewModal={handleViewModal}
              handleDeleteModal={handleDeleteModal}
              handleCreateModal={handleCreateModal}
              modalName="View"
              deleteModal="DeleteModal"
              createModal="CreateModal"
              keyField="id"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>

      <SlideModal
        showModal={showViewModal}
        modalName="View"
        handleModal={handleViewModal}
      >
        {showViewModal && (
          <AddEditModal
            handleModal={handleViewModal}
            id={employeeId}
            menuCode={menuCode}
            userData={userData}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            id={employeeId}
            name={employeeName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showCreateModal} modalName="CreateModal">
        {showCreateModal && (
          <CreateModal
            handleCreateModal={handleCreateModal}
            id={employeeId}
            name={employeeName}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={EMPLOYEE_BULK}
          />
        )}
      </ModalCenter>
    </div>
  );
};

EmployeeInformation.propTypes = {
  menuCode: PropTypes.string
};
export default EmployeeInformation;
