import { TIME_RECORDS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getEmployeeTimeRecords = async searchParams => {
  try {
    let endpoint = `${TIME_RECORDS}/getemployeetimerecords`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();

      endpoint += `?${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getEmployeeProjects = async employee_id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TIME_RECORDS}/employee_project/${employee_id}`
    });
  } catch (error) {
    return error;
  }
};

export const setClockInRecord = async searchParams => {
  try {
    let endpoint = `${TIME_RECORDS}/clockin`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();

      endpoint += `?${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'POST',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const setClockOutRecord = async searchParams => {
  try {
    let endpoint = `${TIME_RECORDS}/clockout`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();

      endpoint += `?${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'PUT',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getClockInInfo = async searchParams => {
  try {
    let endpoint = `${TIME_RECORDS}/can-clock-in`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();

      endpoint += `?${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};
