/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getHolidayService,
  getHolidayItemsService,
  deleteHolidayService
} from 'src/api/modules/holidays';
import { getLowerCasedValue, autoCapitalize } from 'src/helpers/utils';
import { TOAST_OPTIONS } from 'src/helpers/constants';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { getHolidays } from 'src/redux/modules/calendar/holidayActions';

const useHolidays = () => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const [showModal, setShowModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showViewModal, setShowViewModal] = useState(null);
  const [holidayId, setHolidayId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });
  const [holidayList, setHolidayList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const [page, setPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const holidayFlag = useSelector(state => state?.holiday?.holidayList);

  const holidaysColumns = [
    { key: 'id', label: 'ID' },
    { key: 'holiday_name', label: 'Holiday Name' },
    { key: 'holiday_date', label: 'Date' },
    { key: 'day_code', label: 'Day Code' },
    { key: 'is_fixed', label: 'Recurring' },
    { key: 'color', label: 'Color' }
  ];

  const generateFiscalYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const fiscalYearOptions = [];

    fiscalYearOptions.push({
      label: `${currentYear - 1}`,
      value: `${currentYear - 1}`
    });

    for (let i = 0; i <= 10; i++) {
      fiscalYearOptions.push({
        label: `${currentYear + i}`,
        value: `${currentYear + i}`
      });
    }

    return fiscalYearOptions;
  };

  const inputs = [
    {
      label: '',
      type: 'combobox',
      options: [{ label: 'All', value: '' }, ...generateFiscalYearOptions()],
      name: 'year',
      placeholder: 'Select Year'
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-holidays-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-holidays-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getHolidayItemsService(
        savedPageIndex,
        savedSearchParams
      );
      if (mounted.current) {
        const newList =
          savedPageIndex === 1
            ? res.data.items
            : [...holidayList, ...res.data.items];

        setHolidayList(
          newList.map(o => ({
            id: o.id,
            holiday_name: autoCapitalize(o.holiday_name),
            holiday_date: o.holiday_date,
            day_code: o.day_code?.day_code ?? o.day_code,
            day_code_id: o.day_code_id,
            is_fixed: o.is_fixed ? 'Yes' : 'No',
            color: o.color,
            description: o.description
          }))
        );

        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      console.error('Error fetching data:');
    }
  }, [
    page,
    getHolidayService,
    setHolidayList,
    setPage,
    setHasMore,
    holidayList
  ]);

  const holidayListMemo = useMemo(() => {
    return holidayList ?? [];
  }, [holidayList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    localStorage.setItem('hris-holidays-params', searchParams);
  };

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setHolidayId(modal ? id : null);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setHolidayId]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, holiday_name }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, holiday_name });
      setShowDeleteModal(modal);
    },
    [showDeleteModal]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteHolidayService(id);
      if (response?.success) {
        dispatch(getHolidays());
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        dispatch(getHolidays());
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }
    setShowDeleteModal(null);
  };

  const handleChangeFilter = useCallback(
    (value, name) => {
      form.setValue(name, value);
      const updatedValues = form.getValues();

      const fullUrl = window.location.href;
      const url = new URL(fullUrl);

      const searchParams = new URLSearchParams(url.search);
      const searchParamsObject = Object.fromEntries(searchParams);

      const updatedSearchParams = {
        ...searchParamsObject,
        [name]: value === '[object Object]' ? 'all' : value
      };

      Object.keys(updatedSearchParams).forEach(key => {
        if (
          updatedSearchParams[key] === undefined ||
          updatedSearchParams[key] === null ||
          updatedSearchParams[key] === ''
        ) {
          delete updatedSearchParams[key];
        }
      });

      pushQuery(updatedSearchParams);
    },
    [form, pushQuery]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-holidays-params');
    const savedPageIndex = localStorage.getItem('hris-holidays-page-no');
    if (savedPageIndex) {
      setPage(1);
      localStorage.setItem('hris-holidays-page-no', JSON.stringify(1));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-holidays-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      pushQuery({});
      fetchData();
    } else {
      form.reset({});
      pushQuery({});
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-holidays-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, holidayFlag]);

  useEffect(() => {
    localStorage.setItem('hris-holidays-page-no', JSON.stringify(page));
  }, [page]);

  return {
    holidaysColumns,
    isOpen,
    hasMore,
    showModal,
    form,
    inputs,
    showDeleteModal,
    showBulkDeleteModal,
    holidayId,
    holidayListMemo,
    holidayList,
    deleteId,
    fetchData,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    handleDeleteModal,
    handleDelete,
    handleChangeFilter
  };
};

export default useHolidays;
