import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { getLeaveTypes } from 'src/redux/modules/LeaveTypes/LeaveTypesActions';
import { getLeaveTypeDataItems } from 'src/api/modules/leaveType';
import { getShiftsServiceByPage } from 'src/api/modules/shifts';
import { getShifts } from 'src/redux/modules/shifts/shiftsActions';

export const useBulkDeleteModal = ({
  handleBulkDeleteModal,
  apiService,
  endpoint,
  codeType
}) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);

  const fetchLeaveTypeList = async () => {
    getLeaveTypeDataItems(1)
      .then(res => {
        dispatch(getLeaveTypes(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const fetchShiftList = async () => {
    getShiftsServiceByPage(1)
      .then(res => {
        dispatch(getShifts(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await apiService(ids, endpoint);
      if (res.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
        dispatch(setIdSelection([]));
        fetchLeaveTypeList();
        fetchShiftList();
      }
      if (res.status === 405) {
        toast.error('Unable to delete!', {
          icon: <MdOutlineCancel />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete,
    ids
  };
};
