/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { cn } from 'src/helpers/utils';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormGridCard from 'src/components/Common/FormGridCard';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import Loading from 'src/components/Common/loading';
import { useAddEditModal } from 'src/hooks/components/Parts/Announcements/useAddEditModal';
import EditorIndex from './Editor/index';

const AddEditModal = ({ handleModal, id, dataItems, modalTitle, action }) => {
  const {
    formData,
    formLoading,
    validationSchema,
    currentDate,
    errorContent,
    handleChange,
    handleSubmit
  } = useAddEditModal({ handleModal, id, dataItems, action });

  return (
    <>
      <Formik
        enableReinitialize={!!id}
        validateOnMount={!!id}
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          setFieldError
        }) => (
          <Form id="postForm">
            <div className="flex flex-row place-content-between mx-[35px] border-solid border-b-[1px] border-[#eaeaea] mb-[21px]">
              <h4 className="text-[22px] w-[394px] font-bold leading-[27px] pb-5">
                {modalTitle}
              </h4>
            </div>
            <div className="w-full">
              {formLoading && <Loading />}
              <div className="px-[35px] mt-5">
                <FormGridCard cols="1">
                  <FormCard>
                    <FormField
                      className="capitalize"
                      label="Title"
                      name="title"
                      type="text"
                      required
                      placeholder=""
                      value={formData?.title ?? ''}
                      error={errors.title && touched.title}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                    />
                    {errors.title !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.title}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>
                <FormGridCard cols="2">
                  <FormCard>
                    <FormField
                      label="Start Date"
                      name="start_date"
                      value={formData?.start_date ?? ''}
                      type="date"
                      required
                      min={currentDate}
                      error={errors?.start_date && touched.start_date}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                    />
                    {errors.start_date !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.start_date}
                      </div>
                    )}
                  </FormCard>
                  <FormCard>
                    <FormField
                      label="Expire On"
                      name="expire_on"
                      value={formData?.expire_on ?? ''}
                      type="date"
                      required
                      min={currentDate}
                      error={errors?.expire_on && touched.expire_on}
                      onChange={e =>
                        handleChange(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                    />
                    {errors.expire_on !== 'Required' && (
                      <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                        {errors.expire_on}
                      </div>
                    )}
                  </FormCard>
                </FormGridCard>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {/* Rich text content */}
      <div className="px-[35px]">
        <div>
          <label
            className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]"
            htmlFor="content"
          >
            Content <span className="text-[#E43B26]">*</span>
          </label>
          <div
            className={cn(
              'border-solid border-[1px] border-[#eaeaea] rounded bg-white p-[15px] overflow-auto h-[45vh]',
              errorContent && 'border-[#E43B26]'
            )}
            id="content"
          >
            <EditorIndex
              contentData={dataItems?.content ?? {}}
              isEdit
              holder={id ? 'js-editor-edit' : 'js-editor'}
              id={id}
            />
          </div>
        </div>
        <div className="mt-[30px]">
          <button
            form="postForm"
            type="submit"
            disabled={formLoading}
            className={` ${
              formLoading && 'opacity-50'
            } text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_40px] rounded`}
          >
            <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
              Save
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

AddEditModal.propTypes = {
  handleModal: PropTypes.func,
  id: PropTypes.number,
  dataItems: PropTypes.instanceOf(Object),
  modalTitle: PropTypes.string,
  action: PropTypes.string
};
export default AddEditModal;
