/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import FormField from 'src/components/Common/FormField';

// Hooks
import useEvaluationTemplate from 'src/hooks/EvaluationTemplate/useEvaluationTemplate';

const TemplatePart = ({
  templateId,
  handleChange,
  handSelectChange,
  handleTemplateSubmit,
  templateFields,
  totalWeights,
  costCenterOptions,
  salaryGrades,
  viewOnly,
  menuCode,
  approvalError,
  formErrors,
  isTemplateNameExist,
  initialDummyData
}) => {
  const { validationTemplateSchema, statusOptions } = useEvaluationTemplate();
  const { access } = useUserAccess(menuCode);
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';

  return (
    <Formik
      validateOnMount
      initialValues={templateId ? initialDummyData : templateFields}
      validationSchema={validationTemplateSchema}
      onSubmit={() => handleTemplateSubmit(templateId)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="evaluationTemplateForm">
          {approvalError && (
            <div className="error-message">{approvalError}</div>
          )}
          <div className="border-solid border-[1px] border-[#dedede] p-[18px] rounded-[3px] mb-5">
            <div className="flex">
              <div className="w-[1120px] max-w-[100%]">
                <div className="flex gap-4 mb-2">
                  {/* Start Column 1 */}
                  <div className="flex basis-1/2">
                    <div className="flex flex-col w-full gap-3">
                      <div>
                        <FormField
                          label="Template Name"
                          required
                          name="template_name"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={templateFields.template_name ?? ''}
                          error={errors.template_name && touched.template_name}
                          onChange={e =>
                            handleChange(
                              e,
                              'template',
                              null,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          disabled={viewOnly}
                        />
                        {isTemplateNameExist && (
                          <div className="absolute text-[10px] text-[#E43B26] font-stolzlBook">
                            Template name already exists
                          </div>
                        )}
                      </div>
                      <div className="flex w-full gap-4">
                        <div className="basis-1/2">
                          {templateId ? (
                            <FormField
                              label="Cost Center"
                              type={
                                templateFields?.cost_center_code
                                  ? 'combobox'
                                  : 'select'
                              }
                              listType="costcenter"
                              required
                              error={
                                errors?.cost_center_code &&
                                touched?.cost_center_code
                              }
                              placeholder="Select Cost Center"
                              options={costCenterOptions}
                              selectName="cost_center_code"
                              selectedValue={
                                templateFields?.cost_center_code ?? ''
                              }
                              onChangeValue={value =>
                                handSelectChange(
                                  value,
                                  'cost_center_code',
                                  {
                                    part: 'template'
                                  },
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              disabled={viewOnly}
                            />
                          ) : (
                            <FormField
                              label="Cost Center"
                              type="combobox"
                              listType="costcenter"
                              required
                              error={
                                errors?.cost_center_code &&
                                touched?.cost_center_code
                              }
                              placeholder="Select Cost Center"
                              options={costCenterOptions}
                              selectName="cost_center_code"
                              selectedValue={
                                templateFields?.cost_center_code ?? ''
                              }
                              onChangeValue={value =>
                                handSelectChange(
                                  value,
                                  'cost_center_code',
                                  {
                                    part: 'template'
                                  },
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              disabled={viewOnly}
                            />
                          )}
                        </div>
                        <div className="basis-1/2">
                          <FormField
                            label="Status"
                            type="select"
                            className="w-full"
                            options={statusOptions}
                            selectedValue={
                              templateFields?.template_status ?? 'N'
                            }
                            selectName="STATUS_OPTIONS"
                            errors={errors?.templateFields?.template_status}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End column 1 */}
                  {/* Start column 2 */}
                  <div className="flex basis-1/2">
                    <div className="w-full">
                      <FormField
                        className="min-h-[111px]"
                        label="Template Description"
                        required
                        name="template_description"
                        type="textarea"
                        placeholder=""
                        errorMessage="Field Required"
                        value={templateFields?.template_description ?? ''}
                        error={
                          errors.template_description &&
                          touched.template_description
                        }
                        max="255"
                        onChange={e =>
                          handleChange(
                            e,
                            'template',
                            null,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        disabled={viewOnly}
                      />
                    </div>
                  </div>
                  {/* End column 2 */}
                </div>
                <div className="flex">
                  {templateId ? (
                    <div className="flex items-end">
                      <label className="block text-[14px] text-[#232932] font-normal font-stolzlBook leading-6 w-[131px] py-2.5">
                        Total Weight
                      </label>
                      <div className="block w-[calc(100%-130px)]">
                        <div className="flex gap-2">
                          {salaryGrades.map(data => (
                            <div key={data.type}>
                              <div className="block w-20">
                                <span className="block text-[14px] text-[#222222] font-stolzlBook leading-6 text-center">
                                  {data.type}
                                </span>
                                <Field
                                  className={`${classDisabled} block w-full border-solid border-[1px] border-[#e0e0e0] rounded text-[14px] text-[#bcbcbc] font-normal font-stolzlBook leading-6 text-center h-10 px-2`}
                                  type="text"
                                  name="total_weight"
                                  value={`${Math.min(
                                    totalWeights[data.id] || 0,
                                    100
                                  )}%`}
                                  disabled
                                />
                              </div>
                              {formErrors[`weight_error_${data.id}`] ? (
                                <div className="text-[14px] text-[#FC5252] leading-[24px] mb-[20px] flex font-stolzlBook">
                                  <img
                                    src="./icons/error_icn.png"
                                    alt=""
                                    className="w-[17px] h-[17px] mr-[5px] mt-[2px]"
                                  />
                                  <p>Total weight not equal to 100%!</p>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!viewOnly ? (
                    <div className="self-end ml-auto">
                      <Button
                        name="Save"
                        modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[110px] hover:bg-gray-500"
                        spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          (templateId && !access.can_edit) ||
                          !access.can_add ||
                          templateFields.isSaveDisabled
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TemplatePart;
