import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { getAllHolidayEventService } from 'src/api/modules/events';

const useEvents = () => {
  const [eventsList, setEventsList] = useState([]);

  function addOneDay(dateString) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const formattedDate = date.toISOString().slice(0, 10);
    return formattedDate;
  }

  const getAllHolidayEvents = async () => {
    try {
      const res = await getAllHolidayEventService();
      const newList = res.data ?? [];
      setEventsList(
        newList.map(o => {
          const newDate =
            o.endDate !== o.startDate ? addOneDay(o.endDate) : o.endDate;
          return {
            title: o.name,
            start: o.startDate,
            end: newDate,
            color: o.color
          };
        })
      );
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  useEffect(() => {
    getAllHolidayEvents();
  }, []);

  return {
    eventsList
  };
};

export default useEvents;
