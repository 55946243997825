import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getEventsItemsService } from 'src/api/modules/events';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

const useCompanyEvents = () => {
  const mounted = useRef(true);
  const [showModal, setShowModal] = useState(null);
  const [eventsId, setEventsId] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const currentSearchParams = useRef({});
  const [eventsList, setEventsList] = useState([]);
  const eventsFlag = useSelector(state => state?.events?.eventsList);

  const eventsColumn = [
    { key: 'id', label: 'ID' },
    { key: 'event_name', label: 'Event Name' },
    { key: 'start_date', label: 'From' },
    { key: 'end_date', label: 'To' },
    { key: 'occurrence', label: 'Occurrence' },
    { key: 'color', label: 'Color' }
  ];

  const inputs = [
    {
      type: 'date-year',
      options: [
        {
          name: 'year'
        }
      ]
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-events-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-events-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getEventsItemsService(
        savedPageIndex,
        savedSearchParams
      );

      if (mounted.current) {
        const newList =
          savedPageIndex === 1
            ? res.data.items
            : [...eventsList, ...res.data.items];

        setEventsList(
          newList.map(o => ({
            id: o.id,
            event_name: o.event_name,
            end_date: o.end_date,
            description: o.description,
            color: o.color
          }))
        );

        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getEventsItemsService,
    setEventsList,
    setPage,
    setHasMore,
    eventsList
  ]);

  const eventsListMemo = useMemo(() => {
    console.log(eventsList);
    return eventsList ?? [];
  }, [eventsList]);

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setEventsId(modal ? id : null);

      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setEventsId]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;

      // Check for null or empty values
      if (value === null || value === '') {
        delete searchParamsObject[key];
      } else if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: value });
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-employee-search-params', searchParams);
  };

  const removeSearchParam = paramName => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
  };

  const detectDateOnChange = e => {
    const { name, value } = e.target;
    if (Object.keys(currentSearchParams.current).length === 1 && value === '') {
      const currentParams = localStorage.getItem(
        'hris-evaluation-schedule-search-params'
      );

      const paramsList = currentParams.split('&');
      const updatedParamsList = paramsList.filter(
        param => !param.startsWith(name.toString())
      );

      const updatedParams = updatedParamsList.join('&');

      navigate({
        pathname: location.pathname,
        search: updatedParams.toString()
      });

      localStorage.setItem(
        'hris-evaluation-schedule-search-params',
        updatedParams
      );

      removeSearchParam(name);
    }
  };

  const handleDeleteModal = useCallback(
    ({ modal, id }) => {
      setShowDeleteModal(modal);
      setEventsId(modal ? id : null);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setEventsId]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-events-params');
    const savedPageIndex = localStorage.getItem('hris-events-page-no');
    if (savedPageIndex) {
      setPage(1);
      localStorage.setItem('hris-events-page-no', JSON.stringify(1));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-events-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      pushQuery({});
      fetchData();
    } else {
      form.reset({});
      pushQuery({});
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-events-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, eventsFlag]);

  useEffect(() => {
    localStorage.setItem('hris-holidays-page-no', JSON.stringify(page));
  }, [page]);

  return {
    eventsListMemo,
    eventsList,
    inputs,
    eventsId,
    form,
    isOpen,
    hasMore,
    eventsColumn,
    showModal,
    showDeleteModal,
    showBulkDeleteModal,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    detectDateOnChange,
    handleDeleteModal,
    fetchData
  };
};

export default useCompanyEvents;
