import ConfirmModalUI from 'src/components/Common/ConfirmModalUI';
import PropTypes from 'prop-types';

const TimeRecordModal = ({ name, handleModal, modal }) => {
  return (
    <ConfirmModalUI
      label={name}
      submit={() => handleModal}
      cancel={() => handleModal(modal)}
    />
  );
};

TimeRecordModal.propTypes = {
  handleModal: PropTypes.func,
  name: PropTypes.string,
  modal: PropTypes.string
};

export default TimeRecordModal;
