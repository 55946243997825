/* eslint-disable array-callback-return */
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { CgCloseO, CgCheckO } from 'react-icons/cg';

// Services
import { getSectionByIDService } from 'src/api/modules/sections';
import {
  getEvaluationTemplatesService,
  createEvaluationTemplateService,
  updateEvaluationTemplateByIdService,
  updateEvaluationTemplateSectionService,
  updateEvaluationTemplateCriteriaService,
  updateEvaluationTemplateWeightService,
  updateEvaluationTemplateService,
  createEvaluationTemplateCriteriaService,
  getEvaluationTemplateByIdService,
  createEvaluationTemplateSectionService,
  deleteEvaluationTemplateByIdService,
  deleteEvaluationTemplateSectionService,
  deleteEvaluationTemplateCriteriaService
} from 'src/api/modules/evaluation';
import { getAllEmployees } from 'src/redux/modules/employee/employeeActions';
import { getSalaryGrades } from 'src/redux/modules/salaryGrades/salaryGradesActions';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';
import { getActiveScheduleTemplates } from 'src/redux/modules/EvaluationSchedule/evaluationScheduleActions';

// Redux
import { TEMPLATESV2 } from 'src/api/endpoints';

// Utilities
import {
  getCostCenter,
  filterData,
  employeeName,
  deleteObjectsById
} from 'src/helpers/utils';

import { TOAST_OPTIONS } from 'src/helpers/constants';

// Others
import { toast } from 'react-toastify';

const useEvaluationTemplate = (templateId, viewOnly) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultTemplateFields = {
    template_name: '',
    template_description: '',
    cost_center_code: ''
  };

  const initialDummyData = {
    template_name: 'Test',
    template_description: 'Test',
    cost_center_code: '0'
  };

  const errorDefaults = {
    title: false,
    description: false,
    max_rating: false
  };

  const { evaluationTemplates } = useSelector(
    state => state.evaluationTemplate
  );

  const costcenter = useSelector(state => state.costCenter.all);
  const { deletedIds } = useSelector(state => state.datatable);

  const form = useForm({ defaultValues: undefined });

  const { salaryGrades } = useSelector(state => state.salaryGrades);
  const { allEmployees } = useSelector(state => state.employees);
  const [templateFields, setTemplateFields] = useState(defaultTemplateFields);
  const [templateResponseData, setTemplateResponseData] = useState(
    defaultTemplateFields
  );
  const [sectionFields, setSectionFields] = useState([]);
  const [sectionResponseData, setSectionResponseData] = useState([]);
  const [criteriaResponseData, setCriteriaResponseData] = useState([]);
  const [criteriaFields, setCriteriaFields] = useState([]);
  const [weightFields, setWeightFields] = useState([]);
  const [weightResponseData, setWeightResponseData] = useState([]);
  const [totalWeights, setTotalWeights] = useState({});
  const [totalResponseWeights, setTotalResponseWeights] = useState({});
  const [addSectionFlag, setAddSectionFlag] = useState(true);
  const [addCriteriaFlag, setAddCriteriaFlag] = useState(true);
  const [modalTemplateId, setModalTemplateId] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState({});
  const [showViewModal, setShowViewModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [sectionError, setSectionError] = useState(null);
  const [criteriaError, setCriteriaError] = useState(null);
  const [weightError, setWeightError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isTemplateNameExist, setIsTemplateNameExist] = useState(false);
  const [isTemplateActive, setIsTemplateActive] = useState(false);
  const [isFieldEnabled, setIsFieldEnabled] = useState(false);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);
  const [isTemplateInUse, setIsTemplateInUse] = useState(false);
  const [isCritSubmitted, setIsCritSubmitted] = useState(false);
  const [criteriaErrors, setCriteriaErrors] = useState([]);
  const [existCriteriaErrors, setExistCriteriaErrors] = useState([]);

  const costCenterOptions = costcenter?.map(costCenterData => {
    const costCenterLabel = getCostCenter(
      'name',
      costCenterData.cost_center_code
    );

    return {
      value: costCenterData.cost_center_code,
      label: `${costCenterData.cost_center_code} / ${costCenterLabel}`
    };
  });

  const allEmployeesOptions = allEmployees
    ?.filter(employeeInfo => employeeInfo.status === 'A')
    .map(employeeInfo => {
      return {
        id: employeeInfo.id,
        value: employeeInfo.id,
        label: employeeName(employeeInfo)
      };
    });

  const handleAddSection = async () => {
    const evaluatorId = await getSuggestedEvaluator(
      templateFields.cost_center_code
    );
    setSectionFields(prevState => [
      ...prevState,
      {
        ...{
          section_name: '',
          section_type: '',
          evaluator_id: evaluatorId,
          section_description: ''
        }
      }
    ]);
    setAddSectionFlag(false);
  };

  const handleDeleteSection = sectionIndex => {
    setSectionFields(prevData => {
      const newData = [...prevData];
      newData.splice(sectionIndex, 1);
      return newData;
    });
    setAddSectionFlag(true);
  };

  const handleAddCriteria = sectionIndex => {
    const defaultCriteria = {
      R: {
        title: '',
        max_rating: '',
        description: ''
      },
      T: {
        title: '',
        description: '',
        max_rating: ''
      },
      C: {
        max_rating: '',
        question: ''
      }
    };

    const isComment = sectionFields[sectionIndex].section_type === 'C';

    setCriteriaErrors(
      isComment ? { max_rating: false, question: false } : errorDefaults
    );

    setCriteriaFields(prevData => {
      const newData = [...prevData];
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        [Object.keys(newData[sectionIndex]).length]:
          defaultCriteria[sectionFields[sectionIndex].section_type]
      };
      return newData;
    });

    const salaryGrds = {};
    const defaultSalaryGrades = salaryGrades.reduce((result, item) => {
      salaryGrds[item.id] = {
        salary_grade_type: item.type,
        salary_grade_id: item.id,
        percentage: `${0}%`
      };
      return salaryGrds;
    }, {});

    setWeightFields(prevData => {
      const newData = [...prevData];
      newData[sectionIndex] = [...newData[sectionIndex], defaultSalaryGrades];
      return newData;
    });
    setAddCriteriaFlag(false);
    setIsCritSubmitted(false);
  };

  const handleDeleteCriteria = (sectionIndex, criteriaIndex) => {
    // remove fields
    setCriteriaFields(prevData => {
      const newData = [...prevData];
      if (newData[sectionIndex]) {
        const innerArray = Object.values(newData[sectionIndex]);
        innerArray.splice(criteriaIndex, 1);
        newData[sectionIndex] = Object.fromEntries(
          innerArray.map((obj, index) => [index, obj])
        );
      }
      return newData;
    });
    // reset weights
    setWeightFields(prevData => {
      const newData = [...prevData];
      const indexWeight = newData[sectionIndex][criteriaIndex];

      const indexWeightReset = Object.keys(indexWeight).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            ...indexWeight[key],
            percentage: `${0}%`
          }
        };
      }, {});
      newData[sectionIndex][criteriaIndex] = indexWeightReset;
      return newData;
    });

    setAddCriteriaFlag(true);
    setAddSectionFlag(true);
  };

  const handSelectChange = (
    value,
    name,
    params,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    sectionIndex
  ) => {
    if (params.part === 'template') {
      let oldVal = null;
      if (templateId) {
        oldVal = templateResponseData;
        delete oldVal.isSaveDisabled;
      }
      if (isTemplateNameExist && name === 'template_name') {
        setIsTemplateNameExist(false);
      }

      setTemplateFields(prevState => {
        let newTemplateData = {
          ...prevState,
          [name]: value
        };
        delete newTemplateData.isSaveDisabled;
        if (templateId) {
          newTemplateData = {
            ...newTemplateData,
            isSaveDisabled:
              JSON.stringify(oldVal) === JSON.stringify(newTemplateData)
          };
        }
        return newTemplateData;
      });
      setFieldValue(name, value);
      setFieldTouched(name, true);
      setFieldError(name, '');
    }
    if (params.part === 'section') {
      const sectionId = sectionFields[sectionIndex]?.section_id;
      let oldVal = null;
      if (sectionId) {
        oldVal = sectionResponseData[sectionIndex];
        delete oldVal.isSaveDisabled;
      }

      setSectionFields(prevState => {
        const newData = prevState.map((obj, i) => {
          if (i === parseInt(params.sectionIndex, 10)) {
            let newSectionData = {
              ...obj,
              [name]: value
            };
            delete newSectionData.isSaveDisabled;
            if (sectionId) {
              newSectionData = {
                ...newSectionData,
                isSaveDisabled:
                  JSON.stringify(oldVal) === JSON.stringify(newSectionData)
              };
            }
            return newSectionData;
          }
          return obj;
        });
        return newData;
      });
      setFieldValue(name, value);
      setFieldTouched(name, true);
      setFieldError(name, '');
    }
  };

  const handleChange = useCallback(
    (
      e,
      part,
      sectionIndex,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      criIndex
    ) => {
      const { name, value, checked, type, dataset } = e.target;
      const { sectionindex, criteriaindex, weightindex } = dataset;
      const fieldValue = type === 'checkbox' ? checked : value;

      const initialValue = value.match(/^\s/) !== null;
      const sectionId = sectionFields[sectionIndex]?.section_id;

      if (part === 'template') {
        let oldVal = null;
        if (templateId) {
          oldVal = templateResponseData;
          delete oldVal.isSaveDisabled;
        }
        if (isTemplateNameExist && name === 'template_name') {
          setIsTemplateNameExist(false);
        }

        setTemplateFields(prevState => {
          let newTemplateData = {
            ...prevState,
            [name]:
              (name === 'template_name' && initialValue) ||
              (name === 'template_description' && initialValue)
                ? value.trim()
                : value
          };
          delete newTemplateData.isSaveDisabled;
          if (templateId) {
            newTemplateData = {
              ...newTemplateData,
              isSaveDisabled:
                JSON.stringify(oldVal) === JSON.stringify(newTemplateData)
            };
          }
          return newTemplateData;
        });
      }
      if (part === 'section') {
        let oldVal = null;
        if (sectionId) {
          oldVal = sectionResponseData[sectionIndex];
          delete oldVal.isSaveDisabled;
        }
        setSectionFields(prevState => {
          const newData = prevState.map((obj, i) => {
            if (i === parseInt(sectionIndex, 10)) {
              let newSectionData = {
                ...obj,
                [name]: value
              };
              delete newSectionData.isSaveDisabled;
              if (sectionId) {
                newSectionData = {
                  ...newSectionData,
                  isSaveDisabled:
                    JSON.stringify(oldVal) === JSON.stringify(newSectionData)
                };
              }
              return newSectionData;
            }
            return obj;
          });
          return newData;
        });
      }
      if (part === 'criteria') {
        let oldVal = null;
        const criteriaId =
          criteriaFields?.[sectionIndex]?.[criIndex]?.criteria_id;
        if (sectionId && criteriaId) {
          oldVal = criteriaResponseData[sectionIndex][criIndex];
          delete oldVal.isSaveDisabled;
        }

        setCriteriaFields(prevState => {
          const newData = prevState.map((section, i) => {
            if (i === parseInt(sectionIndex, 10)) {
              let updatedCriteria = {
                ...section[criIndex],
                [name]: value
              };
              delete updatedCriteria.isSaveDisabled;
              if (sectionId) {
                updatedCriteria = {
                  ...updatedCriteria,
                  isSaveDisabled:
                    JSON.stringify(oldVal) === JSON.stringify(updatedCriteria)
                };
              }
              // validate
              if (isCritSubmitted) {
                if (updatedCriteria.criteria_id) {
                  checkExistCriteria(updatedCriteria, criteriaId, criIndex);
                } else {
                  checkCriteria(updatedCriteria);
                }
              }
              return { ...section, [criIndex]: updatedCriteria };
            }
            return section;
          });
          return newData;
        });
      }
      if (part === 'weight') {
        // update criteria isSaveDisabled
        let oldVal = null;
        if (sectionId) {
          oldVal = weightResponseData[sectionIndex][criIndex];
        }

        const numericValue = value
          .replace(/[^\d.]/g, '') // remove non-numeric and non-decimal characters
          .replace(/(\.\d\d)\d*/, '$1'); // limit decimal portion to two digits

        let weightFieldsStr = '';
        setWeightFields(prevData => {
          const newData = prevData.map((section, i) => {
            if (i === parseInt(sectionindex, 10)) {
              const updatedSection = section.map((criteria, j) => {
                if (j === parseInt(criteriaindex, 10)) {
                  const updatedCriteria = {
                    ...criteria,
                    [weightindex]: {
                      ...criteria[weightindex],
                      [name]: `${numericValue}%`
                    }
                  };
                  weightFieldsStr = updatedCriteria;
                  return updatedCriteria;
                }
                return criteria;
              });
              return updatedSection;
            }
            return section;
          });

          return newData;
        });

        setCriteriaFields(prevState => {
          const newData = prevState.map((section, i) => {
            if (i === parseInt(sectionIndex, 10)) {
              let updatedCriteria = {
                ...section[criIndex]
              };
              if (sectionId) {
                updatedCriteria = {
                  ...updatedCriteria,
                  isSaveDisabled:
                    JSON.stringify(oldVal) === JSON.stringify(weightFieldsStr)
                };
              }
              return { ...section, [criIndex]: updatedCriteria };
            }
            return section;
          });
          return newData;
        });
        // end
      }
      if (part === 'criteria') {
        setFieldValue(`criteria.${name}`, value);
        setFieldTouched(`criteria.${name}`, true);
        setFieldError(`criteria.${name}`, '');
      } else {
        setFieldValue(name, value);
        setFieldTouched(name, true);
        setFieldError(name, '');
      }
    },
    [isCritSubmitted]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const handleDeleteModal = useCallback(
    (modal, id, name) => {
      setShowDeleteModal(modal.modal);
      setModalTemplateId(modal ? modal.id : null);
      setTemplateName(modal.name);
      // disable scroll when modal is shown
      if (modal.modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setModalTemplateId, setTemplateName]
  );

  const handleDeleteTemplateModal = useCallback(
    modal => {
      setShowDeleteTemplateModal(modal);
      // disable scroll when modal is shown
      if (modal.name) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteTemplateModal]
  );

  // to delete
  // const [templateId, setTemplateId] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [page, setPage] = useState(1);
  const [noData, setNoData] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const templateColumns = [
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'description',
      label: 'Description'
    },
    {
      key: 'cost_center_code',
      label: 'Cost Center'
    },
    {
      key: 'template_createdBy',
      label: 'Created By'
    },
    {
      key: 'created_at',
      label: 'Date'
    },
    {
      key: 'status',
      label: 'Status'
    }
  ];

  const STATUS_OPTIONS = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'N',
      label: 'New'
    },
    {
      value: 'C',
      label: 'Cancelled'
    }
  ];

  const statusOptions = [
    {
      value: 'A',
      label: 'Active',
      hidden: false
    },
    {
      value: 'N',
      label: 'New',
      hidden: true
    },
    {
      value: 'C',
      label: 'Cancelled',
      hidden: false
    }
  ];

  const sectionOptions = [
    {
      value: 'R',
      label: 'Rating'
    },
    {
      value: 'T',
      label: 'Target and Result'
    },
    {
      value: 'C',
      label: 'Comment'
    }
  ];

  const selectedStatuses = localStorage.getItem(
    'hris-evaluation-template-selected-statuses'
  );

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A', 'N', 'C'] // selectedStatuses ? selectedStatuses.split(',') : []
    }
  ];

  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Only letters with spaces, hyphens, or periods are allowed';

  const validationTemplateSchema = Yup.object({
    template_name: Yup.string()
      .required('Template name is required')
      .max(255, 'Maximum 255 characters allowed')
      .matches(blankSpaceRegex, blankSpageMessage),
    template_description: Yup.string()
      .required('Template description is required')
      .matches(blankSpaceRegex, blankSpageMessage),
    cost_center_code: Yup.string().required('Cost center is required')
  });

  const validationSectionSchema = Yup.object({
    section_name: Yup.string()
      .required('Section name is required')
      .max(255, 'Maximum 255 characters allowed')
      .matches(blankSpaceRegex, blankSpageMessage),
    section_type: Yup.string().required('Section type is required'),
    section_description: Yup.string()
      .required('Section description is required')
      .matches(blankSpaceRegex, blankSpageMessage)
  });

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const setStatusFilter = value => {
    localStorage.setItem('hris-evaluation-template-selected-statuses', value);
  };

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;
    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
      setStatusFilter('');
    } else {
      setStatusFilter(searchParamsObject.status);
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value === '' || value === null) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();

    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem(
      'hris-evaluation-template-search-params',
      searchParams
    );
  };

  const handleTemplateSubmit = useCallback(async id => {
    if (id) {
      // UPDATE EVALUATION TEMPLATE
      const {
        cost_center_code,
        status,
        // template_status,
        template_description,
        template_name
      } = templateFields;
      const dataValue = {
        template_name,
        template_description,
        // template_status,
        cost_center_code,
        status
      };
      const updateResponse = await updateEvaluationTemplateByIdService(
        TEMPLATESV2,
        id,
        dataValue
      );

      if (updateResponse.statusCode === 200) {
        toast.success('Successfully Updated!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        getEvaluationInfo(id);
      } else if (updateResponse.response?.data.statusCode === 409) {
        setIsTemplateNameExist(true);
      } else {
        toast.error('Unable to update!', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    } else {
      // CREATE EVALUATION TEMPLATE
      const addResponse = await createEvaluationTemplateService(templateFields);
      if (addResponse.data.statusCode === 201) {
        toast.success('Successfully Added!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        navigate(`/evaluation-templates/edit/${addResponse.data.data.id}`);
      } else if (addResponse.data.statusCode === 409) {
        setIsTemplateNameExist(true);
      } else {
        toast.error('Add Evaluation Template Failed', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    }
  });

  const handleTemplateApprove = async (id, sectionIndex, criteriaIndex) => {
    if (id) {
      let approveArray = {};

      approveArray = {
        ...approveArray,
        [`section_error`]: !Object.keys(sectionFields).length
      };

      sectionFields.map((secData, secIndex) => {
        approveArray = {
          ...approveArray,
          [`criteria_error_${secIndex}`]: !Object.keys(criteriaFields[secIndex])
            .length
        };
      });

      Object.entries(totalWeights ?? {}).map(score => {
        approveArray = {
          ...approveArray,
          [`weight_error_${score[0]}`]: parseInt(score[1], 10) !== 100
        };
      });

      setFormErrors(approveArray);

      const updateResponse = await updateEvaluationTemplateService(id);

      if (updateResponse.statusCode === 200) {
        setIsTemplateActive(updateResponse?.data?.template?.status === 'A');
        navigate(`/evaluation-templates/view/${id}`);
        dispatch(getActiveScheduleTemplates());
        toast.success('Evaluation template has successfully approved', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
      } else {
        toast.error(
          'Failed to approve evaluation template due to some error.\nPlease check.',
          {
            ...TOAST_OPTIONS,
            icon: <CgCloseO />
          }
        );
      }
    }
  };

  const deleteTemplateSubmit = useCallback(async id => {
    if (id) {
      const deleteResponse = await deleteEvaluationTemplateByIdService(id);
      if (deleteResponse.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        handleDeleteTemplateModal({});
        navigate('/evaluation-templates');
      } else {
        toast.error('Delete Evaluation Template Failed', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    }
  });

  const handleSectionSubmit = useCallback(async (id, sectionIndex) => {
    const sectionId = sectionFields[sectionIndex]?.section_id;
    if (sectionId) {
      // UPDATE EVALUATION SECTION

      const { criterias } = sectionFields[sectionIndex];
      const origSectionType = sectionFields[sectionIndex].orig_section_type;

      delete sectionFields[sectionIndex].section_id;
      delete sectionFields[sectionIndex].criterias;
      delete sectionFields[sectionIndex].orig_section_type;
      delete sectionFields[sectionIndex].isSaveDisabled;
      const sectionResponse = await updateEvaluationTemplateSectionService(
        id,
        sectionId,
        sectionFields[sectionIndex]
      );
      sectionFields[sectionIndex].section_id = sectionId;
      sectionFields[sectionIndex].criterias = criterias;
      sectionFields[sectionIndex].orig_section_type = origSectionType;
      if (sectionResponse.statusCode === 200) {
        toast.success('Successfully Updated!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        getEvaluationInfo(id);
        setAddSectionFlag(true);
        setSectionError(null);
        setFormErrors(true);
      } else {
        toast.error('Unable to update!', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    } else {
      // CREATE EVALUATION SECTION
      const addResponse = await createEvaluationTemplateSectionService(
        id,
        sectionFields[sectionIndex]
      );
      if (addResponse.data.statusCode === 201) {
        toast.success('Successfully Added!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        getEvaluationInfo(id);
        setAddSectionFlag(true);
        setSectionError(null);
        setFormErrors(true);
      } else {
        toast.error('Add Evaluation Section Failed!', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    }
    setAddCriteriaFlag(true);
  });

  const deleteSectionSubmit = useCallback(async (id, sectionIndex) => {
    const sectionId = sectionFields[sectionIndex]?.section_id;

    if (sectionId) {
      const deleteResponse = await deleteEvaluationTemplateSectionService({
        id,
        sectionId
      });
      if (deleteResponse.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />
        });
        handleDeleteTemplateModal({});
        getEvaluationInfo(id);
      } else {
        toast.error('Delete Evaluation Section Failed!', {
          ...TOAST_OPTIONS,
          icon: <CgCloseO />
        });
      }
    } else {
      handleDeleteSection(sectionIndex);
    }
  });

  const resetCriteriaErrors = (type, index, isComment) => {
    const errors = isComment
      ? { max_rating: false, question: false }
      : errorDefaults;
    switch (type) {
      case 'new':
        setCriteriaErrors(errors);
        break;
      case 'exist':
        setCriteriaErrors(errors);
        setExistCriteriaErrors(prevItems =>
          prevItems.map((item, idx) =>
            idx === index ? { id: null, ...errors } : item
          )
        );
        break;
      default:
    }
  };

  const getEmptyCriteria = (obj, callback) => {
    // eslint-disable-next-line consistent-return
    const emptyFields = Array.from(Object.keys(obj), key => {
      if (obj[key] === '') {
        return key;
      }
    }).filter(Boolean);

    if (callback && typeof callback === 'function') {
      emptyFields.forEach(field => {
        callback(field);
      });
    }

    return emptyFields;
  };

  const getHasCriteria = (obj, callback) => {
    // eslint-disable-next-line consistent-return
    const emptyFields = Array.from(Object.keys(obj), key => {
      if (obj[key] !== '') {
        return key;
      }
    }).filter(Boolean);

    if (callback && typeof callback === 'function') {
      emptyFields.forEach(field => {
        callback(field);
      });
    }

    return emptyFields;
  };

  const checkCriteria = (payload, index) => {
    getEmptyCriteria(payload, field => {
      setCriteriaErrors(prevState => ({
        ...prevState,
        [field]: true
      }));
    });
    getHasCriteria(payload, field => {
      setCriteriaErrors(prevState => ({
        ...prevState,
        [field]: false
      }));
    });
  };

  const checkExistCriteria = (payload, id, index) => {
    getEmptyCriteria(payload, field => {
      setExistCriteriaErrors(prevState =>
        prevState.map((item, idx) =>
          idx === index ? { ...item, [field]: true } : item
        )
      );
    });
    getHasCriteria(payload, field => {
      setExistCriteriaErrors(prevState =>
        prevState.map((item, idx) =>
          idx === index ? { ...item, [field]: false } : item
        )
      );
    });
  };

  const handleCriteriaSubmit = useCallback(
    async (id, sectionIndex, criteriaIndex) => {
      let criteriaId = criteriaFields[sectionIndex][criteriaIndex].criteria_id;
      const sectionId = sectionFields[sectionIndex]?.section_id;
      const isComment = sectionFields[sectionIndex].section_type === 'C';
      delete criteriaFields[sectionIndex][criteriaIndex].isSaveDisabled;
      setIsCritSubmitted(true);
      try {
        if (criteriaId) {
          if (criteriaFields[sectionIndex][criteriaIndex].max_rating === '0')
            throw new Error('edit_zero');

          delete criteriaFields[sectionIndex][criteriaIndex].criteria_id;

          const criteriaResponse =
            await updateEvaluationTemplateCriteriaService(
              { id, sectionId, criteriaId },
              criteriaFields[sectionIndex][criteriaIndex]
            );
          criteriaFields[sectionIndex][criteriaIndex].criteria_id = criteriaId;

          if (
            criteriaResponse?.response?.status &&
            criteriaResponse?.response?.status >= 400
          ) {
            throw criteriaResponse.response;
          }

          if (criteriaResponse.statusCode === 200) {
            resetCriteriaErrors('exist', criteriaIndex, isComment);
            toast.success('Successfully Updated!', {
              bodyClassName:
                '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
              toastId: 1,
              icon: <CgCheckO />
            });

            getEvaluationInfo(id);
            setAddCriteriaFlag(true);
            setAddSectionFlag(true);
            setFormErrors(true);

            // store weight fields in dataObj
            const dataObj = {
              weights: weightFields[sectionIndex][criteriaIndex]
            };

            // clean dataObj
            const updatedObject = {
              weights: Object.values(dataObj.weights).map(
                ({ salary_grade_id, percentage }) => ({
                  salary_grade_id,
                  percentage:
                    typeof percentage === 'string'
                      ? percentage.replace(/%/g, '')
                      : '0'
                })
              )
            };

            // update weights
            const oldWeightData = Object.values(
              weightResponseData[sectionIndex][criteriaIndex]
            ).map(({ salary_grade_id, percentage }) => ({
              salary_grade_id,
              percentage: percentage.toString()
            }));

            if (
              JSON.stringify(oldWeightData) ===
              JSON.stringify(updatedObject.weights)
            ) {
              getEvaluationInfo(id);
              return;
            }

            const weightResponse = await updateEvaluationTemplateWeightService(
              { id, sectionId, criteriaId },
              updatedObject
            );
            if (weightResponse.statusCode === 200) {
              getEvaluationInfo(id);
              setAddCriteriaFlag(true);
              setAddSectionFlag(true);
              setFormErrors(true);
            } else {
              toast.error('Edit weight error!', {
                bodyClassName:
                  '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
                toastId: 2,
                icon: <CgCloseO />
              });
            }
            getEvaluationInfo(id);
          } else {
            toast.error('Edit criteria error!', {
              bodyClassName:
                '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
              toastId: 1,
              icon: <CgCloseO />
            });
          }
        } else {
          const payload = criteriaFields[sectionIndex][criteriaIndex];
          if (payload.max_rating === '0') throw new Error('zero');

          // CREATE EVALUATION CRITERIA
          const addResponse = await createEvaluationTemplateCriteriaService(
            { id, sectionId },
            payload
          );

          if (
            addResponse?.response?.status &&
            addResponse?.response?.status >= 400
          ) {
            throw addResponse.response;
          }

          if (addResponse.data.statusCode === 201) {
            resetCriteriaErrors('exist', criteriaIndex, isComment);
            toast.success('Add criteria success!', {
              bodyClassName:
                '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
              toastId: 1,
              icon: <CgCheckO />
            });

            getEvaluationInfo(id);
            setAddCriteriaFlag(true);
            setFormErrors(true);
            setExistCriteriaErrors(prev => [
              ...prev,
              isComment
                ? { max_rating: false, question: false }
                : { ...errorDefaults }
            ]);

            criteriaId = addResponse.data.data.id;
            // store weight fields in dataObj
            const dataObj = {
              weights: weightFields[sectionIndex][criteriaIndex]
            };

            // clean dataObj
            const updatedObject = {
              weights: Object.values(dataObj.weights).map(
                ({ salary_grade_id, percentage }) => ({
                  salary_grade_id,
                  percentage:
                    percentage.replace(/%/g, '') !== ''
                      ? percentage.replace(/%/g, '')
                      : 0
                })
              )
            };

            // update weights
            const weightResponse = await updateEvaluationTemplateWeightService(
              { id, sectionId, criteriaId },
              updatedObject
            );
            if (weightResponse.statusCode === 200) {
              toast.success('Add weight success!', {
                bodyClassName:
                  '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
                toastId: 1,
                icon: <CgCheckO />
              });
            } else {
              toast.error('Add weight error!', {
                bodyClassName:
                  '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
                toastId: 1,
                icon: <CgCloseO />
              });
            }
            getEvaluationInfo(id);
            setAddCriteriaFlag(true);
          } else {
            toast.error('Add criteria error!', {
              bodyClassName:
                '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
              toastId: 1,
              icon: <CgCloseO />
            });
          }
        }
      } catch (e) {
        const isMaxRatingZero = e.message === 'zero';
        const { message } = e.data?.validation?.body || {};
        const errorField = e.data?.validation?.body?.keys[0];
        const payload = criteriaFields[sectionIndex][criteriaIndex];
        const hasID = Boolean(payload.criteria_id);

        if (hasID) {
          const isMaxRatingEditZero = e.message === 'edit_zero';
          const { question, criteria_id, ...criteria } = payload;
          const checkPayload = isComment
            ? { question, max_rating: criteria.max_rating }
            : criteria;
          checkExistCriteria(checkPayload, criteria_id, criteriaIndex);
          if (isMaxRatingEditZero) {
            toast.error('Max Rating cannot be zero!', {
              position: 'top-right',
              autoClose: 2500,
              bodyClassName:
                'slim-toast !text-[14px] !text-[#000000] font-stolzlBook mx-4 flex !flex-row gap-2',
              icon: (
                <CgCloseO className="!w-[30px] !text-[30px] !color-[#F05848]" />
              ),
              closeButton: false,
              className:
                'slim-toast-container !h-[75px] !min-h-0 !min-w-0 !mb-4'
            });
          }
          return;
        }

        checkCriteria(payload, criteriaIndex);

        const maxRatingError =
          payload.max_rating !== '' &&
          errorField === 'max_rating' &&
          !Number.isNaN(payload.max_rating);

        if (maxRatingError || isMaxRatingZero) {
          let errorMessage = 'Max Rating must be a number!';
          if (isMaxRatingZero) errorMessage = 'Max Rating cannot be zero!';
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2500,
            bodyClassName:
              'slim-toast !text-[14px] !text-[#000000] font-stolzlBook mx-4 flex !flex-row gap-2',
            icon: (
              <CgCloseO className="!w-[30px] !text-[30px] !color-[#F05848]" />
            ),
            closeButton: false,
            className: 'slim-toast-container !h-[75px] !min-h-0 !min-w-0 !mb-4'
          });
          setCriteriaErrors(prevState => ({
            ...prevState,
            max_rating: true
          }));
        }

        if (['', null].includes(message) && e.message !== 'zero') {
          toast.error('Add criteria error!', {
            toastId: 1,
            ...TOAST_OPTIONS,
            icon: <CgCloseO />
          });
        }
      }
    }
  );

  const deleteCriteriaSubmit = useCallback(
    async (id, sectionIndex, criteriaIndex) => {
      const sectionId = sectionFields[sectionIndex]?.section_id;
      const criteriaId =
        criteriaFields[sectionIndex][criteriaIndex].criteria_id;

      if (sectionId && criteriaId) {
        const deleteResponse = await deleteEvaluationTemplateCriteriaService({
          id,
          sectionId,
          criteriaId
        });
        if (deleteResponse.statusCode === 200) {
          toast.success('Successfully Deleted!', {
            ...TOAST_OPTIONS,
            icon: <CgCheckO />
          });
          handleDeleteTemplateModal({});
          getEvaluationInfo(id);
        } else {
          toast.error('Delete criteria error!', {
            ...TOAST_OPTIONS,
            icon: <CgCloseO />
          });
        }
      } else {
        handleDeleteCriteria(sectionIndex, criteriaIndex);
        setIsCritSubmitted(false);
      }
    }
  );

  const getEvaluationInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getEvaluationTemplateByIdService(id);

      setIsTemplateActive(response.data.status === 'A');

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        const templateData = {
          template_name: responseData.name,
          template_description: responseData.description,
          template_status: responseData.status,
          cost_center_code: responseData.cost_center_code,
          status: !['N', 'A'].includes(responseData.status)
            ? responseData.status
            : null,
          orig_status: responseData.status,
          isSaveDisabled: true
        };
        const sectionData = [];
        const criteriaData = [];
        const weightData = [];

        Object.keys(responseData.sections).forEach(sectionKey => {
          const criteriaItem = [];
          const weightItem = [];
          Object.keys(responseData.sections[sectionKey].criterias).forEach(
            criteriaKey => {
              const criteriaObj =
                responseData.sections[sectionKey].criterias[criteriaKey];
              const newCriteria = {
                criteria_id: criteriaObj.id,
                title: criteriaObj.fields.title,
                description: criteriaObj.fields.description,
                question: criteriaObj.fields.question,
                isSaveDisabled: true
              };
              if (criteriaObj.max_rating) {
                newCriteria.max_rating = criteriaObj.max_rating;
              }
              criteriaItem.push(newCriteria);

              const salaryGrds = {};
              const weightInner = salaryGrades.reduce((result, item) => {
                salaryGrds[item.id] = {
                  salary_grade_type: item.type,
                  salary_grade_id: item.id,
                  percentage: 0
                };
                return salaryGrds;
              }, {});

              Object.keys(criteriaObj.weights).forEach(weightKey => {
                const newWeight = {
                  salary_grade_type:
                    criteriaObj.weights[weightKey].salary_grade.type,
                  salary_grade_id:
                    criteriaObj.weights[weightKey].salary_grade_id,
                  percentage: `${criteriaObj.weights[weightKey].weight_percentage}%`
                };
                const salaryGradeId =
                  criteriaObj.weights[weightKey].salary_grade_id;

                weightInner[salaryGradeId] = newWeight;
              });
              weightItem.push(weightInner);
            }
          );
          weightData.push(weightItem);

          const newSection = {
            section_id: responseData.sections[sectionKey].id,
            section_name: responseData.sections[sectionKey].name,
            section_description: responseData.sections[sectionKey].description,
            section_type: responseData.sections[sectionKey].type,
            orig_section_type: responseData.sections[sectionKey].type,
            evaluator_id: responseData.sections[sectionKey].evaluator?.id,
            isSaveDisabled: true
          };
          const newCriteria = { ...criteriaItem };
          sectionData.push(newSection);
          criteriaData.push(newCriteria);
        });

        setTemplateFields(templateData);
        setTemplateResponseData(templateData);
        setSectionFields(sectionData);
        setSectionResponseData(sectionData);
        setCriteriaFields(criteriaData);
        setCriteriaResponseData(criteriaData);
        setWeightFields(weightData);
        setWeightResponseData(weightData);
        setIsTemplateInUse(
          responseData.is_used && templateData.template_status === 'A'
        );
      }
    } catch (error) {
      toast.error('Error fetching data:', {
        ...TOAST_OPTIONS,
        icon: <CgCloseO />
      });
    }
  };

  const fetchTemplateData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-template-search-params'
      );
      let params = null;

      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getEvaluationTemplatesService(page, params);
      setTotalTemplates(res.data.total_items);
      const modifiedResult = res.data.items.map(item => {
        const { name, description, cost_center_code, status } = item;
        return {
          ...item,
          template_name: name,
          template_description: description,
          template_status: status,
          template_coscenter: cost_center_code,
          template_createdBy: employeeName(item.user_createdBy)
        };
      });
      const newList =
        page !== 1 ? templateList.concat(modifiedResult) : modifiedResult;
      setTemplateList(newList);

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`, {
        ...TOAST_OPTIONS,
        icon: <CgCloseO />
      });
    }
  }, [
    page,
    getEvaluationTemplatesService,
    setTemplateList,
    setPage,
    setHasMore,
    templateList
  ]);

  const calculateTotalPercentageByGradeId = data => {
    const result = {};

    data.forEach(matrix => {
      matrix.forEach(row => {
        Object.values(row).forEach(obj => {
          const { salary_grade_id, percentage } = obj;
          const updatedPercentage = Number.isFinite(parseFloat(percentage))
            ? parseFloat(percentage)
            : 0;
          if (!result[salary_grade_id]) {
            result[salary_grade_id] = updatedPercentage;
          } else {
            result[salary_grade_id] += updatedPercentage;
          }
        });
      });
    });

    return result;
  };

  const getSuggestedEvaluator = async costCenterCode => {
    const sectionNumber = costCenterCode.toString().substring(4, 6);
    const sectionResponse = await getSectionByIDService(sectionNumber, 'GET');
    if (sectionResponse.statusCode === 200) {
      return sectionResponse.data.user_id;
    }
    return null;
  };

  const templateListMemo = useMemo(() => {
    return templateList ?? [];
  }, [templateList]);

  useEffect(() => {
    // for Validation
    const resTotalWeights = calculateTotalPercentageByGradeId(weightFields);
    setTotalWeights(resTotalWeights);
  }, [weightFields]);

  useEffect(() => {
    // for Template Section
    const resTotalWeights =
      calculateTotalPercentageByGradeId(weightResponseData);
    setTotalResponseWeights(resTotalWeights);
  }, [weightResponseData]);

  useEffect(() => {
    dispatch(getSalaryGrades());
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-template-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-template-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem(
        'hris-evaluation-template-page-no',
        JSON.stringify(1)
      );
    }
    fetchTemplateData();

    const handlePageShow = event => {
      if (
        event.persisted ||
        window.performance.getEntriesByType('navigation')[0].type === 'reload'
      ) {
        setIsPageRefreshed(true);
        fetchTemplateData();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    if (isPageRefreshed) fetchTemplateData();
  }, [isPageRefreshed]);

  useEffect(() => {
    return () => {
      localStorage.setItem('hris-evaluation-template-search-params', '');
    };
  }, [navigate]);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-evaluation-template-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchTemplateData();
  }, [location.search]); // @TODO: change the dependency of this hook

  useEffect(() => {
    localStorage.setItem(
      'hris-evaluation-template-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  useEffect(() => {
    if (templateId) {
      getEvaluationInfo(templateId);
    }
  }, [viewOnly]);

  useEffect(() => {
    if (deletedIds) {
      const updatedList = deleteObjectsById(templateListMemo, deletedIds);
      setTemplateList(updatedList);
      dispatch(setIdDeletion(null));
    }
  }, [deletedIds]);

  useEffect(() => {
    if (!templateId) {
      setIsFieldEnabled(true);
      return;
    }
    setIsFieldEnabled(
      (templateFields?.template_status === 'N' ||
        templateFields?.template_status === 'A' ||
        templateFields?.template_status === 'O') &&
        !viewOnly &&
        !isTemplateInUse
    );
  }, [templateFields, viewOnly, isTemplateInUse]);

  useEffect(() => {
    if (templateId && isTemplateInUse && location.pathname.includes('edit')) {
      navigate(`/evaluation-templates/view/${templateId}`);
    }
  }, [isTemplateInUse, templateId, location]);

  // create default errors for existing criteria
  useEffect(() => {
    if (isCritSubmitted) return;
    const criteriaItems = criteriaFields.flatMap(obj => Object.values(obj));
    const errorArray = criteriaItems
      .filter(item => item.criteria_id)
      .map(criteria => {
        return criteria.question
          ? { max_rating: false, question: false }
          : { ...errorDefaults };
      });
    setExistCriteriaErrors(errorArray);
  }, [criteriaFields, isCritSubmitted]);

  return {
    templateName,
    evaluationTemplates,
    setTemplateFields,
    templateFields,
    templateResponseData,
    setSectionFields,
    sectionFields,
    criteriaFields,
    weightFields,
    totalWeights,
    totalResponseWeights,
    getEvaluationInfo,
    handleChange,
    handSelectChange,
    handleAddSection,
    handleAddCriteria,
    handleBulkDeleteModal,
    handleDeleteModal,
    handleTemplateSubmit,
    handleTemplateApprove,
    sectionError,
    criteriaError,
    weightError,
    handleSectionSubmit,
    handleCriteriaSubmit,
    deleteTemplateSubmit,
    deleteCriteriaSubmit,
    deleteSectionSubmit,
    validationTemplateSchema,
    validationSectionSchema,
    costcenter,
    salaryGrades,
    STATUS_OPTIONS,
    costCenterOptions,
    sectionOptions,
    allEmployeesOptions,
    addSectionFlag,
    addCriteriaFlag,
    inputs,
    form,
    handleDeleteTemplateModal,
    showDeleteTemplateModal,
    modalTemplateId,
    templateListMemo,
    showModal,
    showDeleteModal,
    showViewModal,
    showBulkDeleteModal,
    page,
    templateColumns,
    statusOptions,
    hasMore,
    fetchTemplateData,
    submitFilter,
    formErrors,
    isTemplateNameExist,
    initialDummyData,
    isTemplateActive,
    criteriaErrors,
    existCriteriaErrors,
    resetCriteriaErrors,
    isFieldEnabled,
    totalTemplates,
    isTemplateInUse,
    criteriaResponseData
  };
};

export default useEvaluationTemplate;
