import { auth, ENV } from 'src/api/config';
import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import {
  LEAVE_TYPES,
  ADD_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
  DELETE_LEAVE_TYPE,
  LEAVE_TYPE,
  BULK_DELETE_LEAVE_TYPE
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getLeaveTypesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${LEAVE_TYPES}?page=${1}`
    });
  } catch (error) {
    return error;
  }
};

export const getLeaveTypeDataItems = async (page, searchParams) => {
  try {
    let endpoint = `${LEAVE_TYPES}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getLeaveTypeService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: DELETE_LEAVE_TYPE
    });
  } catch (error) {
    return error;
  }
};

export const getLeaveTypeIDSevice = async (id, method) => {
  try {
    let url = LEAVE_TYPE;
    if (method === 'DELETE') url = DELETE_LEAVE_TYPE;
    return await fetchAPI({
      method,
      endpoint: `${url}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const addLeaveTypesService = async data => {
  const payload = { ...data };
  delete payload.id;
  delete payload.status;
  payload.is_payable = payload.is_payable ? 'true' : 'false';
  payload.is_document_required = payload.is_document_required
    ? 'true'
    : 'false';
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `${ADD_LEAVE_TYPE}`,
      body: payload
    });
  } catch (error) {
    return error;
  }
};

export const updateLeaveTypesByIDServices = async data => {
  const payload = { ...data };
  payload.is_payable = payload.is_payable ? 'true' : 'false';
  payload.is_document_required = payload.is_document_required
    ? 'true'
    : 'false';
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${UPDATE_LEAVE_TYPE}`,
      body: payload
    });
  } catch (error) {
    return error;
  }
};

export const deleteLeaveTypeService = async leaveTypeId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${DELETE_LEAVE_TYPE}/${leaveTypeId}`
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteLeaveType = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
