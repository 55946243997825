import React from 'react';
import PageTitle from 'src/components/Common/PageTitle';
import SearchFilter from 'src/components/Common/SearchFilter';
import { filterData } from 'src/helpers/utils';
import SlideModal from 'src/components/Common/SlideModal';
import Datatable from 'src/components/Common/Datatable';
import AddEditModal from 'src/components/Parts/Projects/AddEditModal';
import ViewEditModal from 'src/components/Parts/Projects/ViewEditModal';
import ModalCenter from 'src/components/Common/ModalCenter';
import DeleteModal from 'src/components/Parts/Projects/DeleteModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useProjectsIndex from 'src/hooks/Pages/admin/projects/useProjectsIndex';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import BulkDeleteModal from 'src/components/Parts/Projects/BulkDeleteModal';
import { PROJECTS_BULK_DELETE } from 'src/api/endpoints';
import Filter from 'src/components/Common/Filter/Filter';
import { useUserAccess } from 'src/hooks/useUserAccess';
import PropTypes from 'prop-types';

import 'src/assets/css/user.scss';

const ProjectsIndex = ({ menuCode }) => {
  const {
    projectList,
    projectListMemo,
    dataList,
    showModal,
    showViewModal,
    showDeleteModal,
    projectId,
    projectName,
    hasMore,
    inputs,
    form,
    isOpen,
    projectColumns,
    statusOptions,
    showBulkDeleteModal,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    fetchData,
    submitFilter,
    handleDateOnChange
  } = useProjectsIndex();
  const { menus } = useUserAccess(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'Resource' }, { name: 'Project List' }]} />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto capitalize">
          <Filter
            searchInputPlaceholder="Client, Project Name, Project Leader"
            buttonName="New Project"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
            dateOnChange={handleDateOnChange}
          />
          <InfiniteScroll
            dataLength={projectListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {projectListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
            style={{ overflow: 'visible' }}
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={projectListMemo || []}
              clickableRows={false}
              headingColumns={projectColumns}
              title="Project List"
              actions={['view', 'delete']}
              showModal={showModal}
              showViewModal={showViewModal}
              handleModal={handleModal}
              handleViewModal={handleViewModal}
              handleDeleteModal={handleDeleteModal}
              modalName="View"
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        modalSize="wide"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            projectId={projectId}
            dataList={dataList}
          />
        )}
      </SlideModal>
      <SlideModal
        showModal={showViewModal}
        modalName="View"
        handleModal={handleViewModal}
      >
        {showViewModal && (
          <ViewEditModal
            handleModal={handleViewModal}
            projectId={projectId}
            dataList={dataList}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            id={projectId}
            name={projectName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={PROJECTS_BULK_DELETE}
          />
        )}
      </ModalCenter>
    </div>
  );
};

ProjectsIndex.propTypes = {
  menuCode: PropTypes.string
};

export default ProjectsIndex;
