import { fetchAPI } from '../fetchAPI';
import {
  ANNOUNCEMENTS,
  ANNOUNCEMENTS_ALL,
  ANNOUNCEMENT,
  ANNOUNCEMENTS_DELETE,
  ANNOUNCEMENTS_UPDATE,
  ANNOUNCEMENTS_CREATE,
  ANNOUNCEMENT_BULK_DELETE
} from '../endpoints';

export const getAnnouncementsService = async (page, searchParams) => {
  try {
    let endpoint = `${ANNOUNCEMENTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAnnouncementsAllService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ANNOUNCEMENTS_ALL
    });
  } catch (error) {
    return error;
  }
};

export const getAnnouncementByIdService = async announcementId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ANNOUNCEMENT}/${announcementId}`
    });
  } catch (error) {
    return error;
  }
};

export const updateAnnouncementService = async data => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: ANNOUNCEMENTS_UPDATE,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const deleteAnnouncementService = async announcementId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${ANNOUNCEMENTS_DELETE}/${announcementId}`
    });
  } catch (error) {
    return error;
  }
};

export const getActiveAnnouncementsService = async () => {
  try {
    const endpoint = `${ANNOUNCEMENTS}?status=A`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const createAnnouncementService = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: ANNOUNCEMENTS_CREATE,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteAnnouncementService = async ids => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: ANNOUNCEMENT_BULK_DELETE,
      params: { data: { ids } }
    });
  } catch (error) {
    return error;
  }
};
