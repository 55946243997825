import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import useAddEditModal from 'src/hooks/components/Parts/Timekeeping/LeaveType/useAddEditModal';
import {
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard
} from 'src/components/Common';
import { LEAVE_STATUS_OPTIONS } from 'src/helpers/constants';
import Loading from 'src/components/Common/loading';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, leaveTypeId, dataList, menuCode }) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    handleDeleteModal,
    handleTrimSpaces,
    handleSelectChange,
    handleDelete,
    initialDummyData,
    validationSchema,
    showDeleteModal,
    isPayableCheckboxDisabled,
    isDocumentCheckboxDisabled,
    formLoading,
    handleFocus
  } = useAddEditModal({ handleModal, leaveTypeId, dataList });

  return (
    <>
      <Formik
        enableReinitialize={!!leaveTypeId}
        validateOnMount={!!leaveTypeId}
        initialValues={leaveTypeId ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {leaveTypeId ? 'Edit' : 'Add'} Leave Type
              </h4>
              <div className="text-right mr-[10px]">
                <button
                  type="submit"
                  className="text-[12px] text-white leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                  disabled={isSubmitting}
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Save
                  </span>
                </button>
              </div>
            </div>
            {formLoading && <Loading />}
            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!formData.id ? (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          autoFocus
                          label="Leave Type Code"
                          required
                          name="leave_code"
                          type="text"
                          placeholder=""
                          value={formData.leave_code ?? ''}
                          error={errors.leave_code && touched.leave_code}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.leave_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.leave_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          required
                          name="description"
                          type="text"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <div className="flex items-center mb-[20px]">
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <FormField
                            label="Payable"
                            required
                            name="is_payable"
                            type="checkbox"
                            checked={formData.is_payable}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </FormCard>
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <FormField
                            label="Document Required"
                            required
                            name="is_document_required"
                            type="checkbox"
                            checked={formData.is_document_required}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </FormCard>
                      </div>
                      <FormCard>
                        <label
                          htmlFor="minimum_hours"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                            Minimum Applicable Hours
                            {errors.minimum_hours !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.minimum_hours}
                              </div>
                            )}
                          </i>
                          <FormField
                            name="minimum_hours"
                            type="text"
                            placeholder=""
                            value={formData.minimum_hours ?? ''}
                            error={
                              errors.minimum_hours && touched.minimum_hours
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[50px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Leave Type Code"
                          required
                          name="leave_code"
                          type="text"
                          placeholder=""
                          value={formData.leave_code ?? ''}
                          error={errors.leave_code && touched.leave_code}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.leave_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.leave_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          required
                          name="description"
                          type="text"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard>
                      <div className="flex items-center mb-[20px]">
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <FormField
                            label="Payable"
                            required
                            name="is_payable"
                            type="checkbox"
                            checked={formData.is_payable}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </FormCard>
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <FormField
                            label="Document Required"
                            required
                            name="is_document_required"
                            type="checkbox"
                            checked={formData.is_document_required}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                        </FormCard>
                      </div>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          type="combobox"
                          error={errors.status && touched.status}
                          label="Status"
                          options={LEAVE_STATUS_OPTIONS}
                          placeholder=""
                          errorMessage=""
                          selectedValue={formData.status ?? ''}
                          onChangeValue={(value, name) =>
                            handleSelectChange(
                              value,
                              name,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.status !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.status}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <label
                          htmlFor="minimum_hours"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center mt-[15px]"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                            Minimum Applicable Hours
                            {errors.minimum_hours !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.minimum_hours}
                              </div>
                            )}
                          </i>
                          <FormField
                            name="minimum_hours"
                            type="text"
                            placeholder=""
                            value={(formData.minimum_hours ?? '').toString()}
                            error={
                              errors.minimum_hours &&
                              touched.minimum_hours &&
                              errors.minimum_hours.toString() &&
                              touched.minimum_hours.toString()
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[50px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            id={leaveTypeId}
          />
        )}
      </ModalCenter>
    </>
  );
};
AddEditModal.propTypes = {
  handleModal: PropTypes.func,
  leaveTypeId: PropTypes.number,
  dataList: PropTypes.instanceOf(Object),
  menuCode: PropTypes.string
};

export default AddEditModal;
