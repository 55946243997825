import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserAccess } from 'src/hooks/useUserAccess';
import _ from 'lodash';
import PageNotFound from 'src/Pages/404';
import AccessDenied from 'src/Pages/401';
import Login from 'src/Pages/Login';
import MainView from './layouts/MainView';
import PrivateRoute from './components/PrivateRoute';

// Version 1
import V1Information from './Pages/v1/user/information/Information';
import V1ProjectsUser from './Pages/v1/user/projects/ProjectsUser';
import V1SkillsAndTraining from './Pages/v1/user/skills/Skills';
import V1SkillsAndTrainingEdit from './Pages/v1/user/skills/SkillsEdit';
import V1Projects from './Pages/v1/admin/projects/Projects';
import V1ProjectsAddProject from './Pages/v1/admin/projects/ProjectsAddProject';
import V1ProjectsAddMember from './Pages/v1/admin/projects/ProjectsAddMember';
import V1ProjectsEditMember from './Pages/v1/admin/projects/ProjectsEditMember';
import V1ProjectDetails from './Pages/v1/admin/projects/ProjectDetails';
import V1ProjectEditDetails from './Pages/v1/admin/projects/ProjectEditDetails';
import V1UsersIndex from './Pages/v1/admin/users/UsersIndex';
import V1UserAdd from './Pages/v1/admin/users/UserAdd';
import V1UserDetails from './Pages/v1/admin/users/UserDetails';
import V1UserInfo from './Pages/v1/admin/users/UserInfo';
import V1ReportsIndex from './Pages/v1/admin/reports/ReportsIndex';
import V1ReportsAvailableResource from './Pages/v1/admin/reports/ReportsAvailableResource';
import V1ReportsAvailableResourceFilter from './Pages/v1/admin/reports/ReportsAvailableResourceFilter';
import V1ReportDetails from './Pages/v1/admin/reports/ReportsDetails';
import V1EvaluationScheduleDetail from './Pages/v1/admin/evaluation/EvaluationScheduleDetail';
import V1EvaluationAddSchedule from './Pages/v1/admin/evaluation/EvaluationAddSchedule';
import V1EvaluationAddTemplate from './Pages/v1/admin/evaluation/EvaluationAddTemplate';
import V1EvaluationTemplateDetails from './Pages/v1/admin/evaluation/EvaluationTemplateDetails';
import V1UserEvaluation from './Pages/v1/user/evaluation/UserEvaluation';
import V1BossEvaluation from './Pages/v1/user/evaluation/BossEvaluation';
import V1EvaluationUser from './Pages/v1/user/evaluation/EvaluationUser';
import V1EvaluationSchedule from './Pages/v1/admin/evaluation/EvaluationSchedule';
import V1EvaluationTemplate from './Pages/v1/admin/evaluation/EvaluationTemplate';
import V1MyEvaluation from './Pages/v1/user/evaluation/MyEvaluation';
import V1ForEvaluation from './Pages/v1/user/evaluation/ForEvaluation';

// New Version
import Information from './Pages/user/information/Information';
import ProjectsUser from './Pages/user/projects/ProjectsUser';
import SkillsAndTraining from './Pages/user/skills/Skills';
import SkillsAndTrainingEdit from './Pages/user/skills/SkillsEdit';
import ProjectsIndex from './Pages/projects/ProjectsIndex';
import UsersIndex from './Pages/admin/users/UsersIndex';
import UserAdd from './Pages/admin/users/UserAdd';
import UserDetails from './Pages/admin/users/UserDetails';
import UserInfo from './Pages/admin/users/UserInfo';
import UserGroup from './Pages/admin/users/UserGroup';
import ReportsIndex from './Pages/admin/reports/ReportsIndex';
import ReportsAvailableResource from './Pages/admin/reports/ReportsAvailableResource';
import ReportsAvailableResourceFilter from './Pages/admin/reports/ReportsAvailableResourceFilter';
import ReportDetails from './Pages/admin/reports/ReportsDetails';
import EvaluationScheduleDetail from './Pages/admin/evaluation/EvaluationScheduleDetail';
import EvaluationAddSchedule from './Pages/admin/evaluation/EvaluationAddSchedule';
import EvaluationAddTemplate from './Pages/admin/evaluation/EvaluationAddTemplate';
import EvaluationTemplateDetails from './Pages/admin/evaluation/EvaluationTemplateDetails';
import UserEvaluation from './Pages/user/evaluation/UserEvaluation';
import BossEvaluation from './Pages/user/evaluation/BossEvaluation';
import EvaluationUser from './Pages/user/evaluation/EvaluationUser';
import EvaluationSchedule from './Pages/EvaluationSchedule/EvaluationSchedule';
// import EvaluationScheduleView from './Pages/EvaluationSchedule/EvaluationScheduleView';
// import EvaluationScheduleAdd from './Pages/EvaluationSchedule/EvaluationScheduleAdd';
// import EvaluationScheduleEdit from './Pages/EvaluationSchedule/EvaluationScheduleEdit';
import HandleEvaluationSchedule from './Pages/EvaluationSchedule/HandleEvaluationSchedule';
import EvaluationTemplate from './Pages/admin/evaluation/EvaluationTemplate';
import EvaluationEntry from './Pages/admin/evaluation/EvaluationEntry';
import EvaluationEntryNew from './Pages/admin/evaluation/EvaluationEntryNew';
import EvaluationEntrySingle from './Pages/admin/evaluation/EvaluationEntrySingle';
import MyEvaluations from './Pages/admin/evaluation/MyEvaluation';
import MyEvaluationSingle from './Pages/admin/evaluation/MyEvaluationSingle';
import EvaluationTemplateIndex from './Pages/EvaluationTemplate/EvaluationTemplateIndex';
import MyEvaluation from './Pages/user/evaluation/MyEvaluation';
import EvaluationResult from './Pages/admin/evaluation/EvaluationResult';
import EvaluationResultSingle from './Pages/admin/evaluation/EvaluationResultSingle';
import EvaluationResultSinglePDF from './Pages/admin/evaluation/EvaluationResultSinglePDF';
import ForEvaluation from './Pages/user/evaluation/ForEvaluation';
import HandleEvaluationTemplate from './Pages/EvaluationTemplate/HandleEvaluationTemplate';
import ForgotPassword from './Pages/password/ForgotPassword';
import ForgotPasswordSent from './Pages/password/ForgotPasswordSent';
import CreatePassword from './Pages/password/CreatePassword';
import PasswordReset from './Pages/password/PasswordReset';
import ActivateAccount from './Pages/account/ActivateAccount';
import EmployeeInformation from './Pages/admin/employee/EmployeeInformation';
import CompanyInformation from './Pages/CompanyInformation/CompanyInformation';
import CostCenter from './Pages/CostCenter/Index';
import Department from './Pages/CostCenter/Department/Index';
import Division from './Pages/CostCenter/Division/Index';
import Section from './Pages/CostCenter/Section/Index';
import SubSection from './Pages/CostCenter/SubSection/Index';
import QuizManager from './Pages/LearningManagement/QuizManager/Index';
import HandleQuiz from './Pages/LearningManagement/QuizManager/HandleQuiz';
import TakeQuiz from './Pages/LearningManagement/TakeQuiz/Index';
import QuizResult from './Pages/LearningManagement/TakeQuiz/QuizResult';
import Question from './Pages/LearningManagement/Question/Index';
import QuizQuestion from './Pages/LearningManagement/QuizManager/QuizQuestion';
import HandleQuestion from './Pages/LearningManagement/Question/HandleQuestion';
import Assessment from './Pages/LearningManagement/Assessment/Index';
import AssessmentAction from './Pages/LearningManagement/Assessment/AssessmentAction';
import MyAssessment from './Pages/LearningManagement/MyAssessment/Index';
import MyAssessmentAction from './Pages/LearningManagement/MyAssessment/MyAssessmentAction';
import AssessmentUserList from './Pages/LearningManagement/Assessment/HandleAssessmentUserList';
import AssessmentResult from './Pages/LearningManagement/Assessment/AssessmentResult';
import MyAssessmentResult from './Pages/LearningManagement/MyAssessment/MyAssessmentResult';
import Holidays from './Pages/CalendarSettings/Holidays/Index';
import CompanyEvents from './Pages/CalendarSettings/CompanyEvents/Index';
import Dashboard from './Pages/Dashboard/Index';
import LeaveTypes from './Pages/Timekeeping/LeaveTypes/Index';
import Announcements from './Pages/Announcements/Announcements';
import AnnouncementSingle from './Pages/Announcements/AnnouncementSingle';
import ShiftList from './Pages/Timekeeping/ShiftList/Index';
import DayCodes from './Pages/CalendarSettings/DayCodes/Index';
import EvaluationEntrySinglePDF from './Pages/admin/evaluation/EvaluationEntrySinglePDF';

const Routers = () => {
  const userInfo = useSelector(({ user }) => user.user);
  const { menus } = useUserAccess();

  return (
    <Routes>
      <Route path="page-not-found" element={<PageNotFound />} />
      <Route path="access-denied" element={<AccessDenied />} />
      {_.isEmpty(userInfo?.user) ? (
        <>
          <Route path="*" element={<Login />} />
          <Route path="forgot-password/sent" element={<ForgotPasswordSent />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="create-password" element={<CreatePassword />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="activate-account" element={<ActivateAccount />} />
        </>
      ) : (
        <>
          <Route
            path="/*"
            element={
              <MainView>
                <Routes>
                  {/* Version 1 */}
                  <Route path="v1">
                    <Route index element={<Navigate to="/dashboard" />} />
                    <Route path="info" element={<V1Information />} />
                    <Route path="projects" element={<V1ProjectsUser />} />
                    <Route path="project/:id" element={<V1ProjectDetails />} />s
                    <Route path="skills" element={<V1SkillsAndTraining />} />
                    <Route path="evaluations" element={<V1EvaluationUser />}>
                      <Route index element={<Navigate to="my-evaluations" />} />
                      <Route path=":id" element={<V1UserEvaluation />} />
                      <Route path="boss/:id" element={<V1BossEvaluation />} />
                      <Route
                        path="my-evaluations"
                        element={<V1MyEvaluation />}
                      />
                      <Route
                        path="for-evaluations"
                        element={<V1ForEvaluation />}
                      />
                    </Route>
                  </Route>

                  {/* New Version */}
                  <Route path="/">
                    <Route index element={<Navigate to="/dashboard" />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="info" element={<Information />} />
                    <Route path="projects" element={<ProjectsUser />} />
                    <Route path="project/:id" element={<V1ProjectDetails />} />
                    <Route path="skills" element={<SkillsAndTraining />} />
                    <Route path="evaluations" element={<EvaluationUser />}>
                      <Route index element={<Navigate to="my-evaluations" />} />
                      <Route path=":id" element={<UserEvaluation />} />
                      <Route path="boss/:id" element={<BossEvaluation />} />
                      <Route path="my-evaluations" element={<MyEvaluation />} />
                      <Route
                        path="for-evaluations"
                        element={<ForEvaluation />}
                      />
                    </Route>
                  </Route>
                  {/* Version 1 */}
                  <Route path="v1">
                    <Route index element={<Navigate to="/users" />} />
                    <Route path="project">
                      <Route index element={<V1Projects />} />
                      <Route path="add" element={<V1ProjectsAddProject />} />
                      <Route path=":id" element={<V1ProjectDetails />} />
                      <Route
                        path=":id/add-member"
                        element={<V1ProjectsAddMember />}
                      />
                      <Route
                        path=":id/edit-member/:memberid"
                        element={<V1ProjectsEditMember />}
                      />
                      <Route
                        path=":id/edit"
                        element={<V1ProjectEditDetails />}
                      />
                    </Route>

                    <Route path="users">
                      <Route index element={<V1UsersIndex />} />
                      <Route path="add" element={<V1UserAdd />} />
                      <Route path=":id" element={<V1UserDetails />} />
                      <Route path=":id/info" element={<V1UserInfo />} />
                      <Route
                        path=":id/skills/edit"
                        element={<V1SkillsAndTrainingEdit />}
                      />
                    </Route>

                    <Route path="reports">
                      <Route index element={<V1ReportsIndex />} />
                      <Route path="available-resource">
                        <Route index element={<V1ReportsAvailableResource />} />
                        <Route
                          path="available-resource-filter"
                          element={<V1ReportsAvailableResourceFilter />}
                        />
                      </Route>
                    </Route>

                    <Route path="report/:id" element={<V1ReportDetails />} />

                    <Route path="evaluations">
                      <Route index element={<V1EvaluationSchedule />} />
                      <Route path="schedules">
                        <Route index element={<V1EvaluationSchedule />} />
                        <Route
                          path="add"
                          element={<V1EvaluationAddSchedule />}
                        />
                        <Route
                          path=":id"
                          element={<V1EvaluationScheduleDetail />}
                        />
                      </Route>
                      <Route path="templates">
                        <Route index element={<V1EvaluationTemplate />} />
                        <Route
                          path="add"
                          element={<V1EvaluationAddTemplate />}
                        />
                        <Route
                          path=":id"
                          element={<V1EvaluationTemplateDetails />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  {/* New Version */}
                  <Route path="/">
                    <Route index element={<Navigate to="/users" />} />
                    {/* <Route path="group" element={<UserGroup />} /> */}
                    <Route path="announcements">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<Announcements menuCode="ST07" />}
                          />
                        }
                      />
                      <Route
                        path=":id"
                        element={<AnnouncementSingle menuCode="ST08" />}
                      />
                    </Route>
                    <Route path="projects">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<ProjectsIndex menuCode="RM02" />}
                          />
                        }
                      />
                    </Route>

                    <Route path="users">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<UsersIndex menuCode="ST01" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="users-access">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<UserGroup menuCode="ST02" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="company-information">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<CompanyInformation menuCode="ST03" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="day-codes">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<DayCodes menuCode="ST06" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="holidays">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<Holidays menuCode="ST04" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="company-events">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<CompanyEvents menuCode="ST05" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="employees">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<EmployeeInformation menuCode="RM01" />}
                          />
                        }
                      />
                    </Route>

                    <Route path="reports">
                      <Route index element={<ReportsIndex />} />
                      <Route path="available-resource">
                        <Route index element={<ReportsAvailableResource />} />
                        <Route
                          path="available-resource-filter"
                          element={<ReportsAvailableResourceFilter />}
                        />
                      </Route>
                    </Route>

                    <Route path="report/:id" element={<ReportDetails />} />

                    <Route path="evaluation-templates">
                      <Route
                        index
                        element={<EvaluationTemplateIndex menuCode="PE01" />}
                      />
                      <Route
                        path="add"
                        element={<HandleEvaluationTemplate menuCode="PE01" />}
                      />
                      <Route path="edit">
                        <Route
                          path=":id"
                          element={<HandleEvaluationTemplate menuCode="PE01" />}
                        />
                      </Route>
                      <Route path="view">
                        <Route
                          path=":id"
                          element={
                            <HandleEvaluationTemplate
                              menuCode="PE01"
                              viewOnly
                            />
                          }
                        />
                      </Route>
                      <Route
                        path=":id"
                        element={<EvaluationTemplateDetails />}
                      />
                    </Route>
                    <Route path="evaluation-schedule">
                      <Route
                        index
                        element={<EvaluationSchedule menuCode="PE02" />}
                      />
                      <Route
                        path="add"
                        element={<HandleEvaluationSchedule menuCode="PE02" />}
                      />
                      <Route path="edit">
                        <Route
                          path=":id"
                          element={<HandleEvaluationSchedule menuCode="PE02" />}
                        />
                      </Route>
                      <Route path="view">
                        <Route
                          path=":id"
                          element={
                            <HandleEvaluationSchedule
                              menuCode="PE02"
                              viewOnly
                            />
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="evaluation-entry">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<EvaluationEntryNew menuCode="PE03" />}
                          />
                        }
                      />
                      <Route
                        path=":id"
                        element={<EvaluationEntrySingle menuCode="PE03" />}
                      />
                      <Route
                        path="schedule/:id"
                        element={<EvaluationEntry menuCode="PE03" />}
                      />
                    </Route>
                    <Route path="my-evaluation">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<MyEvaluations menuCode="PE04" />}
                          />
                        }
                      />
                      <Route
                        path=":id"
                        element={<MyEvaluationSingle menuCode="PE04" />}
                      />
                    </Route>
                    <Route path="evaluation-results">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<EvaluationResult menuCode="PE05" />}
                          />
                        }
                      />
                      <Route
                        path=":id"
                        element={<EvaluationResultSingle menuCode="PE05" />}
                      />
                    </Route>
                    <Route path="cost-center">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<CostCenter menuCode="CC01" />}
                          />
                        }
                      />
                      <Route
                        path="division"
                        element={
                          <PrivateRoute
                            element={<Division menuCode="CC02" />}
                          />
                        }
                      />
                      <Route
                        path="department"
                        element={
                          <PrivateRoute
                            element={<Department menuCode="CC03" />}
                          />
                        }
                      />
                      <Route
                        path="section"
                        element={
                          <PrivateRoute element={<Section menuCode="CC04" />} />
                        }
                      />
                      <Route
                        path="sub-section"
                        element={
                          <PrivateRoute
                            element={<SubSection menuCode="CC05" />}
                          />
                        }
                      />
                    </Route>
                    <Route path="quiz-manager">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<QuizManager menuCode="LM03" />}
                          />
                        }
                      />
                      <Route
                        path="add"
                        element={<HandleQuiz menuCode="LM03" />}
                      />
                      <Route path="edit">
                        <Route
                          path=":id"
                          element={<HandleQuiz menuCode="LM03" />}
                        />
                      </Route>
                      <Route path="view">
                        <Route
                          path=":id"
                          element={<HandleQuiz menuCode="LM03" viewOnly />}
                        />
                      </Route>
                      <Route
                        path=":id/questions"
                        element={<QuizQuestion menuCode="LM03" viewOnly />}
                      />
                    </Route>
                    {/* start questions route */}
                    <Route path="questions">
                      <Route index element={<Question menuCode="LM04" />} />
                      <Route
                        path="add"
                        element={<HandleQuestion menuCode="LM04" />}
                      />
                      <Route path="edit">
                        <Route
                          path=":question_id"
                          element={<HandleQuestion menuCode="LM04" />}
                        />
                      </Route>
                      <Route
                        path="view/:question_id"
                        element={<HandleQuestion menuCode="LM04" viewOnly />}
                      />
                    </Route>
                    {/* end questions route */}
                    {/* start assessments route */}
                    <Route path="assessments">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<Assessment menuCode="LM01" />}
                          />
                        }
                      />
                      <Route
                        path="add"
                        element={<AssessmentAction menuCode="LM01" />}
                      />
                      <Route path="edit">
                        <Route
                          path=":id"
                          element={<AssessmentAction menuCode="LM01" />}
                        />
                      </Route>
                      <Route path="view">
                        <Route
                          path=":id"
                          element={
                            <AssessmentAction menuCode="LM01" viewOnly />
                          }
                        />
                      </Route>
                      <Route path="users">
                        <Route
                          path=":id"
                          element={
                            <AssessmentUserList menuCode="LM01" viewOnly />
                          }
                        />
                      </Route>
                      <Route
                        path="users/:assessment_id/result/:user_assessment_id"
                        element={<AssessmentResult menuCode="LM01" />}
                      />
                    </Route>
                    {/* end assessments route */}
                    {/* start my assessments route */}
                    <Route path="my-assessments">
                      <Route
                        index
                        element={
                          <PrivateRoute
                            element={<MyAssessment menuCode="LM05" />}
                          />
                        }
                      />
                      <Route path="view">
                        <Route
                          path=":id"
                          element={
                            <MyAssessmentAction menuCode="LM05" viewOnly />
                          }
                        />
                      </Route>
                      <Route path="take-quiz">
                        <Route
                          path=":id"
                          element={<TakeQuiz menuCode="LM05" />}
                        />
                      </Route>
                      <Route
                        path="results/:assessment_id/:user_assessment_id"
                        element={
                          <MyAssessmentResult menuCode="LM05" viewOnly />
                        }
                      />
                    </Route>
                    {/* end my assessments route */}

                    <Route
                      path="shift-list"
                      element={
                        <PrivateRoute element={<ShiftList menuCode="TK12" />} />
                      }
                    />
                    <Route
                      path="leave-types"
                      element={
                        <PrivateRoute
                          element={<LeaveTypes menuCode="TK09" />}
                        />
                      }
                    />
                    <Route path="dashboard">
                      <Route index element={<Dashboard menuCode="TK01" />} />
                    </Route>
                  </Route>
                  <Route
                    path="*"
                    element={<Navigate replace to="/page-not-found" />}
                  />
                </Routes>
              </MainView>
            }
          />
          <Route
            path="evaluation-results/:id/pdf"
            element={<EvaluationResultSinglePDF menuCode="PE05" />}
          />
          <Route
            path="evaluation-entry/:id/pdf"
            element={<EvaluationEntrySinglePDF menuCode="PE03" />}
          />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Routers;
