import { useEffect, useState } from 'react';

const useApexChart = evaluationProgress => {
  // New | Active | Fulfilled | Cancelled '#DE5858'
  const COLORS = ['#e8f1ff', '#23B53A', '#468EFF'];
  const [series, setSeries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (evaluationProgress) {
      const { new: fresh, pending, completed, cancelled } = evaluationProgress;
      const loadData = () => {
        setSeries([fresh, pending, completed]);
        setChartOptions({
          chart: {
            width: 300,
            type: 'donut'
          },
          colors: COLORS,
          labels: ['Not Started', 'Ongoing', 'Completed'],
          plotOptions: {
            pie: {
              startAngle: 0, // -90,
              endAngle: 360, // 270,
              customScale: 1.1,
              donut: {
                size: '0%'
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'Stolzl Medium, sans-serif',
              fontWeight: 'normal',
              fontSize: '12px'
            }
          },
          fill: {
            type: 'gradient'
          },
          legend: {
            formatter: (val, opts) => {
              return `${val}: ${opts.w.globals.series[opts.seriesIndex]}`;
            },
            fontFamily: 'Stolzl Medium, sans-serif',
            fontSize: '10px',
            fontWeight: 'normal',
            labels: {
              colors: '#414141',
              useSeriesColors: false
            }
          },
          tooltip: {
            style: {
              fontFamily: 'Stolzl Medium, sans-serif',
              fontWeight: 'normal',
              fontSize: '12px'
            },
            theme: 'light'
          }
        });
        setLoading(false);
      };
      loadData();
    }
  }, [evaluationProgress]);

  return { series, chartOptions, loading };
};

export default useApexChart;
