import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getShiftByIDSevice,
  getShiftsServiceByPage
} from 'src/api/modules/shifts';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { MdOutlineClear } from 'react-icons/md';
import { getShifts } from 'src/redux/modules/shifts/shiftsActions';

export const useDeleteModal = ({
  handleDeleteModal,
  editModal,
  code,
  modal
}) => {
  const dispatch = useDispatch();
  const [shiftDetail, setShiftDetail] = useState(null);

  const fetchNewShiftList = async () => {
    getShiftsServiceByPage(1)
      .then(res => {
        dispatch(getShifts(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (code) {
      getShiftByIDSevice(code, 'GET').then(res => {
        const codeDetail = `Shift Code: ${res.data.shift_code}`;
        setShiftDetail(codeDetail);
      });
    }
  }, []);

  const handleDelete = async codeId => {
    if (codeId) {
      const res = await getShiftByIDSevice(codeId, 'DELETE');
      if (res.success) {
        toast.success('Successfully Deleted!', { icon: <CgCheckO /> });
        fetchNewShiftList();
      } else if (res.response.status === 405)
        toast.error('Unable to delete!', { icon: <CgCloseO /> });
      handleDeleteModal(modal);
      if (editModal) {
        editModal(null);
      }
    }
  };
  return {
    shiftDetail,
    handleDelete
  };
};
