import PropTypes from 'prop-types';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';
import { useBulkDeleteModal } from 'src/hooks/components/Parts/Announcements/useBulkDeleteModal';

const BulkDeleteModal = ({ handleBulkDeleteModal, apiService, endpoint }) => {
  const { handleDelete, ids } = useBulkDeleteModal({
    handleBulkDeleteModal,
    endpoint,
    apiService
  });

  return (
    <DeleteModalUI
      label={`the selected ${ids.length} ${ids.length > 1 ? 'items' : 'item'}`}
      submit={() => handleDelete()}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteModal.propTypes = {
  handleBulkDeleteModal: PropTypes.func,
  endpoint: PropTypes.string,
  apiService: PropTypes.func
};
export default BulkDeleteModal;
