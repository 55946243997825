import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import axios from 'axios';
import {
  SHIFTS,
  SHIFT,
  SHIFT_UPDATE,
  SHIFT_ADD,
  SHIFT_DELETE,
  GET_EMPLOYEE_SHIFT_BY_ID
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getShiftsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SHIFTS}?page=${1}`
    });
  } catch (error) {
    return error;
  }
};

export const getShiftsServiceByPage = async (page, searchParams) => {
  try {
    let endpoint = `${SHIFTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getShiftByIDSevice = async (id, method) => {
  try {
    let url = SHIFT;
    if (method === 'DELETE') url = SHIFT_DELETE;
    return await fetchAPI({
      method,
      endpoint: `${url}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateShiftByIDSevice = async data => {
  const payload = { ...data };
  payload.is_default = payload.is_default ? 'true' : 'false';
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${SHIFT_UPDATE}`,
      body: payload
    });
  } catch (error) {
    return error;
  }
};

export const addShiftService = async data => {
  const payload = { ...data };
  delete payload.id;
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `${SHIFT_ADD}`,
      body: payload
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteShiftsService = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getShiftsIsDefaultService = async page => {
  try {
    const endpoint = `${SHIFTS}?page=${page}&is_default=true`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getEmployeeShiftById = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${GET_EMPLOYEE_SHIFT_BY_ID}/${id}`
    });
  } catch (error) {
    return error;
  }
};
