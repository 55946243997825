import {
  getHolidayService,
  getAllHolidaysService
} from 'src/api/modules/holidays';
import {
  GET_HOLIDAYS_LIST,
  GET_ALL_HOLIDAYS_ITEM,
  SET_HOLIDAY_UPDATE,
  FETCH_HOLIDAYS
} from './holidayTypes';

export function setHolidayList(payload) {
  return { type: FETCH_HOLIDAYS, payload };
}

export function setHolidays(data) {
  return { type: GET_HOLIDAYS_LIST, payload: data };
}

export function setAllHolidays(data) {
  return { type: GET_ALL_HOLIDAYS_ITEM, payload: data };
}

export function setHolidayListUpdate(payload) {
  return { type: SET_HOLIDAY_UPDATE, payload };
}

export function getHolidays() {
  return async function (dispatch) {
    const response = await getAllHolidaysService();
    if (response?.success && response?.data)
      dispatch(setHolidays(response.data));
  };
}

export function getAllHolidays() {
  return async function (dispatch) {
    const response = await getAllHolidaysService();
    if (response?.success && response?.data)
      dispatch(setAllHolidays(response.data));
  };
}
