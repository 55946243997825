export function createDictionary(list, type = 'evaluators') {
  return list.reduce((accumulator, currentValue) => {
    if (type === 'evaluators') {
      const { evaluation_id, section_id, evaluator_id, section_index } =
        currentValue || {};

      if (section_id != null && evaluator_id != null && section_index != null) {
        if (!accumulator[evaluation_id]) {
          accumulator[evaluation_id] = [];
        }

        const existingEntry = accumulator[evaluation_id].find(
          entry => entry.section_id === section_id
        );

        if (existingEntry) {
          // Update existing entry
          existingEntry.evaluator_id = evaluator_id;
        } else {
          // Add new entry
          accumulator[evaluation_id].push({
            section_id,
            evaluator_id,
            section_index
          });
        }
      }
    }

    if (type === 'templates') {
      const { evaluation_id, template_id, template_name } = currentValue || {};

      if (template_id != null && template_name != null) {
        if (!accumulator[evaluation_id]) {
          accumulator[evaluation_id] = [];
        }
        accumulator[evaluation_id] = [{ template_id, template_name }];
      }
    }

    if (type === 'defaultEvaluators') {
      const { evaluation_id, section_id, evaluator_id, section_index } =
        currentValue || {};

      if (!accumulator[evaluation_id]) {
        accumulator[evaluation_id] = [];
      }

      const existingEntry = accumulator[evaluation_id].find(
        entry => entry.section_id === section_id
      );

      if (existingEntry) {
        // Update existing entry
        existingEntry.evaluator_id = evaluator_id;
      } else {
        // Add new entry
        accumulator[evaluation_id].push({
          section_id,
          evaluator_id,
          section_index
        });
      }
    }

    return accumulator;
  }, {});
}
