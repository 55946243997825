/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

const useAddEditModal = ({ handleModal, eventsId, dataList }) => {
  const { user } = useSelector(state => state?.user?.user);
  const userByID = useSelector(state => state?.user?.user);
  const [isFixedCheckboxDisabled, setIsFixedCheckboxDisabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [isDoesNotRepeat, setIsDoesNotRepeat] = useState(false);
  const [endsType, setEndsType] = useState('on');
  const [isDateEnabled, setIsDateEnabled] = useState(true);
  const [isOccurrencesEnabled, setIsOccurrencesEnabled] = useState(false);
  const [dayChecked, setDayChecked] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false
  });
  const [formData, setFormData] = useState({
    events_name: '',
    start_date: '',
    end_date: '',
    occurrence: 'D',
    color: '',
    repeat_every_count: null,
    repeat_every_unit: '',
    day: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false
    }
  });

  const initialDummyData = {
    event_name: 'TEST',
    start_date: '2024-01-01',
    end_date: '2024-01-02',
    occurrence: 'Does not repeat',
    color: '#ED9B9C',
    repeat_every_count: 1,
    repeat_every_unit: 'week',
    day: {}
  };

  const handleColorChange = color => {
    setFormData(prevState => ({
      ...prevState,
      color
    }));
  };

  const handleDeleteModal = useCallback(
    (modal, editModalName, id = null) => {
      setShowDeleteModal(modal);
      // setCodeNumber(modal ? id : null);
      setEditModal(editModalName);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setEditModal]
  );

  const handleTrimSpaces = e => {
    const { name, value } = e.target;
    let trimEndValue = '';
    if (typeof value === 'string' && value.length > 1) {
      trimEndValue = value.trimEnd();
      setFormData(prevState => ({
        ...prevState,
        [name]: trimEndValue
      }));
    }
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    const initialValue = value.match(/^\s/) !== null;

    if (name === 'event_name' && initialValue) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.trim()
      }));
    } else {
      let modifiedValue = value;

      if (name === 'id_no') {
        modifiedValue = parseInt(value, 10);
      }

      setFormData(prevState => ({
        ...prevState,
        [name]: modifiedValue
      }));
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const handSelectChange = useCallback(
    (value, name, setFieldTouched, setFieldError) => {
      const modifiedName = name.split('_').slice(0, 1);
      const modifiedValue = value;

      setFormData(prevState => ({
        ...prevState,
        [modifiedName]: modifiedValue
      }));

      setFieldTouched(modifiedName, true);
      setFieldError(modifiedName, '');
    },
    [setFormData]
  );

  const validationSchema = Yup.object().shape({
    event_name: Yup.string().required('Event name is required')
  });

  const handleSubmit = useCallback(async () => {
    // if (eventsId) {
    //   // EDIT
    //   updateEvents(eventsId, formData);
    // } else {
    //   // ADD
    //   addDivisionCode(formData);
    // }
  });

  // const uniqueOccurrences = [...new Set(events.map(event => event.occurrence))];

  // const handleSelectChange = (value, name, setFieldTouched, setFieldError) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  //   setFieldTouched(name, true);
  //   setFieldError(name, '');
  //   console.log(formData.occurrence);
  // };

  const handleSelectChange = useCallback(
    (value, name, setFieldTouched, setFieldError) => {
      let modifiedName = name;
      let modifiedValue = value;

      if (name === 'industry_id') {
        modifiedName = 'industry_id';
        modifiedValue = parseInt(value, 10);
      } else if (name === 'occurrence') {
        modifiedName = 'occurrence';
      } else if (name === 'project_role_id') {
        // Add this block
        modifiedName = 'project_role_id';
        modifiedValue = parseInt(value, 10);
      }

      setFormData(prevState => ({
        ...prevState,
        [modifiedName]: modifiedValue
      }));

      setFieldTouched(modifiedName, true);
      setFieldError(modifiedName, '');
    },
    [setFormData]
  );

  const handleDayChange = day => {
    setDayChecked(prevState => ({
      ...prevState,
      [day]: !prevState[day]
    }));
  };
  const handleEndsTypeChange = value => {
    setEndsType(value);
    if (value === 'on') {
      setIsDateEnabled(true);
      setIsOccurrencesEnabled(false);
    } else {
      setIsDateEnabled(false);
      setIsOccurrencesEnabled(true);
    }
  };

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      occurrence: 'Does not repeat'
    }));
  }, []);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      day: { ...dayChecked }
    }));
  }, [dayChecked]);

  useEffect(() => {
    setIsDoesNotRepeat(formData.occurrence === 'Does not repeat');
  }, [formData.occurrence]);

  useEffect(() => {
    setFormData(initialDummyData);
  }, []);

  useEffect(() => {
    if (user?.is_system_admin) {
      setIsFixedCheckboxDisabled(false);
    } else {
      setIsFixedCheckboxDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (eventsId) {
      const events = dataList.find(e => e.id === eventsId);
      if (events) {
        setFormData({
          event_name: events.event_name,
          start_date: events.start_date,
          end_date: events.end_date,
          occurrence: events.occurrence,
          color: events.color
        });
      }
    } else {
      setFormData({
        event_name: '',
        start_date: '',
        end_date: '',
        occurrence: '',
        color: '',
        description: ''
      });
    }
  }, [eventsId, dataList]);

  return {
    // uniqueOccurrences,
    isFixedCheckboxDisabled,
    formData,
    initialDummyData,
    validationSchema,
    showDeleteModal,
    isDoesNotRepeat,
    editModal,
    dayChecked,
    endsType,
    isDateEnabled,
    isOccurrencesEnabled,
    handSelectChange,
    handleDeleteModal,
    handleColorChange,
    handleChange,
    handleTrimSpaces,
    handleSubmit,
    handleSelectChange,
    handleDayChange,
    handleEndsTypeChange
  };
};

export default useAddEditModal;
