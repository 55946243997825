import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useEmployees(evaluationId) {
  const { allEmployees } = useSelector(state => state.employees);
  const employees = useMemo(
    () =>
      allEmployees?.map(
        ({ id, first_name, middle_name, last_name, suffix, status }) => {
          const checkSuffix = ['', null].every(nullish => nullish !== suffix);
          const hasSuffix = checkSuffix ? ` ${suffix}` : '';
          const hasMiddleName = middle_name
            ? `${Array.from(middle_name)[0]}. `
            : '';
          const name = `${first_name} ${hasMiddleName}${last_name}${hasSuffix}`;
          return { id, name, status };
        }
      ),
    [evaluationId]
  );
  return { employees, allEmployees };
}
