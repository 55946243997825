/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  getHolidaysService,
  getHolidayByIDService,
  updateHolidayService,
  createHolidayService,
  deleteHolidayService,
  updateHolidayById,
  addHolidayService
} from 'src/api/modules/holidays';
import { DateTime } from 'luxon';
import {
  getHolidays,
  getAllHolidays,
  setHolidayListUpdate
} from 'src/redux/modules/calendar/holidayActions';
import { toast } from 'react-toastify';
import { getDaycodeItem } from 'src/api/modules/daycode';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import { TOAST_OPTIONS } from 'src/helpers/constants';
import { MdOutlineErrorOutline } from 'react-icons/md';
import moment from 'moment';

const useAddEditModal = ({ handleModal, holidayId, dataList }) => {
  const dispatch = useDispatch();
  const [daycodeOptions, setDaycodeOptions] = useState([]);
  const [isFixedCheckboxDisabled, setIsFixedCheckboxDisabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });
  const holidayFlag = useSelector(state => state?.holiday?.holidayList);
  const [selectedDayCode, setSelectedDayCode] = useState('');

  const [formData, setFormData] = useState({
    holiday_name: '',
    fiscal_year: null,
    holiday_date: '',
    day_code_id: null,
    is_fixed: false,
    color: '#BEBCBC',
    description: ''
  });

  const initialDummyData = {
    holiday_name: 'TEST',
    fiscal_year: 2024,
    holiday_date: '2024-01-01',
    day_code_id: 6,
    is_fixed: true,
    color: '#E0726D',
    description: 'TEST'
  };

  const fetchDaycodes = async () => {
    const response = await getDaycodeItem();
    const daycodes = response.data.map(daycode => ({
      label: daycode.day_code,
      value: daycode.id
    }));

    setDaycodeOptions(daycodes);
  };

  const handleSelectChange = useCallback(
    (value, name, setFieldTouched, setFieldError) => {
      let modifiedName = name;
      let modifiedValue = value;

      if (name === 'day_code_id') {
        modifiedName = 'day_code_id';
        modifiedValue = parseInt(value, 10);
      }

      setFormData(prevState => ({
        ...prevState,
        [modifiedName]: modifiedValue
      }));

      setFieldTouched(modifiedName, true);
      setFieldError(modifiedName, '');
    },
    [setFormData]
  );

  function convertDateWithMoment(dateString) {
    const formattedDate = moment(dateString, 'MM/DD/YYYY').format('yyyy-MM-DD');
    return formattedDate;
  }

  const fetchHolidays = async holidayId => {
    try {
      if (!holidayId || holidayId === 'all') {
        return;
      }
      const response = await getHolidayByIDService(holidayId);

      if (response.data) {
        const responseData = response.data;
        if (responseData.holidays && Array.isArray(responseData.holidays)) {
          const modifiedHolidays = responseData.holidays.map(holiday => ({
            ...holiday,
            holiday_date: DateTime.fromFormat(
              holiday.holiday_date,
              'yyyy-MM-dd'
            ).toFormat('yyyy-MM-dd')
          }));
          responseData.holidays = modifiedHolidays;
        }
        responseData.holiday_date = DateTime.fromFormat(
          responseData.holiday_date,
          'MM/dd/yyyy'
        ).toFormat('yyyy-MM-dd');

        setFormData(responseData);
      }
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };

  const handleColorChange = color => {
    setFormData(prevState => ({
      ...prevState,
      color
    }));
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, holiday_name }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, holiday_name });
      setShowDeleteModal(modal);
    },
    [showDeleteModal]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteHolidayService(id);
      if (response?.success) {
        dispatch(getHolidays());
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        dispatch(getHolidays());
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }
    setShowDeleteModal(null);
  };

  const handleTrimSpaces = e => {
    const { name, value } = e.target;
    let trimEndValue = '';
    if (typeof value === 'string' && value.length > 1) {
      trimEndValue = value.trimEnd();
      setFormData(prevState => ({
        ...prevState,
        [name]: trimEndValue
      }));
    }
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
      }));
    } else {
      const initialValue = value.match(/^\s/) !== null;

      if (
        (name === 'holiday_name' && initialValue) ||
        (name === 'description' && initialValue)
      ) {
        setFormData(prevState => ({
          ...prevState,
          [name]: value.trim()
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: fieldValue
        }));
      }
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const colorValidator = value => {
    // Regular expression to match a hexadecimal color code
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;

    // Regular expression to match a CSS color name
    const cssColorNameRegex = /^(?:[a-z]+-)*[a-z]+$/i;

    // Check if the value is a valid hexadecimal color or a valid CSS color name
    if (!hexColorRegex.test(value) && !cssColorNameRegex.test(value)) {
      return false;
    }

    return true;
  };

  const validationSchema = Yup.object().shape({
    holiday_name: Yup.string().required('Required'),
    day_code_id: Yup.string().nullable().required('Required'),
    holiday_date: Yup.date().required('Required'),
    color: Yup.string()
      .test('is-color', 'Invalid color', colorValidator)
      .required('Required'),
    description: Yup.string().max(
      255,
      'Description must be at most 255 characters'
    )
  });

  const fetchUpdateHoliday = async () => {
    getHolidaysService(1)
      .then(() => {
        dispatch(getHolidays());
        dispatch(getAllHolidays());
      })
      .catch(err => {
        return err;
      });
  };

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateHoliday = async (id, payload) => {
    updateHolidayById(id, payload)
      .then(response => {
        if (response.success) {
          // fetchUpdateHoliday();
          fetchHolidays(holidayId);
          toast.success('Successfully Updated!', {
            icon: <CgCheckO />,
            toastId: id
          });
          handleModal(null);
        }
        setFormData(formData);
        dispatch(getHolidays());
      })
      .catch(err => {
        toast.error(err, {
          icon: <MdOutlineErrorOutline />,
          toastId: id
        });
      });
  };

  const addHoliday = async payload => {
    const response = await addHolidayService(payload);

    if (response.status === 201) {
      fetchHolidays(holidayId);
      toast.success('Successfully Added!', { icon: <CgCheckO /> });
      handleModal(null);
      dispatch(getHolidays());
    }
  };

  const handleSubmit = useCallback(async () => {
    // EDIT

    if (holidayId) {
      const payload = {
        holiday_name: formData.holiday_name,
        holiday_date: formData.holiday_date,
        day_code_id: formData.day_code_id,
        is_fixed: formData.is_fixed,
        color: formData.color,
        description: formData.description ?? '',
        fiscal_year: parseInt(new Date(formData.holiday_date).getFullYear(), 10)
      };

      updateHoliday(holidayId, payload);
    } else {
      // ADD
      const payload = {
        ...formData,
        day_code_id: parseInt(formData.day_code_id, 10),
        fiscal_year: parseInt(new Date(formData.holiday_date).getFullYear(), 10)
      };
      addHoliday(payload);
    }
  });

  useEffect(() => {
    if (holidayFlag?.is_fixed) {
      setIsFixedCheckboxDisabled(true);
    } else {
      setIsFixedCheckboxDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (holidayId) {
      fetchHolidays(holidayId);
    }
  }, []);

  useEffect(() => {
    fetchDaycodes();
  }, []);

  useEffect(() => {
    if (holidayId) {
      const holiday = dataList.find(e => e.id === holidayId);

      if (holiday) {
        setFormData({
          holiday_name: holiday.holiday_name,
          holiday_date: convertDateWithMoment(holiday.holiday_date),
          day_code_id: holiday.day_code_id ?? '',
          is_fixed: holiday.is_fixed === 'Yes',
          color: holiday.color,
          description: holiday.description || ''
        });
      }
    } else {
      setFormData({
        holiday_name: '',
        holiday_date: '',
        day_code_id: null,
        is_fixed: false,
        color: '#BEBCBC',
        description: ''
      });
    }
  }, [holidayId, dataList]);

  return {
    isFixedCheckboxDisabled,
    formData,
    initialDummyData,
    validationSchema,
    daycodeOptions,
    formLoading,
    showDeleteModal,
    deleteId,
    selectedDayCode,
    handleDeleteModal,
    handleDelete,
    handleColorChange,
    handleChange,
    handleTrimSpaces,
    handleSubmit,
    handleSelectChange
  };
};

export default useAddEditModal;
