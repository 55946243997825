import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Button } from 'src/components/Common';
import { Formik, Form } from 'formik';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import useEvaluationEntrySingle from 'src/hooks/Pages/admin/evaluation/useEvaluationEntrySingle';
import tooltipIcon from 'src/assets/icons/circle-question.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { cn } from 'src/helpers/utils';

const EvaluationEntrySingle = ({ id = null }) => {
  const {
    user,
    infoDataColumns,
    employeeDetail,
    scoreMultiInputsForm,
    evaluationGroupData,
    handleSubmit,
    handleChange,
    handleInputBlur,
    customError,
    isFulfilled,
    inputPlaceholder,
    handleFocus,
    handleCheckBossScore,
    handleCheckComments,
    textareaRefHandler,
    handlePDFDownload,
    firstName,
    scheduleData
  } = useEvaluationEntrySingle();

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Performance Evaluation' },
          { name: 'Evaluation Entry', link: '/evaluation-entry' },
          {
            name: scheduleData.name,
            link: `/evaluation-entry/schedule/${scheduleData.id}`
          },
          { name: firstName }
        ]}
      />
      <div className="flex items-start flex-wrap gap-x-[50px] gap-y-[10px] mt-6 mb-4">
        {infoDataColumns.map((item, i) => (
          <div key={`data-${item.key}`} className="mb-2">
            <h5 className="text-[14px] font-stolzlMedium">{item.label}</h5>
            <p className="text-[14px] font-stolzlBook">
              {employeeDetail[item.key]}
            </p>
          </div>
        ))}
        <div className="ml-auto" />
        <Button
          type="button"
          name="Evaluation Report"
          modifier="bg-gray-400 hover:bg-gray-500 text-white p-[8px_14px] text-[12px] leading-[24px] rounded"
          spanModifier={cn('before:absolute before:left-0 !pl-0')}
          onClick={handlePDFDownload}
        />
      </div>
      <Formik
        initialValues={scoreMultiInputsForm}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, setFieldTouched, setFieldError }) => (
          <Form>
            {evaluationGroupData.map((item, i) => (
              <table
                key={item.section_id}
                className="w-full mb-4 bg-white border border-[#E0E0E0]]"
              >
                <thead>
                  <tr>
                    {item.table?.map((column, sub_i) => (
                      <th
                        key={`data-${sub_i + 1}`}
                        className={cn(
                          'w-[125px] last:w-auto border-t border-t-[#DEDEDE] border-b border-b-[#DEDEDE] first:border-l first:border-l-[#DEDEDE] border-r border-r-[#DEDEDE] p-[10px] bg-[#F0F0F0] font-normal whitespace-nowrap',
                          column.key === 'name' && 'w-[380px]',
                          (column.key === 'target_details' ||
                            column.key === 'description_details') &&
                            'w-[300px]',
                          !sub_i
                            ? 'text-[15px] font-stolzlBold text-left'
                            : 'text-[14px] font-stolzlBook',
                          user.user.employee?.id === item.evaluator_id &&
                            'bg-[#DFEBFF]'
                        )}
                      >
                        {column.label || item.group_name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {item?.details?.map((column, sub_i) => (
                    <tr key={`data-${sub_i + 1}`}>
                      {item.table?.map((sub_column, sub_ii) => (
                        <td
                          key={`data-${sub_ii + 1}`}
                          className={cn(
                            'p-0 text-[15px] text-center font-stolzlMedium align-top border-b border-b-[#DEDEDE] first:border-l first:border-l-[#DEDEDE] border-r border-r-[#DEDEDE] first:text-left last:text-[14px] last:font-stolzlBook last:text-left',
                            ['R', 'T', 'C'].includes(item.type) &&
                              ['boss_score', 'boss_comment'].includes(
                                sub_column.key
                              ) &&
                              isFulfilled &&
                              'align-middle'
                          )}
                        >
                          <div>
                            {sub_column.key !== 'name' &&
                            user.user.employee?.id === item.evaluator_id ? (
                              <span
                                data-tooltip-id={
                                  sub_column.key === 'boss_score'
                                    ? `tooltip-${item.section_id}_${column.criteria_id}_${sub_column.key}`
                                    : ``
                                }
                                data-tooltip-content={
                                  sub_column.key === 'boss_score'
                                    ? `max score: ${column.max_score}`
                                    : ``
                                }
                              >
                                {['R', 'T', 'C'].includes(item.type) &&
                                (sub_column.key === 'description_details' ||
                                  isFulfilled) ? (
                                  <div className={cn('p-2 font-stolzlBook')}>
                                    {
                                      scoreMultiInputsForm[
                                        `evaluation_section_id-${item.section_id}`
                                      ][`criteria_id-${column.criteria_id}`][
                                        sub_column.key
                                      ]
                                    }
                                  </div>
                                ) : (
                                  <FormCard className="!mb-0">
                                    <FormField
                                      placeholder={inputPlaceholder(
                                        sub_column.key
                                      )}
                                      label={
                                        ['boss_comment', 'comment'].includes(
                                          sub_column.key
                                        ) && ['R', 'T', 'C'].includes(item.type)
                                          ? 'Required'
                                          : ''
                                      }
                                      required={
                                        ['boss_comment', 'comment'].includes(
                                          sub_column.key
                                        ) && ['R', 'T', 'C'].includes(item.type)
                                      }
                                      name={`${sub_column.key}-${item.type}-${item.section_id}-${column.criteria_id}`}
                                      type={
                                        sub_column.key === 'boss_score'
                                          ? 'number'
                                          : 'textarea'
                                      }
                                      labelModifier="!hidden"
                                      className={cn(
                                        'font-stolzlBook text-[14px] !rounded-none h-auto !h-[39px]__ border-[#fff] focus:border-[#888]',
                                        sub_column.key === 'boss_score' &&
                                          'text-center font-stolzlMedium',
                                        customError[
                                          `${sub_column.key}-${item.type}-${item.section_id}-${column.criteria_id}`
                                        ] &&
                                          '!bg-[#FBDFDB] !border-[#EF8D81] !focus:border-[#EF8D81] !focus:bg-[#FEFAFA] !text-[#F05848]'
                                      )}
                                      value={`${
                                        scoreMultiInputsForm[
                                          `evaluation_section_id-${item.section_id}`
                                        ][`criteria_id-${column.criteria_id}`][
                                          sub_column.key
                                        ]
                                      }`}
                                      onChange={e =>
                                        handleChange(
                                          e,
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError
                                        )
                                      }
                                      onFocus={handleFocus}
                                      max={
                                        sub_column.key === 'boss_score'
                                          ? column.max_score.toFixed()
                                          : undefined // '255'
                                      }
                                      onBlur={event => {
                                        handleInputBlur();

                                        if (sub_column.key === 'boss_score') {
                                          handleCheckBossScore(
                                            event,
                                            sub_column.key,
                                            item.type,
                                            item.section_id,
                                            column.criteria_id
                                          );
                                        }

                                        if (
                                          ['boss_comment', 'comment'].includes(
                                            sub_column.key
                                          )
                                        ) {
                                          handleCheckComments(
                                            event,
                                            sub_column.key,
                                            item.type,
                                            item.section_id,
                                            column.criteria_id
                                          );
                                        }
                                      }}
                                      innerRef={textareaRefHandler(
                                        column,
                                        sub_column
                                      )}
                                    />
                                  </FormCard>
                                )}
                              </span>
                            ) : (
                              <div className="pl-2 pt-2 pb-2 font-stolzlBook">
                                <span>{column[sub_column.key]}</span>

                                {sub_column.key === 'name' &&
                                  column.description !== '' && (
                                    <span
                                      className="w-[20px] inline-block"
                                      data-tooltip-id={`tooltip-${item.section_id}_${column.criteria_id}_${sub_column.key}`}
                                      data-tooltip-content={column.description}
                                    >
                                      <img
                                        className="ml-[5px] cursor-pointer inline-block align-text-top"
                                        src={tooltipIcon}
                                        alt="tooltip"
                                      />
                                    </span>
                                  )}
                              </div>
                            )}
                            <ReactTooltip
                              className="max-w-[450px] !bg-white !text-[#222222] !text-[12px] border-[1px] border-gray-600 !font-stolzlBook shadow-lg"
                              id={`tooltip-${item.section_id}_${column.criteria_id}_${sub_column.key}`}
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

EvaluationEntrySingle.propTypes = {
  id: PropTypes.number
};

export default EvaluationEntrySingle;
