import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Common Components
import PageTitle from 'src/components/Common/PageTitle';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import Button from 'src/components/Common/Button';

// Hooks
import useEvaluationTemplate from 'src/hooks/EvaluationTemplate/useEvaluationTemplate';

// Evaluation Components
import TemplatePart from 'src/Pages/EvaluationTemplate/components/TemplatePart';
import SectionPart from 'src/Pages/EvaluationTemplate/components/SectionPart';
import ModalCenter from 'src/components/Common/ModalCenter';
import DeleteTemplateModal from 'src/Pages/EvaluationTemplate/components/DeleteTemplateModal';
import { useUserAccess } from 'src/hooks/useUserAccess';

// eslint-disable-next-line react/prop-types
const HandleEvaluationTemplate = ({ menuCode, viewOnly }) => {
  const { id } = useParams();
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();
  const {
    handleTemplateSubmit,
    handleSectionSubmit,
    handleCriteriaSubmit,
    templateFields,
    templateResponseData,
    sectionFields,
    criteriaFields,
    weightFields,
    totalWeights,
    totalResponseWeights,
    getEvaluationInfo,
    handleChange,
    handSelectChange,
    handleAddSection,
    handleAddCriteria,
    deleteTemplateSubmit,
    deleteCriteriaSubmit,
    deleteSectionSubmit,
    costCenterOptions,
    allEmployeesOptions,
    costcenter,
    salaryGrades,
    addSectionFlag,
    addCriteriaFlag,
    handleDeleteTemplateModal,
    handleTemplateApprove,
    showDeleteTemplateModal,
    sectionError,
    criteriaError,
    weightError,
    formErrors,
    isTemplateNameExist,
    initialDummyData,
    isTemplateActive,
    criteriaErrors,
    existCriteriaErrors,
    resetCriteriaErrors,
    isFieldEnabled,
    isTemplateInUse,
    criteriaResponseData
  } = useEvaluationTemplate(id, viewOnly);

  return (
    <div className="user__container">
      <div className="flex justify-between">
        <div className="flex-none w-[75%]">
          <Breadcrumbs
            crumbs={[
              { name: 'Performance Evaluation' },
              { name: 'Evaluation Templates', link: '/evaluation-templates' },
              {
                // eslint-disable-next-line no-nested-ternary
                name: `${
                  !id
                    ? 'Add Template'
                    : templateResponseData.template_name ?? ''
                }`
              }
            ]}
          />
        </div>
        <div>
          {id ? (
            <div>
              {!viewOnly ? (
                <Button
                  modifier="bg-[#FFFFFF] border border-solid border-1 text-white p-[10px_14px] text-[12px] leading-unset rounded mr-[10px] hover:bg-gray-700"
                  spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[url('/src/assets/icons/trash.svg')]"
                  type="button"
                  onClick={() => {
                    handleDeleteTemplateModal({
                      name: 'DeleteModal',
                      part: 'template',
                      templateId: id
                    });
                  }}
                  disabled={
                    templateFields.orig_status !== 'N' || !access.can_delete
                  }
                />
              ) : null}
              <button
                type="button"
                className={`${
                  !access.can_edit || isTemplateInUse ? 'opacity-50' : ''
                } text-[12px] text-white font-normal leading-[100%] bg-[#232932] border-none p-[14px_19px] rounded hover:bg-gray-500 ${
                  !access.can_delete ? 'bg-gray-700 hover:bg-gray-700' : ''
                }`}
                onClick={e => {
                  e.preventDefault();
                  navigate(
                    `/evaluation-templates/${viewOnly ? 'edit' : 'view'}/${id}`
                  );
                }}
                disabled={!access.can_delete || isTemplateInUse}
              >
                {viewOnly ? (
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Edit
                  </span>
                ) : (
                  <span className="relative ml-[7px] mr-[7px]">View</span>
                )}
              </button>
              {!viewOnly ? (
                <button
                  type="submit"
                  className="text-[12px] text-white font-normal leading-[100%] bg-[#468EFF] hover:bg-gray-500 border-none p-[14px_21px] rounded ml-[10px] w-[94px]"
                  onClick={() => handleTemplateApprove(id)}
                  disabled={!access.can_delete}
                >
                  <span>Approve</span>
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-4 pb-[200px]">
        <TemplatePart
          templateId={id}
          getEvaluationInfo={getEvaluationInfo}
          handleChange={handleChange}
          handSelectChange={handSelectChange}
          handleTemplateSubmit={handleTemplateSubmit}
          templateFields={templateFields}
          totalWeights={totalResponseWeights}
          costCenterOptions={costCenterOptions}
          costcenter={costcenter}
          salaryGrades={salaryGrades}
          viewOnly={!isFieldEnabled}
          menuCode={menuCode}
          formErrors={formErrors}
          isTemplateNameExist={isTemplateNameExist}
          initialDummyData={initialDummyData}
        />
        {id ? (
          <>
            <SectionPart
              sectionError={sectionError}
              criteriaError={criteriaError}
              weightError={weightError}
              templateId={id}
              handleChange={handleChange}
              handSelectChange={handSelectChange}
              handleSectionSubmit={handleSectionSubmit}
              handleCriteriaSubmit={handleCriteriaSubmit}
              handleAddCriteria={handleAddCriteria}
              deleteSectionSubmit={deleteSectionSubmit}
              deleteCriteriaSubmit={deleteCriteriaSubmit}
              allEmployeesOptions={allEmployeesOptions}
              templateFields={templateFields}
              sectionFields={sectionFields}
              criteriaFields={criteriaFields}
              weightFields={weightFields}
              totalWeights={totalWeights}
              salaryGrades={salaryGrades}
              addCriteriaFlag={addCriteriaFlag}
              handleDeleteTemplateModal={handleDeleteTemplateModal}
              viewOnly={!isFieldEnabled}
              menuCode={menuCode}
              formErrors={formErrors}
              criteriaErrors={criteriaErrors}
              existCriteriaErrors={existCriteriaErrors}
              resetCriteriaErrors={resetCriteriaErrors}
              criteriaData={criteriaResponseData}
            />
            {formErrors.section_error && !viewOnly ? (
              <div className="text-[14px] text-[#FC5252] leading-[24px] mb-[20px] flex font-stolzlBook">
                <img
                  src="./icons/error_icn.png"
                  alt=""
                  className="w-[17px] h-[17px] mr-[5px] mt-[2px]"
                />
                <p>Section not found</p>
              </div>
            ) : null}
          </>
        ) : null}
        {id && !viewOnly ? (
          <div className="flex justify-center border border-t-0 border-x-0 border-b-1 border-[#e1e1e1] relative">
            <span className="bg-[#FFF] absolute -bottom-2 h-[18px] flex">
              <Button
                name="+ Add Section"
                modifier="bg-[#FFF] text-[#468EFF] text-[12px] rounded px-4 hover:text-[#0264FF]"
                spanModifier="!pl-0"
                type="button"
                onClick={handleAddSection}
                disabled={
                  !addSectionFlag || !access.can_edit || isTemplateInUse
                }
              />
            </span>
          </div>
        ) : null}
      </div>

      <ModalCenter
        showModal={showDeleteTemplateModal.name}
        modalName="DeleteModal"
      >
        {showDeleteTemplateModal.name && (
          <DeleteTemplateModal
            handleDeleteTemplateModal={handleDeleteTemplateModal}
            data={showDeleteTemplateModal}
            deleteTemplateSubmit={deleteTemplateSubmit}
            deleteSectionSubmit={deleteSectionSubmit}
            deleteCriteriaSubmit={deleteCriteriaSubmit}
          />
        )}
      </ModalCenter>
    </div>
  );
};

export default HandleEvaluationTemplate;
