import { GET_LEAVE_TYPES_LIST } from './LeaveTypes';

const INITIAL_STATE = {
  leaves: [],
  all: []
};

export default function leaveTypeReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_LEAVE_TYPES_LIST:
      return {
        ...state,
        leaves: payload
      };
    default:
      return state;
  }
}
