/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

const Announcements = ({ announcements }) => {
  const hasAnnouncements = announcements && announcements.length > 0;
  return (
    <div className="announcement__container mt-[10px]">
      <div className="flex mb-[10px]">
        <img src="icons/announcement-icon.svg" alt="announcement icon" />
        <h2 className="ml-[4px] text-[14px] font-bold">Announcements</h2>
      </div>
      <ul className="border-[#DEDEDE] border rounded-[4px] announcement-border max-h-[350px] lg:max-h-[420px] overflow-scroll no-scrollbar">
        {hasAnnouncements ? (
          announcements.map(announcement => (
            <li
              key={announcement.id}
              className="border-b-[1px] border-[#DEDEDE] px-[20px] pt-[13px] lg:pt-[7px] pb-[10px] last:border-[0] hover:bg-[#F0F0F0] hover:opacity-[85%]"
            >
              <Link to={`/announcements/${announcement.id}`}>
                <div className="flex">
                  <img
                    src="icons/note-icon.svg"
                    className="w-[16px] h-[16px] mr-[4px]"
                    alt="note icon"
                  />

                  <div className="announcement_event">
                    <h3 className="text-[#222222] font-bold text-[14px] lg:text-[12px]">
                      {announcement.title}
                    </h3>
                    <p className="truncate max-w-[100%] lg:w-[223px] w-[260px] text-[#909090] text-[14px] lg:text-[12px]">
                      {announcement.sub_text}
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          ))
        ) : (
          <p className="text-center bg-[#F0F0F0] flex items-center justify-center text-[#909090] px-[10px] h-[100px] lg:h-screen max-h-[350px] lg:max-h-[420px] pt-[10px] pb-[10px]">
            No Announcements for Today
          </p>
        )}
      </ul>
    </div>
  );
};

export default Announcements;
