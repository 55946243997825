import React, { Suspense, lazy } from 'react';

const Editor = lazy(() => import('./EditorTemplate'));

const fallbackTemplate = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1>Loading...</h1>
    </div>
  );
};

const index = ({ contentData, isEdit, holder, id }) => {
  return (
    <Suspense fallback={fallbackTemplate}>
      <Editor
        contentData={contentData}
        isEdit={isEdit}
        holder={holder}
        id={id}
      />
    </Suspense>
  );
};

export default index;
