import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getHolidays } from 'src/redux/modules/calendar/holidayActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import {
  deleteHolidayService,
  getHolidayByIDService,
  getHolidayItemsService
} from 'src/api/modules/holidays';

export const useDeleteModal = ({
  handleDeleteModal,
  editModal,
  holidayId,
  modal
}) => {
  const dispatch = useDispatch();
  const [holidayDetail, setHolidayDetail] = useState(null);

  const fetchNewUserList = async () => {
    getHolidayItemsService(1)
      .then(res => {
        dispatch(getHolidays(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (holidayId) {
      getHolidayByIDService(holidayId).then(res => {
        setHolidayDetail(holidayDetail);
      });
    }
  }, []);

  const handleDelete = async codeId => {
    if (codeId) {
      const res = await deleteHolidayService(codeId, 'DELETE');
      if (res.success) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: codeId
        });
        fetchNewUserList();
      } else if (res.response.status === 405)
        toast.error('Unable to delete!', {
          icon: <CgCloseO />,
          toastId: codeId
        });
      handleDeleteModal(modal);
      if (editModal) {
        editModal(null);
      }
    }
  };
  return {
    holidayDetail,
    handleDelete
  };
};
