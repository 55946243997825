/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import useAddEditModal from 'src/hooks/components/Parts/CalendarSettings/Holiday/useAddEditModal';
import ColorPicker from 'src/components/Common/ColorPicker';
import {
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard
} from 'src/components/Common';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Loading from 'src/components/Common/loading';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, holidayId, dataList, menuCode }) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    handleDeleteModal,
    handleTrimSpaces,
    handleSelectChange,
    handleDelete,
    initialDummyData,
    validationSchema,
    showDeleteModal,
    isFixedCheckboxDisabled,
    daycodeOptions,
    formLoading,
    deleteId,
    handleColorChange
  } = useAddEditModal({ handleModal, holidayId, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <>
      <Formik
        enableReinitialize={!!holidayId}
        validateOnMount={!!holidayId}
        initialValues={holidayId ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {holidayId ? 'Edit' : 'Add'} Holiday
              </h4>
              <div className="flex mx-[35px]">
                <div className="text-right mr-[10px]">
                  <button
                    type="submit"
                    className="text-[12px] text-white leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                    disabled={isSubmitting}
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                      Save
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {formLoading && <Loading />}
            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!holidayId ? (
                  <>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Holiday Name"
                          required
                          name="holiday_name"
                          type="text"
                          placeholder=""
                          value={formData.holiday_name ?? ''}
                          error={errors.holiday_name && touched.holiday_name}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.holiday_name !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.holiday_name}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Day Code"
                          required
                          type="combobox"
                          options={daycodeOptions}
                          placeholder="Select Day Code"
                          onChangeValue={(value, name) =>
                            handleSelectChange(
                              value,
                              'day_code_id',
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          selectedValue={formData.day_code_id ?? ''}
                          name="day_code_id"
                          errors={errors?.day_code_id && touched.day_code_id}
                        />
                        {errors.day_code_id !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.day_code_id}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Holiday Date"
                          required
                          error={errors?.holiday_date}
                          errorMessage="Field Required"
                          name="holiday_date"
                          type="date"
                          className="employee_info_input"
                          placeholder=""
                          value={formData.holiday_date ?? ''}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.holiday_date !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.holiday_date}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard>
                      <div className="flex items-center mb-[20px]">
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                            <input
                              type="checkbox"
                              checked={formData.is_fixed}
                              name="is_fixed"
                              disabled={isFixedCheckboxDisabled}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              hidden
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                isFixedCheckboxDisabled
                                  ? '!bg-disabled !border-disabled'
                                  : ''
                              }`}
                            >
                              <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                              <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                            </span>
                            <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                              Fixed
                            </i>
                          </label>
                        </FormCard>
                        <ColorPicker
                          name="color"
                          value={formData.color}
                          onChange={handleColorChange}
                          onBlur={handleTrimSpaces}
                          required
                          error={errors?.color}
                          errors={errors?.color && touched.color}
                        />
                      </div>
                    </FormGridCard>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          name="description"
                          type="textarea"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          max="255"
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              'description'
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Holiday Name"
                          required
                          name="holiday_name"
                          type="text"
                          placeholder=""
                          value={formData.holiday_name ?? ''}
                          error={errors.holiday_name && touched.holiday_name}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.holiday_name !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.holiday_name}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        {daycodeOptions.length ? (
                          <FormField
                            label="Day Code"
                            required
                            type="combobox"
                            options={daycodeOptions}
                            placeholder=""
                            // onBlur={handleTrimSpaces}
                            onChangeValue={value =>
                              handleSelectChange(
                                value,
                                'day_code_id',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            selectedValue={formData.day_code_id ?? ''}
                            name="day_code_id"
                            selectName="day_code_id"
                            errors={errors?.day_code_id && touched.day_code_id}
                          />
                        ) : null}
                        {errors.day_code_id !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.day_code_id}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Holiday Date"
                          required
                          error={errors?.holiday_date}
                          errorMessage="Field Required"
                          name="holiday_date"
                          type="date"
                          className="employee_info_input"
                          placeholder=""
                          value={formData.holiday_date ?? ''}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.holiday_date !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.holiday_date}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard>
                      <div className="flex items-center mb-[20px]">
                        <FormCard className="holiday-checkbox flex items-center mr-[23px]">
                          <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                            <input
                              type="checkbox"
                              checked={formData.is_fixed}
                              name="is_fixed"
                              disabled={isFixedCheckboxDisabled}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              hidden
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                isFixedCheckboxDisabled
                                  ? '!bg-disabled !border-disabled'
                                  : ''
                              }`}
                            >
                              <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                              <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                            </span>
                            <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                              Fixed
                            </i>
                          </label>
                        </FormCard>
                        <ColorPicker
                          name="color"
                          value={formData.color}
                          onChange={handleColorChange}
                          onBlur={handleTrimSpaces}
                          required
                          error={errors?.color}
                          errors={errors?.color && touched.color}
                        />
                      </div>
                    </FormGridCard>
                    <FormGridCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          name="description"
                          type="textarea"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          max="255"
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              'description'
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            id={holidayId}
          />
        )}
      </ModalCenter>
    </>
  );
};

export default AddEditModal;
