import { getDaycodes } from 'src/api/modules/daycode';
import {
  FETCH_DAYCODES,
  GET_ALL_DAYCODES_ITEM,
  GET_DAYCODE_LIST,
  SET_DAYCODE_UPDATE
} from './dayCodeTypes';

export function setDayCodeList(payload) {
  return { type: FETCH_DAYCODES, payload };
}

export function setDayCodes(data) {
  return { type: GET_DAYCODE_LIST, payload: data };
}

export function setAllDayCodes(data) {
  return { type: GET_ALL_DAYCODES_ITEM, payload: data };
}

export function setDayCodeListUpdate(payload) {
  return { type: SET_DAYCODE_UPDATE, payload };
}

export function getDayCode() {
  return async function (dispatch) {
    const response = await getDaycodes();
    if (response?.success && response?.data)
      dispatch(setDayCodes(response.data));
  };
}

export function getAllDayCodes() {
  return async function (dispatch) {
    const response = await getDaycodes();
    if (response?.success && response?.data)
      dispatch(setAllDayCodes(response.data));
  };
}
