import React from 'react';
import { SlideModal, ModalCenter, Breadcrumbs } from 'src/components/Common';
import PropTypes from 'prop-types';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Datatable from 'src/components/Common/Datatable';
import { useUserAccess } from 'src/hooks/useUserAccess';
import useDayCodes from 'src/hooks/Pages/CalendarSettings/DayCodes/useDayCodes';
import { deleteBulkDayCodes } from 'src/api/modules/daycode';
import ModalUI from 'src/components/Common/ModalUI';
import { DAYCODE_BULK_DELETE } from 'src/api/endpoints';
import BulkDeleteModal from '../BulkDeleteModal';
import AddEditModal from './components/AddEditModal';
import DeleteModal from './components/DeleteModal';

const Index = ({ menuCode }) => {
  const {
    dayCodeColumns,
    isOpen,
    showCreateModal,
    showViewModal,
    hasMore,
    showModal,
    form,
    showDeleteModal,
    showBulkDeleteModal,
    dayCodeId,
    dayCodeListMemo,
    dayCodeList,
    deleteId,
    fetchData,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    handleViewModal,
    handleDeleteModal,
    handleDelete,
    setShowDeleteModal,
    handleCreateModal,
    detectDateOnChange
  } = useDayCodes();
  const { menus } = useUserAccess();

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'System ' }, { name: 'Day Codes' }]} />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Day Code, Description"
            buttonName="Day Code"
            buttonLink={false}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
            dateOnChange={detectDateOnChange}
          />
          <InfiniteScroll
            dataLength={dayCodeListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {dayCodeListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
            style={{ overflow: 'visible' }}
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={dayCodeListMemo || []}
              clickableRows={false}
              headingColumns={dayCodeColumns}
              title="DayCodes"
              actions={['edit', 'delete']}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              shouldEllipsis
              isDayCode
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>

      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            dayCodeId={dayCodeId}
            dataList={dayCodeList}
            menuCode={menuCode}
            showDeleteModal={showDeleteModal}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <ModalUI
            header="Delete"
            type="delete"
            submit={() => handleDelete(deleteId.id)}
            cancel={() => handleDeleteModal({ modal: null })}
          >
            <div className="text-center font-stolzlRegular text-[14px] mt-7">
              Are you sure you want to delete
            </div>
            <div className="text-center font-stolzlBold mt-2 capitalize">
              Day Code: {deleteId.day_code}?
            </div>
          </ModalUI>
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            apiService={deleteBulkDayCodes}
            endpoint={DAYCODE_BULK_DELETE}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
