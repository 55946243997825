import React from 'react';
import { useNavigate } from 'react-router-dom';

// Common components
import PageTitle from 'src/components/Common/PageTitle';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import ModalCenter from 'src/components/Common/ModalCenter';
import DeleteModal from 'src/Pages/EvaluationTemplate/components/DeleteModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import PropTypes from 'prop-types';

// Assets
import 'src/assets/css/user.scss';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useEvaluationTemplate from 'src/hooks/EvaluationTemplate/useEvaluationTemplate';

const EvaluationTemplateIndex = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    submitFilter,
    handleDeleteModal,
    showModal,
    showViewModal,
    showDeleteModal,
    modalTemplateId,
    templateColumns,
    inputs,
    form,
    templateName,
    hasMore,
    fetchTemplateData,
    templateListMemo,
    evaluationName,
    totalTemplates
  } = useEvaluationTemplate(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Performance Evaluation' },
          { name: 'Evaluation Templates' }
        ]}
      />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Name, Description, Cost Center, Created By, Date"
            buttonName="Template"
            inputs={inputs}
            buttonLink={false}
            deleteModal="DeleteModal"
            modalName="AddEdit"
            buttonOnClick={() => navigate('/evaluation-templates/add')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={templateListMemo?.length ?? 0}
            next={fetchTemplateData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <div className="mt-5 flex justify-between items-center font-bold">
                <div className="flex-shrink-0 opacity-0">&nbsp;</div>
                <div className="flex-shrink-0 text-center">
                  {templateListMemo.length !== 0
                    ? 'No more data to load'
                    : 'No records found'}
                </div>
                <div className="text-right">
                  {totalTemplates !== 0 && <>Total: {totalTemplates}</>}
                </div>
              </div>
            }
          >
            <Datatable
              isEvaluationList
              shouldDisplayEditable={false}
              datasource={templateListMemo || []}
              clickableRows={false}
              headingColumns={templateColumns}
              title="Evaluation Template"
              actions={['view', 'delete']}
              actionLinks={{
                view: '/evaluation-templates/view'
              }}
              showModal={showModal}
              showViewModal={showViewModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            id={modalTemplateId}
            name={templateName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
    </div>
  );
};

EvaluationTemplateIndex.propTypes = {
  menuCode: PropTypes.string
};

export default EvaluationTemplateIndex;
