import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import { DAYCODE, DAYCODE_ALL, DAYCODES, DAYCODE_DELETE } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getDaycodes = async () => {
  try {
    const endpoint = `${DAYCODES}?page=1`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getDaycodeItem = async () => {
  try {
    const endpoint = `${DAYCODE}?page=1`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getDayCodeItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${DAYCODES}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getDayCodeByIDService = async (id, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${DAYCODE}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const getDayCodeByIDService2 = async (id, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${DAYCODE}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateDayCodeByID = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${DAYCODE}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateDayCodeService = async (id, data) => {
  try {
    const response = await axios.put(
      `${ENV.test}${DAYCODE}/${id}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createDayCodeService = async data => {
  try {
    const response = await axios.post(`${ENV.test}${DAYCODE}`, data, auth());
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteDayCodeService = async dayCodeId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${DAYCODE_DELETE}/${dayCodeId}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteBulkDayCodes = async (ids, endpoint) => {
  try {
    console.log(ids, endpoint);
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });
    console.log(response);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
