/* eslint-disable no-param-reassign */
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getDayCodeItemsService,
  deleteDayCodeService,
  getDaycodes
} from 'src/api/modules/daycode';
import { getLowerCasedValue, autoCapitalize } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import { TOAST_OPTIONS } from 'src/helpers/constants';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { getDayCode } from 'src/redux/modules/dayCodes/dayCodeActions';

const useDayCodes = () => {
  const dispatch = useDispatch();
  const dataList = useSelector(state => state.dayCodes.dayCodes.items);
  const mounted = useRef(true);
  const [showModal, setShowModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showViewModal, setShowViewModal] = useState(null);
  const [dayCodeId, setDayCodeId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [deleteId, setDeleteId] = useState({ id: undefined, day_code: null });
  const [dayCodeList, setDayCodeList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const [page, setPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const currentSearchParams = useRef({});
  const dayCodeFlag = useSelector(state => state?.dayCodes?.dayCodeList);

  const dayCodeColumns = [
    { key: 'id', label: 'ID' },
    { key: 'day_code', label: 'Day Code' },
    { key: 'description', label: 'Description' },
    { key: 'hol_premium_rate', label: 'Holiday Premium Rate' },
    { key: 'ot_rate', label: 'Overtime Rate' },
    { key: 'night_diff_rate', label: 'Night Differential' }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-day-codes-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-day-codes-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getDayCodeItemsService(
        savedPageIndex,
        savedSearchParams
      );

      if (mounted.current) {
        const newList =
          savedPageIndex !== 1
            ? dayCodeList.concat(res.data.items)
            : res.data.items;

        setDayCodeList(
          newList.map(o => {
            return {
              id: o.id,
              day_code: autoCapitalize(o.day_code),
              description: o.description,
              hol_premium_rate: o.hol_premium_rate + '%'.toString(),
              ot_rate: o.ot_rate + '%'.toString(),
              night_diff_rate: o.night_diff_rate + '%'.toString()
            };
          })
        );

        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [page, getDaycodes, setDayCodeList, setPage, setHasMore, dayCodeList]);

  const dayCodeListMemo = useMemo(() => {
    return dayCodeList ?? [];
  }, [dayCodeList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    localStorage.setItem('hris-day-codes-params', searchParams);
  };

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setDayCodeId(modal ? id : null);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setDayCodeId]
  );

  const handleViewModal = useCallback(
    (modal, id = null, data = null) => {
      setShowViewModal(modal);
      setDayCodeId(modal ? id : null);
      setIsOpen(!!modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setDayCodeId]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, day_code }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, day_code });
      setShowDeleteModal(modal);
    },
    [showDeleteModal, setDeleteId]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteDayCodeService(id);
      if (response?.success) {
        dispatch(getDayCode());
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        dispatch(getDayCode());
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }
    setShowDeleteModal(null);
  };

  const removeSearchParam = paramName => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
  };

  const detectDateOnChange = e => {
    const { name, value } = e.target;

    if (name === 'year') {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(name, value);

      localStorage.setItem('hris-day-codes-params', searchParams.toString());
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    } else if (value === '') {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(name);

      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });

      localStorage.setItem('hris-day-codes-params', searchParams.toString());

      // Call removeSearchParam when value is empty
      // removeSearchParam(name);
    }
  };

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-day-codes-params');
    const savedPageIndex = localStorage.getItem('hris-day-codes-page-no');
    if (savedPageIndex) {
      setPage(1);
      localStorage.setItem('hris-day-codes-page-no', JSON.stringify(1));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-day-codes-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      pushQuery({});
      fetchData();
    } else {
      form.reset({});
      pushQuery({});
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-day-codes-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    localStorage.setItem('hris-day-codes-page-no', JSON.stringify(page));
  }, [page]);

  return {
    dataList,
    dayCodeColumns,
    isOpen,
    showCreateModal,
    showViewModal,
    hasMore,
    showModal,
    form,
    showDeleteModal,
    showBulkDeleteModal,
    dayCodeId,
    dayCodeListMemo,
    dayCodeList,
    deleteId,
    fetchData,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    handleViewModal,
    handleDeleteModal,
    detectDateOnChange,
    handleDelete,
    setShowDeleteModal
  };
};

export default useDayCodes;
