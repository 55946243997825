import { useDeleteModal } from 'src/hooks/components/Parts/Timekeeping/ShiftList/useDeleteModal';
import PropTypes from 'prop-types';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const DeleteModal = ({ handleDeleteModal, editModal, code, name, modal }) => {
  const { shiftDetail, handleDelete } = useDeleteModal({
    handleDeleteModal,
    editModal,
    code,
    modal
  });
  return (
    <DeleteModalUI
      label={name ? `Shift Code: ${name}` : shiftDetail}
      submit={() => handleDelete(code)}
      cancel={() => handleDeleteModal(modal)}
    />
  );
};

DeleteModal.propTypes = {
  handleDeleteModal: PropTypes.func,
  editModal: PropTypes.func,
  code: PropTypes.number,
  name: PropTypes.string,
  modal: PropTypes.string
};

export default DeleteModal;
