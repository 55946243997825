export const LOGIN = '/api/auth/signin';
export const DASHBOARD = '/api/user/dashboard';
export const USER_INFO = '/api/user/info';
export const USER_PROJECTS = '/api/user/projects';
export const USER_SKILLS = '/api/user/skills';
export const USER_SKILL_CATEGORY_LIST = '/api/user/Skill-Category-List';
export const RESET_PASSWORD = '/api/v2/users/reset-password/send';
export const CREATE_NEW_PASSWORD = '/api/v2/users/reset-password/auth';
export const ACTIVATE_ACCOUNT = '/api/v2/users/activate-account/auth';
export const UPDATE_PASSWORD = '/api/user/update-password';
export const USER_DETAILS = '/api/admin/user_skills';
export const USER_DETAILS_BY_ID = '/api/admin/user/user-details';
export const USER_SKILLS_BY_ID = '/api/admin/user_skills';
export const USER_SKILL_BY_ID = '/api/admin/user_skill';
export const USER_INFO_BY_ID_MY_TEAM = '/api/admin/user/team_info';
export const USER_GROUPS = '/api/v2/users/groups';
export const USER_GROUPS_BULK_DELETE = '/api/v2/users/groups/bulk-delete';
export const USER_ALL = '/api/v2/users';
export const USER_BULK_DELETE = '/api/v2/users/bulk-delete';
export const ADD_USER = '/api/v2/users';
export const USER_IMAGE_UPDATE = '/api/v2/users/image/upload';
export const USER_MENUS = '/api/v2/users/menus';
export const COMPANY = '/api/v2/company';
export const COMPANY_IMAGE_UPDATE = 'api/v2/company/image/upload';

export const INDUSTRY_ALL = '/api/v2/industries';
export const PROJECTS = '/api/admin/projects';
export const PROJECT_LIST = '/api/admin/projectlist';
export const PROJECTS_BULK_DELETE = '/api/v2/projects/bulk-delete';
export const PROJECT_MEMBER_BY_ID = '/api/admin/getProjectMemberByID';
export const EDIT_MEMBER = '/api/admin/editmember';
export const ALL_MEMBERS = '/api/admin/getallmembers';
export const ALL_MEMBERS_BY_ROLE = '/api/admin/getAllMembersByRole';
export const ALL_SKILLS = '/api/admin/allskills';
export const ALL_ROLES = '/api/admin/allroles';
export const REPORT_SUMMARY = '/api/dtr/getReportSummaryFunction';
export const REPORT_DETAIL = '/api/dtr/getReportDetailFunction';

// EVALUATIONS
export const USER_EVALUATION_HISTORY = 'api/user/evaluations';
export const USER_EVALUATION_LIST = 'api/user/for-evaluations';
export const TEMPLATESV2 = '/api/v2/evaluations/templates';
export const TEMPLATES_BULK_DELETE =
  '/api/v2/evaluations/templates/bulk-delete';
export const TEMPLATES = '/api/admin/evaluations/templates';
export const SECTIONS = '/api/admin/evaluations/templates/sections';
export const SUB_SECTIONS =
  '/api/admin/evaluations/templates/sections/sub-sections';
export const FIELDS =
  '/api/admin/evaluations/templates/sections/sub-sections/fields';
export const SCHEDULES = '/api/admin/evaluations/schedules';
export const EVALUATION_SCHEDULES = '/api/v2/evaluations/schedules';
export const USER_EVALUATION_STATUS = '/api/user/evaluations/statuses';
export const SCHEDULES_VERIFICATION =
  '/api/admin/evaluations/schedules-verification';
export const USER_FOR_EVALUATIONS_BY_ID = '/api/user/for-evaluations';
export const USER_EVALUATIONS_BY_ID = '/api/user/evaluations';
export const EMPLOYEE_EVALUATIONS = 'api/v2/employee/evaluations';
export const EMPLOYEE_FOR_EVALUATIONS = '/api/v2/employee/for-evaluations';

export const ALL_COST_CENTER = '/api/costcenter/all';
export const COST_CENTER = '/api/v2/cost-centers';
export const ALL_DEPARTMENTS = '/api/v2/departments';
export const DEPARTMENTS = '/api/departmentList';
export const ALL_DIVISIONS = '/api/v2/divisions';
export const DIVISIONS = '/api/v2/divisions';
export const ALL_SECTIONS = '/api/v2/sections';
export const ADMIN_SECTIONS = '/api/admin/sections';
export const ADMIN_SUB_SECTIONS = '/api/admin/sub-sections';
export const ALL_SUB_SECTIONS = '/api/v2/sub-sections';
export const ADMIN_ROLES = '/api/admin/roles';
export const ADMIN_POSITIONS = '/api/admin/positions';
export const ADMIN_SALARY_GRADES = 'api/admin/salary-grades';
export const ADMIN_EMPLOYMENT_STATUS = '/api/admin/employment-status';
export const UPDATE_USER_PASSWORD = '/api/admin/users';
export const ADMIN_USER_IMAGE_UPDATE = '/api/admin/users/image_update';
export const UPDATE_COST_CENTER_BY_USER = '/api/user/team_info/cost_center';
export const ADMIN_ADD_USER = '/api/admin/users';
export const ADMIN_PROJECT_TEAM_INFO = '/api/admin/user/project_team_info';
export const ADMIN_USER_INFO_EDIT_IS_EXEMPTED =
  '/api/admin/users/user-info/edit-is-excempted';
export const ADMIN_PROJECTS = '/api/v2/projects';
export const PROJECT_ROLES = '/api/v2/projects/roles';

// DAYCODE
export const DAYCODE = '/api/daycode';
export const DAYCODE_DELETE = '/api/daycode/delete';
export const DAYCODES = '/api/daycode/search-by';
export const DAYCODE_ALL = '/api/daycode/all';
export const DAYCODE_BULK_DELETE = '/api/daycode/bulk-delete-daycode';

// HOLIDAY
export const HOLIDAYS_ALL = '/api/calendar/holidays/all';
export const HOLIDAYS = '/api/calendar/holidays';
export const HOLIDAY_BY_ID = '/api/calendar/holiday';
export const HOLIDAY_UPDATE = '/api/calendar/holidays';
export const HOLIDAY_DELETE = '/api/calendar/holidays';
export const HOLIDAY_BULKDELETE = '/api/calendar/holidays/bulk-delete';

// COMPANY EVENTS
export const EVENTS = '/api/calendar/events';

export const ALL_MENUS = 'api/v2/menus/all';
export const MENUS = 'api/v2/menus';
export const EMPLOYEE = 'api/v2/employees';
export const EMPLOYEE_BULK = '/api/v2/employees/bulk-delete';
export const SALARY_GRADES = 'api/v2/salary-grades';
export const GENDERS = 'api/v2/enums/genders';
export const CIVIL_STATUS = 'api/v2/enums/civil-statuses';
export const EMPLOYMENT_STATUS = 'api/v2/enums/employment-statuses';
export const EMPLOYEE_IMAGE_UPDATE = 'api/v2/employees/image/upload';
export const CREATE_EMPLOYEE_USER = 'api/v2/employees/create/user';
export const TEAM = 'api/v2/teams';
export const POSITION = 'api/v2/positions';

export const BULK_DELETE_COST_CENTER = '/api/v2/cost-centers/bulk-delete';
export const BULK_DELETE_DIVISIONS = '/api/v2/divisions/bulk-delete';
export const BULK_DELETE_DEPARTMENTS = '/api/v2/departments/bulk-delete';
export const BULK_DELETE_SECTIONS = '/api/v2/sections/bulk-delete';
export const BULK_DELETE_SUB_SECTIONS = '/api/v2/sub-sections/bulk-delete';
export const ALL_EMPLOYEES = 'api/v2/employees/all';

export const EVALUATIONS_VERIFY = 'api/v2/evaluations/schedules/verify';
export const EVALUATIONS_SCHEDULES = '/api/v2/evaluations/schedules';

// Learning Management
export const QUIZZES = '/api/v1/quizzes';
export const BULK_DELETE_QUIZZES = '/api/v1/quizzes/bulk-delete';
export const SKILLS = '/api/v1/skills';
export const SKILLS_ALL = '/api/v1/skills/all';
export const ALL_STATUSES = '/api/v2/statuses';
export const QUESTIONS = '/api/v1/questions';
export const BULK_DELETE_QUESTIONS = '/api/v1/questions/bulk-delete';
export const ASSESSMENTS = '/api/v1/assessments';
export const MY_ASSESSMENTS = '/api/v1/assessments/me';
export const BULK_DELETE_ASSESSMENTS = '/api/v1/assessments/bulk-delete';

// Announcements
export const ANNOUNCEMENT = '/api/announcement/announcement';
export const ANNOUNCEMENTS_ALL = '/api/announcement/announcements/all';
export const ANNOUNCEMENTS_DELETE = '/api/announcement/delete_announcement';
export const ANNOUNCEMENTS_UPDATE = '/api/announcement/update_announcement';
export const ANNOUNCEMENTS_CREATE = '/api/announcement/add_announcement';
export const ANNOUNCEMENT_BULK_DELETE =
  '/api/announcement/bulk_delete_announcement';

// DTR
export const ANNOUNCEMENTS = '/api/announcement/announcements';
export const TIME_RECORDS = '/api/dtr/employeetimerecords';
export const ALL_HOLIDAY_EVENTS = '/api/calendar/dashboard';

// Leave Types
export const LEAVE_TYPES = '/api/leave-types/get-list';
export const ADD_LEAVE_TYPE = '/api/leave-types/add';
export const UPDATE_LEAVE_TYPE = '/api/leave-types/update';
export const DELETE_LEAVE_TYPE = '/api/leave-types/delete';
export const BULK_DELETE_LEAVE_TYPE = '/api/leave-types/bulk-delete';
export const LEAVE_TYPE = '/api/leave-types';

// SHIFTS
export const SHIFT = '/api/shift';
export const SHIFTS = '/api/shift/shift_search';
export const SHIFT_ADD = '/api/shift/add_shift';
export const SHIFT_UPDATE = '/api/shift/update_shift';
export const SHIFT_DELETE = '/api/shift/delete_shift';
export const SHIFT_BULK_DELETE = '/api/shift/bulk_delete_shift';
export const GET_EMPLOYEE_SHIFT_BY_ID =
  '/api/shift/employee_shift/get_employee_shift';
