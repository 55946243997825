/* eslint-disable prettier/prettier */
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { getEmployeeShiftById } from 'src/api/modules/shifts'
import moment from 'moment';

import {
  getEmployeeTimeRecords,
  setClockInRecord,
  setClockOutRecord,
  getEmployeeProjects,
  getClockInInfo
} from 'src/api/modules/timeRecords';

const useDTR = () => {
  const today = new Date().toISOString().slice(0, 10);

  const [timeIn, setTimeIn] = useState(true);
  const [timeOut, setTimeOut] = useState(false);
  const loggedUser = useSelector(state => state?.user?.user?.user);
  const [myParams, setMyParams] = useState({
    to: today,
    from: today,
    employee_id: loggedUser.employee_id_no
  });
  const [showModal, setShowModal] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const { user } = useSelector(state => state.user);
  const [myShift, setMyShift] = useState('');
  const [myShiftId, setMyShiftId] = useState(null);
  const [latestRecord, setLatestRecord] = useState(null);

  const getTimeRecords = async () => {
    try {
      const data = await getEmployeeTimeRecords(myParams);
      if (data?.success) {
        setLatestRecord(data?.data[data.data.length - 1]);
      }
    } catch (e) {
      console.error(`Error fetching data: ${e.message}`);
    }
  };

  const getClockInData = async () => {
    try {
      const userId = user.user?.employee?.id || user.user.id;
      const payload = {
        employee_id: userId
      }
      if (currentProject) {
        payload.project_id = currentProject;
      }
      const response = await getClockInInfo(payload);
      if (response?.data?.canClockIn) {
        setTimeIn(true);
        setTimeOut(false);
        setCurrentProject(null);
      } else {
        setCurrentProject(latestRecord?.project_id)
        setTimeIn(false);
        setTimeOut(true);
      }
    } catch (e) {
      console.error(`Error fetching data: ${e.message}`);
    }
  };

  const getEmployeeShift = async (userInfo) => {
    if (userInfo.employee) {
      const response = await getEmployeeShiftById(userInfo.employee.id);
      const shiftString = `${moment(response?.data?.start_of_shift, 'HH:mm:ss').format('hh:mm A')} - ${moment(response?.data?.end_of_shift, 'HH:mm:ss').format('hh:mm A')}`;
      setMyShiftId(response?.data?.id);
      setMyShift(shiftString);
    }
  };

  const getProjectList = async () => {
    try {
      const res = await getEmployeeProjects(loggedUser.employee_id_no);

      const newList = res?.data;
      const formattedList =
        newList &&
          Array.isArray(newList) &&
          newList.every(item => item.id && item.name)
          ? newList.map(o => ({ value: o.id, label: o.name }))
          : [];

      setOptionList(formattedList);
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`);
    }
  };

  const handleModal = useCallback(
    (modal, id = null, data = null) => {
      setShowModal(modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal]
  );

  const handleTimeRecord = useCallback(async data => {
    try {
      if (timeIn) {
        const res = await setClockInRecord(data);

        if (res.data) {
          if (res.data.datetime_in) {
            toast.success(
              `Successfully Clocked in at ${moment(res.data.datetime_in).format(
                'HH:mm:ss'
              )}`,
              {
                icon: <CgCheckO />
              }
            );
            setTimeIn(false);
            setCurrentProject(res.data.project_id);
          }
        } else {
          toast.error('Cannot Clock in!', { icon: <CgCloseO /> });
        }
      } else {
        const { shift_id, ...rest } = data;
        const res = await setClockOutRecord(rest);
        setCurrentProject(null);

        if (res.data) {
          if (res.data.datetime_out) {
            toast.success(
              `Successfully Clocked out at ${moment(
                res.data.datetime_out
              ).format('HH:mm:ss')}`,
              {
                icon: <CgCheckO />
              }
            );
            setTimeIn(true);
          }
        } else {
          toast.error('Cannot clock out', { icon: <CgCloseO /> });
        }
      }
    } catch (e) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  });

  useEffect(() => {
    getEmployeeShift(user.user);
    getTimeRecords();
    getProjectList();
  }, []);

  useEffect(() => {
    getClockInData();
  }, [latestRecord])

  return {
    showModal,
    setShowModal,
    handleModal,
    timeIn,
    timeOut,
    getTimeRecords,
    handleTimeRecord,
    getProjectList,
    optionList,
    loggedUser,
    currentProject,
    myShift,
    myShiftId
  };
};

export default useDTR;
