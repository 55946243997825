import { isEqual } from 'src/helpers/utils';

export function removeDefaultEvaluators({
  schedule_id,
  defaultEvaluators,
  evaluatorsPayload,
  evaluatorsPayloadCopy = undefined,
  updatePayload,
  type = 'single'
}) {
  if (!defaultEvaluators[schedule_id]) return;
  const defaults = defaultEvaluators[schedule_id];
  const payload = evaluatorsPayload[schedule_id];
  const some = defaults.some(defaultItem =>
    payload.some(payloadItem => isEqual(defaultItem, payloadItem))
  );

  if (some) {
    const dataPayload = evaluatorsPayload;
    const filteredPayload = payload.filter(payloadItem => {
      return !defaults.some(defaultItem => isEqual(payloadItem, defaultItem));
    });

    dataPayload[schedule_id] = filteredPayload;

    if (type === 'single') {
      if (isEqual(defaults, payload)) updatePayload([]);
    }

    if (type === 'batch' && evaluatorsPayloadCopy !== undefined) {
      if (!dataPayload[schedule_id].length) {
        const copy = evaluatorsPayloadCopy;
        const filtered = copy.filter(
          item => item.evaluation_id !== schedule_id
        );
        updatePayload(filtered);
      }
    }
  }
}
