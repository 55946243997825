import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getDivisionItemsService } from 'src/api/modules/divisions';
import { getShiftsServiceByPage } from 'src/api/modules/shifts';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  getLowerCasedValue,
  filterData,
  autoCapitalize
} from 'src/helpers/utils';
import { SHIFT_STATUS_OPTIONS } from 'src/helpers/constants';

const useShift = () => {
  const dataList = useSelector(state => state.shifts.shifts.items);
  const [shiftsList, setshiftsList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [uniqueCode, setuniqueCode] = useState(null);
  const [uniqueCodeName, setUniqueCodeName] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const shiftColumns = [
    { key: 'id', label: 'ID' },
    { key: 'shift_code', label: 'Shift Code' },
    { key: 'description', label: 'Description' },
    { key: 'start_of_shift', label: 'Start of Shift' },
    { key: 'end_of_shift', label: 'End of Shift' },
    { key: 'start_of_break', label: 'Start of Break' },
    { key: 'end_of_break', label: 'End of Break' },
    { key: 'status', label: 'Status' }
  ];

  const selectedStatuses = localStorage.getItem(
    'hris-evaluation-template-selected-statuses'
  );

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(SHIFT_STATUS_OPTIONS, 'label', 'value'),
      multiOptions: SHIFT_STATUS_OPTIONS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A', 'N']
    }
  ];

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-timekeeping-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-users-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }
      const res = await getShiftsServiceByPage(savedPageIndex, params);
      const newList =
        savedPageIndex !== 1
          ? shiftsList.concat(res.data.items)
          : res.data.items;
      setshiftsList(
        newList.map(o => {
          return {
            id: o.id,
            shift_code: o.shift_code,
            description: o.description,
            start_of_shift: moment(o.start_of_shift, 'HH:mm:ss').format(
              'HH:mm'
            ),
            end_of_shift: moment(o.end_of_shift, 'HH:mm:ss').format('HH:mm'),
            start_of_break: moment(o.start_of_break, 'HH:mm:ss').format(
              'HH:mm'
            ),
            end_of_break: moment(o.end_of_break, 'HH:mm:ss').format('HH:mm'),
            is_default: o.is_default,
            status: o.status
          };
        })
      );

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getShiftsServiceByPage,
    setshiftsList,
    setPage,
    setHasMore,
    shiftsList
  ]);

  const shiftsListMemo = useMemo(() => {
    return shiftsList ?? [];
  }, [shiftsList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const setStatusFilter = value => {
    localStorage.setItem('hris-evaluation-template-selected-statuses', value);
  };

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
      setStatusFilter('');
    } else {
      setStatusFilter(searchParamsObject.status);
    }
    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();

    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-timekeeping-params', searchParams);
  };

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setuniqueCode(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setuniqueCode]
  );

  const handleViewModal = useCallback(
    (modal, id = null) => {
      setShowViewModal(modal);
      setuniqueCode(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setuniqueCode]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, shift_code }) => {
      setShowDeleteModal(modal);
      setuniqueCode(modal ? id : null);
      setUniqueCodeName(modal ? shift_code : null);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setuniqueCode, setUniqueCodeName]
  );

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    localStorage.setItem('hris-users-page-no', JSON.stringify(page));
  }, [page]);

  return {
    dataList,
    showModal,
    showViewModal,
    showDeleteModal,
    uniqueCode,
    uniqueCodeName,
    shiftsList,
    shiftsListMemo,
    page,
    hasMore,
    form,
    shiftColumns,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    submitFilter,
    showBulkDeleteModal,
    inputs,
    isOpen
  };
};

export default useShift;
