import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import PropTypes from 'prop-types';

const DetailsButton = ({
  isExpired,
  formLoading,
  menuCode,
  modalName,
  handleViewModal,
  handleArchiveSubmit,
  handleDeleteSubmit
}) => {
  const { access } = useUserAccess(menuCode);

  return (
    <div className="flex gap-[10px] text-[12px]">
      <div className="">
        {access.can_edit ? (
          <Button
            type="button"
            name="Edit"
            modifier="bg-gray-400 hover:bg-gray-500 text-white sm:p-[4px_7px] lg:p-[8px_14px] text-[12px] leading-[24px] rounded"
            spanModifier="before:absolute before:left-0 before:content-[url('/src/assets/icons/edit_icn.svg')]"
            onClick={() => handleViewModal(modalName)}
          />
        ) : null}
      </div>
      <div className="">
        {isExpired && access.can_edit ? (
          <Button
            type="button"
            name="Archived this post"
            modifier="bg-gray-400 hover:bg-gray-500 text-white sm:p-[4px_7px] lg:p-[8px_14px] text-[12px] leading-[24px] rounded"
            spanModifier="before:absolute before:left-0 before:content-[url('/src/assets/icons/addWhite.svg')]"
            onClick={() => handleArchiveSubmit()}
            disabled={formLoading}
          />
        ) : null}
      </div>
      <div className="">
        {access.can_delete ? (
          <Button
            type="button"
            modifier="bg-[#FFFFFF] border border-solid border-1 text-white sm:p-[6px_9px] lg:p-[8px_14px] text-[12px] leading-unset rounded mr-[10px] hover:bg-gray-700"
            spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[url('/src/assets/icons/trash.svg')]"
            onClick={() => handleDeleteSubmit()}
          />
        ) : null}
      </div>
    </div>
  );
};

DetailsButton.propTypes = {
  isExpired: PropTypes.bool,
  formLoading: PropTypes.bool,
  menuCode: PropTypes.string,
  modalName: PropTypes.string,
  handleViewModal: PropTypes.func,
  handleArchiveSubmit: PropTypes.func,
  handleDeleteSubmit: PropTypes.func
};

export default DetailsButton;
