import { GET_SHIFT_LIST } from './shiftsTypes';

const INITIAL_STATE = {
  shifts: [],
  all: []
};

export default function shiftReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_SHIFT_LIST:
      return {
        ...state,
        shifts: payload
      };
    default:
      return state;
  }
}
