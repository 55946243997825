import clock_icon from 'src/assets/icons/bundy_icon.svg';
import React, { useMemo } from 'react';

import { Breadcrumbs, ModalCenter } from 'src/components/Common';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import './components/Calendar.scss';
import ClockInOut from 'src/components/Parts/Dashboard/ClockInOut';
import Announcements from 'src/components/Parts/Dashboard/Announcements';
import useAnnouncements from 'src/hooks/components/Parts/Dashboard/useAnnouncements';
import useEvents from 'src/hooks/components/Parts/Dashboard/useEvents';
import useDTR from 'src/hooks/components/Parts/Dashboard/useDTR';
import customToolbar from './components/CustomToolbar';
import TimeRecordModal from './components/TimeRecordModal';

const Index = ({ menuCode }) => {
  const { announcementList } = useAnnouncements();
  const { eventsList } = useEvents();
  const { showModal, handleModal } = useDTR();
  const { CustomToolbar } = customToolbar();
  const localizer = momentLocalizer(moment);

  const { formats } = useMemo(
    () => ({
      formats: {
        dateFormat: (date, culture) => localizer.format(date, 'D', culture),
        dayHeaderFormat: (date, culture) =>
          localizer.format(date, 'ddd D', culture)
      }
    }),
    []
  );

  return (
    <div className="user__container ">
      <div className="hidden lg:block">
        <Breadcrumbs crumbs={[{ name: 'Dashboard' }]} />
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="basis-5/6 order-2 lg:order-1 ">
          <Calendar
            eventPropGetter={event => {
              const backgroundColor = event.color;
              return { style: { backgroundColor } };
            }}
            localizer={localizer}
            events={eventsList}
            views={[Views.MONTH]}
            components={{
              toolbar: CustomToolbar
            }}
            formats={formats}
            popup
          />
        </div>
        <div className="basis-1/6 flex-1 order-1 lg:order-2 lg:pl-[20px]">
          <div className="lg:mt-[28px]">
            <div className="flex mb-[5px]">
              <img src={clock_icon} alt="clock_icon" />
              <h2 className="ml-[4px] text-[14px] font-bold">Bundy Clock</h2>
            </div>

            <ClockInOut />
          </div>
          <div className="hidden lg:block">
            <Announcements announcements={announcementList} />
          </div>
        </div>
        <div className="basis-1/3 order-3 lg:order-2 lg:hidden mt-[10px]">
          <div className="">
            <Announcements announcements={announcementList} />
          </div>
        </div>
      </div>
      <ModalCenter showModal={showModal} modalName="TimeRecordModal">
        {showModal && (
          <TimeRecordModal
            handleModal={handleModal('TimeRecordModal')}
            id={1}
            name="test"
            modal={showModal}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
