/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import 'src/assets/css/custom/scrollbar.scss';
import { STATUS_OPTIONS } from 'src/helpers/constants';

import { useAddEditModal } from 'src/hooks/components/Parts/User/Group/useAddEditModal';
import FormLabel from 'src/components/Common/FormLabel';
import FormCard from 'src/components/Common/FormCard';
import FormGridCard from 'src/components/Common/FormGridCard';
import FormField from 'src/components/Common/FormField';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Loading from 'src/components/Common/loading';
import { employeeName } from 'src/helpers/utils';
import 'src/assets/css/input.scss';

const AddEditModal = ({ handleModal, id, menuCode, joinGroup }) => {
  const {
    userRef,
    menuRef,
    groupForm,
    searchUser,
    searchMenu,
    initialDummyData,
    handleChange,
    handleMenu,
    handleSearchMenu,
    handleSearchUser,
    handleAddMenu,
    handSelectChange,
    handleDeleteMenu,
    handleDeleteMember,
    handleAddMember,
    setIsEdit,
    isEdit,
    validationSchema,
    validationEditSchema,
    submitForm,
    modalTitle,
    formLoading,
    selectedUserIndex,
    selectedMenuIndex,
    handleKeyPress
  } = useAddEditModal({ handleModal, id });
  const pageAccess = useUserAccess(menuCode);

  return (
    <Formik
      enableReinitialize={!!id}
      initialValues={id ? initialDummyData : groupForm}
      validationSchema={id ? validationEditSchema : validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitForm}
    >
      {({
        errors,
        values,
        status,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="userGrpForm" onKeyPress={handleKeyPress}>
          {/* Modal Head Section | Start */}
          {formLoading && <Loading />}
          <div className="flex justify-between mx-[50px]">
            <h4 className="text-[22px] font-StolzlMedium leading-[27px] ">
              {modalTitle}
            </h4>
            <div className="text-right">
              {!isEdit && id ? (
                <button
                  type="button"
                  disabled={!pageAccess.access.can_edit || joinGroup[id]}
                  onClick={e => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                  className={`text-[12px] text-white ${
                    !pageAccess.access.can_edit || joinGroup[id]
                      ? 'bg-gray-400/20'
                      : 'bg-gray-400 hover:bg-gray-500'
                  } font-stolzlBook leading-[100%] h-10 w-20 border-none rounded`}
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                    Edit
                  </span>
                </button>
              ) : (
                isEdit &&
                id && (
                  <button
                    form="userGrpForm"
                    type="submit"
                    disabled={formLoading}
                    className={` ${
                      formLoading && 'opacity-50'
                    } text-[12px] text-white font-stolzlBook leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_40px] rounded`}
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                      Save
                    </span>
                  </button>
                )
              )}
            </div>
          </div>
          {/* Modal Head Section | End */}
          <div className="mb-5 mx-[40px] mt-6 border-solid border-b-[1px] border-[#eaeaea]" />
          <div
            className={`p-[20px_40px_0] h-[calc(100vh-238px)] overflow-auto relative ${
              formLoading ? 'opacity-0' : 'opacity-100'
            } transition-opacity`}
          >
            <FormGridCard cols="2">
              <FormCard>
                <FormField
                  label="Group"
                  required
                  name="user_group_name"
                  type="text"
                  placeholder=""
                  errorMessage="Field Required"
                  error={errors.user_group_name}
                  value={groupForm.user_group_name ?? ''}
                  onChange={e =>
                    handleChange(
                      e,
                      null,
                      null,
                      setFieldValue,
                      setFieldTouched,
                      setFieldError,
                      isSubmitting
                    )
                  }
                  disabled={id && !isEdit}
                />
                {errors.user_group_name !== 'Required' && (
                  <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                    {errors.user_group_name}
                  </div>
                )}
              </FormCard>
              <FormCard>
                <FormField
                  label="Status"
                  type="select"
                  options={STATUS_OPTIONS}
                  onChangeValue={handSelectChange}
                  selectedValue={groupForm.status}
                  disabled={id && !isEdit}
                />
              </FormCard>
            </FormGridCard>

            {/* Members Section | Start */}
            <FormGridCard cols="1">
              <FormCard>
                <div className="mb-4">
                  <FormLabel text="Members" htmlFor="members" required />
                  <div className="relative">
                    <input
                      className={`block w-full border-solid border-[1px] mt-2.5 ${
                        errors.members
                          ? 'border-[#E43B26] placeholder:text-[#f19d94]'
                          : 'border-[#eaeaea] text-[14px]'
                      } rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[12px_13px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                      ref={userRef}
                      type="text"
                      name="members"
                      id="members"
                      placeholder={errors.members ? 'Field Required' : 'Search'}
                      autoComplete="off"
                      disabled={id && !isEdit}
                      onChange={e =>
                        handleSearchUser(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      onKeyDown={event => {
                        handleAddMember(
                          event,
                          '',
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        );
                      }}
                    />
                    {searchUser.length ? (
                      <div className="absolute left-0 top-[calc(100%+2px)] w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                        <ul className="max-h-40 overflow-auto custom-scrollbar dropdown">
                          {searchUser.map((user, index) => (
                            <li
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className={`flex items-center text-[14px] text-[#232932] font-stolzlBook leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 capitalize ${
                                index === selectedUserIndex ? 'selected' : ''
                              } `}
                              onClick={event =>
                                handleAddMember(
                                  event,
                                  user,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                              role="button"
                              tabIndex={0}
                            >
                              {employeeName({
                                first_name: user.first_name,
                                middle_name: user.middle_name,
                                last_name: user.last_name,
                                suffix: user.suffix
                              })}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-[31px] mt-2.5">
                  <div className="flex flex-row items-center gap-[5px] border-solid border-b-[1px] border-[#eaeaea] pb-2.5 mt-5 mb-[18px]">
                    <div className="basis-[27.5%]">
                      <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                        Name
                      </h5>
                    </div>
                    <div className="basis-[33%]">
                      <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                        Cost Center
                      </h5>
                    </div>
                    <div className="basis-[34.2%]">
                      <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                        Position
                      </h5>
                    </div>
                    <div className="flex-none w-5" />
                  </div>
                  <div className="max-h-[240px] overflow-auto custom-scrollbar">
                    {groupForm?.users?.length ? (
                      groupForm.users.map((user, index) => (
                        <div
                          className="flex flex-row items-center gap-[5px] mb-[3px]"
                          key={index}
                        >
                          <div className="basis-[27.5%]">
                            <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px] capitalize">
                              {employeeName({
                                first_name: user.first_name,
                                middle_name: user.middle_name,
                                last_name: user.last_name,
                                suffix: user.suffix
                              })}
                            </p>
                          </div>
                          <div className="basis-[33%]">
                            <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                              {user?.cost_center_code}
                              {user?.cost_center_tree &&
                              user?.cost_center_tree.length
                                ? ` / ${
                                    user?.cost_center_tree[
                                      user.cost_center_tree.length - 1
                                    ]
                                  }`
                                : '-'}
                            </p>
                          </div>
                          <div className="basis-[34.2%]">
                            <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                              {user.position ?? '-'}
                            </p>
                          </div>
                          {isEdit && (
                            <div className="flex-none w-5">
                              <button
                                type="button"
                                className="relative inline-block align-super w-5 h-3.5"
                                name={user.id}
                                disabled={!isEdit}
                                onClick={() => handleDeleteMember(user.id)}
                              >
                                <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-45 rounded" />
                                <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-[-45deg] rounded" />
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                        No user selected.
                      </p>
                    )}
                  </div>
                </div>
              </FormCard>
            </FormGridCard>
            {/* Members Section | End */}

            {/* Access Menu Section | Start */}
            <div className="mb-5 pt-[21px] border-solid border-t-[1px] border-[#eaeaea]">
              <FormCard>
                <div className="mb-4">
                  <FormLabel
                    text="Access Menu"
                    htmlFor="access_menus"
                    required
                  />
                  <div className="relative">
                    <input
                      className={`block w-full border-solid border-[1px] mt-2.5 ${
                        errors.access_menus
                          ? 'border-[#E43B26] placeholder:text-[#f19d94]'
                          : 'border-[#eaeaea] text-[14px]'
                      } rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[12px_13px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                      ref={menuRef}
                      type="text"
                      name="access_menus"
                      id="access_menus"
                      placeholder={
                        errors.access_menus ? 'Field Required' : 'Search'
                      }
                      autoComplete="off"
                      disabled={id && !isEdit}
                      onChange={e =>
                        handleSearchMenu(
                          e,
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        )
                      }
                      onKeyDown={event => {
                        handleAddMenu(
                          event,
                          '',
                          setFieldValue,
                          setFieldTouched,
                          setFieldError
                        );
                      }}
                    />
                    {searchMenu.length ? (
                      <div className="absolute left-0 top-[calc(100%+2px)] w-full bg-white z-10 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                        <ul className="max-h-40 overflow-auto custom-scrollbar dropdown">
                          {searchMenu.map((menu, index) => (
                            <li
                              key={menu.id}
                              className={`flex items-center text-[14px] text-[#232932] font-stolzlBook leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 ${
                                index === selectedMenuIndex ? 'selected' : ''
                              } `}
                              onClick={event =>
                                handleAddMenu(
                                  event,
                                  menu,
                                  setFieldValue,
                                  setFieldTouched,
                                  setFieldError
                                )
                              }
                              role="button"
                              tabIndex={0}
                            >
                              {menu.menu_code} - {menu.menu_name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-row items-center my-[20px]">
                  <div className="w-[220px]">
                    <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                      Menu Code
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      View
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Add
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Edit
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Delete
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Print
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Approve
                    </h5>
                  </div>
                  <div className="w-[65px] text-center">
                    <h5 className="text-[10px] text-[#414141] font-stolzlBook leading-[16px] ml-[2px]">
                      Generate
                    </h5>
                  </div>
                  <div className="w-[17px]" />
                </div>
                <div className="max-h-[calc(100vh-405px)] overflow-auto custom-scrollbar">
                  {groupForm?.menus?.length ? (
                    groupForm.menus.map((access, index) => (
                      <div
                        className="flex flex-row items-top pt-[19px] pb-[16px] border-solid border-b-[1px] border-[#eaeaea] first:pt-0 last:border-none"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        <div className="w-[220px] relative after:content-[''] after:block after:w-[1px] after:h-[calc(100%+36px)] after:bg-[#eaeaea] after:absolute after:top-[-20px] after:right-0">
                          <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                            {access.menu_code} - {access.menu_name}{' '}
                          </p>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_view[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_view[${access.id}]`}
                              id={`can_view[${access.id}]`}
                              checked="checked"
                              onChange={() => null}
                              hidden
                            />
                            <span className="inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative">
                              <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                              <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_add[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_add[${access.id}]`}
                              id={`can_add[${access.id}]`}
                              checked={
                                access.user_group_access.can_add
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() => handleMenu(access.id, 'can_add')}
                              hidden
                              disabled={!access.can_add || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_add &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_add && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_edit[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_edit[${access.id}]`}
                              id={`can_edit[${access.id}]`}
                              checked={
                                access.user_group_access.can_edit
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() => handleMenu(access.id, 'can_edit')}
                              hidden
                              disabled={!access.can_edit || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_edit &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_edit && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_delete[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_delete[${access.id}]`}
                              id={`can_delete[${access.id}]`}
                              checked={
                                access.user_group_access.can_delete
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() =>
                                handleMenu(access.id, 'can_delete')
                              }
                              hidden
                              disabled={!access.can_delete || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_delete &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_delete && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_print[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_print[${access.id}]`}
                              id={`can_print[${access.id}]`}
                              checked={
                                access.user_group_access.can_print
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() =>
                                handleMenu(access.id, 'can_print')
                              }
                              hidden
                              disabled={!access.can_print || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_print &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_print && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_approve[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_approve[${access.id}]`}
                              id={`can_approve[${access.id}]`}
                              checked={
                                access.user_group_access.can_approve
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() =>
                                handleMenu(access.id, 'can_approve')
                              }
                              hidden
                              disabled={!access.can_approve || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_approve &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_approve && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="w-[65px] text-center">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`can_generate[${access.id}]`}
                          >
                            <input
                              type="checkbox"
                              name={`can_generate[${access.id}]`}
                              id={`can_generate[${access.id}]`}
                              checked={
                                access.user_group_access.can_generate
                                  ? 'checked'
                                  : ''
                              }
                              onChange={() =>
                                handleMenu(access.id, 'can_generate')
                              }
                              hidden
                              disabled={!access.can_generate || !isEdit}
                            />
                            <span
                              className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative ${
                                !access.can_generate &&
                                '!bg-disabled !border-disabled'
                              }`}
                            >
                              {access.can_generate && (
                                <>
                                  <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                                  <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                                </>
                              )}
                            </span>
                          </label>
                        </div>
                        {isEdit && (
                          <div className="w-[17px] relative left-[10.3%] text-right">
                            <button
                              type="button"
                              className="relative inline-block align-super w-[17px] h-5"
                              name={access.menu_code}
                              disabled={!isEdit}
                              onClick={() => handleDeleteMenu(access.menu_code)}
                            >
                              <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%-4px)] left-[calc(50%+4px)] translate-x-[-50%] rotate-45 rounded" />
                              <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%-4px)] left-[calc(50%+4px)] translate-x-[-50%] rotate-[-45deg] rounded" />
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                      No menu available.
                    </p>
                  )}
                </div>
              </FormCard>
            </div>
          </div>
          <div className="mb-5 mx-[40px] border-solid border-b-[1px] border-[#eaeaea]" />
          {/* Access Menu | End */}
          <div className="p-[10px_40px_0] grid grid-cols-2 gap-[20px]">
            {!id && (
              <div>
                <button
                  form="userGrpForm"
                  type="submit"
                  disabled={isSubmitting}
                  className="text-[12px] text-white font-stolzlBook leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_40px] rounded"
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[22px] tracking-[-0.5px]">
                    Save
                  </span>
                </button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddEditModal.propTypes = {
  handleModal: PropTypes.func,
  id: PropTypes.number,
  menuCode: PropTypes.string,
  joinGroup: PropTypes.instanceOf(Object)
};
export default AddEditModal;
