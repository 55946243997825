import { useWatch } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';

const useCheckboxMultiSelect = ({
  form,
  name,
  item,
  submitFilter,
  selected,
  placeholder
}) => {
  const [defaultValue] = useState(placeholder);
  const [currentValues, setCurrentValues] = useState(() => selected || ['A']);
  const [isDropdownDisplayed, setIsDropdownDisplayed] = useState(false);
  const buttonRef = useRef();
  const dropdownRef = useRef();

  useWatch({
    control: form.control,
    name,
    defaultValue: selected
  });

  const handleDropdown = () => {
    setIsDropdownDisplayed(!isDropdownDisplayed);
  };

  const getToggledOptions = (currentOptions, value) => {
    if (currentOptions.includes(value)) {
      return currentOptions.filter(option => option !== value);
    }
    return [...currentOptions, value];
  };

  const handleChange = value => {
    setCurrentValues(getToggledOptions(currentValues, value));
  };

  const handleAllCheckboxChange = isChecked => {
    if (isChecked) {
      const allValues = item.map(option => option.value);
      setCurrentValues(allValues);
    } else {
      setCurrentValues([]);
    }
  };

  const getLabelByValue = value => {
    const selectedOption = item?.find(option => option.value === value);
    return selectedOption ? selectedOption.label : '';
  };

  useEffect(() => {
    form.setValue(name, currentValues.join(','));
    submitFilter();
  }, [currentValues]);

  useEffect(() => {
    setCurrentValues(selected);
    const handleOutsideClick = event => {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target) &&
        !buttonRef?.current?.contains(event.target)
      ) {
        setIsDropdownDisplayed(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return {
    currentValues,
    defaultValue,
    isDropdownDisplayed,
    dropdownRef,
    buttonRef,
    getLabelByValue,
    getToggledOptions,
    handleChange,
    handleAllCheckboxChange,
    handleDropdown
  };
};

export default useCheckboxMultiSelect;
