/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  getLowerCasedValue,
  employeeName,
  filterData
} from 'src/helpers/utils';
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useParams
} from 'react-router-dom';
import { getAllEmployeeEvaluationsService } from 'src/api/modules/evaluation';
import { getEvaluationScheduleByIdService } from 'src/api/modules/evaluationSchedule';
import { useEmployees } from 'src/hooks/EvaluationSchedule/helpers';

const useEvaluationEntry = () => {
  const routerParams = useParams();
  const [evaluationEntryList, setEvaluationEntryList] = useState([]);
  const [evaluationEntryColumns, setEvaluationEntryColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [scheduleName, setScheduleName] = useState('');
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const rawDataList = useRef([]);
  const columnCount = useRef(0);
  const entryItems = useRef([]);
  const [evaluationEntryCount, setEvaluationEntryCount] = useState(0);
  const { allEmployees } = useEmployees(1);
  const itemsPerPage = 20;

  const evaluationEntryBaseColumns = [
    { key: 'name', label: 'Name' },
    { key: 'cost_center', label: 'Cost Center' },
    { key: 'template', label: 'Template' },
    { key: 'status', label: 'Status' }
  ];

  const access = {
    can_add: true,
    can_approve: true,
    can_delete: true,
    can_edit: true,
    can_generate: true,
    can_print: true,
    can_view: true
  };

  const EVALUATION_STATUS = [
    {
      value: 'A',
      label: 'Ongoing'
    },
    {
      value: 'F',
      label: 'Completed'
    },
    {
      value: 'N',
      label: 'Not Started'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(EVALUATION_STATUS, 'label', 'value'),
      multiOptions: EVALUATION_STATUS,
      name: 'evaluation_entry_status',
      label: 'Entry Status',
      defaultValue: ['A', 'F', 'N'],
      size: 'L'
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.evaluation_entry_status === '') {
      delete searchParamsObject.evaluation_entry_status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'evaluation_entry_status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-evaluation-entry-search-params', searchParams);
  };

  const getEvaluatorEntryStatus = criteriaDetails => {
    if (!criteriaDetails) return 'N';
    const count = criteriaDetails.length;
    const hasEntryCount = criteriaDetails.filter(
      ({ evaluator_value }) => evaluator_value !== null
    ).length;
    if (hasEntryCount <= 0) return 'N';
    if (count === hasEntryCount) return 'F';
    return 'A';
  };

  const generateColumns = useCallback(
    async sections => {
      if (sections) {
        const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
          key: `evaluator_${i + 1}`,
          label: `Evaluator ${i + 1}`
        }));
        setEvaluationEntryColumns(headers => [
          ...headers,
          ...evaluationEntryBaseColumns.slice(0, 3),
          ...evaluatorColumns,
          ...evaluationEntryBaseColumns.slice(-1)
        ]);
      }
    },
    [evaluationEntryList]
  );

  const getList = useCallback(
    maxCount => {
      const finalMaxCount = Math.max(maxCount || 0, 1000);
      entryItems.current = [
        ...(evaluationEntryList?.map(
          ({
            id,
            status,
            employee,
            evaluation_template,
            employee_evaluation_sections: sections = []
          }) => {
            // prettier-ignore
            const { cost_center_code = '', employee_info = {} } = employee || {};
            const sectionCount = sections.length;
            const missing = finalMaxCount - sectionCount;
            const filledSections = [
              ...sections,
              ...Array.from({ length: missing > 0 ? missing : 0 }, () => ({
                id: null,
                section_id: null,
                evaluator: null,
                criteria_details: null
              }))
            ];
            const item = {
              id,
              name: employeeName(employee_info) || '-',
              status: status || '-',
              cost_center: cost_center_code || '-',
              template: evaluation_template?.name || '-',
              templateId: evaluation_template?.id || '-',
              sections: filledSections,
              sectionCount
            };
            const evaluation_sections = filledSections.map(
              ({ evaluator = {}, criteria_details = {} }, index) => {
                const { middle_name } =
                  // prettier-ignore
                  allEmployees?.find(cody =>
                    // prettier-ignore
                    cody.first_name?.trim() === evaluator?.employee_info?.first_name?.trim()
                  ) || {};
                const employeeFullInfo = {
                  ...evaluator?.employee_info,
                  middle_name
                };
                return {
                  [`evaluator_${index + 1}`]:
                    employeeName(employeeFullInfo) || '-',
                  [`eval_entry_status_${index + 1}`]:
                    getEvaluatorEntryStatus(criteria_details) || '-'
                };
              }
            );
            return evaluation_sections.reduce(
              (object, current) => ({ ...object, ...current }),
              { ...item }
            );
          }
        ) || [])
      ];
    },
    [evaluationEntryList, allEmployees]
  );
  const evaluationListMemo = useMemo(() => {
    return entryItems.current || [];
  }, [evaluationEntryList, entryItems.current]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-entry-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-evaluation-entry-page-no'),
        10
      );

      // const newSearchParams = savedSearchParams
      //   ?.replace('status=A&', '')
      //   ?.replace('status=A', '');

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(savedSearchParams);
      params.append('schedule_id', routerParams?.id);
      const res = await getAllEmployeeEvaluationsService(
        newPageIndex || page,
        params
      );

      setEvaluationEntryCount(res.data.items?.length);

      columnCount.current = res.data.max_section;

      const newList =
        savedPageIndex !== 1
          ? evaluationEntryList.concat(res.data.items)
          : res.data.items;

      setTotalPage(res.data.total_pages);

      rawDataList.current = newList;
      setEvaluationEntryList(newList);

      if (res.data.items.length < itemsPerPage) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPage(savedPageIndex + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAllEmployeeEvaluationsService,
    setEvaluationEntryList,
    setPage,
    setHasMore,
    evaluationEntryList,
    routerParams
  ]);

  const handleViewPage = useCallback((modal, id = null) => {
    navigate(`/evaluation-entry/${id}`);
  }, []);

  const getEvaluationInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getEvaluationScheduleByIdService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        setScheduleName(responseData.name);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  useEffect(() => {
    if (columnCount.current) {
      generateColumns(columnCount.current);
      getList(columnCount.current);
    }
  }, [evaluationEntryList]);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-entry-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-entry-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchData();
    } else {
      // set default status
      form.reset({ evaluation_entry_status: 'A,F,N' });
    }
    return () => {
      localStorage.setItem('hris-evaluation-entry-search-params', '');
    };
  }, []);

  useEffect(() => {
    const newLocation = { ...location, search: '' };
    navigate(newLocation);
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    return () => {
      localStorage.setItem('hris-evaluation-entry-search-params', '');
    };
  }, [navigate]);

  useEffect(() => {
    getEvaluationInfo(routerParams.id);
  }, []);

  return {
    page,
    form,
    hasMore,
    access,
    evaluationEntryColumns,
    evaluationEntryList,
    evaluationListMemo,
    evaluationEntryCount,
    scheduleName,
    fetchData,
    submitFilter,
    handleViewPage,
    inputs
  };
};

export default useEvaluationEntry;
