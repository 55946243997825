import React from 'react';
import { SlideModal, ModalCenter, Breadcrumbs } from 'src/components/Common';
import PropTypes from 'prop-types';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Datatable from 'src/components/Common/Datatable';
import { useUserAccess } from 'src/hooks/useUserAccess';
import useLeaveType from 'src/hooks/Pages/Timekeeping/LeaveTypes/useLeaveTypeIndex';
import { BULK_DELETE_LEAVE_TYPE } from 'src/api/endpoints';
import { bulkDeleteLeaveType } from 'src/api/modules/leaveType';
import AddEditModal from './components/AddEditModal';
import DeleteModal from './components/DeleteModal';
import BulkDeleteModal from './components/BulkDeleteModal';

const Index = ({ menuCode }) => {
  const {
    leaveTypeColumns,
    isOpen,
    showCreateModal,
    showViewModal,
    hasMore,
    showModal,
    form,
    inputs,
    showDeleteModal,
    showBulkDeleteModal,
    leaveTypeId,
    leaveTypeListMemo,
    leaveTypeList,
    deleteId,
    fetchData,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    handleViewModal,
    handleDeleteModal,
    handleDelete,
    setShowDeleteModal,
    handleCreateModal,
    detectDateOnChange
  } = useLeaveType();
  const { menus } = useUserAccess();
  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Timekeeping ' }, { name: 'Leave Types' }]}
      />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Leave Code, Description"
            buttonName="Leave Type"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
          />

          <InfiniteScroll
            dataLength={leaveTypeListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {leaveTypeListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={leaveTypeListMemo || []}
              clickableRows={false}
              headingColumns={leaveTypeColumns}
              title="Leave Types"
              actions={['edit', 'delete']}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              // isHoliday
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>

      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            leaveTypeId={leaveTypeId}
            dataList={leaveTypeList}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            code={deleteId.id}
            name={deleteId.leave_code}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            apiService={bulkDeleteLeaveType}
            endpoint={BULK_DELETE_LEAVE_TYPE}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
