import { getShiftsService } from 'src/api/modules/shifts';
import { GET_SHIFT_LIST } from './shiftsTypes';

export function setShifts(data) {
  return { type: GET_SHIFT_LIST, payload: data };
}

export function getShifts() {
  return async function (dispatch) {
    const response = await getShiftsService();
    if (response?.success && response?.data) dispatch(setShifts(response.data));
  };
}
