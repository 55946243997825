import React from 'react';
import { useParams } from 'react-router-dom';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useQuizManagerAction from 'src/hooks/Pages/LearningManagement/QuizManager/useQuizManagerAction';

// Quiz Components
import AddQuiz from 'src/Pages/LearningManagement/QuizManager/components/AddQuiz';

// eslint-disable-next-line react/prop-types
const HandleQuiz = ({ menuCode, viewOnly }) => {
  const { id } = useParams();

  const {
    getQuizInfo,
    handleChange,
    handleNumberInput,
    handleSelectChange,
    quizFields,
    handleQuizSubmit,
    validationQuizSchema,
    statusOptions,
    handleSearchSkills,
    searchSkill,
    handleAddSkill,
    addedSkill,
    skillsRef,
    handleDeleteSkill
  } = useQuizManagerAction(id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Quiz Manager', link: '/quiz-manager' },
          {
            name: `${!id ? 'Add Quiz' : 'Quiz Information'}`
          }
        ]}
      />

      <div className="pt-2">
        <AddQuiz
          quizId={id}
          getQuizInfo={getQuizInfo}
          handleChange={handleChange}
          handleNumberInput={handleNumberInput}
          handleSelectChange={handleSelectChange}
          handleQuizSubmit={handleQuizSubmit}
          viewOnly={viewOnly}
          menuCode={menuCode}
          statusOptions={statusOptions}
          quizFields={quizFields}
          validationQuizSchema={validationQuizSchema}
          handleSearchSkills={handleSearchSkills}
          searchSkill={searchSkill}
          handleAddSkill={handleAddSkill}
          addedSkill={addedSkill}
          skillsRef={skillsRef}
          handleDeleteSkill={handleDeleteSkill}
        />
      </div>
    </div>
  );
};

export default HandleQuiz;
