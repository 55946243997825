/* eslint-disable import/no-unresolved */
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { getAnnouncementsService } from 'src/api/modules/announcements';
import { useSelector, useDispatch } from 'react-redux';
import { setAnnouncementList } from 'src/redux/modules/announcement/announcementActions';

export const useBulkDeleteModal = ({ handleBulkDeleteModal, apiService }) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);

  const fetchUpdateAnnouncementList = async () => {
    try {
      const res = await getAnnouncementsService(1);
      const modifiedResult = res.data.items?.map(item => {
        const { id, title, post_date } = item;

        return {
          id,
          title,
          post_date
        };
      });
      dispatch(setAnnouncementList(modifiedResult));
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await apiService(ids);
      if (res?.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
        dispatch(setIdSelection([]));
        fetchUpdateAnnouncementList();
        if (res?.status === 405) {
          toast.error('Unable to delete!', {
            icon: <MdOutlineCancel />
          });
        }
        handleBulkDeleteModal(null);
      }
    }
  };

  return {
    handleDelete,
    ids
  };
};
