import { GET_HOLIDAYS_LIST } from './holidayTypes';

const INITIAL_STATE = {
  holidayList: []
};

export default function holidayReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_HOLIDAYS_LIST:
      return {
        ...state,
        holidayList: payload
      };
    default:
      return state;
  }
}
