import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { getActiveAnnouncementsService } from 'src/api/modules/announcements';

const useAnnouncements = () => {
  const [announcementList, setAnnouncementList] = useState([]);

  const getActiveAnnouncements = async () => {
    try {
      const res = await getActiveAnnouncementsService();
      const newList = res.data.items ?? [];
      if (newList.length !== 0) {
        setAnnouncementList(
          newList.map(o => {
            return {
              id: o.id,
              expire_date: o.expire_date,
              post_date: o.post_date,
              title: o.title,
              content: o.content,
              sub_text: `Posted:${formatDate(o.post_date)} by ${
                o.created.first_name
              } ${o.created.last_name}`
            };
          })
        );
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  function formatDate(date) {
    return moment(date, 'MM/DD/YYYY').format('MMMM DD, YYYY');
  }

  useEffect(() => {
    getActiveAnnouncements();
  }, []);

  return {
    announcementList,
    getActiveAnnouncements
  };
};

export default useAnnouncements;
