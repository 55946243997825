import React from 'react';
import PropTypes from 'prop-types';
import { useUserAccess } from 'src/hooks/useUserAccess';
import useFilter from 'src/hooks/components/Common/Filter/useFilter';
import { useSelector } from 'react-redux';
import { cn } from 'src/helpers/utils';
import Button from '../Button';
import '../../../assets/css/search-filter.scss';
import Search from './Search';

const Filter = ({
  searchInputPlaceholder = 'Search',
  buttonName,
  buttonLink,
  inputs,
  showSearchBox = true,
  showActionBtn = true,
  buttonOnClick,
  bulkDeleteClick,
  menuCode,
  form,
  isOpen,
  submitFilter,
  children,
  dateOnChange,
  onChangeValue,
  customButton,
  exportCSV,
  localQuery
}) => {
  const { access } = useUserAccess(menuCode);
  const { handleFiltersDisplay, searchValue } = useFilter({
    form,
    submitFilter,
    isOpen,
    dateOnChange,
    onChangeValue,
    menuCode,
    localQuery
  });

  const { ids } = useSelector(state => state.datatable);

  return (
    <div className="search-filter__container">
      <form
        onSubmit={e => {
          e.preventDefault();
          submitFilter();
        }}
      >
        <div className="flex items-end flex-wrap gap-[30px]">
          {showSearchBox ? (
            <div className="flex w-[300px]">
              <Search
                form={form}
                searchInputPlaceholder={searchInputPlaceholder}
                submitFilter={submitFilter}
                defaultSearch={searchValue || ''}
              />
            </div>
          ) : null}

          {!!inputs?.length &&
            inputs.map((input, i) => (
              <div
                className={`${
                  input.type === 'date-range' ? 'w-[300px]' : 'w-[255px]'
                }`}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                <div
                  className={`search-filter__label relative ${
                    isOpen ? '' : 'z-[1]'
                  }`}
                  htmlFor="inputs"
                >
                  {input.label ? (
                    <span className="block mb-1 text-[12px] font-stolzlBook leading-[14px]">
                      {input.label}
                    </span>
                  ) : null}
                  {handleFiltersDisplay(input)}
                </div>
              </div>
            ))}

          <div className="ml-auto flex">
            {bulkDeleteClick ? (
              <Button
                type="button"
                modifier="bg-[#FFFFFF] border border-solid border-1 text-white p-[10px_14px] text-[12px] leading-unset rounded mr-[10px] hover:bg-gray-700"
                spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[url('/src/assets/icons/trash.svg')]"
                link={buttonLink}
                onClick={bulkDeleteClick}
                disabled={!ids.length || !access.can_delete}
              />
            ) : null}
            {showActionBtn && (
              <Button
                type="button"
                name={`Add ${buttonName}`}
                modifier="bg-gray-400 hover:bg-gray-500 text-white p-[8px_14px] text-[12px] leading-[24px] rounded"
                spanModifier="before:absolute before:left-0 before:content-[url('/src/assets/icons/addWhite.svg')]"
                link={buttonLink}
                onClick={buttonOnClick}
                disabled={!access.can_add}
              />
            )}
            {exportCSV && (
              <Button
                type={exportCSV.type}
                name={exportCSV.name}
                modifier="bg-gray-400 hover:bg-gray-500 text-white p-[8px_14px] text-[12px] leading-[24px] rounded mr-[10px]"
                spanModifier={cn('before:absolute before:left-0 !pl-0')}
                onClick={exportCSV.clickHandler}
              />
            )}
            {customButton && (
              <Button
                type={customButton.type}
                name={customButton.name}
                modifier="bg-gray-400 hover:bg-gray-500 text-white p-[8px_14px] text-[12px] leading-[24px] rounded"
                spanModifier={cn('before:absolute before:left-0 !pl-0')}
                onClick={customButton.clickHandler}
                disabled={!!customButton.disabled}
              />
            )}
            {children || null}
          </div>
        </div>
      </form>
    </div>
  );
};

Filter.propTypes = {
  searchInputPlaceholder: PropTypes.string,
  buttonName: PropTypes.string,
  buttonLink: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  showSearchBox: PropTypes.bool,
  showActionBtn: PropTypes.bool,
  buttonOnClick: PropTypes.func,
  bulkDeleteClick: PropTypes.func,
  menuCode: PropTypes.string,
  form: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  submitFilter: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.node
  ]),
  dateOnChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  customButton: PropTypes.shape({
    name: PropTypes.string,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string
  }),
  exportCSV: PropTypes.shape({
    name: PropTypes.string,
    clickHandler: PropTypes.func,
    type: PropTypes.string
  }),
  localQuery: PropTypes.string
};

export default Filter;
