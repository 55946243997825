/* eslint-disable no-shadow */
import moment from 'moment';
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAnnouncementByIdService,
  getActiveAnnouncementsService,
  updateAnnouncementService,
  deleteAnnouncementService
} from 'src/api/modules/announcements';
import { setAnnouncementSingle } from 'src/redux/modules/announcement/announcementActions';

const useAnnouncements = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const [announcementSingleData, setAnnouncementSingleData] = useState();
  const [announcementsData, setAnnouncementsData] = useState();
  const [showViewModal, setShowViewModal] = useState(null);
  const announcementSingle = useSelector(
    state => state?.announcement?.announcementSingle
  );

  const fetchAnnouncement = async id => {
    await getAnnouncementByIdService(id).then(res => {
      setAnnouncementSingleData({
        id,
        title: res?.data?.title,
        startDate: moment(res?.data?.start_date).format('MMMM DD, YYYY'),
        postDate: moment(res?.data?.post_date).format('MMMM DD, YYYY'),
        updatedDate: moment(res?.data?.updated_at).format('MMMM DD, YYYY'),
        expireDate: moment(res?.data?.expire_date).format('MMMM DD, YYYY'),
        postedBy: `${res?.data?.created.first_name} ${res?.data?.created.last_name}`,
        status: res?.data?.status,
        content: res?.data?.content
      });
    });
  };

  const getActiveAnnouncements = async id => {
    try {
      const res = await getActiveAnnouncementsService();
      const newList = res.data.items ?? [];
      if (newList.length !== 0) {
        setAnnouncementsData(
          newList
            .filter(el => el.id !== Number(id))
            ?.map(o => {
              return {
                id: o.id,
                expire_date: o.expire_date,
                post_date: o.post_date,
                title: o.title,
                content: o.content,
                sub_text: `Posted: ${moment(o.post_date).format(
                  'MMMM DD, YYYY'
                )} by ${o.created.first_name} ${o.created.last_name}`
              };
            })
        );
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  const handleViewModal = useCallback(
    modal => {
      setShowViewModal(modal);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },

    [showViewModal, setShowViewModal]
  );

  const handleArchiveSubmit = async () => {
    const today = new Date().toJSON().slice(0, 10);
    const payload = {
      id,
      status: 'F',
      expire_date: today
    };

    setFormLoading(true);

    try {
      const response = await updateAnnouncementService(payload);
      if (response?.success) {
        dispatch(setAnnouncementSingle(payload));
        setTimeout(() => {
          setFormLoading(false);
        }, 500);
        toast.success('Successfully Archived!', {
          icon: <CgCheckO />
        });
      } else {
        toast.error(response?.data?.message, {
          icon: <MdOutlineErrorOutline />
        });
      }
    } catch (error) {
      toast.error('Failed to Archive!', {
        icon: <MdOutlineErrorOutline />
      });
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      const response = await deleteAnnouncementService(id);
      if (response?.success) {
        fetchAnnouncement(id);
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />
        });
      } else {
        fetchAnnouncement(id);
        toast.error(response?.data?.message, {
          icon: <MdOutlineErrorOutline />
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        icon: <MdOutlineErrorOutline />
      });
    }
  };

  const announcementSingleMemo = useMemo(() => {
    return announcementSingleData ?? {};
  }, [announcementSingleData]);

  useEffect(() => {
    if (id) {
      setAnnouncementSingleData({});
      fetchAnnouncement(id);
      getActiveAnnouncements(id);
    }
  }, [id, announcementSingle]);

  return {
    formLoading,
    showViewModal,
    announcementSingleMemo,
    announcementSingleData,
    announcementsData,
    handleViewModal,
    handleArchiveSubmit,
    handleDeleteSubmit
  };
};

export default useAnnouncements;
