/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-big-calendar';

import next_icon from 'src/assets/icons/next_icon.svg';
import prev_icon from 'src/assets/icons/prev_icon.svg';
import useDashboardIndex from 'src/hooks/Pages/Dashboard/useDashboardIndex';

const customToolbar = () => {
  const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
  };

  const { convertDate } = useDashboardIndex();

  class CustomToolbar extends React.Component {
    navigate = action => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onNavigate(action);
    };

    render() {
      // eslint-disable-next-line react/prop-types
      const { label, date } = this.props;
      const currentDate = convertDate(date);
      const onPickerChange = newDate =>
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onNavigate(Navigate.DATE, newDate);

      const handleDateChange = event => {
        onPickerChange(event.target.value);
      };
      return (
        <>
          <div className="w-full">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 p-[6px] pl-0 pb-0 pt-0 lg:pt-[6px]">
                <div className="grid grid-cols-1 gap-2">
                  <span className="relative font-bold before:absolute lg:mt-[18px] before:top-[1px] before:left-[-17px] left-[17px] before:h-10 before:w-10 text-[14px] before:content-[url('/src/assets/icons/calendar_icon.svg')]">
                    Calendar
                  </span>
                </div>
              </div>

              <div className="w-full md:w-1/2 py-[6px] px-0 pb-[16px] lg:pb-[6px] right ">
                <input
                  className="input float-right input__date !sm:max-w-none "
                  type="date"
                  name="calendarpicker"
                  id="calendarpicker"
                  placeholder="ew"
                  value={currentDate}
                  onChange={handleDateChange}
                  max="9999-12-31"
                />
              </div>
            </div>
          </div>
          <div className="!mb-0 border border-b-0 rbc-toolbar !justify-start relative border-[#f0f0f0] rounded-t-lg px-[10px] lg:px-[20px] py-[10px] !flex-row">
            <span className="rbc-toolbar-label absolute right-0 md:inset-x-0 z-0">
              {label}
            </span>
            <div className="z-[1]">
              <span className="prev-button float-left">
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                >
                  <img
                    className="w-[6px] h-[10px] lg:w-[5px] lg:h-[8px] block mx-auto"
                    src={prev_icon}
                    alt="Prev"
                  />
                </button>
              </span>
              <span className="next-button float-left">
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.NEXT)}
                >
                  <img
                    className="w-[6px] h-[10px] lg:w-[5px] lg:h-[8px] block mx-auto"
                    src={next_icon}
                    alt="Next"
                  />
                </button>
              </span>

              <span className="ml-[10px] today-button float-left">
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.TODAY)}
                >
                  Today
                </button>
              </span>
            </div>
          </div>
        </>
      );
    }
  }

  return {
    convertDate,
    CustomToolbar
  };
};

export default customToolbar;
