import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginService } from 'src/api/modules/login';
import { setUser } from 'src/redux/modules/users/userActions';
import { MENU_CODES } from 'src/static/menuCodes';
import { getUserMenusServiceByMenuCode } from 'src/api/modules/userMenus';
import {
  getAllCostCenterService,
  getAllCostCenterItems
} from 'src/redux/modules/costcenter/costcenterActions';
import {
  getDepartments,
  getAllDepartment
} from 'src/redux/modules/departments/departmentsActions';
import {
  getDivisions,
  getAllDivision
} from 'src/redux/modules/divisions/divisionsActions';
import {
  getSections,
  getAllSection
} from 'src/redux/modules/sections/sectionsActions';
import {
  getAllSubSection,
  getSubSections
} from 'src/redux/modules/subsections/subsectionsActions';
import { getSalaryGrades } from 'src/redux/modules/salaryGrades/salaryGradesActions';
import { getEmploymentStatus } from 'src/redux/modules/employmentStatus/employmentStatusActions';
import { getPositions } from 'src/redux/modules/positions/positionsActions';
import { getAllEmployees } from 'src/redux/modules/employee/employeeActions';

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMountedRef = useRef(true);

  const RESPONSE_ERROR = {
    PASSWORD: 'Invalid password',
    EMAIL: 'User not registered',
    UNREGISTERED: 'User not registered',
    INTERNAL_SERVER: 'Internal Server Error',
    UNAUTHORIZED: 'Unauthorized',
    NOT_ACTIVATED: 'User was not activated or cancelled'
  };

  const ERROR_MESSAGE = {
    INCORRECT_EMAIL: 'The inputted email does not exist in the system',
    INCORRECT_PASSWORD: 'Incorrect Password',
    INVALID_EMAIL: 'Invalid e-mail',
    UNREGISTERED_EMAIL: 'The inputted email does not exist in the system'
  };

  const initialFormData = {
    email: '',
    password: ''
  };

  const initialValidation = {
    email: '',
    password: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [validation, setValidation] = useState(initialValidation);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const inputRef = useRef(null);

  const handleChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const togglePassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
    inputRef.current.focus();
  };

  const validateEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setValidation(() => ({
        ...initialValidation,
        email: 'Invalid email address'
      }));
      return false;
    }
    if (!email.endsWith('@cody.inc')) {
      setValidation(() => ({
        ...initialValidation,
        email: 'Invalid email: Email domain must end with @cody.inc'
      }));
      return false;
    }
    return true;
  };

  const handleLoginSubmit = async e => {
    e.preventDefault();
    const validated = validateEmail(formData.email);
    if (validated) {
      setIsLoading(true);
      const res = await loginService({
        email: formData.email,
        password: formData.password
      });
      const validationCheck = validation;
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (res.status === 200) {
        dispatch(setUser(res.data));

        if (res.data.user.is_system_admin) {
          dispatch(getDivisions());
          dispatch(getDepartments());
          dispatch(getSections());
          dispatch(getSubSections());
          dispatch(getSalaryGrades());
          dispatch(getEmploymentStatus());
          dispatch(getPositions());
          dispatch(getAllEmployees());
        }

        dispatch(getAllCostCenterItems());
        dispatch(getAllDivision());
        dispatch(getAllSection());
        dispatch(getAllDepartment());
        dispatch(getAllSubSection());
        dispatch(getAllCostCenterService());
        // dispatch(getAllEmployees());

        if (!isMountedRef.current) return;
        navigate(`/info`);
      } else {
        if (res.data.message === RESPONSE_ERROR.EMAIL) {
          setValidation(prevState => ({
            ...initialValidation,
            email: ERROR_MESSAGE.INCORRECT_EMAIL
          }));
        } else if (res.data.message === RESPONSE_ERROR.PASSWORD) {
          setValidation(prevState => ({
            ...initialValidation,
            password: ERROR_MESSAGE.INCORRECT_PASSWORD
          }));
        } else if (res.data.message === RESPONSE_ERROR.NOT_ACTIVATED) {
          setValidation(prevState => ({
            ...initialValidation,
            email: RESPONSE_ERROR.NOT_ACTIVATED
          }));
        } else if (res.data.message === RESPONSE_ERROR.UNAUTHORIZED) {
          toast.error(res.data.message);
        } else if (
          res.data.message === RESPONSE_ERROR.INTERNAL_SERVER ||
          res.data.message === RESPONSE_ERROR.UNREGISTERED
        ) {
          if (formData.email.match(emailFormat)) {
            setValidation(() => ({
              ...initialValidation,
              email: ERROR_MESSAGE.UNREGISTERED_EMAIL
            }));
          } else {
            validationCheck.email = ERROR_MESSAGE.INVALID_EMAIL;
          }
        }
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (formData.email !== '' && formData.password !== '' && !isLoading) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [formData, isLoading]);

  return {
    formData,
    validation,
    isButtonEnabled,
    isLoading,
    companyLogo,
    handleChange,
    handleLoginSubmit,
    togglePassword,
    showPassword,
    inputRef
  };
};
