import {
  SET_EDITOR,
  SET_ANNOUNCEMENT_LIST,
  SET_ANNOUNCEMENT_SINGLE
} from './announcementTypes';

export function setEditor(payload) {
  return { type: SET_EDITOR, payload };
}

export function setAnnouncementList(payload) {
  return { type: SET_ANNOUNCEMENT_LIST, payload };
}

export function setAnnouncementSingle(payload) {
  return { type: SET_ANNOUNCEMENT_SINGLE, payload };
}
