import { cn } from 'src/helpers/utils';
import PropTypes from 'prop-types';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import useAnnouncementSingle from 'src/hooks/Pages/Announcement/useAnnouncementSingle';
import DetailsButton from 'src/components/Parts/Announcements/DetailsButton';
import Announcements from 'src/components/Parts/Dashboard/Announcements';
import SlideModal from 'src/components/Common/SlideModal';
import AddEditModal from 'src/components/Parts/Announcements/AddEditModal';
import EditorIndex from 'src/components/Parts/Announcements/Editor/index';
import Loading from 'src/components/Common/loading';
import { useUserAccess } from 'src/hooks/useUserAccess';

const AnnouncementSingle = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);

  const {
    formLoading,
    showViewModal,
    announcementSingleMemo,
    announcementsData,
    handleViewModal,
    handleArchiveSubmit,
    handleDeleteSubmit
  } = useAnnouncementSingle();

  let status = {
    color: '',
    text: ''
  };

  switch (announcementSingleMemo?.status) {
    case 'A':
      status = {
        color: 'text-[#23B53A]',
        text: ' Active'
      };
      break;
    case 'N':
      status = {
        color: 'text-[#000]',
        text: ' New'
      };
      break;
    case 'F':
      status = {
        color: 'text-[#5886CE]',
        text: ' Fulfilled'
      };
      break;
    default:
      break;
  }

  return (
    <div className="announcements__container">
      {access.can_edit ? (
        <Breadcrumbs
          crumbs={[
            { name: 'System' },
            { name: 'Announcements', link: '/announcements' },
            { name: announcementSingleMemo?.title }
          ]}
        />
      ) : (
        <Breadcrumbs
          crumbs={[
            { name: 'System' },
            { name: 'Announcements' },
            { name: announcementSingleMemo?.title }
          ]}
        />
      )}
      {formLoading && <Loading />}
      <div className="flex flex-col pt-3 lg:flex-row">
        <div className="mt-[10px] mb-[10px] lg:hidden">
          <DetailsButton
            isExpired
            formLoading={formLoading}
            menuCode={menuCode}
            modalName="Edit"
            handleViewModal={handleViewModal}
            handleArchiveSubmit={handleArchiveSubmit}
            handleDeleteSubmit={handleDeleteSubmit}
          />
        </div>
        <div className="basis-5/6 order-2 lg:order-1 ">
          <h1 className="text-[24px] font-bold">
            {announcementSingleMemo?.title}
          </h1>
          <p className="text-[12px]">
            <img
              className="w-[10px] h-[10px] inline m-1"
              src="icons/calendar-black.svg"
              alt="Calendar"
            />
            {announcementSingleMemo?.expireDate}
            <span className="ml-[10px]">
              By {announcementSingleMemo?.postedBy || ''}
            </span>
          </p>
          <div className="mt-[15px] border-solid border-[1px] border-[#eaeaea] rounded bg-white p-[15px]">
            {announcementSingleMemo?.content ? (
              <EditorIndex
                contentData={announcementSingleMemo?.content}
                isEdit={false}
                id={Number(announcementSingleMemo?.id)}
                holder="js-editor-view"
              />
            ) : null}
          </div>
        </div>
        <div className="flex-1 lg:order-2 lg:pl-[20px] order-3">
          <div className="mt-[10px] lg:block hidden">
            <DetailsButton
              isExpired
              menuCode={menuCode}
              modalName="Edit"
              handleViewModal={handleViewModal}
              handleArchiveSubmit={handleArchiveSubmit}
              handleDeleteSubmit={handleDeleteSubmit}
            />
          </div>
          <div className="mt-[15px] border-solid border-[1px] border-[#eaeaea] rounded bg-white p-[15px]">
            <p className="text-[12px] font-bold">
              Status:
              <span className={cn('font-bold', status?.color)}>
                {status?.text}
              </span>
            </p>
            <p className="text-[12px] font-bold">
              Last updated: {announcementSingleMemo?.updatedDate}
            </p>
          </div>
          <div>
            <Announcements announcements={announcementsData} />
          </div>
        </div>
      </div>
      <SlideModal
        showModal={showViewModal}
        modalName="Edit"
        handleModal={handleViewModal}
      >
        {showViewModal && (
          <AddEditModal
            handleModal={handleViewModal}
            id={Number(announcementSingleMemo?.id)}
            dataItems={announcementSingleMemo}
            modalTitle="Edit Post"
            action="edit"
          />
        )}
      </SlideModal>
    </div>
  );
};

AnnouncementSingle.propTypes = {
  menuCode: PropTypes.string
};
export default AnnouncementSingle;
