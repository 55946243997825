import axios from 'axios';
import { ALL_HOLIDAY_EVENTS, EVENTS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getAllHolidayEventService = async () => {
  try {
    const endpoint = `${ALL_HOLIDAY_EVENTS}`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getEventsItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${EVENTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};
