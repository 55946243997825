/* eslint-disable no-nested-ternary */
// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
// Common Components
import Datatable from 'src/components/Common/Datatable';
import Button from 'src/components/Common/Button';
import Filter from 'src/components/Common/Filter/Filter';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import ModalCenter from 'src/components/Common/ModalCenter';
import ModalUI from 'src/components/Common/ModalUI';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useEvaluationSchedule from 'src/hooks/EvaluationSchedule/useEvaluationSchedule';

// Evaluation Components
import SchedulePart from 'src/Pages/EvaluationSchedule/components/SchedulePart';

// Utils
import { cn } from 'src/helpers/utils';

// eslint-disable-next-line react/prop-types
const HandleEvaluationSchedule = ({ menuCode, viewOnly }) => {
  const { id } = useParams();
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();
  const [buttonMode, setButtonMode] = useState(viewOnly ? 'edit' : 'update');

  useEffect(() => {
    if (viewOnly) {
      setButtonMode('edit');
    } else {
      setButtonMode('update');
    }
  }, [viewOnly]);

  const handleSubmit = async e => {
    handleScheduleSubmit();
    navigate(`/evaluation-schedule/view/${id}`);
  };

  const handleEditButtonClick = () => {
    if (buttonMode === 'edit') {
      navigate(`/evaluation-schedule/edit/${id}`);
    } else if (buttonMode === 'view') {
      navigate(`/evaluation-schedule/view/${id}`);
    } else if (buttonMode === 'update') {
      handleSubmit();
    }
  };

  let buttonText;
  switch (buttonMode) {
    case 'edit':
      buttonText = 'Edit';
      break;
    case 'view':
      buttonText = 'View';
      break;
    default:
      buttonText = 'Update';
  }

  const {
    ids,
    form,
    inputs,
    actions,
    columns,
    templateFields,
    totalResultItems,
    showStartEvaluationModal,
    evaluationStarted,
    isScheduleItemActive,
    isScheduleItemOnhold,
    isResultItemsGenerated,
    hasActiveCancelledItems,
    isScheduleNameExist,
    scheduleResultItems,
    generateExclude,
    isScheduleItemFulfilled,
    hasData,
    editCurrentId,
    initialDummyData,
    updateFormError,
    isFieldDisabledOnActive,
    evaluationProgress,
    queryString,
    showResetScoreModal,
    showOverwriteTemplateModal,
    scheduleSelectedIds,
    handleChange,
    submitFilter,
    handleUpdateRow,
    getEvaluationInfo,
    handleDateOnChange,
    handleConfirmModal,
    handleScheduleSubmit,
    handleCloseEvaluation,
    handleStartEvaluation,
    getScheduleItemDetail,
    handleExcludeEvaluation,
    handleCloseConfirmModal,
    handleResetScheduleItem,
    handleGenerateEvaluation,
    updateSortedData,
    handleResetScore,
    handleOverwriteTemplate,
    handleCancelOverwriteTemplate,
    setSortType
  } = useEvaluationSchedule(id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Performance Evaluation' },
          { name: 'Evaluation Schedules', link: '/evaluation-schedule' },
          {
            // eslint-disable-next-line no-nested-ternary
            name: `${!id ? 'Add Schedule' : templateFields.name ?? ''}`
          }
        ]}
      />

      <div className="user__heading evaluation-schedule">
        <div className="user__heading--title evaluation-schedule relative">
          {id ? (
            <div
              className={cn(
                'absolute top-1/2 right-0 transform -translate-y-1/2',
                isScheduleItemActive && '-right-[10px]'
              )}
            >
              {!isScheduleItemFulfilled && (
                <button
                  type="button"
                  className={cn(
                    'text-[12px] text-white font-normal leading-[100%] border-none p-[14px_19px] rounded mr-[10px] transition duration-150 bg-[#23B53A] cursor-pointer',
                    evaluationStarted && 'bg-[#DD5859]',
                    (!access.can_edit ||
                      (!isResultItemsGenerated && !evaluationStarted)) &&
                      'opacity-50 duration-150 cursor-not-allowed',
                    !access.can_delete &&
                      isScheduleItemActive &&
                      'hover:bg-opacity-50'
                  )}
                  onClick={() => {
                    if (evaluationStarted) {
                      handleCloseEvaluation();
                    } else {
                      handleConfirmModal('StartEvaluation');
                    }
                  }}
                  disabled={
                    !access.can_delete ||
                    (!isResultItemsGenerated && !evaluationStarted)
                  }
                >
                  {evaluationStarted ? 'Close Evaluation' : 'Start Evaluation'}
                </button>
              )}

              {!isScheduleItemFulfilled && (
                <button
                  type="button"
                  className={cn(
                    'text-[12px] text-white font-normal leading-[100%] bg-[#232932] border-none p-[14px_19px] rounded hover:bg-gray-500 transition duration-150',
                    (!access.can_edit && !isResultItemsGenerated) ||
                      (isScheduleItemOnhold &&
                        'bg-gray-500 opacity-50 duration-150 cursor-not-allowed'),
                    !access.can_delete &&
                      'bg-gray-700 hover:bg-gray-700 cursor-not-allowed'
                  )}
                  onClick={handleEditButtonClick}
                  disabled={
                    (!access.can_delete && !isResultItemsGenerated) ||
                    isScheduleItemOnhold
                  }
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    {buttonText}
                  </span>
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5">
        <SchedulePart
          scheduleId={id}
          viewOnly={isFieldDisabledOnActive || viewOnly}
          menuCode={menuCode}
          templateFields={templateFields}
          isScheduleNameExist={isScheduleNameExist}
          handleChange={handleChange}
          getEvaluationInfo={getEvaluationInfo}
          handleScheduleSubmit={handleScheduleSubmit}
          initialDummyData={initialDummyData}
          fieldErrors={updateFormError}
          evaluationProgress={evaluationProgress}
          evaluationStarted={evaluationStarted}
        />

        {id ? (
          <div className="filter__content--search overflow-visible">
            <Filter
              key={queryString}
              searchInputPlaceholder="Username, Cost Center, Evaluators, Template"
              showActionBtn={false}
              showDeleteBtn={false}
              inputs={inputs}
              buttonLink={false}
              form={form}
              submitFilter={submitFilter}
              menuCode={menuCode}
              disabled
              dateOnChange={handleDateOnChange}
              localQuery="hris-evaluation-schedule-item-search-params"
            >
              {id && !isScheduleItemFulfilled && (
                <div className="relative flex justify-end mt-[30px]__mb-[30px]">
                  <button
                    type="button"
                    className={cn(
                      'cursor-pointer text-[12px] text-[#232932] font-normal leading-[100%] border-none p-[14px_19px] rounded mr-[10px] bg-[#EEEEEE] hover:opacity-50 transition duration-150',
                      (!access.can_edit || ids.length === 0) &&
                        'opacity-50 duration-150 cursor-not-allowed',
                      !access.can_delete && 'hover:opacity-500',
                      Boolean(ids) &&
                        generateExclude.all &&
                        generateExclude.cancelled &&
                        'opacity-50 duration-150 cursor-not-allowed'
                    )}
                    onClick={() => {
                      handleExcludeEvaluation(id);
                    }}
                    disabled={
                      ids.length === 0 ||
                      !access.can_delete ||
                      (generateExclude.all && generateExclude.cancelled)
                    }
                  >
                    Exclude
                  </button>
                  <button
                    type="button"
                    className={cn(
                      'cursor-pointer text-[12px] text-white font-normal leading-[100%] border-none p-[14px_19px] rounded bg-[#222222] hover:opacity-50 transition duration-150',
                      (!access.can_edit || ids.length === 0) &&
                        'opacity-50 duration-150 cursor-not-allowed',
                      !access.can_delete && 'hover:opacity-500',
                      Boolean(ids) &&
                        generateExclude.all &&
                        generateExclude.active &&
                        'opacity-50 duration-150 cursor-not-allowed'
                    )}
                    onClick={() => handleGenerateEvaluation(id)}
                    disabled={
                      ids.length === 0 ||
                      !access.can_delete ||
                      (generateExclude.all && generateExclude.active)
                    }
                  >
                    Generate
                  </button>
                </div>
              )}
            </Filter>
            {!hasData && <p className="text-center mt-4">Loading...</p>}
            {hasData && Boolean(columns.length) && (
              <Datatable
                keyField="id"
                shouldEllipsis
                isSchedule
                editCurrentId={editCurrentId}
                access={access}
                actions={actions}
                onExport={false}
                isExport={false}
                clickableRows={false}
                title="Evaluation Schedule"
                headingColumns={columns}
                handleUpdateRow={handleUpdateRow}
                datasource={scheduleResultItems || []}
                handleResetScheduleItem={handleResetScheduleItem}
                scheduleItem={getScheduleItemDetail}
                updateSortedData={updateSortedData}
                setSortType={setSortType}
                scheduleIds={scheduleSelectedIds}
                viewOnly={viewOnly}
              />
            )}
            {hasData && !scheduleResultItems.length && (
              <p className="text-center mt-4 font-bold">No records found</p>
            )}
          </div>
        ) : null}
      </div>

      {showStartEvaluationModal && (
        <ModalCenter
          showModal={showStartEvaluationModal}
          modalName="StartEvaluation"
        >
          {showStartEvaluationModal && (
            <ModalUI
              header="Start Evaluation"
              submit={handleStartEvaluation}
              cancel={() => handleCloseConfirmModal('StartEvaluation')}
            >
              <div className="mt-7">
                Are you sure you want to start
                <br /> the evaluation?
              </div>
            </ModalUI>
          )}
        </ModalCenter>
      )}

      {showResetScoreModal && (
        <ModalCenter showModal={showResetScoreModal} modalName="ResetScore">
          {showResetScoreModal && (
            <ModalUI
              header="Update Evaluator"
              submit={handleResetScore}
              cancel={() => handleCloseConfirmModal('ResetScore')}
              yes="Proceed"
              no="Cancel"
            >
              <div className="mt-7">
                This action will reset the current input/score
              </div>
            </ModalUI>
          )}
        </ModalCenter>
      )}

      {showOverwriteTemplateModal && (
        <ModalCenter
          showModal={showOverwriteTemplateModal}
          modalName="OverwriteTemplate"
        >
          {showOverwriteTemplateModal && (
            <ModalUI
              header="Overwrite Template"
              submit={handleOverwriteTemplate}
              cancel={handleCancelOverwriteTemplate}
              yes="Proceed"
              no="Cancel"
            >
              <div className="mt-7">
                This action will overwrite the current template
              </div>
            </ModalUI>
          )}
        </ModalCenter>
      )}
    </div>
  );
};

export default HandleEvaluationSchedule;
