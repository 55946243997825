import PropTypes from 'prop-types';
import { cn } from 'src/helpers/utils';

const ModalUI = ({
  header,
  cancel,
  children,
  type = '',
  actions = true,
  submit = () => {},
  yes = 'Yes',
  no = 'No'
}) => {
  return (
    <>
      <div className="font-stolzlBold text-[14px] p-[14px] border-b-[1px] border-[#DEDEDE] relative">
        {header}
        <button
          className="w-[12px] h-[12px] absolute right-[15px] top-1/2 transform -translate-y-1/2 after:content-[''] after:w-full after:h-[2px] after:left-0 before:left-0 after:bg-[#999999] after:absolute after:rotate-45 after:rounded before:rounded before:content-[''] before:w-full before:h-[2px] before:bg-[#999999] before:absolute before:-rotate-45"
          type="button"
          onClick={cancel}
        />
      </div>
      <div className="text-center text-[14px]">{children}</div>
      {actions && (
        <div className="flex justify-between w-[210px] mt-[30px] m-auto">
          <div className="">
            <button
              form="userGrpForm"
              type="submit"
              className={cn(
                'text-[12px] text-white leading-[100%] bg-gray-400 hover:bg-gray-500 border-none rounded w-[100px] h-10',
                type === 'delete' && 'bg-delete hover:bg-[#F29396]'
              )}
              onClick={submit}
            >
              <span>{yes}</span>
            </button>
          </div>
          <div className="">
            <button
              type="button"
              className="text-[12px] text-[#232932] font-normal leading-[100%] bg-gray-700 hover:bg-gray-600 border-none rounded w-[100px] h-10"
              onClick={cancel}
            >
              <span>{no}</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

ModalUI.propTypes = {
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.node
  ]),
  submit: PropTypes.func,
  cancel: PropTypes.func,
  actions: PropTypes.bool,
  type: PropTypes.string,
  yes: PropTypes.string,
  no: PropTypes.string
};

export default ModalUI;
