/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getEmployeesService,
  getEmployeeInfoService,
  updateEmployeeInfoService,
  addEmployeeService,
  updateEmployeeImage
} from 'src/api/modules/employee';
import {
  getSalaryGrade,
  getGender,
  getCivilStatus,
  getEmploymentStatus,
  setEmployees,
  setEmployeeListUpdate
} from 'src/redux/modules/employee/employeeActions';
import { getCostCenter } from 'src/helpers/utils';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import { IoWarningOutline } from 'react-icons/io5';

import moment from 'moment';
import * as Yup from 'yup';
import validationYupSchema from 'src/helpers/v1/validationYupSchema';

export const useAddEditModal = ({ handleModal, id, userData }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    employee_input: {
      id_no: '',
      email: '',
      status: 'N',
      image: '',
      cost_center_code: null,
      salary_grade: null,
      team_id: null,
      position_id: null,
      employment_status: '',
      immediate_superior: '',
      date_hired: null,
      date_regularized: null,
      date_resigned: null,
      work_years: 1,
      work_months: 1,
      is_exempted: false
    },
    employee_info_input: {
      first_name: '',
      middle_name: '',
      last_name: '',
      suffix: '',
      personal_email: '',
      birth_date: null,
      phone_no: '',
      landline_no: '',
      gender: null,
      blood_type: null,
      civil_status: null,
      emergency_contact: '',
      emergency_contact_relation: null,
      emergency_contact_no_1: '',
      emergency_contact_no_2: ''
    }
  });

  const initialDummyData = {
    employee_input: {
      id_no: 0,
      email: 'john_wick@cody.inc',
      status: 'A',
      image: 'https://picsum.photos/seed/picsum/200/300',
      cost_center_code: '01010307',
      salary_grade: 1,
      team_id: 1,
      position_id: 1,
      employment_status: 'Regular',
      immediate_superior: 'Jule Eric Bernasor',
      date_hired: '2021-12-08T18:49:10.123Z',
      date_regularized: '2021-12-08T18:49:10.123Z',
      date_resigned: '2021-12-08T18:49:10.123Z',
      work_years: 1,
      work_months: 1,
      is_exempted: false
    },
    employee_info_input: {
      first_name: 'John',
      middle_name: 'Weak',
      last_name: 'Wick',
      suffix: 'III',
      personal_email: 'john_wick@cody.inc',
      birth_date: '2021-12-08T18:49:10.123Z',
      home_address: 'Cebu City, Philippines, 6000',
      current_address: 'Cebu City, Philippines, 6000',
      phone_no: '09123456789',
      landline_no: '09123456789',
      gender: 'Male',
      blood_type: 'A',
      civil_status: 'Single',
      state_record_id: 1,
      emergency_contact: 'Helen Wick',
      emergency_contact_relation: 'Wife',
      emergency_contact_no_1: '09123456789',
      emergency_contact_no_2: '09123456789',
      emergency_contact_address: 'Cebu City, Cebu, Philippines, 6000'
    }
  };
  const {
    salaryGrade,
    gender,
    civilStatus,
    employmentStatus,
    positions,
    teams
  } = useSelector(state => state?.employees);
  const costcenter = useSelector(state => state.costCenter.all);
  const [userImage, setUserImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [dateYesterday, setDateYesterday] = useState();

  const fetchBirthDate = useCallback(async () => {
    const today = new Date();

    if (typeof today === 'object' && today !== null && 'getDate' in today) {
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const yesterday = String(today.getDate() - 1).padStart(2, '0');
      const dateDiff = `${year}-${month}-${yesterday}`;

      setDateYesterday(dateDiff);
      return dateYesterday;
    }

    return null;
  });

  const image = useMemo(() => {
    if (id && userData?.image) {
      return `${userData.image}`;
    }
    return null;
  }, [id, formData.employee_input.image]);
  const costCenterOptions = costcenter?.map(costCenterData => {
    const costCenterLabel = getCostCenter(
      'name',
      costCenterData.cost_center_code
    );

    return {
      value: costCenterData.cost_center_code,
      label: `${costCenterData.cost_center_code} / ${costCenterLabel}`
    };
  });

  const salaryGradeOptions = Object.values(salaryGrade).map(({ id, type }) => ({
    value: id,
    label: type
  }));

  const positionOptions = Object.values(positions).map(({ id, name }) => ({
    value: id,
    label: name
  }));

  const teamOptions = Object.values(teams).map(({ id, name }) => ({
    value: id,
    label: name
  }));

  const fetchEmployeeInfo = async employeeId => {
    getEmployeeInfoService(employeeId).then(res => {
      if (res) {
        const { employee_info, ...rest } = res.data;
        const responseKeys1 = Object.keys(rest);
        const employee_info_key = Object.keys(employee_info);

        responseKeys1.forEach(key => {
          if (typeof formData.employee_input[key] === 'undefined') {
            delete rest[key];
          }
        });

        Object.keys(employee_info).forEach(key => {
          if (key === 'birth_date') {
            if (employee_info[key]) {
              employee_info[key] = moment(employee_info[key]).format(
                'YYYY-MM-DD'
              );
            }
          }
        });

        Object.keys(rest).forEach(key => {
          if (
            key === 'date_hired' ||
            key === 'date_regularized' ||
            key === 'date_resigned'
          ) {
            if (rest[key]) {
              rest[key] = moment(rest[key]).format('YYYY-MM-DD');
            }
          }
          if (key === 'salary_grade') {
            rest[key] = rest[key]?.id;
          }
        });

        const final = {
          employee_input: rest,
          employee_info_input: employee_info
        };

        setFormData(final);
      }
    });
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const splitName = name.split('.')[1];
    const trimValue = [
      'id_no',
      'phone_no',
      'landline_no',
      'personal_email',
      'emergency_contact_no_1',
      'emergency_contact_no_2',
      'email',
      'employee_id_no'
    ].includes(splitName)
      ? value.replace(/\s/g, '')
      : value;

    const fieldValue = type === 'checkbox' ? checked : trimValue;

    const updateFormData = (inputType, field, fieldValue) => {
      setFormData(prevState => ({
        ...prevState,
        [inputType]: {
          ...prevState[inputType],
          [field]: fieldValue
        }
      }));
    };

    const isEmployeeInput = e.target.classList.contains('employee_input');

    const inputType = isEmployeeInput
      ? 'employee_input'
      : 'employee_info_input';

    updateFormData(inputType, splitName, fieldValue);

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const handSelectChange = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    const { name, value } = e.target;
    const splitName = name.split('.')[1];
    if (e.target.classList.contains('employee_input')) {
      setFormData(prevState => ({
        ...prevState,
        employee_input: {
          ...prevState.employee_input,
          [splitName]: value
        }
      }));
    } else if (e.target.classList.contains('employee_info_input')) {
      setFormData(prevState => ({
        ...prevState,
        employee_info_input: {
          ...prevState.employee_info_input,
          [splitName]: value
        }
      }));
    }

    setFieldValue(name, value);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const handleStatusChange = useCallback(
    (value, name, setFieldValue, setFieldTouched, setFieldError) => {
      const splitName = name.split('.');

      if (splitName[0] === 'employee_input') {
        setFormData(prevState => ({
          ...prevState,
          employee_input: {
            ...prevState.employee_input,
            [splitName[1]]: value
          }
        }));
      }
      if (splitName[0] === 'employee_info_input') {
        setFormData(prevState => ({
          ...prevState,
          employee_info_input: {
            ...prevState.employee_info_input,
            [splitName[1]]: value
          }
        }));
      }
      setFieldValue(name, value);
      setFieldTouched(name, true);
      setFieldError(name, '');
    },
    [setFormData]
  );

  const customToastStyle = {
    fontSize: '14px',
    letterSpacing: '0em'
  };

  const validateFile = async file => {
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 4 * 1024 * 1024; // 4 MB
    if (!allowedTypes.includes(file.type)) {
      toast.warning(
        <div>
          File type not supported
          <span className="block text-sm">
            (Only JPEG/JPG and PNG files are allowed)
          </span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else if (file.size > maxSize) {
      // File size is too large
      toast.warning(
        <div>
          File is too large.
          <span className="block text-sm">(Maximum file size is 4 MB)</span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else {
      previewFile(file);
      setUserImage(file);
    }
  };

  const previewFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
  };

  const handleImageChange = useCallback(
    (event, { setFieldValue, setFieldTouched, setFieldError }) => {
      const file = event.target.files[0];

      setFieldValue('employee_input.image', file);
      setFieldTouched('employee_input.image', true);
      setFieldError('employee_input.image', '');
      validateFile(file);
      if (file) setImageUpdate(true);
    },
    []
  );

  const fetchEmployeeList = async () => {
    getEmployeesService(1)
      .then(res => {
        dispatch(setEmployees(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const updateEmployeeInfo = async (employeeID, payload) => {
    setFormLoading(true);
    updateEmployeeInfoService(employeeID, payload)
      .then(response => {
        if (response.success) {
          fetchEmployeeList();
          setTimeout(() => {
            setIsEdit(false);
            setFormLoading(false);
          }, 500);
          dispatch(
            setEmployeeListUpdate({ id: employeeID, payload: response.data })
          );
        }
        setFormData(formData);
      })
      .catch(err => {
        toast.error(err, { icon: <CgCloseO /> });
        setIsEdit(false);
        setFormLoading(false);
      });
  };

  const handleSubmit = useCallback(async employeeID => {
    // EDIT
    if (id) {
      if (imageUpdate) {
        const imageData = new FormData();
        imageData.append('employee_id', id);
        imageData.append('image', userImage);

        updateEmployeeImage(imageData).then(response => {
          if (response.success) {
            const payload = {
              employee_info_input: formData.employee_info_input,
              employee_input: {
                ...formData.employee_input,
                image: response.data.image
              }
            };
            updateEmployeeInfo(id, payload);
          }
        });
      } else {
        updateEmployeeInfo(id, formData);
      }
    } else {
      // ADD
      const res = await addEmployeeService(formData);
      if (res.data.statusCode === 201) {
        if (imageUpdate) {
          const imageData = new FormData();
          imageData.append('employee_id', res.data.data.employee.id);
          imageData.append('image', userImage);

          updateEmployeeImage(imageData).then(response => {
            if (response.success) {
              setFormData(prevState => {
                return {
                  ...prevState,
                  employee_input: {
                    ...prevState.employee_input,
                    image: response.data.image
                  }
                };
              });
              fetchEmployeeList();
              toast.success('Successfully Added!', {
                icon: <CgCheckO />
              });
              handleModal(null);
              setFormData(formData);
            }
          });
        } else {
          fetchEmployeeList();
          toast.success('Successfully Added!', {
            icon: <CgCheckO />
          });
          handleModal(null);
          setFormData(formData);
        }
      } else if (res.data?.statusCode === 409) {
        fetchEmployeeList();
      } else {
        const errorMessage = Object.values(res.data.data.error).join('<br />');
        toast.error(
          <div className="">
            Add Employee Information Failed: <br />
            <div
              className="text-[14px] italic text-error"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          </div>
        );
      }
    }
  });

  const employeeInfoSchema = Yup.object().shape({
    first_name: validationYupSchema.textRequiredWithMax(50),
    middle_name: validationYupSchema.textNullableWithMax(50),
    suffix: validationYupSchema.textNullableWithMax(5),
    emergency_contact: validationYupSchema.textNullableWithMax(100),
    last_name: validationYupSchema.textRequiredWithMax(50),
    personal_email: validationYupSchema.personalEmail(255),
    phone_no: validationYupSchema.number(),
    landline_no: validationYupSchema.number(),
    emergency_contact_no_1: validationYupSchema.number(),
    emergency_contact_no_2: validationYupSchema.number()
  });

  const employeeInputSchema = Yup.object().shape({
    email: validationYupSchema
      .email(255)
      .test(
        'is-cody-inc-email',
        'Only @cody.inc email address is allowed',
        function check(value) {
          if (value) {
            return value.endsWith('@cody.inc');
          }
          return true;
        }
      ),
    salary_grade: validationYupSchema.textRequired(),
    cost_center_code: validationYupSchema.textRequired(),
    employment_status: validationYupSchema.textRequired(),
    status: validationYupSchema.textRequired(),
    immediate_superior: validationYupSchema.textNullableWithMax(50),
    image: validationYupSchema.image(),
    id_no: Yup.number()
      .required('Required')
      .typeError('Must be a number')
      .test('maxDigits', 'Maximum of 10 digits allowed', value => {
        if (value === null || value === undefined || value === '') {
          return true;
        }
        const stringValue = String(value);
        return stringValue.length <= 10;
      })
      .typeError('Must be a number')
      .test('unique-id-no', 'ID Number is already taken', async value => {
        if (!value) return true;
        const searchParams = { id_no: value };
        const response = await getEmployeesService(1, searchParams);
        if (response.data?.items.length > 1) {
          return false;
        }
        return true;
      })
  });

  const validationSchema = Yup.object().shape({
    employee_input: employeeInputSchema,
    employee_info_input: employeeInfoSchema
  });

  let title;
  if (id && isEdit) {
    title = 'Edit';
  } else if (id && !isEdit) {
    title = '';
  } else {
    title = 'Add';
  }
  const modalTitle = `${title} Employee Information`;

  useEffect(() => {
    if (userData) {
      const { employee_info } = userData;
      Object.keys(employee_info).forEach(key => {
        if (key === 'birth_date') {
          if (employee_info[key]) {
            employee_info[key] = moment(employee_info[key]).format(
              'YYYY-MM-DD'
            );
          }
        }
      });
      Object.keys(userData).forEach(key => {
        if (
          key === 'date_hired' ||
          key === 'date_regularized' ||
          key === 'date_resigned'
        ) {
          if (userData[key]) {
            userData[key] = moment(userData[key]).format('YYYY-MM-DD');
          }
        }
        if (key === 'salary_grade') {
          userData[key] = userData[key]?.id;
        }
      });
      setFormData({
        employee_info_input: {
          birth_date: userData?.employee_info.birth_date,
          blood_type: userData?.employee_info.blood_type,
          civil_status: userData?.employee_info.civil_status,
          emergency_contact: userData?.employee_info.emergency_contact,
          emergency_contact_no_1:
            userData?.employee_info.emergency_contact_no_1,
          emergency_contact_no_2:
            userData?.employee_info.emergency_contact_no_2,
          emergency_contact_relation:
            userData?.employee_info.emergency_contact_relation,
          first_name: userData?.employee_info.first_name,
          gender: userData?.employee_info.gender,
          landline_no: userData?.employee_info.landline_no,
          last_name: userData?.employee_info.last_name,
          middle_name: userData?.employee_info.middle_name,
          personal_email: userData?.employee_info.personal_email,
          phone_no: userData?.employee_info.phone_no,
          suffix: userData?.employee_info.suffix
        },
        employee_input: {
          cost_center_code: userData?.cost_center_code,
          date_hired: userData?.date_hired,
          date_regularized: userData?.date_regularized,
          date_resigned: userData?.date_resigned,
          email: userData?.email,
          employment_status: userData?.employment_status,
          id_no: userData?.id_no,
          image: userData?.image,
          team_id: userData?.team?.id,
          position_id: userData?.position?.id,
          immediate_superior: userData?.immediate_superior,
          is_exempted: userData?.is_exempted,
          salary_grade: userData?.salary_grade,
          status: userData?.status,
          work_months: userData?.work_months,
          work_years: userData?.work_years
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getSalaryGrade());
    dispatch(getGender());
    dispatch(getCivilStatus());
    dispatch(getEmploymentStatus());
    fetchBirthDate();
  }, []);

  return {
    formData,
    initialDummyData,
    image,
    previewImage,
    modalTitle,
    handleChange,
    handleImageChange,
    handleStatusChange,
    setIsEdit,
    isEdit,
    handleSubmit,
    salaryGradeOptions,
    gender,
    civilStatus,
    employmentStatus,
    positionOptions,
    teamOptions,
    validationSchema,
    employeeInputSchema,
    employeeInfoSchema,
    costCenterOptions,
    formLoading,
    dateYesterday
  };
};
