import { useWatch } from 'react-hook-form';
import { useCallback, useState, useEffect } from 'react';

const useDate = ({ form, name, item, componentName, submitFilter }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [dateValue, setDateValue] = useState();

  useWatch({ control: form.control, name, defaultValue: false });
  const onSelectDropdown = e => {
    const selectName = e.target.name;
    const { value } = e.target;
    form.setValue(selectName, value);

    setDateValue(value);
  };
  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-project-params');
    const savedPageIndex = localStorage.getItem('hris-users-page-no');

    submitFilter();

    if (componentName !== 'DateYear') {
      submitFilter();
    }
  }, [dateValue]);

  return {
    isChecked,
    dateValue,
    onSelectDropdown
  };
};

export default useDate;
