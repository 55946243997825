import React from 'react';
import { useParams } from 'react-router-dom';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useAssessmentUserListAction from 'src/hooks/Pages/LearningManagement/Assessment/useAssessmentUserListAction';

// Assessment User List Components
import AssessmentUserList from 'src/Pages/LearningManagement/Assessment/components/AssessmentUserList';

// eslint-disable-next-line react/prop-types
const HandleAssessmentUserList = ({ menuCode, viewOnly }) => {
  const { id } = useParams();
  const { getAssessmentInfo, title } = useAssessmentUserListAction(
    id,
    viewOnly
  );

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Assessments', link: '/assessments' },
          { name: title }
        ]}
      />

      <div className="pt-2">
        <AssessmentUserList
          assessmentId={id}
          getAssessmentInfo={getAssessmentInfo}
          viewOnly={viewOnly}
          menuCode={menuCode}
        />
      </div>
    </div>
  );
};

export default HandleAssessmentUserList;
