/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
import InputMask from 'react-input-mask';
import moment from 'moment';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { employeeName, cn } from 'src/helpers/utils';
import FormLabel from './FormLabel';
import Select from './Select';
import ComboBox from './ComboBox';
import '../../assets/css/timePicker.scss';

const TextField = ({
  className = '',
  error,
  errorMessage = 'Field Required', // Default value if not set
  value,
  onChange,
  onBlur,
  name,
  type,
  readOnly = false,
  label,
  required = false,
  disabled = false,
  placeholder = '',
  max,
  min,
  labelModifier = '',
  onFocus,
  autoFocus
}) => {
  const isError = (error && required) || error;
  return (
    <>
      <FormLabel
        className={labelModifier}
        text={label}
        htmlFor={name}
        required={required}
      />
      <Field
        className={cn(
          'block w-full border-solid border-[1px] border-[#eaeaea] text-[14px] placeholder-[rgba(35,41,50,0.3)] focus:outline-none focus:border-[#000] rounded text-[#232932] font-stolzlBook leading-[17px] h-10 pl-2 pr-3',
          value ? 'text-[14px]' : 'text-[12px]',
          disabled ? '' : ' bg-white', 
          isError &&
            'border-[#E43B26] placeholder-[#f19d94] text-[#f19d94] focus:outline-none focus:border-[#E43B26]',
          className
        )}
        type={type}
        name={name}
        id={name}
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={error && required ? errorMessage : placeholder}
        max={max}
        min={min}
        disabled={disabled}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
    </>
  );
};

const ImageField = ({
  preview,
  image,
  defaultImage,
  type,
  name,
  onChange,
  id,
  isEdit,
  firstName,
  middleName,
  lastName,
  suffix,
  idNo,
  email
}) => {
  return (
    <>
      <div className="w-[120px] h-[120px] border-[1px] border-solid border-[#eaeaea] rounded">
        {preview ? (
          <img
            src={preview}
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        ) : (
          <img
            src={image || defaultImage}
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        )}
        <label
          htmlFor="image"
          className="align-top mt-2.5 text-[12px] font-stolzlBook leading-[14px] text-[#458FFF] underline hover:text-[#458FFF] focus:text-[#458FFF] cursor-pointer flex justify-center hover:text-opacity-50"
        >
          <input
            type={type}
            id="image"
            name={name}
            onChange={onChange}
            hidden
          />

          {!isEdit && !id ? (
            <span>Upload Image</span>
          ) : (
            isEdit && <span>Change Image</span>
          )}
        </label>
      </div>
      <div className="flex-1">
        <h5
          className={`text-[22px] font-stolzlMedium leading-[27px] mt-5 capitalize max-w-md break-words ${
            firstName || lastName ? 'text-input' : 'text-[#eaeaea]'
          }`}
        >
          {`${firstName || lastName}`
            ? `${employeeName({
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                suffix
              })}`
            : `Auto Input Name`}
        </h5>
        <p
          className={`text-[14px] font-normal leading-[14px] mt-[11px] ${
            idNo ? 'text-input' : 'text-[#eaeaea]'
          }`}
        >
          {idNo ? `ID: ${idNo}` : 'ID: '}
        </p>
        <p
          className={`text-[14px] font-normal leading-[14px] mt-[13px] ${
            email ? 'text-input' : 'text-[#eaeaea]'
          }`}
        >
          {email ? `${email}` : `Auto Input Email Address`}
        </p>
      </div>
    </>
  );
};

const SearchField = ({
  className = '',
  value,
  error,
  onChange,
  name,
  placeholder,
  readOnly = false,
  label,
  required = false,
  innerRef,
  autoComplete = 'off',
  errorMessage = ''
}) => {
  return (
    <>
      <FormLabel text={label} htmlFor={name} required={required} />
      <input
        className={`block w-full bg-white border-solid border-[1px] mt-2.5 ${
          (error && required) || error
            ? 'border-[#E43B26] placeholder-[#f19d94] text-[12px] focus:outline-none focus:border-[#E43B26]'
            : 'border-[#eaeaea] text-[14px] placeholder-[rgba(35,41,50,0.3)]'
        } 
        ${
          readOnly ? 'focus:outline-none bg-[#f7f7f7]' : 'focus:border-[#000]'
        } rounded  font-stolzlBook text-[#232932] focus:outline-none  leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[12px_13px] bg-[url('/src/assets/icons/black-search-icon.svg')] ${className}`}
        onChange={onChange}
        ref={innerRef}
        type="text"
        name={name}
        id={name}
        placeholder={error && required ? errorMessage : placeholder}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
    </>
  );
};

const SelectField = ({
  label,
  name,
  required = false,
  options = [],
  onChangeValue,
  selectedValue,
  disabled = false,
  placeholder = '',
  selectName,
  errors = null,
  currentValue,
  errorMessage = 'Field Required' // Default value
}) => {
  return (
    <>
      <FormLabel text={label} htmlFor={name} required={required} />
      <Select
        options={options}
        onChangeValue={onChangeValue}
        selectedValue={selectedValue}
        disabled={disabled}
        selectName={selectName}
        errorMessage={errorMessage}
        placeholder={placeholder}
        required={required}
        errors={errors}
        currentValue={currentValue}
      />
    </>
  );
};

const TimeField = ({
  onChange,
  value,
  disabled = false,
  showSecond = false,
  label = '',
  name,
  required = false
}) => {
  return (
    <>
      {label !== '' && (
        <FormLabel text={label} htmlFor={name} required={required} />
      )}
      <div
        className={`flex items-center timepicker bg-white ${
          label !== ''
            ? 'w-full bg-white rounded text-[#232932] font-stolzlBook text-[14px]'
            : ''
        }`}
      >
        <input
          type="text"
          placeholder={showSecond ? '__:__:__' : '__:__'}
          value={value.substring(0, 5)}
          onChange={onChange}
          disabled={disabled}
          maxLength={5}
        />
      </div>
    </>
  );
};

const TextArea = ({
  className = '',
  error,
  errorMessage = 'Field Required', // Default value if not set
  value,
  onChange,
  onBlur,
  name,
  label,
  required = false,
  disabled = false,
  placeholder = '',
  max,
  labelModifier = '',
  innerRef
}) => {
  return (
    <>
      <FormLabel
        className={labelModifier}
        text={label}
        htmlFor={name}
        required={required}
      />
      <textarea
        className={`${className} block w-full resize-none border-solid border-[1px] rounded-default text-[#232932] leading-[17px] p-1 ${
          error && required
            ? `border-[#E43B26] placeholder-[#f19d94] ${
                value ? 'text-[14px]' : 'text-[12px] '
              } focus:outline-none focus:border-[#E43B26]`
            : 'border-[#eaeaea] text-[14px] placeholder-[rgba(35,41,50,0.3)] focus:outline-none focus:border-[#000]'
        } $ ${disabled ? 'bg-[#fafafa]' : 'bg-white'}`}
        name={name}
        placeholder={error && required ? errorMessage : placeholder}
        rows="4"
        maxLength={max}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        ref={innerRef}
      />
    </>
  );
};

const Checkbox = ({
  label,
  name,
  required,
  type,
  checked,
  disabled,
  onChange,
  error,
  errorMessage
}) => {
  return (
    <label
      htmlFor={name}
      className="custom__checkbox inline-block align-middle text-[0] cursor-pointer"
      data-ripple-dark="true"
    >
      <Field
        id={name}
        type="checkbox"
        checked={checked}
        name={name}
        disabled={disabled}
        onChange={onChange}
        className="absolute peer cursor-pointer appearance-none h-5 w-5 bg-white border-solid border-[1px] border-[#eaeaea] transition-all focus:outline-none focus:border-[#000] rounded ease duration-200"
      />
      <span className="inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] focus:outline-none focus:border-[#000] rounded ease duration-200 relative opacity-0 transition-opacity peer-checked:opacity-100">
        <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
        <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
      </span>
      <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
        {label}
      </i>
    </label>
  );
};

const FormField = ({
  className = '',
  error,
  errorMessage = 'Field Required', // default value
  value,
  onChange,
  onBlur,
  name,
  type,
  readOnly = false,
  label,
  required = false,
  placeholder = '',
  listType = '',
  max,
  min,

  options = [],
  onChangeValue,
  selectedValue,
  disabled = false,
  selectName,
  currentValue,
  errors = null,

  innerRef,
  autoComplete = 'off',

  preview,
  image,
  defaultImage,
  id,
  isEdit,
  firstName,
  middleName,
  lastName,
  suffix,
  idNo,
  email,
  labelModifier = '',
  onFocus,

  timeValue,
  showSecond,

  checked,
  autoFocus = false
}) => {
  const renderField = fieldType => {
    switch (fieldType) {
      case 'text':
        return (
          <TextField
            label={label}
            className={className}
            error={error}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            readOnly={readOnly}
            required={required}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            labelModifier={labelModifier}
            autoFocus={autoFocus}
          />
        );
      case 'date':
        return (
          <TextField
            label={label}
            className={className}
            error={error}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            readOnly={readOnly}
            required={required}
            placeholder={placeholder}
            type={type}
            max={max}
            min={min}
            disabled={disabled}
            labelModifier={labelModifier}
          />
        );
      case 'time':
        return (
          <TimeField
            onChange={onChange}
            value={timeValue}
            disabled={disabled}
            showSecond={showSecond}
            name={name}
            label={label}
          />
        );
      case 'tel':
        return (
          <TextField
            label={label}
            className={className}
            error={error}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            name={name}
            readOnly={readOnly}
            required={required}
            placeholder={placeholder}
            type={type}
            labelModifier={labelModifier}
          />
        );
      case 'select':
        return (
          <SelectField
            label={label}
            htmlFor={name}
            required={required}
            options={options}
            onChangeValue={onChangeValue}
            selectedValue={selectedValue}
            errorMessage={errorMessage}
            disabled={disabled}
            selectName={selectName}
            errors={errors}
            currentValue={currentValue}
            placeholder={placeholder}
          />
        );
      case 'search':
        return (
          <SearchField
            label={label}
            htmlFor={name}
            onChange={onChange}
            innerRef={innerRef}
            value={value}
            name={name}
            id={name}
            required={required}
            placeholder={placeholder}
            autoComplete={autoComplete}
            readOnly={readOnly}
            errorMessage={errorMessage}
            error={error}
          />
        );
      case 'file':
        return (
          <ImageField
            preview={preview}
            image={image}
            defaultImage={defaultImage}
            id={id}
            isEdit={isEdit}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            suffix={suffix}
            idNo={idNo}
            email={email}
            name={name}
            onChange={onChange}
            type={type}
          />
        );
      case 'number':
        return (
          <TextField
            label={label}
            className={className}
            error={error}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            readOnly={readOnly}
            required={required}
            placeholder={placeholder}
            type={type}
            max={max}
            labelModifier={labelModifier}
            onFocus={onFocus}
          />
        );
      case 'textarea':
        return (
          <TextArea
            label={label}
            className={className}
            error={error}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            required={required}
            placeholder={placeholder}
            type={type}
            max={max}
            disabled={disabled}
            labelModifier={labelModifier}
            innerRef={innerRef}
          />
        );
      case 'combobox':
        return (
          <ComboBox
            error={error}
            label={label}
            required={required}
            listType={listType}
            datasource={options}
            isDisabled={disabled}
            placeholder={placeholder}
            errorMessage={errorMessage}
            defaultValue={selectedValue}
            onChangeValue={onChangeValue}
            name={name}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            label={label}
            name={name}
            required={required}
            type={type}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            error={error}
            errorMessage={errorMessage}
          />
        );
      default:
        return null;
    }
  };

  return renderField(type);
};

ImageField.propTypes = {
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  isEdit: PropTypes.bool,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  email: PropTypes.string,
  idNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number
};

TextField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  labelModifier: PropTypes.string,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool
};

SearchField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  errorMessage: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

SelectField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({ Object })),
    PropTypes.array
  ]),
  onChangeValue: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectName: PropTypes.string,
  currentValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool
};

TimeField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  showSecond: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
};

TextArea.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  max: PropTypes.string,
  labelModifier: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
};

FormField.propTypes = {
  // PropTypes for TextField
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  listType: PropTypes.string,
  max: PropTypes.string,

  // PropTypes for SelectField
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({ Object })),
    PropTypes.array
  ]),
  onChangeValue: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  selectName: PropTypes.string,
  currentValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  autoComplete: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),

  // PropTypes for File Image
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  idNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,

  // PropTypes for Time
  timeValue: PropTypes.string,
  showSecond: PropTypes.bool
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string
};

export default FormField;
