/* eslint-disable no-console */
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  useParams,
  useLocation,
  useNavigate,
  createSearchParams
} from 'react-router-dom';
// Services
import {
  startEvaluationService,
  closeEvaluationService,
  excludeEvaluationService,
  generateEvaluationService,
  getEvaluationResultByIdService,
  createEvaluationTemplateService,
  getEvaluationScheduleByIdService,
  updateEvaluationTemplateByIdService,
  bulkUpdateEmployeeEvaluationScheduleService
} from 'src/api/modules/evaluationSchedule';

// Redux
import { EVALUATIONS_SCHEDULES } from 'src/api/endpoints';

// Utilities
import {
  isEqual,
  filterData,
  formatDate,
  employeeName,
  costCenterTree,
  sortData
} from 'src/helpers/utils';

// Others
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';

// helpers
import {
  log,
  merge,
  useEmployees,
  createDictionary,
  isArrayHasLength,
  isObjectHasLength,
  sortBySortedItems,
  useActiveTemplates,
  filterDuplicatesById,
  useRemoveSelectedItems,
  removeDefaultEvaluators
} from 'src/hooks/EvaluationSchedule/helpers';

import { STATUS_OPTIONS, TOAST_OPTIONS } from 'src/helpers/constants';

const useEvaluationSchedule = (templateId, viewOnly) => {
  const evaluationResultId = useParams().id || null;
  const location = useLocation();
  const navigate = useNavigate();

  const { unSelectItems } = useRemoveSelectedItems();
  const { employees, allEmployees } = useEmployees(evaluationResultId);
  const { activeScheduleTemplates } = useActiveTemplates();
  const [isFieldDisabledOnActive, setIsFieldDisabledOnActive] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const isFirstLoadRef = useRef(isFirstLoad);
  const [queryString, setQueryString] = useState('empty');
  const [sortType, setSortType] = useState('');

  const initialDummyData = {
    name: 'Test',
    description: 'Test',
    start_date: '1900-01-01',
    end_date: '1900-12-01'
  };

  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Only letters with spaces, hyphens, or periods are allowed';

  const validationTemplateSchema = Yup.object({
    name: Yup.string()
      .required('Field Required')
      .max(255, 'Maximum 255 characters allowed')
      .matches(blankSpaceRegex, blankSpageMessage),
    description: Yup.string()
      .required('Field Required')
      .matches(blankSpaceRegex, blankSpageMessage),
    start_date: Yup.string().required('Field Required'),
    end_date: Yup.string().required('Field Required')
  });

  const defaultTemplateFields = {
    name: '',
    start_date: '',
    end_date: '',
    description: ''
  };

  const form = useForm({ defaultValues: undefined });

  const costCenterList = useSelector(
    state => state.costCenter.costCenterAll.items
  );

  const { ids } = useSelector(state => state.datatable);
  const [evaluationStarted, setEvaluationStarted] = useState(false);
  const [scheduleResultColumns, setScheduleResultColumns] = useState([]);
  const [isScheduleItemActive, setIsScheduleItemActive] = useState(false);
  const [isScheduleItemOnhold, setIsScheduleItemOnhold] = useState(false);
  const [templateFields, setTemplateFields] = useState(defaultTemplateFields);
  const [isResultItemsGenerated, setIsResultItemsGenerated] = useState(false);
  const [isScheduleItemFulfilled, setIsScheduleItemFulfilled] = useState(false);
  const [isScheduleItemCancelled, setIsScheduleItemCancelled] = useState(false);
  const [evaluationResultItemList, setEvaluationResultItemList] = useState([]);
  const [showStartEvaluationModal, setShowStartEvaluationModal] =
    useState(null);
  const [showResetScoreModal, setShowResetScoreModal] = useState(null);
  const [showOverwriteTemplateModal, setShowOverwriteTemplateModal] =
    useState(null);

  const [totalResultItems, setTotalResultItems] = useState(0);
  const [evaluatorsData, setEvaluatorsData] = useState([]);
  const [hasActiveCancelledItems, setHasActiveCancelledItems] = useState(false);
  const [isScheduleNameExist, setIsScheduleNameExist] = useState(false);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [updateFormError, setUpdateFormError] = useState({
    name: false,
    description: false,
    start_date: false,
    end_date: false
  });

  const currentSearchParams = useRef({});
  const evaluatorsPayload = useRef({});
  const defaultRawData = useRef([]);
  const defaultEvaluatorsData = useRef([]);
  const defaultEvaluators = useRef({});
  const pristineData = useRef([]);
  const selectedIds = useRef([]);
  const filteredItems = useRef([]);
  const once = useRef({
    onMount: true,
    onFilter: false
  });

  const defaultTemplates = useRef({});
  const bulkTemplatesPayload = useRef({});
  const [defaultTemplatesRaw, setDefaultTemplatesRaw] = useState([]);
  const [bulkTemplatesData, setBulkTemplatesData] = useState([]);
  const [generateExclude, setGenerateExclude] = useState({
    all: false,
    active: false,
    cancelled: false
  });

  const maxSections = useRef({
    once: true,
    count: 0,
    original: 0
  });

  const [hasData, setHasData] = useState(false);
  const [evaluationProgress, setEvaluationProgress] = useState(null);
  const isBulkUpdateSuccess = useRef(false);
  const selectedScheduleItem = useRef(null);
  const selectedScheduleItemPayload = useRef({
    payload: null,
    closeItem: () => {},
    type: null
  });
  const [isPieChartFirstLoad, setIsPieChartFirstLoad] = useState(true);
  const isPieChartFirstLoadRef = useRef(isFirstLoad);
  const overwriteTemplatePayload = useRef({
    payload: null,
    closeItem: () => {}
  });
  const isOverwriteTemplate = useRef(false);
  const cancelCount = useRef(0);
  const isBulkProceed = useRef({
    proceed: false,
    count: 0,
    maxSections: 0,
    data: []
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const currentSaveParams = useRef(null);

  const SCHEDULE_STATUS = STATUS_OPTIONS.filter(option => option.value !== 'O');

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(SCHEDULE_STATUS, 'label', 'value'),
      multiOptions: SCHEDULE_STATUS,
      name: 'status',
      label: 'Status'
    },
    {
      type: 'date-range',
      options: [
        {
          label: 'Hired Date (From)',
          name: 'hired_date_from'
        },
        {
          label: 'Hired Date (To)',
          name: 'hired_date_to'
        }
      ]
    }
  ];

  const handleChange = (
    e,
    part,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    const initialValue = value.match(/^\s/) !== null;

    if (part === 'schedule') {
      if (hasFormErrors) {
        setUpdateFormError(fields => ({
          ...fields,
          [name]: false
        }));
      }
      if (name === 'name' && isScheduleNameExist) setIsScheduleNameExist(false);
      if (
        (name === 'name' && initialValue) ||
        (name === 'description' && initialValue)
      ) {
        setTemplateFields(prevState => ({
          ...prevState,
          [name]: fieldValue.trim()
        }));
      } else {
        setTemplateFields(prevState => ({
          ...prevState,
          [name]: fieldValue
        }));
      }
    } else {
      setFieldValue(name, fieldValue);
      setFieldTouched(name, true);
      setFieldError(name, '');
    }
  };

  const currentTemplateInfo = useRef({
    id: null,
    template_id: null,
    schedule_id: null,
    template_name: null,
    is_template_active: null,
    sections: []
  });

  const bulkWithNullEvaluator = useRef([]);
  const pristineRecordList = useRef([]);
  const pristineBulkList = useRef({
    firstLoad: true,
    isOpen: false,
    data: {},
    original: {},
    maxSections: 0,
    selectedIds: []
  });

  // Open modal and capture scroll position
  const openModal = () => {
    setScrollPosition(window.scrollY); // Save current scroll position
    document.body.style.overflow = 'hidden'; // Prevent scrolling
    setIsModalOpen(true);
  };

  // Close modal and restore scroll position
  const closeModal = (duration = 50) => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition); // Restore scroll position
      document.body.style.overflow = 'auto'; // Restore scrolling
    }, duration);
    setIsModalOpen(false);
    setScrollPosition(0);
  };

  function getDefaultDetails(list, onUpdate) {
    if (!isArrayHasLength(list)) return;
    list.forEach((data, i) => {
      const matchingTemplate = activeScheduleTemplates.find(
        template => template.id === data.evaluation_template.id
      );

      const { id, evaluation_template, employee_evaluation_sections } = data;

      employee_evaluation_sections?.forEach((section, idx) => {
        const template_section_id = matchingTemplate?.sections[idx].section_id;
        const section_evaluator_id = section.evaluator?.id;

        defaultEvaluatorsData.current = [
          ...defaultEvaluatorsData.current,
          {
            evaluation_id: id,
            section_id: template_section_id ?? null,
            evaluator_id: section_evaluator_id ?? null,
            section_index: idx
          }
        ];
      });

      setDefaultTemplatesRaw(prevState => [
        ...prevState,
        {
          evaluation_id: id,
          template_id: evaluation_template?.id,
          template_name: evaluation_template?.name
        }
      ]);

      setTemplatePayload(id);
    });
    getDefaultBulkItems();
  }

  const pristineRecordFirstLoad = useRef(true);
  const pristineRecord = useRef({
    isOpen: false,
    data: {},
    original: {},
    maxSections: 0
  });

  const closeEditItem = useRef({
    closeItem: () => {}
  });

  const getScheduleItemDetail = (item, closeItem) => {
    if (!item) return;

    closeEditItem.current = { closeItem };

    const { actions, ...rest } = item;
    selectedScheduleItem.current = rest;

    const {
      id,
      templateId: currentTemplateId,
      template,
      sections: currentSections,
      is_template_active
    } = item;

    currentTemplateInfo.current = {
      ...currentTemplateInfo.current,
      id,
      schedule_id: id,
      template_id: currentTemplateId,
      template_name: template,
      sections: currentSections,
      is_template_active
    };

    if (
      pristineRecordFirstLoad.current &&
      (selectedIds.current.length === 0 || selectedIds.current.length === 1) &&
      (selectedIdsCopy.current.data.length === 0 ||
        selectedIdsCopy.current.data.length === 1)
    ) {
      pristineRecordFirstLoad.current = false;
      const currentPayload = defaultEvaluatorsData.current.filter(
        ({ evaluation_id }) => evaluation_id === id
      );

      const nullPayload = currentPayload.map(payload => {
        const { evaluator_id, section_id } = payload || {};
        return {
          ...payload,
          evaluator_id: evaluator_id ?? null,
          section_id: section_id ?? null
        };
      });

      const originalData = pristineData.current.find(
        ({ id: scheduleId }) => scheduleId === id
      );

      pristineRecord.current = {
        ...pristineRecord.current,
        isOpen: true,
        data: {
          schedule_id: id,
          template_id: currentTemplateId,
          template_name: template,
          payloadSections: nullPayload
        },
        original: originalData,
        maxSections: maxSections.current.original
      };

      // console.clear();
      // console.log('-----------------------------');
      // console.log('-----------------------------');
      // console.log('pristineRecord ON_EDIT: ', pristineRecord.current);
      // console.log('-----------------------------');
      // console.log('-----------------------------');
    }
  };

  const resetCurrentTemplateInfo = () => {
    currentTemplateInfo.current = {
      ...currentTemplateInfo.current,
      id: null,
      template_id: null,
      template_name: null,
      schedule_id: null,
      is_template_active: null,
      sections: []
    };
  };

  const isBulkTemplateSelection = useRef(false);

  // ** BASE COLUMNS
  const scheduleResultBaseColumns = [
    {
      key: 'id',
      label: 'ID',
      hidden: isScheduleItemActive // evaluationStarted
    },
    { key: 'name', label: 'Name', readOnly: true },
    { key: 'cost_center_code', label: 'Cost Center', readOnly: true },
    { key: 'hired_date', label: 'Hired Date', readOnly: true },
    {
      key: 'template',
      label: 'Template',
      type: 'combobox', // 'select'
      listType: 'schedule',
      placeholder: 'Select Template',
      selectOptions: activeScheduleTemplates.map(({ id, name }) => ({
        value: id,
        label: name
      })),
      onChangeFn: async ({ data, key }) => {
        setTimeout(() => setEvaluatorsData([]), 0);
        const isSelected = selectedIds.current.some(id => id === data.id);
        const selectedItems = defaultRawData.current
          .filter(item => selectedIds.current.some(id => id === item.id))
          .map(item => item.id);

        if (selectedIds.current.length > 1 && isSelected) {
          isBulkTemplateSelection.current = true;
          selectedItems.forEach(id => updateTemplate(data[key], id));
          setEditCurrentId(data.id);
        } else {
          updateTemplate(data[key], data.id);
        }
      }
    },
    { key: 'status', label: 'Status' }
  ];

  // ** GENERATE COLUMNS
  const generateColumns = useCallback(
    async sections => {
      if (sections) {
        const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
          key: `evaluator_${i + 1}`,
          label: `Evaluator ${i + 1}`,
          type: 'combobox', // 'select',
          listType: 'schedule',
          selectOptions: employees
            ?.filter(({ status }) => status === 'A')
            .map(({ id, name }) => ({
              value: id,
              label: name
            })),
          placeholder: 'Select Evaluator',
          onChangeFn: async ({ data, key }) => {
            const isSelected = selectedIds.current.some(id => id === data.id);
            const selectedItems = defaultRawData.current
              .filter(item => selectedIds.current.some(id => id === item.id))
              .map(item => item.id);

            if (selectedIds.current.length > 1 && isSelected) {
              selectedItems.forEach(id => updateEvaluator(data, key, id));
            } else {
              updateEvaluator(data, key, data.id);
            }
          }
        }));

        const isEvaluated = isScheduleItemFulfilled || isScheduleItemActive;
        const start = isEvaluated ? 1 : 0;
        const items = isEvaluated ? 3 : 4;

        const total =
          maxSections.current.count + scheduleResultBaseColumns.length;

        const checkTotal = [
          ...scheduleResultBaseColumns.slice(start, items),
          ...evaluatorColumns,
          ...scheduleResultBaseColumns.slice(-2)
        ].length;

        if (checkTotal === total && !isEvaluated) {
          setScheduleResultColumns([]);
          setScheduleResultColumns(prevState => [
            ...prevState,
            ...scheduleResultBaseColumns.slice(start, items),
            ...evaluatorColumns,
            ...scheduleResultBaseColumns.slice(-2)
          ]);
        } else {
          setScheduleResultColumns(prevState => [
            ...prevState,
            ...scheduleResultBaseColumns.slice(start, items),
            ...evaluatorColumns,
            ...scheduleResultBaseColumns.slice(-2)
          ]);
        }
      }
    },
    [isScheduleItemFulfilled, isScheduleItemActive]
  );

  const columns = useMemo(() => {
    return scheduleResultColumns || [];
  }, [evaluationResultItemList, maxSections.current.count, hasData]);

  // ** SCHEDULE LIST
  const scheduleResultItems = useMemo(() => {
    if (!evaluationResultId) return null;
    if (!evaluationResultItemList) return [];

    const maxCount = maxSections.current.count;

    const res = evaluationResultItemList?.map(
      ({
        id,
        status,
        employee,
        notSelected,
        evaluation_template,
        employee_evaluation_sections
      }) => {
        const {
          cost_center_code = '',
          date_hired = '',
          employee_info = {}
        } = employee || {};

        const sectionCount = employee_evaluation_sections.length;
        const missing = maxCount - sectionCount;

        const sections = [
          ...employee_evaluation_sections,
          ...Array.from({ length: missing }, () => ({
            id: null,
            section_id: null,
            evaluator: null
          }))
        ];

        const item = {
          id,
          status,
          name: employeeName(employee_info),
          cost_center_code,
          cost_center_tree: costCenterTree(
            employee?.cost_center_code,
            costCenterList
          ),
          hired_date: date_hired ? formatDate(date_hired) : '-',
          template: evaluation_template?.name,
          is_template_active: activeScheduleTemplates.some(
            template => template.name === evaluation_template?.name
          ),
          templateId: evaluation_template?.id,
          sections,
          notSelected
        };

        const evaluationSections = sections.map(
          ({ id: evaluationSectionId, evaluator }, index) => ({
            [`evaluator_${index + 1}`]:
              // eslint-disable-next-line no-nested-ternary
              evaluationSectionId && evaluator?.id
                ? employees?.find(cody => cody?.id === evaluator?.id)?.name
                : evaluationSectionId === null
                ? '-‎'
                : '-'
          })
        );

        return evaluationSections?.reduce(
          (object, current) => ({ ...object, ...current }),
          { ...item }
        );
      }
    );

    if (sortType) {
      const { column, isDate, order } = sortType;
      const sortedRes = sortData(res, column, isDate, order);
      return sortedRes;
    }

    return res || [];
  }, [evaluationResultItemList, maxSections.current.count, sortType]);

  // ** EVALUATORS PAYLOAD
  const payloadDictionary = useMemo(() => {
    return createDictionary(evaluatorsData);
  }, [evaluatorsData]);

  // ** TEMPLATE PAYLOAD
  const templatesPayloadDictionary = useMemo(() => {
    return createDictionary(bulkTemplatesData, 'templates');
  }, [bulkTemplatesData]);

  // ** DEFAULT EVALUATORS
  const defaultEvaluatorsDictionary = useMemo(() => {
    return createDictionary(defaultEvaluatorsData.current, 'defaultEvaluators');
  }, [defaultEvaluatorsData.current]);

  // ** DEFAULT TEMPLATES
  const defaultTemplatesDictionary = useMemo(() => {
    return createDictionary(defaultTemplatesRaw, 'templates');
  }, [defaultTemplatesRaw]);

  const columnCount = useRef({
    currentMax: 0,
    oldMax: 0
  });

  // ** API (List) **
  const getResultDetails = useCallback(
    async (onUpdate = null) => {
      log(onUpdate, {
        name: 'getResultDetails:',
        color: 'orange',
        enable: false
      });

      if (!once.current.onMount || !evaluationResultId) return;

      if (onUpdate === 'onBulkUpdate') {
        isBulkUpdateSuccess.current = false;
        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }
      }

      if (onUpdate === 'onResetSingleRecord') {
        rebuildColumns(pristineRecord.current.maxSections);
        setEditCurrentId(null);

        pristineRecordFirstLoad.current = true;

        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }
      }

      if (onUpdate === 'onResetBulkRecord') {
        isBulkTemplateSelection.current = false;
        handleResetColumns('onResetBulkRecord');
      }

      const params = checkParams();

      const resultDataItems = await getEvaluationResultByIdService(
        params,
        evaluationResultId
      );

      once.current.onMount = false;

      pristineData.current = [];
      setDefaultTemplatesRaw([]);
      defaultEvaluatorsData.current = [];

      log(resultDataItems?.details?.duration, {
        name: '[API] response-time:',
        color: 'green',
        enable: false
      });

      const {
        items,
        total_items,
        max_section,
        for_evaluation,
        evaluation_progress
      } = resultDataItems.data || {};

      log(resultDataItems.data, {
        name: 'resultDataItems.data:',
        color: 'orangered',
        enable: false
      });

      if (isBulkUpdateFired.current) {
        log(pristineBulkList.current, {
          name: 'resultDataItems.data:',
          color: 'powderblue',
          enable: false
        });
      }

      if (for_evaluation && isPieChartFirstLoadRef.current) {
        setIsPieChartFirstLoad(false);
        setEvaluationProgress(evaluation_progress);
      }

      if (total_items) {
        setHasData(true);
      }

      if (max_section) {
        if (maxSections.current.once) {
          maxSections.current = {
            ...maxSections.current,
            once: false,
            count: max_section,
            original: max_section
          };
        }
      }

      const hasActiveAndCancelled =
        items?.some(({ status }) => ['A', 'C'].includes(status)) || {};

      const filterNullEmployee = items
        ?.filter(item => item.employee !== null)
        ?.map(item => ({
          ...item,
          notSelected: false
        }));

      pristineData.current = merge(pristineData.current, filterNullEmployee);

      if (params.size && onUpdate === 'onFilter') {
        defaultRawData.current = [];
        filteredItems.current = [];

        filteredItems.current = merge(
          filteredItems.current,
          filterNullEmployee
        );

        defaultRawData.current = merge(
          defaultRawData.current,
          filteredItems.current
        );
      } else {
        filteredItems.current = [];
        defaultRawData.current = pristineData.current;
      }

      setTotalResultItems(total_items);
      setIsResultItemsGenerated(for_evaluation);

      if (onUpdate === 'onMount') {
        setEvaluationResultItemList(filterNullEmployee);
      } else {
        setHasData(false);
        setEvaluationResultItemList(filterNullEmployee);
        setScheduleSelectedIds(pristineBulkList.current.selectedIds);
        setTimeout(() => setHasData(true), 0);
      }

      // setHasActiveCancelledItems(hasActiveAndCancelled);
      getDefaultDetails(defaultRawData.current, onUpdate);
    },
    [viewOnly, scheduleResultItems]
  );

  const handleGenerateEvaluation = async () => {
    const hasIds = isArrayHasLength(selectedIds.current);
    if (hasIds) {
      const payload = { evaluation_id: ids };
      const res = await generateEvaluationService(payload, templateId);

      if (hasIds) {
        deleteParams('unselectItems');
      }

      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 207) {
        once.current.onMount = true;
        setIsPieChartFirstLoad(true);
        getResultDetails('onGenerate');
        toastNotification('Successfully Generated Evaluation!', 'success');
      } else {
        const errorMessage = res?.response?.data?.message;
        toastNotification(errorMessage, 'error');
      }
      unSelectItems();
    }
  };

  const handleExcludeEvaluation = async () => {
    const hasIds = isArrayHasLength(selectedIds.current);
    if (hasIds) {
      unSelectItems();
      const res = await excludeEvaluationService(
        {
          evaluation_id: ids
        },
        templateId
      );

      if (hasIds) {
        deleteParams('unselectItems');
        unSelectItems();
      }

      if (res?.data?.statusCode === 200 || res?.data?.statusCode === 207) {
        once.current.onMount = true;
        setIsPieChartFirstLoad(true);
        getResultDetails('onExclude');
        toastNotification('Successfully Excluded Evaluation!', 'success');
      } else {
        toastNotification('Failed Evaluation Exclusion', 'error');
      }
    }
  };

  const handleStartEvaluation = async () => {
    setShowStartEvaluationModal(null);
    const res = await startEvaluationService(templateId);
    if (res?.data?.statusCode === 200) {
      toastNotification('Successfully Started Evaluation!', 'success');
      setEvaluationStarted(true);
      getResultDetails('onStartEvaluation');
    } else if (res?.response?.status === 409) {
      toastNotification(res?.response?.data?.message, 'error');
      getResultDetails('onErrorStartEvaluation');
    } else {
      toastNotification('Failed to Start Evaluation', 'error');
    }
  };

  const handleCloseEvaluation = async () => {
    const res = await closeEvaluationService(templateId);
    if (res?.data?.statusCode === 200) {
      setIsScheduleItemFulfilled(res?.data?.data?.status === 'F');
      setIsScheduleItemCancelled(res?.data?.data?.status === 'C');
      getResultDetails('onCloseEvaluation');
      toastNotification('Successfully Closed Evaluation!', 'success');
      setEvaluationStarted(true);
    } else if (res?.response?.status === 409) {
      toastNotification(res?.response?.data?.message, 'error');
      getResultDetails('onErrorCloseEvaluation');
    } else {
      toastNotification('Failed to Close Evaluation', 'error');
    }
  };

  const isBulkUpdateFired = useRef(false);

  const selectedIdsCopy = useRef({
    onMount: true,
    data: []
  });

  const handleResetScore = () => {
    const { type, payload, closeItem } = selectedScheduleItemPayload.current;
    if (type === 'single') update(payload, closeItem);
    if (type === 'bulk') handleBulkUpdate(payload, closeItem, 'onResetScore');
    handleCloseConfirmModal('ResetScore');
  };

  const isBulkModalTriggered = useRef(false);

  // ** UPDATE EVALUATION TEMPLATE on CHANGE
  const handleOverwriteTemplate = () => {
    const { closeItem } = overwriteTemplatePayload.current;

    //! BULK TEMPLATE SELECTION
    if (isBulkTemplateSelection.current) {
      isBulkProceed.current = {
        ...isBulkProceed.current,
        proceed: true,
        // eslint-disable-next-line no-plusplus
        count: ++isBulkProceed.current.count,
        maxSections: maxSections.current.count,
        data: defaultRawData.current
      };

      if (pristineBulkList.current.firstLoad) {
        const currentData = defaultRawData.current.filter(item =>
          selectedIdsCopy.current.data.some(id => id === item.id)
        );

        const originalPayload =
          bulkWithNullEvaluator.current.length > 0
            ? bulkWithNullEvaluator.current
            : pristineRecordList.current;

        pristineBulkList.current = {
          ...pristineBulkList.current,
          firstLoad: false,
          isOpen: true,
          data: currentData,
          original: originalPayload,
          maxSections: maxSections.current.original,
          selectedIds: selectedIdsCopy.current.data
        };
      }

      let bulkPayload = [];
      const templatesPayload = bulkTemplatesPayload.current;
      const evaluatorsBulkPayload = evaluatorsPayload.current;

      Object.keys(templatesPayload).forEach(templateScheduleId => {
        const [_, evaluatorPayload] = Object.entries(
          evaluatorsBulkPayload
        ).find(([key]) => key === templateScheduleId) || [null, []]; // If not found, default to an empty array.
        // Create evaluatorSections based on whether evaluatorPayload is empty or not
        const evaluatorSections =
          evaluatorPayload.length > 0
            ? evaluatorPayload.map(({ section_index, ...rest }) => rest)
            : Array.from({ length: maxSections.current.count }, () => ({
                section_id: null,
                evaluator_id: null
              }));

        const item = {
          evaluation_id: +templateScheduleId,
          template_id: templatesPayload[templateScheduleId][0].template_id,
          sections: evaluatorSections
        };
        bulkPayload = [...bulkPayload, item];
      });

      const filteredPayload = bulkPayload.filter(({ evaluation_id }) =>
        selectedIds.current.includes(evaluation_id)
      );

      // console.log('BULK UPDATE ~ ~ ~ ~ ~ ~');
      handleBulkUpdate(filteredPayload, closeItem, 'onBulkOverwriteTemplate');
      bulkPayload = [];

      handleCloseConfirmModal('OverwriteTemplate');
      return;
    }

    const { id } = currentTemplateInfo.current;
    const evaluatorsPayloadAPI = evaluatorsPayload.current;
    const { template_id: currentTemplateId } = currentTemplateInfo.current;
    const hasEvaluators = isObjectHasLength(evaluatorsPayload.current);
    const items = evaluatorsPayloadAPI[id];

    isOverwriteTemplate.current = true;

    const payloadSections =
      hasEvaluators && items
        ? items.map(({ section_index, ...rest }) => rest)
        : Array.from({ length: maxSections.current.count }, () => ({
            section_id: null,
            evaluator_id: null
          }));

    const payload = [
      {
        evaluation_id: id,
        template_id: currentTemplateId,
        sections: payloadSections
      }
    ];

    updateOverwriteTemplate(payload, closeItem);
    handleCloseConfirmModal('OverwriteTemplate');
  };

  const handleCancelOverwriteTemplate = () => {
    if (scrollPosition) closeModal();

    // // [ ] temp fix for default state when canceling the overwrite template
    // if (!isBulkTemplateSelection.current) {
    //   const { schedule_id, template_name } = pristineRecord.current.data;
    //   updateTemplate(template_name, schedule_id);
    // }

    // setEditCurrentId(null);
    isBulkTemplateSelection.current = false;
    handleResetScheduleItem('onCancel');
    handleCloseConfirmModal('OverwriteTemplate');
  };

  const handleConfirmModal = type => {
    switch (type) {
      case 'StartEvaluation':
        setShowStartEvaluationModal('StartEvaluation');
        break;
      case 'ResetScore':
        setShowResetScoreModal('ResetScore');
        break;
      case 'OverwriteTemplate':
        openModal();
        setShowOverwriteTemplateModal('OverwriteTemplate');
        break;
      default:
        break;
    }
  };

  const handleCloseConfirmModal = type => {
    switch (type) {
      case 'StartEvaluation':
        setShowStartEvaluationModal(null);
        break;
      case 'ResetScore':
        setShowResetScoreModal(null);
        break;
      case 'OverwriteTemplate':
        closeModal();
        setShowOverwriteTemplateModal(null);
        break;
      default:
        break;
    }
  };

  const submitFilter = form.handleSubmit(params => {
    if (!isFirstLoadRef.current) {
      pushQuery(params);
    }
  });

  const pushQuery = params => {
    const searchParamsQ = new URLSearchParams(location.search);
    const loadedParams = {};

    searchParamsQ.forEach((value, key) => {
      loadedParams[key] = value;
    });
    const searchParamsObject = { ...loadedParams, ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    if (params.hired_date_from === '') {
      delete searchParamsObject.hired_date_from;
    }

    if (params.hired_date_to === '') {
      delete searchParamsObject.hired_date_to;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value === '' || value === null) {
        delete searchParamsObject[key];
      }
    });

    if ('hired_date_from' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        hired_date_from: searchParamsObject.hired_date_from
      };
    } else {
      delete currentSearchParams.current.hired_date_from;
    }

    if ('hired_date_to' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        hired_date_to: searchParamsObject.hired_date_to
      };
    } else {
      delete currentSearchParams.current.hired_date_to;
    }

    const searchParams = createSearchParams(searchParamsObject).toString();

    // Save search params in localStorage
    localStorage.setItem(
      'hris-evaluation-schedule-item-search-params',
      searchParams
    );

    once.current = {
      onMount: true,
      onFilter: true
    };

    // fix for the first load (filter status)
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    setQueryString(searchParams);

    if (searchParams) currentSaveParams.current = searchParams;
    else currentSaveParams.current = null;

    navigate({
      pathname: location.pathname,
      search: searchParams
    });
  };

  const getEmptyFields = (obj, callback, type = null) => {
    // eslint-disable-next-line array-callback-return, consistent-return
    const emptyFields = Array.from(Object.keys(obj), key => {
      if (type === 'not') {
        if (obj[key] !== '') return key;
      } else if (obj[key] === '') return key;
    }).filter(Boolean);

    if (callback && typeof callback === 'function') {
      emptyFields.forEach(field => {
        callback(field);
      });
    }

    return emptyFields;
  };

  const checkFormFields = formData => {
    const errors = getEmptyFields(formData, field => {
      setUpdateFormError(fields => ({
        ...fields,
        [field]: true
      }));
    });
    getEmptyFields(
      formData,
      field => {
        setUpdateFormError(fields => ({
          ...fields,
          [field]: false
        }));
      },
      'not'
    );
    return errors;
  };

  const handleScheduleSubmit = useCallback(async () => {
    try {
      if (evaluationResultId) {
        const { orig_status, status, ...dataValue } = templateFields;
        const errors = checkFormFields(dataValue);

        setHasFormErrors(errors.length > 0);

        if (errors.length > 0)
          throw new Error('Please fill all required fields');

        const updateResponse = await updateEvaluationTemplateByIdService(
          EVALUATIONS_SCHEDULES,
          evaluationResultId,
          dataValue
        );

        if (updateResponse.statusCode === 200) {
          toastNotification(
            'Successfully Edited Evaluation Schedule!',
            'success'
          );
        }
      } else {
        const addResponse = await createEvaluationTemplateService(
          templateFields,
          templateId
        );

        if (addResponse.data ?? addResponse.response) {
          if (addResponse.response) {
            const { data, status } = addResponse.response || {};
            if (status === 409) setIsScheduleNameExist(true);
            throw new Error(data.message);
          }

          if (addResponse.data) {
            const { data, statusCode } = addResponse.data || {};
            const { id: scheduleId } = data.evaluation_schedule;
            if (statusCode === 201) {
              toastNotification(
                'Successfully Added Evaluation Schedule!',
                'success'
              );
              navigate(`/evaluation-schedule/view/${scheduleId}`);
            }
          }
        }
      }
    } catch (e) {
      console.error('error: ', e.message); // `Failed to ${id ? 'Edit' : 'Add'} Evaluation Schedule`
    }
  }, [templateFields]);

  const getEvaluationInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getEvaluationScheduleByIdService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        const templateData = {
          name: responseData.name,
          start_date: responseData.start_date,
          end_date: responseData.end_date,
          description: responseData.description,
          status: !['N', 'A'].includes(responseData.status)
            ? responseData.status
            : null,
          orig_status: responseData.status
        };

        setTemplateFields(templateData);
        setIsScheduleItemOnhold(responseData.status === 'O');
        // setIsScheduleItemActive(responseData.status === 'A');
        setEvaluationStarted(responseData.status === 'A');
        setIsScheduleItemFulfilled(responseData.status === 'F');
      }
    } catch (error) {
      toastNotification('Error fetching data:', 'error');
    }
  };
  const trackScrollPosition = useRef(0);

  // * UPDATE ROW
  const handleUpdateRow = async ({ id: scheduleId }, closeItem) => {
    const isIdSelected = selectedIds.current.some(id => id === scheduleId);
    if (isArrayHasLength(selectedIds.current) && isIdSelected) {
      if (isIdSelected) bulkUpdate(closeItem);
      else closeItem();
    } else {
      updateRow(scheduleId, closeItem);
    }
  };

  // ! CLOSE RESET
  const handleResetScheduleItem = (onUpdate, scheduleId, closeItem) => {
    setScheduleSelectedIds([]);
    const isExact = isEqual(defaultRawData.current, pristineData.current);

    log(onUpdate, {
      name: 'handleResetScheduleItem:',
      color: 'orange',
      enable: false
    });

    if (onUpdate === 'onSubmitReset') {
      setTimeout(() => {
        setEvaluatorsData([]);
        defaultRawData.current = pristineData.current;
        filteredItems.current = pristineData.current;
        setEvaluationResultItemList(pristineData.current);
      }, 1000);
      return;
    }

    // ! Revert Original Record
    if (
      pristineRecord.current.isOpen &&
      pristineRecord.current.data.schedule_id === scheduleId
    ) {
      const currentTemplateId = pristineRecord.current.data.template_id;
      const pristineItems = pristineRecord.current.data.payloadSections;
      const originalRecord = pristineRecord.current.original; // log
      const currentRecord = defaultRawData.current.find(
        ({ id }) => id === pristineRecord.current.data.schedule_id
      ); // log

      if (isArrayHasLength(pristineItems)) {
        if (!isEqual(currentRecord, originalRecord)) {
          // console.log('SINGLE RECORD REVERT', scheduleId);

          const sanitizedItems = pristineItems.map(
            ({ evaluator_id, section_id }) => {
              return {
                section_id: [null, undefined].includes(evaluator_id)
                  ? null
                  : section_id,
                evaluator_id: [null, undefined].includes(evaluator_id)
                  ? null
                  : evaluator_id
              };
            }
          );

          const payload = [
            {
              evaluation_id: scheduleId,
              template_id: currentTemplateId,
              sections: [...sanitizedItems]
            }
          ];

          handleRevertOriginalRecord(payload);
        }
      }

      pristineRecordFirstLoad.current = true;
    }

    //! Revert Original Record List
    if (
      onUpdate === 'onCancel' &&
      isBulkUpdateFired.current &&
      !isBulkModalTriggered.current &&
      pristineBulkList.current.isOpen &&
      isArrayHasLength(selectedIds.current)
    ) {
      setEditCurrentId(null); // closeItem

      const payloadRevert = pristineBulkList.current.original;

      handleBulkUpdate(payloadRevert, closeItem, 'onBulkCancel');

      isBulkUpdateFired.current = false;

      setTimeout(() => unSelectIds('unselectItems'), 0);
      // console.log('CANCELLING WITH TEMPLATE CHANGES');
      if (trackScrollPosition.current) {
        setTimeout(() => {
          window.scrollTo(0, trackScrollPosition.current);
        }, 1000);
      }
    }

    if (
      onUpdate === 'onCancel' &&
      !isBulkTemplateSelection.current &&
      !pristineBulkList.current.isOpen
    ) {
      unSelectIds('unselectItems');
      // console.log('CANCELLING WITH NO CHANGES (SINGLE | BULK)');
    }

    if (onUpdate === 'onUpdate') {
      resetCurrentTemplateInfo();
      defaultEvaluatorsData.current = [];
      getResultDetails(onUpdate, scheduleId);
    }

    if (onUpdate === 'onFail') {
      setEditCurrentId(null);
      pristineRecordFirstLoad.current = true;
      rebuildColumns(pristineRecord.current.maxSections);
    }

    if (['onCancel', 'onFail'].includes(onUpdate)) {
      if (!isExact) {
        const revertedItems =
          isBulkProceed.current.count > 0
            ? isBulkProceed.current.data
            : pristineData.current;

        setEvaluatorsData([]);
        resetCurrentTemplateInfo();
        defaultRawData.current = pristineData.current;
        filteredItems.current = pristineData.current;
        setHasData(false);
        setEvaluationResultItemList(revertedItems);
        setTimeout(() => setHasData(true), 0);

        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }
      }
    }

    //! resetColumn
    handleResetColumns(onUpdate);
  };

  const handleRevertOriginalRecord = async data => {
    try {
      const response = await bulkUpdateEmployeeEvaluationScheduleService(data);

      if (response?.success && response?.statusCode === 200) {
        isOverwriteTemplate.current = false;

        once.current.onMount = true;

        maxSections.current = {
          ...maxSections.current,
          once: true
        };

        if (maxSections.current.count > maxSections.current.original) {
          maxSections.current.original = maxSections.current.count;
        } else {
          maxSections.current.count = maxSections.current.original;
        }

        // setIsPieChartFirstLoad(true);
        handleResetColumns('onUpdate');
        getResultDetails('onResetSingleRecord');
        setEvaluatorsData([]);

        pristineRecord.current = {
          isOpen: false,
          data: {},
          original: {},
          maxSections: 0
        };

        return;
      }

      const errorStatusCodes = [400, 404, 409];
      const { statusCode } = response.response?.data || {};

      if (errorStatusCodes.includes(statusCode)) {
        toastNotification(
          `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
          'error'
        );

        handleResetScheduleItem('onFail');
      }
    } catch (error) {
      toastNotification('Revert Failed!', 'error');
    }
  };

  const handleResetColumns = onUpdate => {
    if (onUpdate === 'onResetBulkRecord') {
      rebuildColumns(pristineBulkList.current.maxSections);
      // resetPristineBulkList();
      // if (trackScrollPosition.current) {
      //   setTimeout(() => {
      //     window.scrollTo(0, trackScrollPosition.current);
      //   }, 1100);
      // }
    }

    if (
      onUpdate !== 'onResetBulkRecord' &&
      maxSections.current.count !== maxSections.current.original
    ) {
      const sectionCount =
        isBulkProceed.current.count > 0
          ? isBulkProceed.current.maxSections
          : maxSections.current.original;
      rebuildColumns(sectionCount);
    }

    if (isArrayHasLength(selectedIds.current)) {
      cancelCount.current += 1;
    }

    if (
      onUpdate === 'onCancel' &&
      showOverwriteTemplateModal === null &&
      isArrayHasLength(selectedIds.current)
    ) {
      setScheduleSelectedIds([]);
      cancelCount.current = 0;
    }

    if (
      onUpdate === 'onCancel' &&
      cancelCount.current !== 0 &&
      isArrayHasLength(selectedIds.current)
    ) {
      setScheduleSelectedIds(selectedIds.current);
    }

    log(onUpdate, {
      name: 'handleResetColumns:',
      color: 'orange',
      enable: false
    });
  };

  const handleDateOnChange = e => {
    const searchParams = new URLSearchParams(location.search);
    const { name, value } = e.target;
    if (Object.keys(currentSearchParams.current).length === 1 && value === '') {
      const paramsList = currentSaveParams.current.split('&');
      const updatedParamsList = paramsList.filter(
        param => !param.startsWith(name.toString())
      );

      const updatedParams = updatedParamsList.join('&');

      navigate({
        pathname: location.pathname,
        search: updatedParams.toString()
      });

      localStorage.setItem(
        'hris-evaluation-schedule-item-search-params',
        updatedParams
      );

      searchParams.delete(name);

      once.current = {
        onMount: true,
        onFilter: true
      };
    }
  };

  function rebuildColumns(sections) {
    setHasData(false);
    maxSections.current.count = sections;
    generateColumns(maxSections.current.count);
    setTimeout(() => setHasData(true), 0);
  }

  function setTemplatePayload(scheduleId) {
    const { evaluation_template } =
      defaultRawData.current.find(
        scheduleItem => scheduleItem.id === scheduleId
      ) || {};

    setBulkTemplatesData(prevState => [
      ...prevState,
      {
        evaluation_id: scheduleId,
        template_id: evaluation_template?.id,
        template_name: evaluation_template?.name
      }
    ]);
  }

  function activeCancelledStatus(selectedItems) {
    if (isArrayHasLength(selectedItems)) {
      const filteredNoNew = defaultRawData.current
        .filter(data => ids.some(id => id === data.id))
        .every(({ status }) => status !== 'N');

      const filteredHasNew = defaultRawData.current
        .filter(data => ids.some(id => id === data.id))
        .some(data => data.status === 'N');

      const activeItem = defaultRawData.current
        .filter(item => selectedIds.current.some(id => id === item.id))
        .every(item => item.status === 'A');

      const cancelledItem = defaultRawData.current
        .filter(item => selectedIds.current.some(id => id === item.id))
        .every(item => item.status === 'C');

      if (filteredHasNew) {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: false,
          cancelled: false
        }));
      }

      if (filteredNoNew) {
        setGenerateExclude(prevState => ({
          ...prevState,
          all: filteredNoNew
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          all: false
        }));
      }

      if (activeItem) {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: activeItem
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: false
        }));
      }

      if (cancelledItem) {
        setGenerateExclude(prevState => ({
          ...prevState,
          cancelled: cancelledItem
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          cancelled: false
        }));
      }
    }

    if (!isArrayHasLength(selectedIds.current)) {
      setGenerateExclude({
        all: false,
        active: false,
        cancelled: false
      });
    }
  }

  function checkSelectedIds(selectedItems) {
    const savedSearchParams = currentSaveParams.current;
    const allDefault = ['', null].includes(savedSearchParams);
    const allFiltered = savedSearchParams === 'status=N%2CA%2CC%2CF';
    const allStatusFiltered = filteredItems.current.every(({ status }) =>
      ['N', 'A', 'C'].includes(status)
    );
    const hasNoFulfilled = defaultRawData.current
      .filter(({ id }) => selectedItems.includes(id))
      .some(({ status }) => ['N', 'A', 'C'].includes(status)); // 'N'
    const isDefaultAndFilteredAll =
      (!isArrayHasLength(selectedItems) && allDefault) ||
      (!isArrayHasLength(selectedItems) && allFiltered) ||
      (isArrayHasLength(selectedItems) && !hasNoFulfilled) ||
      (!isArrayHasLength(selectedItems) && allStatusFiltered);

    if (isArrayHasLength(selectedItems) && hasNoFulfilled) {
      // ! FILTER APPLIED
      if (isArrayHasLength(filteredItems.current)) {
        const updateFilteredNotSelected = filteredItems.current.map(item => {
          if (!selectedItems.includes(item.id)) {
            return {
              ...item,
              notSelected: true
            };
          }
          return {
            ...item,
            notSelected: false
          };
        });
        defaultRawData.current = updateFilteredNotSelected;
        setEvaluationResultItemList(updateFilteredNotSelected);
      }

      // ! NO FILTER APPLIED (ALL ITEMS)
      if (
        !isArrayHasLength(filteredItems.current) ||
        (isArrayHasLength(selectedItems) && allFiltered)
      ) {
        const updateNotSelected = defaultRawData.current.map(item => {
          if (!selectedItems.includes(item.id)) {
            return {
              ...item,
              notSelected: true
            };
          }
          return {
            ...item,
            notSelected: false
          };
        });
        defaultRawData.current = updateNotSelected;
        setEvaluationResultItemList(updateNotSelected);
      }
    }

    if (isDefaultAndFilteredAll) {
      const revertNotSelected = defaultRawData.current.map(item => ({
        ...item,
        notSelected: false
      }));
      defaultRawData.current = revertNotSelected;
      setEvaluationResultItemList(revertNotSelected);
    }
  }

  function updateSortedData(sortedData) {
    // const sortedIds = sortedData.map(({ id }) => id);
    // const sorted = sortBySortedItems(defaultRawData.current, sortedIds);
    // defaultRawData.current = sorted;
    // if (checkParams().size) filteredItems.current = sorted;
  }

  function checkParams() {
    return new URLSearchParams(
      localStorage.getItem('hris-evaluation-schedule-item-search-params')
    );
  }

  function deleteParams(param) {
    if (!param) return;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(param)) {
      setTimeout(() => {
        searchParams.delete(param);
        navigate({
          pathname: location.pathname,
          search: location.search.toString()
        });
      }, 0);
    }
  }

  function unSelectIds(param) {
    if (!param) return;
    if (isArrayHasLength(selectedIds.current)) {
      deleteParams(param);
      unSelectItems();
    }
  }

  const [scheduleSelectedIds, setScheduleSelectedIds] = useState([]);
  const [editCurrentId, setEditCurrentId] = useState(undefined);

  function updateTemplate(value, scheduleId) {
    if (window.scrollY) setScrollPosition(window.scrollY);

    if (selectedIdsCopy.current.onMount) {
      selectedIdsCopy.current = {
        ...selectedIdsCopy.current,
        onMount: false,
        data: selectedIds.current
      };
      setScheduleSelectedIds(selectedIdsCopy.current.data);
    }

    const originalTemplate = pristineData.current.find(
      item => item.id === scheduleId
    );

    const selectedTemplate =
      activeScheduleTemplates.find(
        template => template.name.trim() === value.trim()
      ) || {};

    const { id, name, sections } = selectedTemplate;

    const sameSize =
      defaultRawData.current.length === selectedIds.current.length;

    //! check new max count from selected template
    if (
      sections.length >= maxSections.current.original ||
      (sections.length < maxSections.current.original && sameSize)
    ) {
      setEditCurrentId(currentTemplateInfo.current.id);
      rebuildColumns(sections.length);
    }

    //! get previous max count before transforming
    const oldMaxCount = pristineData.current.reduce((maxCount, record) => {
      const currentCount = record.employee_evaluation_sections.filter(
        section => section.id !== null
      ).length;
      return Math.max(maxCount, currentCount);
    }, 0);

    //! check if template is the same
    if (
      originalTemplate?.evaluation_template?.name === selectedTemplate?.name
    ) {
      return;
    }

    const updateSections =
      defaultRawData.current.map(scheduleItem => {
        if (scheduleItem.id !== scheduleId) return scheduleItem;
        const { employee_evaluation_sections } = scheduleItem;
        const newSections = sections.map((sectionData, index) => {
          const existingSection = employee_evaluation_sections[index] || {};
          const { section_id, evaluator } = sectionData;
          // Update evaluators data
          setTimeout(() => {
            setEvaluatorsData(prevState => [
              ...prevState,
              {
                evaluation_id: scheduleId,
                section_id,
                evaluator_id: evaluator?.id,
                section_index: index
              }
            ]);
            setTimeout(() => {
              if (
                isObjectHasLength(evaluatorsPayload.current) &&
                !isArrayHasLength(selectedIds.current)
              ) {
                removeDefaultEvaluators({
                  schedule_id: scheduleId,
                  defaultEvaluators: defaultEvaluators.current,
                  evaluatorsPayload: evaluatorsPayload.current,
                  updatePayload: setEvaluatorsData
                });
              }
            }, 0);
          }, 0);

          return {
            ...existingSection,
            section_id,
            evaluator_id: evaluator?.id,
            evaluator
          };
        });

        return {
          ...scheduleItem,
          employee_evaluation_sections: newSections,
          evaluation_template: { id, name }
        };
      }) || [];

    defaultRawData.current = updateSections;
    filteredItems.current = updateSections;
    setEvaluationResultItemList(updateSections);
    setTemplatePayload(scheduleId);

    // ! Track current record (defaultRawData)
    const { maxCount } = allSectionsSameCount(defaultRawData.current) || {};

    //! create a copy of current max count
    columnCount.current = {
      ...columnCount.current,
      currentMax: maxCount,
      oldMax: oldMaxCount
    };

    if (maxCount < oldMaxCount) rebuildColumns(maxCount);

    if (
      originalTemplate?.evaluation_template?.name !== selectedTemplate?.name
    ) {
      handleConfirmModal('OverwriteTemplate');
      setEditCurrentId(scheduleId);
    }
  }

  function updateEvaluator(data, key, scheduleId) {
    const fullName = data[key];
    const sectionIndex = key.split('evaluator_').at(-1) - 1;
    const evaluator = employees?.find(({ name }) => name === fullName);
    const templateSelect = activeScheduleTemplates.find(
      template => template.name.trim() === data.template.trim()
    );

    if (!templateSelect) return;

    const { sections: activeSections } = templateSelect;

    const sectionId = activeSections?.[sectionIndex]?.section_id;

    setEvaluatorsData(prevState => [
      ...prevState,
      {
        evaluation_id: scheduleId,
        section_id: sectionId,
        evaluator_id: evaluator.id,
        section_index: sectionIndex
      }
    ]);

    setTimeout(() => {
      if (
        isObjectHasLength(evaluatorsPayload.current) &&
        !isArrayHasLength(selectedIds.current)
      ) {
        removeDefaultEvaluators({
          schedule_id: scheduleId,
          defaultEvaluators: defaultEvaluators.current,
          evaluatorsPayload: evaluatorsPayload.current,
          updatePayload: setEvaluatorsData
        });
      }
    }, 0);

    const selectedEvaluator = allEmployees.find(
      ({ id }) => id === evaluator?.id
    );

    const updateEvaluators = defaultRawData.current.map(scheduleData => {
      if (scheduleData?.id !== scheduleId) return scheduleData;

      const updatedSections = scheduleData.employee_evaluation_sections.map(
        (section, index) => {
          if (sectionIndex !== index) {
            return section;
          }

          return {
            ...section,
            evaluator: {
              id: selectedEvaluator?.id,
              employee_info: {
                first_name: selectedEvaluator?.first_name,
                last_name: selectedEvaluator?.last_name,
                suffix: selectedEvaluator?.suffix
              }
            }
          };
        }
      );

      return {
        ...scheduleData,
        employee_evaluation_sections: updatedSections
      };
    });

    defaultRawData.current = updateEvaluators;
    filteredItems.current = updateEvaluators;
    setEvaluationResultItemList(updateEvaluators);
  }

  function updateRow(scheduleId, closeItem) {
    const evaluatorsPayloadAPI = evaluatorsPayload.current;
    const { template_id: currentTemplateId } = currentTemplateInfo.current;
    const hasEvaluators = isObjectHasLength(evaluatorsPayload.current);
    const items = evaluatorsPayloadAPI[scheduleId];

    if (items === undefined) {
      if (isOverwriteTemplate.current) {
        if (!hasEvaluators) {
          // console.log('UPDATE ROW 1');
          toastNotification('No assigned evaluator on template', 'error');
        } else {
          isOverwriteTemplate.current = false;
        }
      } else {
        const checkSections = defaultRawData.current.find(
          item => item.id === scheduleId
        );
        const hasSections = checkSections?.employee_evaluation_sections?.filter(
          ({ evaluator_id }) => evaluator_id !== null
        ).length;

        if (!hasSections) {
          // console.log('UPDATE ROW 2');
          toastNotification('No assigned evaluator on template', 'error');
        } else {
          // console.log('UPDATE ROW 3');
          setEditCurrentId(null);
          pristineRecordFirstLoad.current = true;
          pristineRecord.current = {
            ...pristineRecord.current,
            isOpen: false,
            data: {},
            original: {},
            maxSections: 0
          };
          closeItem();
        }
      }
      return;
    }

    //! Ensure all sections filled out with evaluators
    const currentRecord = defaultRawData.current.find(
      item => item.id === scheduleId
    );
    const currentRecordSections =
      currentRecord?.employee_evaluation_sections.length;
    const currentPayloadLength = evaluatorsPayloadAPI[scheduleId]?.length;
    const hasNullEvaluators = currentRecord?.employee_evaluation_sections?.some(
      ({ evaluator_id }) => evaluator_id === null
    );

    if (
      hasNullEvaluators &&
      selectedIds.current.length === 0 &&
      currentRecordSections !== currentPayloadLength
    ) {
      // console.log('UPDATE ROW 4');
      toastNotification('No assigned evaluator on template', 'error');
      return;
    }

    let payload = null;

    if (hasEvaluators && items) {
      const sanitizedItems = items.map(({ section_index, ...rest }) => rest);

      payload = [
        {
          evaluation_id: scheduleId,
          template_id: currentTemplateId,
          sections: [...sanitizedItems]
        }
      ];

      if (payload[0].sections.length === 0) {
        // console.log('UPDATE ROW 5');
        closeItem();
        return;
      }

      overwriteTemplatePayload.current = {
        ...overwriteTemplatePayload.current,
        payload,
        closeItem
      };

      selectedScheduleItemPayload.current = {
        ...selectedScheduleItemPayload.current,
        payload,
        closeItem,
        type: 'single'
      };

      let toUpdate = true;
      const item = selectedScheduleItem.current;
      evaluatorsPayload.current[scheduleId].forEach(({ section_index }) => {
        const sections = item?.sections[section_index].criteria_details;
        const hasEntryCount = sections?.filter(
          ({ evaluator_value }) => evaluator_value !== null
        ).length;

        if (hasEntryCount && toUpdate) {
          toUpdate = false;
          handleConfirmModal('ResetScore');
        }
      });

      if (toUpdate) update(payload, closeItem);
    }

    if (Object.keys(evaluatorsPayload.current).length === 1) {
      if (evaluatorsPayload.current[scheduleId].length === 0) {
        handleResetScheduleItem();
        setEvaluatorsData([]);
        // console.log('UPDATE ROW 6');
        closeItem();
      }
    }

    if (!hasEvaluators) {
      handleResetScheduleItem();
      setEvaluatorsData([]);
      // console.log('UPDATE ROW 7');
      closeItem();
    }
  }

  async function update(data, closeItem) {
    try {
      const response = await bulkUpdateEmployeeEvaluationScheduleService(data);

      log(response, {
        name: 'response',
        color: 'green',
        enable: false
      });

      if (response?.success && response?.statusCode === 200) {
        // console.log('SINGLE UPDATE: trackScroll', trackScrollPosition.current);
        toastNotification('Successfully Updated!', 'success');
        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }

        setEditCurrentId(null);

        once.current.onMount = true;

        maxSections.current = {
          ...maxSections.current,
          once: true
        };

        if (maxSections.current.count > maxSections.current.original) {
          maxSections.current.original = maxSections.current.count;
        } else {
          maxSections.current.count = maxSections.current.original;
        }

        isOverwriteTemplate.current = false;
        // setIsPieChartFirstLoad(true);
        handleResetColumns('onUpdate');
        setEvaluatorsData([]);
        getResultDetails('onUpdate');

        pristineRecordFirstLoad.current = true;
        pristineRecord.current = {
          ...pristineRecord.current,
          isOpen: false,
          data: {},
          original: {},
          maxSections: 0
        };
      }

      const errorStatusCodes = [400, 404, 409];
      const { statusCode } = response.response?.data || {};

      if (errorStatusCodes.includes(statusCode)) {
        toastNotification(
          `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
          'error'
        );
        handleResetScheduleItem('onFail');
      }
    } catch (error) {
      console.error('error: ', error);
      toastNotification('Update Failed!', 'error');
    }
    closeItem();
  }

  async function updateOverwriteTemplate(data, closeItem) {
    try {
      const response = await toast.promise(
        bulkUpdateEmployeeEvaluationScheduleService(data),
        {
          pending: 'Overwriting template...',
          // success: {
          //   render: 'New template applied!',
          //   icon: getToastIcon('success')
          // },
          error: {
            render: 'Update failed!',
            icon: getToastIcon('error')
          }
        },
        TOAST_OPTIONS
      );

      log(response, {
        name: 'updateOverwriteTemplate response',
        color: 'green',
        enable: false
      });

      if (response?.success && response?.statusCode === 200) {
        if (scrollPosition) closeModal(1000);

        isOverwriteTemplate.current = false;

        once.current.onMount = true;

        maxSections.current = {
          ...maxSections.current,
          once: true
        };

        if (maxSections.current.count > maxSections.current.original) {
          maxSections.current.original = maxSections.current.count;
        } else {
          maxSections.current.count = maxSections.current.original;
        }

        // setIsPieChartFirstLoad(true);
        // handleResetColumns('onUpdate');
        getResultDetails('onOverwriteSingleRecord');
        setEvaluatorsData([]);
        return;
      }

      const errorStatusCodes = [400, 404, 409];
      const { statusCode } = response.response?.data || {};

      if (errorStatusCodes.includes(statusCode)) {
        toastNotification(
          `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
          'error'
        );
        handleResetScheduleItem('onFail');
      }
    } catch (error) {
      toastNotification('Update Failed!', 'error');
    }
    closeItem();
  }

  async function bulkUpdate(closeItem) {
    let bulkPayload = [];
    const templatesPayload = bulkTemplatesPayload.current;
    const evaluatorsBulkPayload = evaluatorsPayload.current;

    Object.keys(evaluatorsBulkPayload).forEach(payloadScheduleId => {
      const [_, template] = Object.entries(templatesPayload).find(
        ([key]) => key === payloadScheduleId
      );

      const evaluatorSections = evaluatorsBulkPayload[payloadScheduleId].map(
        ({ section_index, ...rest }) => rest
      );

      const item = {
        evaluation_id: +payloadScheduleId,
        template_id: template[0].template_id,
        sections: [...evaluatorSections]
      };

      bulkPayload = [...bulkPayload, item];
    });

    // ! prompt user if no actions were made
    if (
      isArrayHasLength(selectedIds.current) &&
      !isObjectHasLength(evaluatorsBulkPayload)
    ) {
      const matchItems = defaultRawData.current.filter(item =>
        ids.includes(item.id)
      );

      const hasSections = matchItems.some(({ employee_evaluation_sections }) =>
        employee_evaluation_sections.some(
          ({ evaluator_id }) =>
            evaluator_id !== null && evaluator_id !== undefined
        )
      );

      if (!hasSections) {
        // console.log('BULK UPDATE 1');
        toastNotification('No assigned evaluator on template', 'error');
      } else {
        isBulkUpdateFired.current = false;
        pristineRecordFirstLoad.current = true;
        isBulkTemplateSelection.current = false;
        setEditCurrentId(null);
        resetPristineBulkList();
        unSelectIds('unselectItems');
        setScheduleSelectedIds([]);
        selectedIdsCopy.current.data = [];
        isBulkProceed.current = {
          ...isBulkProceed.current,
          data: [],
          count: 0,
          proceed: false,
          maxSections: 0
        };
        closeItem();
        // console.clear();
        // console.log('BULK UPDATE 1: NO ACTIONS MADE');
      }
    }

    //! update new max count from selected template
    maxSections.current = {
      ...maxSections.current,
      original: maxSections.current.count
    };

    let toUpdate = true;
    if (isArrayHasLength(bulkPayload)) {
      const hasDefaultEvaluators = isObjectHasLength(defaultEvaluators.current);
      const allSameTemplateId = bulkPayload.every(
        item => item.template_id === bulkPayload[0].template_id
      );

      if (allSameTemplateId) {
        isBulkTemplateSelection.current = false;
      }

      if (!allSameTemplateId && hasDefaultEvaluators) {
        bulkPayload = mixTemplatesBulkUpdate();
      }

      selectedScheduleItemPayload.current = {
        ...selectedScheduleItemPayload.current,
        payload: bulkPayload,
        closeItem,
        type: 'bulk'
      };

      Object.entries(evaluatorsPayload.current).forEach(
        ([scheduleId, payload]) => {
          const scheduleItem = scheduleResultItems.find(
            item => item.id === Number(scheduleId)
          );

          payload.forEach(({ section_index }) => {
            const sections =
              scheduleItem?.sections[section_index]?.criteria_details;
            const hasEntryCount = sections?.some(
              ({ evaluator_value }) => evaluator_value !== null
            );

            if (hasEntryCount && toUpdate) {
              toUpdate = false;
              handleConfirmModal('ResetScore');
            }
          });
        }
      );

      if (toUpdate) {
        if (allSameTemplateId) {
          //! check if all sections have updated evaluators
          const updatedBulkPayload = pristineRecordList.current.map(item => {
            const evaluationId = item.evaluation_id;
            const payloadSections =
              evaluatorsPayload.current[evaluationId] || [];
            const updatedSections = item.sections.map((section, index) => {
              const matchingPayload = payloadSections.find(
                ({ section_index }) => section_index === index
              );

              if (matchingPayload) {
                const { section_index, ...rest } = section;
                return {
                  ...rest,
                  evaluator_id: matchingPayload.evaluator_id
                };
              }

              return section;
            });

            return {
              ...item,
              sections: updatedSections
            };
          });

          const hasInvalidIds = hasNullOrUndefinedEvaluator(updatedBulkPayload);

          if (hasInvalidIds) {
            toastNotification('No assigned evaluator on template', 'error');
            return;
          }
        }

        if (!allSameTemplateId) {
          const allEvaluatorsNonNull = bulkPayload.every(evaluation =>
            evaluation.sections.every(section => section.evaluator_id !== null)
          );

          if (!allEvaluatorsNonNull) {
            toastNotification('No assigned evaluator on template', 'error');
            return;
          }
        }

        // console.log('BULK UPDATE # # # # # #');
        handleBulkUpdate(bulkPayload, closeItem, 'onBulkUpdate');
        bulkPayload = [];
      }
    }
  }

  function hasNullOrUndefinedEvaluator(recordList) {
    return recordList.some(record =>
      record.sections.some(section => section.evaluator_id == null)
    );
  }

  //! BULK UPDATE
  async function handleBulkUpdate(payload, closeItem, onUpdate) {
    const { pending, success: renderSuccess } = getToastMessages(onUpdate);
    try {
      const response = await toast.promise(
        bulkUpdateEmployeeEvaluationScheduleService(payload),
        {
          pending,
          success: {
            render: renderSuccess,
            icon: getToastIcon('success')
          },
          error: {
            render: 'Update failed!',
            icon: getToastIcon('error')
          }
        },
        TOAST_OPTIONS
      );

      const { success, statusCode: code } = response ?? {};
      const goodResponse = success && code === 200;

      if (
        goodResponse &&
        onUpdate === 'onBulkOverwriteTemplate' &&
        isArrayHasLength(selectedIds.current)
      ) {
        // console.log('BULK OVERWRITE TEMPLATE scrollPosition', scrollPosition);
        // if (scrollPosition) closeModal(1000);
        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }

        once.current.onMount = true;
        // setIsPieChartFirstLoad(true);
        isBulkUpdateFired.current = true;
        isBulkModalTriggered.current = false;

        getResultDetails('onBulkOverwriteTemplate');
        setEvaluatorsData([]);

        selectedIdsCopy.current = {
          ...selectedIdsCopy.current,
          onMount: true,
          data: []
        };

        pristineRecord.current = {
          isOpen: false,
          data: {},
          original: {},
          maxSections: 0
        };
      }

      if (goodResponse && onUpdate === 'onBulkCancel') {
        // console.log('BULK CANCEL trackScroll', trackScrollPosition.current);
        // console.log('BULK CANCEL scrollPosition', scrollPosition);

        // console.log('scrollPosition: ', scrollPosition);
        // if (scrollPosition) closeModal(1100);

        if (trackScrollPosition.current) {
          setTimeout(() => {
            window.scrollTo(0, trackScrollPosition.current);
          }, 1000);
        }

        setEvaluatorsData([]);
        once.current.onMount = true;
        // setIsPieChartFirstLoad(true);
        maxSections.current.once = true;
        getResultDetails('onResetBulkRecord');
        bulkWithNullEvaluator.current = [];

        selectedIdsCopy.current = {
          ...selectedIdsCopy.current,
          onMount: true,
          data: []
        };

        pristineRecordFirstLoad.current = true;
        pristineRecord.current = {
          isOpen: false,
          data: {},
          original: {},
          maxSections: 0
        };

        // [ ]
        resetPristineBulkList();
      }

      if (
        goodResponse &&
        onUpdate !== 'onBulkCancel' &&
        !isBulkTemplateSelection.current
      ) {
        setEditCurrentId(null);
        // toastNotification('Successfully Updated!', 'success');

        setEvaluatorsData([]);
        resetPristineBulkList();
        setScheduleSelectedIds([]);
        once.current.onMount = true;
        // setIsPieChartFirstLoad(true);
        isBulkUpdateFired.current = false;
        isBulkUpdateSuccess.current = true;
        unSelectIds('unselectItems');
        getResultDetails('onBulkUpdate');
        // console.log('NORMAL BULK UPDATE API');
        closeItem();
      }

      const errorStatusCodes = [400, 404, 409];
      const { statusCode } = response.response?.data || {};

      if (errorStatusCodes.includes(statusCode)) {
        toastNotification(
          `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
          'error'
        );
        handleResetScheduleItem('onFail');
      }
    } catch (error) {
      toastNotification('Update Failed!', 'error');
      handleResetScheduleItem('onFail');
    }
  }

  function resetPristineBulkList() {
    pristineBulkList.current = {
      ...pristineBulkList.current,
      firstLoad: true,
      isOpen: false,
      data: [],
      original: [],
      maxSections: 0,
      selectedIds: []
    };
  }

  function mixTemplatesBulkUpdate() {
    //! Filter selected evaluators
    const filteredEvaluators = Object.fromEntries(
      Object.entries(defaultEvaluators.current).filter(([key]) =>
        selectedIds.current.includes(parseInt(key, 10))
      )
    );

    //! Filter selected templates
    const filteredTemplates = Object.fromEntries(
      Object.entries(defaultTemplates.current).filter(([key]) =>
        selectedIds.current.includes(parseInt(key, 10))
      )
    );

    //! Prepare mixed template payload
    const mixTemplatePayload = Object.keys(filteredEvaluators).map(
      evaluation_id => {
        const evaluators = filteredEvaluators[evaluation_id] || [];
        const template = filteredTemplates[evaluation_id]?.[0] || {};
        const updatedSections = evaluators.map(evaluator => ({
          section_id: evaluator.section_id,
          evaluator_id: evaluator.evaluator_id
        }));

        return {
          evaluation_id: +evaluation_id,
          template_id: template.template_id || null,
          sections: updatedSections
        };
      }
    );

    //! Update payload with selected evaluators
    const updatedBulkPayload = mixTemplatePayload.map(item => {
      const evaluationId = item.evaluation_id;
      const payloadSections = evaluatorsPayload.current[evaluationId] || [];
      const updatedSections = item.sections.map((section, index) => {
        const matchingPayload = payloadSections.find(
          ({ section_index }) => section_index === index
        );

        if (matchingPayload) {
          const { section_index, ...rest } = section;
          return {
            ...rest,
            evaluator_id: matchingPayload.evaluator_id
          };
        }

        return section;
      });

      return {
        ...item,
        sections: updatedSections
      };
    });

    log(updatedBulkPayload, {
      name: 'bulkPayload',
      color: 'green',
      enable: false
    });

    return updatedBulkPayload;
  }

  function allSectionsSameCount(records) {
    if (!records.length) return { maxCount: 0, allSame: true };
    const standardCount = records[0].employee_evaluation_sections.length;
    const allSame = records.every(record => {
      const currentCount = record.employee_evaluation_sections.length;
      return currentCount === standardCount;
    });
    const maxCount = records.reduce((count, record) => {
      const currentCount = record.employee_evaluation_sections.filter(
        section => section.id !== null
      ).length;
      return Math.max(count, currentCount);
    }, 0);
    return { maxCount, allSame };
  }

  function getDefaultBulkItems() {
    let mixTemplatePayload = [];
    const filteredEvaluators = Object.fromEntries(
      Object.entries(defaultEvaluators.current).filter(([key]) =>
        selectedIds.current.includes(parseInt(key, 10))
      )
    );
    const filteredTemplates = Object.fromEntries(
      Object.entries(defaultTemplates.current).filter(([key]) =>
        selectedIds.current.includes(parseInt(key, 10))
      )
    );
    const checkNullEvaluator = defaultRawData.current.some(
      ({ employee_evaluation_sections }) =>
        employee_evaluation_sections.some(
          ({ evaluator_id }) => evaluator_id === null
        )
    );

    if (checkNullEvaluator) {
      Object.keys(filteredTemplates).forEach(templateScheduleId => {
        const [_, evaluatorPayload] = Object.entries(filteredEvaluators).find(
          ([key]) => key === templateScheduleId
        ) || [null, []]; // If not found, default to an empty array.
        // Create evaluatorSections based on whether evaluatorPayload is empty or not
        const evaluatorSections =
          evaluatorPayload.length > 0
            ? evaluatorPayload.map(({ section_id, evaluator_id }) => ({
                section_id: evaluator_id && section_id, // set section_id to null if evaluator_id is null
                evaluator_id
              }))
            : Array.from({ length: maxSections.current.count }, () => ({
                section_id: null,
                evaluator_id: null
              }));

        const item = {
          evaluation_id: +templateScheduleId,
          template_id: filteredTemplates[templateScheduleId][0].template_id,
          sections: evaluatorSections
        };

        mixTemplatePayload = [...mixTemplatePayload, item];
      });
      bulkWithNullEvaluator.current = mixTemplatePayload;
    } else {
      mixTemplatePayload = Object.keys(filteredEvaluators).map(
        evaluation_id => {
          const evaluators = filteredEvaluators[evaluation_id] || [];
          const template = filteredTemplates[evaluation_id]?.[0] || {};
          const updatedSections = evaluators.map(evaluator => ({
            section_id: evaluator.section_id,
            evaluator_id: evaluator.evaluator_id
          }));

          return {
            evaluation_id: +evaluation_id,
            template_id: template.template_id || null,
            sections: updatedSections
          };
        }
      );
    }

    pristineRecordList.current = mixTemplatePayload;
  }

  function getToastIcon(toastType) {
    switch (toastType) {
      case 'success':
        return <CgCheckO />;
      case 'error':
        return <MdOutlineErrorOutline />;
      default:
        return <MdOutlineErrorOutline />;
    }
  }

  function getToastMessages(onUpdate) {
    const toastMessages = {
      onBulkOverwriteTemplate: {
        pending: 'Overwriting templates...',
        success: 'Templates overwritten successfully!'
      },
      onBulkCancel: {
        pending: 'Cancelling bulk updates...',
        success: 'Bulk updates cancelled successfully!'
      },
      default: {
        pending: 'Updating evaluators...',
        success: 'Successfully Updated!'
      }
    };
    return toastMessages[onUpdate] || toastMessages.default;
  }

  function toastNotification(message, type) {
    toast[type](message, {
      ...TOAST_OPTIONS,
      icon: getIcon(type),
      toastId: evaluationResultId
    });

    function getIcon(toastType) {
      switch (toastType) {
        case 'success':
          return <CgCheckO />;
        case 'error':
          return <MdOutlineErrorOutline />;
        default:
          return <MdOutlineErrorOutline />;
      }
    }
  }

  useEffect(() => {
    isPieChartFirstLoadRef.current = isPieChartFirstLoad;
  }, [isPieChartFirstLoad]);

  useEffect(() => {
    if (evaluatorsData.length === 0) {
      evaluatorsPayload.current = {};
    } else {
      evaluatorsPayload.current = {
        ...evaluatorsPayload.current,
        ...payloadDictionary
      };
    }
    log(evaluatorsPayload.current, {
      name: 'Evaluators Payload:',
      color: 'red',
      enable: false
    });
  }, [evaluatorsData]);

  useEffect(() => {
    defaultEvaluators.current = {
      ...defaultEvaluators.current,
      ...defaultEvaluatorsDictionary
    };
    log(defaultEvaluators.current, {
      name: 'Default Evaluators:',
      color: 'blue',
      enable: false
    });
  }, [defaultEvaluatorsData.current]);

  useEffect(() => {
    if (defaultTemplatesRaw.length === 0) {
      defaultTemplates.current = {};
    } else {
      defaultTemplates.current = {
        ...defaultTemplates.current,
        ...defaultTemplatesDictionary
      };
    }

    log(defaultTemplates.current, {
      name: 'Default Templates:',
      color: 'blue',
      enable: false
    });
  }, [defaultTemplatesRaw]);

  useEffect(() => {
    if (bulkTemplatesData.length === 0) {
      bulkTemplatesPayload.current = {};
    } else {
      bulkTemplatesPayload.current = {
        ...bulkTemplatesPayload.current,
        ...templatesPayloadDictionary
      };
    }

    log(bulkTemplatesPayload.current, {
      name: 'Templates Payload:',
      color: 'orange',
      enable: false
    });

    log(isEqual(bulkTemplatesPayload.current, defaultTemplates.current), {
      name: 'isSame (template) [default = payload]',
      color: 'green',
      enable: false
    });
  }, [bulkTemplatesData]);

  useEffect(() => {
    if (maxSections.current.count) generateColumns(maxSections.current.count);
  }, [scheduleResultItems]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      once.current.onFilter &&
      !isBulkUpdateSuccess.current &&
      !searchParams.has('unselectItems')
    ) {
      once.current.onFilter = false;
      setIsPieChartFirstLoad(true);
      getResultDetails('onFilter');
    }
    if (searchParams.has('unselectItems')) {
      searchParams.delete('unselectItems');
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
    log(once.current, {
      name: 'once.current:',
      color: 'orange',
      enable: false
    });
  }, [location.search]);

  useEffect(() => {
    selectedIds.current = ids;
    checkSelectedIds(selectedIds.current);
    activeCancelledStatus(selectedIds.current);

    selectedIdsCopy.current.onMount = true;
    if (selectedIds.current.length === 0) setScheduleSelectedIds([]);

    getDefaultBulkItems();

    if (ids.length > 1) {
      setScrollPosition(window.scrollY);
      pristineRecordFirstLoad.current = true;
      pristineRecord.current = {
        isOpen: false,
        data: {},
        original: {},
        maxSections: 0
      };
    }
  }, [ids, selectedIds.current]);

  useEffect(() => {
    if (
      showOverwriteTemplateModal === 'OverwriteTemplate' &&
      isArrayHasLength(selectedIdsCopy.current.data)
    ) {
      isBulkModalTriggered.current = true;
    } else {
      isBulkModalTriggered.current = false;
    }
  }, [
    defaultRawData.current,
    showOverwriteTemplateModal,
    selectedIdsCopy.current.data
  ]);

  useEffect(() => {
    unSelectIds('unselectItems');
    closeEditItem.current.closeItem();

    if (!isEqual(defaultRawData.current, pristineData.current)) {
      handleResetScheduleItem('onSubmitReset');
    }

    return () => {
      // Cleanup hris-evaluation-schedule-item-search-params
      localStorage.setItem('hris-evaluation-schedule-item-search-params', '');
      setEvaluatorsData([]);
      setDefaultTemplatesRaw([]);
      defaultEvaluators.current = {};
    };
  }, [navigate]);

  useEffect(() => {
    const searchParamsQ = new URLSearchParams(location.search);
    const loadedParams = {};

    searchParamsQ.forEach((value, key) => {
      loadedParams[key] = value;
    });
    if (once.current.onMount) getResultDetails('onMount');
    if (templateId) getEvaluationInfo(templateId);
    pushQuery(loadedParams);
    setIsFirstLoad(false);
    return () => {
      // Cleanup hris-evaluation-schedule-item-search-params
      localStorage.setItem('hris-evaluation-schedule-item-search-params', '');
      setEvaluatorsData([]);
      defaultEvaluators.current = {};
    };
  }, []);

  useEffect(() => {
    isFirstLoadRef.current = isFirstLoad;
  }, [isFirstLoad]);

  useEffect(() => {
    setIsFieldDisabledOnActive(
      viewOnly || templateFields?.template_status === 'A'
    );
  }, [templateFields, viewOnly]);

  useEffect(() => {
    // console.log(
    //   'isModalOpen:',
    //   isModalOpen,
    //   new Date().toLocaleTimeString('en-US', {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hour12: true
    //   })
    // );

    // console.log(
    //   'scrollPosition:',
    //   scrollPosition,
    //   new Date().toLocaleTimeString('en-US', {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hour12: true
    //   })
    // );

    if (isModalOpen && scrollPosition) {
      // console.log(
      //   'isModalOpen && scrollPosition: ',
      //   isModalOpen && scrollPosition
      // );
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 50);
    }

    if (scrollPosition) {
      trackScrollPosition.current = scrollPosition;
      // console.log('trackScrollPosition.current: ', trackScrollPosition.current);
    }
  }, [scrollPosition, trackScrollPosition.current]);

  // useEffect(
  //   () => {
  //     log(maxSections.current, {
  //       name: 'maxSections:',
  //       color: 'blue',
  //       enable: false
  //     });
  //     log(selectedIds.current, {
  //       name: 'selectedIds:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(isBulkModalTriggered.current, {
  //       name: 'isBulkModalTriggered:',
  //       color: 'red',
  //       enable: false
  //     });
  //     log(isBulkUpdateFired.current, {
  //       name: 'isBulkUpdateFired:',
  //       color: 'purple',
  //       enable: false
  //     });
  //     log(selectedIdsCopy.current, {
  //       name: 'selectedIdsCopy:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(scheduleSelectedIds, {
  //       name: 'scheduleSelectedIds:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(isBulkTemplateSelection.current, {
  //       name: 'isBulkTemplateSelection',
  //       color: 'violet',
  //       enable: false
  //     });
  //     log(isOverwriteTemplate.current, {
  //       name: 'isOverwriteTemplate',
  //       color: 'violet',
  //       enable: false
  //     });
  //     log(pristineRecord.current, {
  //       name: 'pristineRecord:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(pristineRecordList.current, {
  //       name: 'pristineRecordList:',
  //       color: 'navy',
  //       enable: false
  //     });
  //     log(pristineBulkList.current, {
  //       name: 'pristineBulkList:',
  //       color: 'deepskyblue',
  //       enable: false
  //     });
  //     log(pristineRecordFirstLoad.current, {
  //       name: 'pristineRecordFirstLoad:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(isPieChartFirstLoadRef.current, {
  //       name: 'isPieChartFirstLoadRef:',
  //       color: 'green',
  //       enable: false
  //     });
  //     log(evaluationProgress, {
  //       name: 'evaluationProgress:',
  //       color: 'limegreen',
  //       enable: false
  //     });
  //     log(isBulkProceed.current, {
  //       name: 'isBulkProceed:',
  //       color: 'purple',
  //       enable: false
  //     });
  //     log(cancelCount.current, {
  //       name: 'cancelCount:',
  //       color: 'purple',
  //       enable: false
  //     });
  //   },
  //   [
  //     // scheduleSelectedIds,
  //     // cancelCount.current,
  //     // maxSections.current,
  //     // selectedIds.current,
  //     // pristineRecord.current,
  //     // isOverwriteTemplate.current,
  //     // defaultRawData.current,
  //     // selectedIdsCopy.current,
  //     // handleResetScheduleItem,
  //     // pristineBulkList.current,
  //     // isBulkUpdateFired.current,
  //     // pristineRecordList.current,
  //     // isBulkProceed.current,
  //     // isBulkModalTriggered.current,
  //     // pristineRecordFirstLoad.current,
  //     // isBulkTemplateSelection.current,
  //     // isPieChartFirstLoadRef.current,
  //     // evaluationProgress
  //   ]
  // );

  const actions =
    isScheduleItemFulfilled || isScheduleItemCancelled ? [] : ['editRow'];

  return {
    // * identifiers
    ids,
    form,
    inputs,
    actions,
    columns,
    templateFields,
    totalResultItems,
    showStartEvaluationModal,
    evaluationStarted,
    isScheduleItemActive,
    isScheduleItemOnhold,
    isResultItemsGenerated,
    hasActiveCancelledItems,
    isScheduleNameExist,
    scheduleResultItems,
    generateExclude,
    isScheduleItemFulfilled,
    hasData,
    editCurrentId,
    validationTemplateSchema,
    initialDummyData,
    updateFormError,
    isFieldDisabledOnActive,
    evaluationProgress,
    queryString,
    showResetScoreModal,
    showOverwriteTemplateModal,
    scheduleSelectedIds,
    // * functions
    handleChange,
    submitFilter,
    handleUpdateRow,
    getEvaluationInfo,
    handleDateOnChange,
    handleConfirmModal,
    handleScheduleSubmit,
    handleCloseEvaluation,
    handleStartEvaluation,
    getScheduleItemDetail,
    handleExcludeEvaluation,
    handleCloseConfirmModal,
    handleResetScheduleItem,
    handleGenerateEvaluation,
    updateSortedData,
    handleResetScore,
    handleOverwriteTemplate,
    handleCancelOverwriteTemplate,
    setSortType
  };
};

export default useEvaluationSchedule;
