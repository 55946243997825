/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import PropTypes from 'prop-types';
import React from 'react';
import ColorPicker from 'src/components/Common/ColorPicker';
import { Formik, Form, ErrorMessage } from 'formik';
import { useAddEditModal } from 'src/hooks/components/Parts/Projects/useAddEditModal';

import FormGridCard from 'src/components/Common/FormGridCard';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Loading from 'src/components/Common/loading';
import 'src/assets/css/input.scss';

const ViewEditModal = ({ handleModal, projectId, dataList, menuCode }) => {
  const {
    employeeRef,
    formData,
    searchEmployee,
    industryOptions,
    validationSchema,
    projectRoleOptions,
    statusOptions,
    isEdit,
    handleColorChange,
    setIsEdit,
    handleChange,
    handleSubmit,
    handleAddMember,
    handSelectChange,
    handleSearchMember,
    handleUpdateMember,
    handleDeleteMember,
    formLoading,
    selectedItemIndex,
    handleKeyPress
  } = useAddEditModal({ handleModal, projectId, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError,
        values
      }) => (
        <Form id="userForm" onKeyPress={handleKeyPress}>
          {formLoading && <Loading />}
          <div className="flex flex-row place-content-between mx-[35px]">
            <h4 className="text-[22px] w-[394px] font-bold leading-[27px] pb-5">
              Project Informations
            </h4>
            <div className="flex">
              <ColorPicker
                name="color_hex"
                value={formData.color_hex}
                onChange={handleColorChange}
                disabled={!isEdit || formLoading}
              />
              <div className="">
                {isEdit ? (
                  <button
                    type="submit"
                    className={`${
                      formLoading && 'opacity-50'
                    } text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded`}
                    disabled={formLoading}
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                      Save
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`text-[12px] text-white ${
                      !access.can_edit
                        ? 'bg-gray-400/20 '
                        : 'bg-gray-400 hover:bg-gray-500'
                    }  font-normal leading-[100%] border-none p-[14px_19px] rounded`}
                    onClick={e => {
                      e.preventDefault();
                      setIsEdit(!isEdit);
                    }}
                    disabled={!access.can_edit}
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                      Edit
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className={` ${
              formLoading ? 'opacity-0' : 'opacity-100'
            } w-full overflow-scroll flex flex-col justify-between grow`}
          >
            <div className="px-[35px] mt-5 overflow-auto flex-col">
              <FormGridCard cols="2">
                <FormCard>
                  <FormField
                    className="capitalize"
                    label="Client"
                    required
                    name="client"
                    type="text"
                    placeholder=""
                    error={errors?.client}
                    value={formData.client ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    disabled={projectId && !isEdit}
                  />
                  {errors.client !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.client}
                    </div>
                  )}
                </FormCard>
                <FormCard>
                  <FormField
                    className="capitalize"
                    label="Project Name"
                    required
                    name="name"
                    type="text"
                    placeholder=""
                    // errorMessage="Field Required"
                    error={errors?.name}
                    value={formData.name ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    disabled={projectId && !isEdit}
                  />
                  {errors.name !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.name}
                    </div>
                  )}
                </FormCard>
              </FormGridCard>
              <FormGridCard cols="1">
                <FormCard>
                  <FormField
                    className="capitalize"
                    label="Project Description"
                    required
                    name="description"
                    type="text"
                    placeholder=""
                    // errorMessage="Field Required"
                    error={errors?.description}
                    value={formData.description ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    disabled={projectId && !isEdit}
                  />
                  {errors.description !== 'Required' && (
                    <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                      {errors.description}
                    </div>
                  )}
                </FormCard>
              </FormGridCard>
              <div className="flex flex-row gap-[17px]">
                <div className="flex-1">
                  <FormField
                    label="Start Date"
                    required
                    name="start_date"
                    type="date"
                    placeholder=""
                    errorMessage="Field Required"
                    error={errors?.start_date}
                    value={formData.start_date ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    disabled={projectId && !isEdit}
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    label="End Date"
                    required
                    name="end_date"
                    type="date"
                    placeholder=""
                    errorMessage="Field Required"
                    error={errors?.end_date}
                    value={formData.end_date ?? ''}
                    onChange={e =>
                      handleChange(
                        e,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                    }
                    disabled={projectId && !isEdit}
                  />
                  {errors.end_date !== 'Required' && (
                    <ErrorMessage
                      name="end_date"
                      component="div"
                      className="text-[10px] text-[#E43B26] font-stolzlBook mt-1"
                    />
                  )}
                </div>
                <div className="flex-1">
                  <FormCard>
                    {formData.status && (
                      <FormField
                        label="Status"
                        type="combobox"
                        required
                        placeholder="Select Status"
                        options={statusOptions}
                        onChangeValue={value =>
                          handSelectChange(
                            value,
                            'status',
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        selectedValue={formData.status}
                        selectName="status"
                        disabled={projectId && !isEdit}
                        error={errors?.status && touched.status}
                      />
                    )}
                  </FormCard>
                </div>
                <div className="flex-1">
                  <FormCard>
                    {formData.industry_id ? (
                      <FormField
                        label="Industry Type"
                        type="combobox"
                        required
                        options={industryOptions}
                        onChangeValue={value =>
                          handSelectChange(
                            value,
                            'industry_id',
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        selectedValue={formData.industry_id}
                        selectName="industry_id"
                        disabled={projectId && !isEdit}
                        error={errors?.industry_id && touched.industry_id}
                      />
                    ) : null}
                  </FormCard>
                </div>
              </div>
              <div className="mb-[31px]">
                <div className="mb-4">
                  <label
                    className="block mb-2.5 text-[12px] text-[#414141] text-xs font-normal leading-[14px] font-stolzlBook"
                    htmlFor="search_members"
                  >
                    Members
                    <div className="relative">
                      <input
                        className={`block w-full border-solid border-[1px] mt-2.5 ${
                          errors.search_members
                            ? 'border-[#E43B26] placeholder:text-[#f19d94]'
                            : 'border-[#eaeaea] text-[14px]'
                        } rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[12px_13px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                        ref={employeeRef}
                        type="text"
                        name="search_members"
                        id="search_members"
                        placeholder="Search"
                        autoComplete="off"
                        disabled={projectId && !isEdit}
                        onChange={e =>
                          handleSearchMember(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        onKeyDown={event => {
                          handleAddMember(
                            event,
                            '',
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          );
                        }}
                      />
                      {searchEmployee.length ? (
                        <div className="absolute left-0 top-[calc(100%+2px)] w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                          <ul className="max-h-[123px] overflow-auto custom-scrollbar dropdown">
                            {searchEmployee.map((item, index) => (
                              <li
                                key={index}
                                className={`flex items-center text-[14px] text-[#222222] font-normal leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 capitalize font-stolzlBook ${
                                  index === selectedItemIndex ? 'selected' : ''
                                } `}
                                role="button"
                                tabIndex={0}
                                onClick={event =>
                                  handleAddMember(
                                    event,
                                    item,
                                    setFieldValue,
                                    setFieldTouched,
                                    setFieldError
                                  )
                                }
                              >
                                {item.first_name} {item.last_name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </label>
                  {values.project_members.length === 0 && (
                    <div className="text-[10px] text-[#E43B26]">
                      {errors.project_members}
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-center gap-[5px] border-solid border-b-[1px] border-[#eaeaea] pb-2.5 mb-[18px]">
                  <div className="basis-[25%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Name
                    </h5>
                  </div>
                  <div className="basis-[28%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Project Role
                    </h5>
                  </div>
                  <div className="basis-[6%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Lead
                    </h5>
                  </div>
                  <div className="basis-[10%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Work Load
                    </h5>
                  </div>
                  <div className="basis-[34%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Assignment Duration
                    </h5>
                  </div>
                  <div className="flex-none w-5" />
                </div>
                <div className="h-[200px] overflow-auto custom-scrollbar">
                  {formData?.project_members?.length ? (
                    formData.project_members.map((member, index) => (
                      <div
                        className="flex flex-row items-center gap-[5px] mb-[3px]"
                        key={member.employee?.employee_info.employee_id}
                      >
                        <div className="basis-[25%]">
                          <p className="text-[14px] text-[#232932] font-normal leading-[17px] font-stolzlBook capitalize">
                            {`${member.employee?.employee_info.first_name} ${member.employee?.employee_info.last_name}`}
                          </p>
                        </div>
                        <div className="basis-[28%] mb-[-25px]">
                          <FormCard className="projects_select">
                            {projectRoleOptions.length && (
                              <FormField
                                type="combobox"
                                name="project_role_id"
                                options={projectRoleOptions}
                                onChangeValue={value =>
                                  handleUpdateMember(
                                    {
                                      target: { name: 'project_role_id', value }
                                    },
                                    member.employee?.employee_info.employee_id,
                                    index
                                  )
                                }
                                selectedValue={member.project_role_id ?? ''}
                                selectName="project_role_id"
                                disabled={projectId && !isEdit}
                                errors={
                                  errors?.project_role_id &&
                                  touched.project_role_id
                                }
                              />
                            )}
                          </FormCard>
                        </div>
                        <div className="basis-[6%]">
                          <label
                            className="custom__checkbox inline-block align-top text-[0] cursor-pointer ml-[2px]"
                            htmlFor={`is_project_lead_${member.employee?.employee_info.employee_id}`}
                          >
                            <input
                              type="checkbox"
                              name="is_project_lead"
                              id={`is_project_lead_${member.employee?.employee_info.employee_id}`}
                              checked={member.is_project_lead}
                              disabled={projectId && !isEdit}
                              onChange={event =>
                                handleUpdateMember(
                                  event,
                                  member.employee?.employee_info.employee_id,
                                  index
                                )
                              }
                              hidden
                            />
                            <span className="inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative">
                              <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded" />
                              <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded" />
                            </span>
                          </label>
                        </div>
                        <div className="basis-[10%]">
                          <input
                            className={`focus:outline-none focus:border-[#000] inline-block w-[45px] border-solid border-[1px] rounded text-[14px] text-[#232932] font-normal leading-[17px] h-7 pl-2 pr-3 font-stolzlBook text-xs ${
                              errors.project_members?.[index]?.resource_perc &&
                              touched.project_members?.[index]?.resource_perc
                                ? 'border-[#E43B26] placeholder-[#E43B26]'
                                : 'border-[#eaeaea] text-[14px]'
                            }`}
                            type="number"
                            name="resource_perc"
                            value={member.resource_perc}
                            disabled={projectId && !isEdit}
                            onChange={event =>
                              handleUpdateMember(
                                event,
                                member.employee_id,
                                index
                              )
                            }
                          />
                          <label className="inline-block font-stolzlBook text-[12px] pl-0.5">
                            %
                          </label>
                          <ErrorMessage
                            name={`project_members[${index}].resource_perc`}
                            component="div"
                            className="text-[10px] text-[#E43B26] mt-1"
                          />
                        </div>
                        <div className="basis-[34%] flex flex-row items-center place-content-between">
                          <div>
                            <input
                              className={`focus:outline-none focus:border-[#000] block w-[110px] border-solid border-[1px] rounded text-[#232932] font-normal leading-[17px] h-7 pl-2 pr-3 font-stolzlBook text-xs ${
                                errors.project_members?.[index]
                                  ?.assignment_end_date &&
                                touched.project_members?.[index]
                                  ?.assignment_end_date
                                  ? 'border-[#E43B26] placeholder-[#E43B26]'
                                  : 'border-[#eaeaea] text-[14px]'
                              }`}
                              type="date"
                              name="assignment_start_date"
                              value={
                                member.assignment_start_date
                                  ? member.assignment_start_date
                                  : formData.start_date
                              }
                              onChange={event =>
                                handleUpdateMember(
                                  event,
                                  member.employee?.employee_info.employee_id,
                                  index
                                )
                              }
                              disabled={projectId && !isEdit}
                            />
                            <ErrorMessage
                              name={`project_members[${index}].assignment_start_date`}
                              component="div"
                              className="text-[10px] text-[#E43B26] mt-1"
                            />
                          </div>
                          -
                          <div>
                            <input
                              className={`focus:outline-none focus:border-[#000] block w-[110px] border-solid border-[1px] rounded text-[#232932] font-normal leading-[17px] h-7 pl-2 pr-3 font-stolzlBook text-xs ${
                                errors.project_members?.[index]
                                  ?.assignment_end_date &&
                                touched.project_members?.[index]
                                  ?.assignment_end_date
                                  ? 'border-[#E43B26] placeholder-[#E43B26]'
                                  : 'border-[#eaeaea] text-[14px]'
                              }`}
                              type="date"
                              name="assignment_end_date"
                              value={
                                member.assignment_end_date
                                  ? member.assignment_end_date
                                  : formData.end_date
                              }
                              onChange={event =>
                                handleUpdateMember(
                                  event,
                                  member.employee?.employee_info.employee_id,
                                  index
                                )
                              }
                              disabled={projectId && !isEdit}
                            />
                            <ErrorMessage
                              name={`project_members[${index}].assignment_end_date`}
                              component="div"
                              className="text-[10px] text-[#E43B26] mt-1"
                            />
                          </div>
                        </div>
                        <div className="flex-none w-5">
                          <button
                            type="button"
                            className="relative inline-block align-super w-4 h-3.5"
                            disabled={projectId && !isEdit}
                            onClick={() =>
                              handleDeleteMember(
                                member.employee?.employee_info.employee_id
                              )
                            }
                          >
                            <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-45 rounded" />
                            <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-[-45deg] rounded" />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      No employee selected.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ViewEditModal.propTypes = {
  handleModal: PropTypes.func,
  projectId: PropTypes.number,
  dataList: PropTypes.instanceOf(Object),
  menuCode: PropTypes.string
};

export default ViewEditModal;
