import React from 'react';
import { useNavigate } from 'react-router-dom';
// Common components
import { Breadcrumbs, ModalCenter } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useQuestionIndex from 'src/hooks/Pages/LearningManagement/Question/useQuestionIndex';

import { BULK_DELETE_QUESTIONS } from 'src/api/endpoints';
import BulkDeleteQuestionModal from './components/BulkDeleteQuestionModal';
import DeleteQuestionModal from './components/DeleteQuestionModal';

const Index = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    questionListMemo,
    hasMore,
    questionColumns,
    showDeleteModal,
    showModal,
    form,
    submitFilter,
    handleModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs,
    questionTitle,
    modalQuestionId
  } = useQuestionIndex(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Learning Management' }, { name: 'Questions' }]}
      />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            buttonName="Question"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => navigate(`/questions/add`)}
            bulkDeleteClick={() =>
              handleBulkDeleteModal('BulkDeleteQuestionModal')
            }
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={questionListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {!questionListMemo.length && <b>No records found</b>}
                {questionListMemo.length >= 20 && <b>No more data to load</b>}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={questionListMemo || []}
              clickableRows={false}
              headingColumns={questionColumns}
              title="Question"
              actions={['view', 'delete']}
              actionLinks={{
                view: `/questions/view`
              }}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteQuestionModal"
              keyField="id"
              shouldEllipsis
              ellipsisModifier="max-w-[320px]"
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteQuestionModal">
        {showDeleteModal && (
          <DeleteQuestionModal
            handleDeleteModal={handleDeleteModal}
            id={modalQuestionId}
            name={questionTitle}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter
        showModal={showBulkDeleteModal}
        modalName="BulkDeleteQuestionModal"
      >
        {showBulkDeleteModal && (
          <BulkDeleteQuestionModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={BULK_DELETE_QUESTIONS}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
