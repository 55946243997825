/* eslint-disable  prettier/prettier */
/* eslint-disable  prefer-template */

import { React, useState, useEffect } from 'react';
import FormField from 'src/components/Common/FormField';
import useDTR from 'src/hooks/components/Parts/Dashboard/useDTR';
import { toast } from 'react-toastify';


const ClockInOut = () => {

  const { timeIn, handleTimeRecord, optionList, loggedUser, currentProject, setCurrentProject, myShiftId, myShift } = useDTR();
  const time = new Date().toLocaleTimeString('default', { hour12: false });
  const [currentTime, setCurrentTime] = useState(time);
  const [currentDate, setCurrentDate] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const timeText = timeIn ? 'Clock In' : 'Clock Out';
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (value) => {
    if (value) {
      setSelectedProject(value);
    }
  };

  const handleClick = async () => {
    setIsSubmitting(true);

    try {
      await handleTimeRecord({
        employee_id: loggedUser.employee.id,
        project_id: selectedProject,
        shift_id: myShiftId
      });
    } catch (error) {
      toast.error(`Error submitting data: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const t = new Date().toLocaleTimeString('default', { hour12: false });
      setCurrentTime(t);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let date = new Date();
    const dayName = date.toLocaleString('en-us', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    date = `${dayName}, ${month} ${day}, ${year}`;
    setCurrentDate(date);
  }, [])

  useEffect(() => {
    setSelectedProject(currentProject)
  }, [currentProject]);

  return (
    <div className="mb-[20px] p-[16px] pb-[25px] lg:pt-[20px] lg:pb-[20px] border-solid border-[1px] border-[#eeeeee] rounded-md">
      <div className='bg-gradient-to-b  from-[#6B6B6B] lg:min-w-[250px] to-[#222222] rounded-md text-center px-[10px] pt-0 pb-[10px] lg:pt-[7px] lg:pb-[16px] text-white'>
        <h1 className='text-[50px] font-bold clock-text'> {currentTime}</h1>
        <p className='text-[16px] lg:text-[12px]'>{currentDate}</p>
        <p className='text-[12px] lg:text-[10px] font-stolzlBook'>(Shift {myShift})</p>
      </div>
      <div className='mt-[20px]'>
        <div className='relative'>
          <FormField
            type="select"
            placeholder="Select Project"
            options={optionList}
            onChangeValue={handleChange}
            selectedValue={selectedProject}
            selectName="projects"
            disabled={currentProject !== null}
          />
        </div>
      </div>
      <div className='mt-[20px] text-white text-center'>
        <button
          type="button"
          onClick={handleClick}
          className={`w-full rounded-md p-[8px] lg:p-[5px] ${selectedProject ? 'bg-[#000000]' : ' bg-[#c9c9c9]'}`}
          disabled={isSubmitting || !selectedProject}
        >
          <img
            className="inline-block h-[15px] m-[4px]"
            src="/icons/clock-in-out.svg"
            alt=""
          />

          {timeText}
        </button>
      </div>
    </div>
  );
};

export default ClockInOut;