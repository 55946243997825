import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDepartment from 'src/hooks/Pages/CostCenter/Department/useDepartment';
import PropTypes from 'prop-types';
import {
  SlideModal,
  ModalCenter,
  PageTitle,
  SearchFilter,
  Breadcrumbs
} from 'src/components/Common';
import { BULK_DELETE_DEPARTMENTS } from 'src/api/endpoints';
import { deleteBulkCostCenter } from 'src/api/modules/costcenter';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import { useUserAccess } from 'src/hooks/useUserAccess';
import BulkDeleteModal from '../BulkDeleteModal';
import AddEditModal from './AddEditModal';
import DeleteModal from './DeleteModal';

const Index = ({ menuCode }) => {
  const {
    departmentList,
    departmentListMemo,
    hasMore,
    form,
    departmentColumns,
    showModal,
    showDeleteModal,
    uniqueCode,
    uniqueCodeName,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    submitFilter,
    showBulkDeleteModal
  } = useDepartment();
  const { menus } = useUserAccess();

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'Cost Center' }, { name: 'Department' }]} />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Code, Name, Head"
            buttonName="Department"
            buttonLink={false}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={departmentListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {departmentListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={departmentListMemo || []}
              clickableRows={false}
              headingColumns={departmentColumns}
              title="Department"
              actions={['edit', 'delete']}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              // keyField="id"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
              isCostCenter
              codeField="department_code"
            />
          </InfiniteScroll>
        </div>
      </div>
      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            uniqueCode={uniqueCode}
            dataList={departmentList}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            code={uniqueCode}
            name={uniqueCodeName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={BULK_DELETE_DEPARTMENTS}
            apiService={deleteBulkCostCenter}
            codeType="department_code"
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
