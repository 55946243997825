/* eslint-disable no-param-reassign */
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLeaveTypeService,
  getLeaveTypeDataItems,
  deleteLeaveTypeService
} from 'src/api/modules/leaveType';
import {
  getLowerCasedValue,
  autoCapitalize,
  filterData
} from 'src/helpers/utils';
import { LEAVE_STATUS_OPTIONS, TOAST_OPTIONS } from 'src/helpers/constants';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { getLeaveTypes } from 'src/redux/modules/LeaveTypes/LeaveTypesActions';

const useLeaveType = () => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const dataList = useSelector(state => state.leaves.leaves.items);
  const [showModal, setShowModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showViewModal, setShowViewModal] = useState(null);
  const [leaveTypeId, setleaveTypeId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [deleteId, setDeleteId] = useState({ id: undefined, leave_code: null });
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const [page, setPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  const leaveTypeColumns = [
    { key: 'id', label: 'ID' },
    { key: 'leave_code', label: 'Leave Code' },
    { key: 'description', label: 'Description' },
    { key: 'minimum_hours', label: 'Min Applicable Hours' },
    { key: 'is_payable', label: 'Payable' },
    { key: 'is_document_required', label: 'Document Required' },
    { key: 'status', label: 'Status' }
  ];

  const selectedStatuses = localStorage.getItem(
    'hris-evaluation-template-selected-statuses'
  );
  const setStatusFilter = value => {
    localStorage.setItem('hris-evaluation-template-selected-statuses', value);
  };

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(LEAVE_STATUS_OPTIONS, 'label', 'value'),
      multiOptions: LEAVE_STATUS_OPTIONS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A', 'N']
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-leave-types-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-leave-types-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getLeaveTypeDataItems(
        savedPageIndex,
        savedSearchParams
      );

      if (mounted.current) {
        const newList =
          savedPageIndex === 1
            ? res.data.items
            : [...leaveTypeList, ...res.data.items];

        setLeaveTypeList(
          newList.map(o => ({
            id: o.id,
            leave_code: autoCapitalize(o.leave_code),
            description: o.description,
            minimum_hours: o.minimum_hours.toString(),
            is_payable: o.is_payable ? 'Yes' : 'No',
            is_document_required: o.is_document_required ? 'Yes' : 'No',
            status: o.status
          }))
        );

        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getLeaveTypeService,
    setLeaveTypeList,
    setPage,
    setHasMore,
    leaveTypeList
  ]);

  const leaveTypeListMemo = useMemo(() => {
    return leaveTypeList ?? [];
  }, [leaveTypeList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
      setStatusFilter('');
    } else {
      setStatusFilter(searchParamsObject.status);
    }
    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    localStorage.setItem('hris-leave-types-params', searchParams);
  };

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setleaveTypeId(modal ? id : null);
      setIsOpen(!!modal);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setleaveTypeId]
  );

  const handleViewModal = useCallback(
    (modal, id = null, data = null) => {
      setShowViewModal(modal);
      setleaveTypeId(modal ? id : null);
      setIsOpen(!!modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setleaveTypeId]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, leave_code }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, leave_code });
      setShowDeleteModal(modal);
    },
    [showDeleteModal]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteLeaveTypeService(id);
      if (response?.success) {
        dispatch(getLeaveTypes());
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        dispatch(getLeaveTypes());
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }
    setShowDeleteModal(null);
  };

  const removeSearchParam = paramName => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
  };

  const detectDateOnChange = e => {
    const { name, value } = e.target;

    if (name === 'year') {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(name, value);

      localStorage.setItem('hris-leave-types-params', searchParams.toString());
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    } else if (value === '') {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(name);

      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });

      localStorage.setItem('hris-leave-types-params', searchParams.toString());

      // Call removeSearchParam when value is empty
      removeSearchParam(name);
    }
  };

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-leave-types-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    localStorage.setItem('hris-leave-types-page-no', JSON.stringify(page));
  }, [page]);

  return {
    leaveTypeColumns,
    isOpen,
    showCreateModal,
    showViewModal,
    hasMore,
    showModal,
    form,
    inputs,
    showDeleteModal,
    showBulkDeleteModal,
    leaveTypeId,
    leaveTypeListMemo,
    leaveTypeList,
    deleteId,
    fetchData,
    submitFilter,
    handleModal,
    handleBulkDeleteModal,
    handleViewModal,
    handleDeleteModal,
    detectDateOnChange,
    handleDelete,
    setShowDeleteModal
  };
};

export default useLeaveType;
