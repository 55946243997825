/* eslint-disable import/no-unresolved */
import moment from 'moment';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAnnouncementByIdService,
  getAnnouncementsService,
  updateAnnouncementService,
  createAnnouncementService
} from 'src/api/modules/announcements';
import {
  setAnnouncementList,
  setAnnouncementSingle
} from 'src/redux/modules/announcement/announcementActions';

export const useAddEditModal = ({ handleModal, id, dataItems, action }) => {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const [startDate, setStartDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [errorContent, setErrorContent] = useState(false);
  const isEdited = useRef(false);
  const editor = useSelector(state => state?.announcement?.editor);

  const initialFormData = {
    title: '',
    start_date: '',
    expire_on: '',
    content: {}
  };

  const [formData, setFormData] = useState(initialFormData);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255, 'Maximum of 255 characters allowed')
      .required('Required'),
    start_date: Yup.date().required('Required'),
    expire_on: Yup.date()
      .required('Required')
      .test(
        'is-greater',
        'Expiry date should be greater than Start Date',
        function () {
          if (!startDate || !expiryDate) {
            return true;
          }
          return expiryDate > startDate;
        }
      )
  });

  const fetchCurrentDate = () => {
    const today = new Date().toJSON().slice(0, 10);
    setCurrentDate(today);
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    isEdited.current = true;
  };

  const handleSubmit = () => {
    const status = formData?.start_date === currentDate ? 'A' : 'N';

    let payload = {
      title: formData?.title,
      start_date: formData?.start_date,
      expire_date: formData?.expire_on,
      status
    };

    editor
      .save()
      .then(async outputData => {
        const isContentError = validateEditor(outputData, dataItems?.content);
        setErrorContent(isContentError);

        if (!isContentError) {
          payload = {
            ...payload,
            content: outputData
          };

          if (id) {
            if (isEdited.current) {
              handleUpdateSubmit(payload);
            }
          } else {
            setFormLoading(true);
            handleModal(null);
            handleCreateSubmit(payload);
          }
        }
      })
      .catch(error => {
        toast.error(error, { icon: <CgCloseO /> });
      });
  };

  const handleUpdateSubmit = async payload => {
    const newPayload = {
      ...payload,
      id
    };

    setFormLoading(true);
    await updateAnnouncementService(newPayload)
      .then(res => {
        if (res.success) {
          fetchUpdateAnnounceSingle();
        } else {
          toast.error(res.message.errors, { icon: <CgCloseO /> });
        }
      })
      .catch(err => {
        toast.error(err, { icon: <CgCloseO /> });
        setFormLoading(false);
      });
  };

  const handleCreateSubmit = async payload => {
    await createAnnouncementService(payload)
      .then(res => {
        if (res.success) {
          handleModal(null);
          fetchUpdateAnnouncementList();
        } else {
          toast.error(res.message.errors, { icon: <CgCloseO /> });
        }
      })
      .catch(err => {
        toast.error(err, { icon: <CgCloseO /> });
        setFormLoading(false);
      });
  };

  const fetchUpdateAnnounceSingle = async () => {
    try {
      await getAnnouncementByIdService(id).then(res => {
        const { title, start_date, post_date } = res.data;

        const modifiedResult = {
          id,
          title,
          start_date,
          post_date
        };

        dispatch(setAnnouncementSingle(modifiedResult));
        setTimeout(() => {
          setFormLoading(false);
        }, 500);

        toast.success('Successfully Updated!', {
          icon: <CgCheckO />
        });
      });

      handleModal(null);
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  const fetchUpdateAnnouncementList = async () => {
    try {
      const res = await getAnnouncementsService(1);
      const modifiedResult = res.data.items?.map(item => {
        const { title, post_date } = item;

        return {
          id: item.id,
          title,
          post_date
        };
      });

      dispatch(setAnnouncementList(modifiedResult));
      setTimeout(() => {
        setFormLoading(false);
      }, 500);

      toast.success('Successfully Added!', {
        icon: <CgCheckO />
      });
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  const validateEditor = (outputData, oldData) => {
    if (!outputData.blocks.length) {
      return true;
    }

    if (action === 'edit') {
      if (!outputData.blocks.length && !oldData.blocks.length) {
        return true;
      }
      if (
        outputData.blocks.length > oldData.blocks.length ||
        outputData.blocks.length < oldData.blocks.length
      ) {
        isEdited.current = true;
      }
    }

    return false;
  };

  useEffect(() => {
    fetchCurrentDate();
    if (id) {
      setFormData({
        title: dataItems?.title,
        start_date: moment(dataItems?.startDate).format('YYYY-MM-DD'),
        expire_on: moment(dataItems?.expireDate).format('YYYY-MM-DD')
      });
    }
  }, []);

  useEffect(() => {
    setStartDate(formData?.start_date);
    setExpiryDate(formData?.expire_on);
  }, [setFormData, formData]);

  return {
    formData,
    formLoading,
    validationSchema,
    currentDate,
    errorContent,
    handleChange,
    handleSubmit
  };
};
