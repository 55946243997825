/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAddEditModal } from 'src/hooks/components/Parts/Timekeeping/ShiftList/useAddEditModal';
import {
  Button,
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard,
  Text
} from 'src/components/Common';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { SHIFT_STATUS_OPTIONS } from 'src/helpers/constants';
import ModalUI from 'src/components/Common/ModalUI';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, uniqueCode, dataList, menuCode }) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    handleDeleteModal,
    handleTrimSpaces,
    validationSchema,
    initialDummyData,
    shiftValidation,
    showDeleteModal,
    handleSelectChange,
    handleTimeChange,
    hoursFormat,
    showConfirmModal,
    handleConfirm,
    handleCancelModal,
    handleSubmitConfirm,
    shiftId
  } = useAddEditModal({ handleModal, uniqueCode, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <>
      <Formik
        enableReinitialize={!!uniqueCode}
        initialValues={uniqueCode ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {uniqueCode ? 'Edit' : 'Add'} Shift Details
              </h4>
              <div className="text-right mr-[10px]">
                <button
                  type="submit"
                  className="text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Save
                  </span>
                </button>
              </div>
            </div>
            <div className="mx-[35px] mt-[20px] mb-[30px] w-auto">
              <Text tag="error" markup={shiftValidation} />
            </div>
            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!formData.id ? (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Shift Code"
                          required
                          name="shift_code"
                          type="text"
                          placeholder=""
                          value={formData.shift_code ?? ''}
                          error={errors.shift_code && touched.shift_code}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.shift_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.shift_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          required
                          name="description"
                          type="text"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormGridCard cols="2">
                        <FormCard>
                          <FormField
                            label="Start of Shift"
                            required
                            name="start_of_shift"
                            type="time"
                            timeValue={formData.start_of_shift || ''}
                            error={
                              errors.start_of_shift && touched.start_of_shift
                            }
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'start_of_shift',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.start_of_shift !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.start_of_shift}
                            </div>
                          )}
                        </FormCard>
                        <FormCard>
                          <FormField
                            label="End of Shift"
                            required
                            name="end_of_shift"
                            type="time"
                            timeValue={formData.end_of_shift || ''}
                            error={errors.end_of_shift && touched.end_of_shift}
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'end_of_shift',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_of_shift !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.end_of_shift}
                            </div>
                          )}
                        </FormCard>
                      </FormGridCard>
                      <FormGridCard cols="2">
                        <FormCard>
                          <FormField
                            label="Start of break"
                            required
                            name="start_of_break"
                            type="time"
                            timeValue={formData.start_of_break || ''}
                            error={
                              errors.start_of_break && touched.start_of_break
                            }
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'start_of_break',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.start_of_break !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.start_of_break}
                            </div>
                          )}
                        </FormCard>
                        <FormCard>
                          <FormField
                            label="End of break"
                            required
                            name="end_of_break"
                            type="time"
                            timeValue={formData.end_of_break || ''}
                            error={errors.end_of_break && touched.end_of_break}
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'end_of_break',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_of_break !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.end_of_break}
                            </div>
                          )}
                        </FormCard>
                      </FormGridCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard className="flex items-center mr-[23px] mb-[20px] ">
                        <FormField
                          label="Default"
                          name="is_default"
                          type="checkbox"
                          checked={formData.is_default}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Shift Code"
                          required
                          name="shift_code"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={formData.shift_code ?? ''}
                          error={errors.shift_code && touched.shift_code}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.shift_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.shift_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          required
                          name="description"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormGridCard cols="2">
                        <FormCard>
                          <FormField
                            label="Start of Shift"
                            required
                            name="start_of_shift"
                            type="time"
                            timeValue={formData.start_of_shift || ''}
                            error={
                              errors.start_of_shift && touched.start_of_shift
                            }
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'start_of_shift',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.start_of_shift !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.start_of_shift}
                            </div>
                          )}
                        </FormCard>
                        <FormCard>
                          <FormField
                            label="End of Shift"
                            required
                            name="end_of_shift"
                            type="time"
                            timeValue={formData.end_of_shift || ''}
                            error={errors.end_of_shift && touched.end_of_shift}
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'end_of_shift',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_of_shift !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.end_of_shift}
                            </div>
                          )}
                        </FormCard>
                      </FormGridCard>
                      <FormGridCard cols="2">
                        <FormCard>
                          <FormField
                            label="Start of break"
                            required
                            name="start_of_break"
                            type="time"
                            timeValue={formData.start_of_break || ''}
                            error={
                              errors.start_of_break && touched.start_of_break
                            }
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'start_of_break',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.start_of_break !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.start_of_break}
                            </div>
                          )}
                        </FormCard>
                        <FormCard>
                          <FormField
                            label="End of break"
                            required
                            name="end_of_break"
                            type="time"
                            timeValue={formData.end_of_break}
                            error={errors.end_of_break && touched.end_of_break}
                            onChange={e =>
                              handleTimeChange(
                                e,
                                'end_of_break',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                          />
                          {errors.end_of_break !== 'Required' && (
                            <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                              {errors.end_of_break}
                            </div>
                          )}
                        </FormCard>
                      </FormGridCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          type="combobox"
                          error={errors.status && touched.status}
                          label="Status"
                          required
                          options={SHIFT_STATUS_OPTIONS}
                          placeholder=""
                          errorMessage=""
                          selectedValue={formData.status ?? ''}
                          onChangeValue={(value, name) =>
                            handleSelectChange(
                              value,
                              name,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                      </FormCard>
                      <FormCard className="flex items-center mr-[23px] ">
                        <FormField
                          label="Default"
                          name="is_default"
                          type="checkbox"
                          checked={formData.is_default}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                      </FormCard>
                    </FormGridCard>
                  </>
                )}
              </div>
            </div>
            <div className="flex mx-[35px] mb-[30px]">
              <div className="w-full h-full rounded-default bg-[#F0F0F0] p-5">
                <div className="text-[14px] text-[#222222] font-stolzlBold leading-[24px] text-left">
                  <div className="mb-[10px]">
                    Working Hours: {hoursFormat.working_hours} hr (s)
                  </div>
                  Break {hoursFormat.break_time} min (s)
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            code={uniqueCode}
          />
        )}
      </ModalCenter>
    </>
  );
};
AddEditModal.propTypes = {
  handleModal: PropTypes.func,
  uniqueCode: PropTypes.number,
  dataList: PropTypes.instanceOf(Object),
  menuCode: PropTypes.string
};

export default AddEditModal;
