import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useShift from 'src/hooks/Pages/Timekeeping/ShiftList/useShiftListIndex';
import PropTypes from 'prop-types';
import {
  SlideModal,
  ModalCenter,
  PageTitle,
  SearchFilter,
  Breadcrumbs
} from 'src/components/Common';
import { SHIFT_BULK_DELETE } from 'src/api/endpoints';
import { bulkDeleteShiftsService } from 'src/api/modules/shifts';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import { useUserAccess } from 'src/hooks/useUserAccess';
import BulkDeleteModal from '../BulkDeleteModal';
import AddEditModal from './components/AddEditModal';
import DeleteModal from './components/DeleteModal';

const Index = ({ menuCode }) => {
  const {
    shiftsList,
    shiftsListMemo,
    hasMore,
    form,
    shiftColumns,
    showModal,
    showDeleteModal,
    uniqueCode,
    uniqueCodeName,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    submitFilter,
    showBulkDeleteModal,
    inputs,
    isOpen
  } = useShift();
  const { menus } = useUserAccess();

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'Timekeeping' }, { name: 'Shift List' }]} />

      <div className="pt-2">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Shift Code, Description"
            buttonName="Shift"
            buttonLink={false}
            inputs={inputs}
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
          />
          <InfiniteScroll
            dataLength={shiftsListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {shiftsListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={shiftsListMemo || []}
              clickableRows={false}
              headingColumns={shiftColumns}
              title="Shifts"
              actions={['edit', 'delete']}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              // keyField="id"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddEditModal
            handleModal={handleModal}
            uniqueCode={uniqueCode}
            dataList={shiftsList}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            code={uniqueCode}
            name={uniqueCodeName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={SHIFT_BULK_DELETE}
            apiService={bulkDeleteShiftsService}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
