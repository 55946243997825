import { GET_DAYCODE_LIST } from './dayCodeTypes';

const INITIAL_STATE = {
  dayCodes: []
};

export default function dayCodeReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_DAYCODE_LIST:
      return {
        ...state,
        dayCodes: payload
      };
    default:
      return state;
  }
}
