import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getShiftByIDSevice,
  updateShiftByIDSevice,
  getShiftsServiceByPage,
  addShiftService,
  getShiftsIsDefaultService
} from 'src/api/modules/shifts';
import { getShifts } from 'src/redux/modules/shifts/shiftsActions';
import { autoCapitalize, timeChecker } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { TOAST_OPTIONS } from 'src/helpers/constants';
import * as Yup from 'yup';

export const useAddEditModal = ({ handleModal, uniqueCode, dataList }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: '',
    shift_code: '',
    description: '',
    start_of_shift: '00:00',
    end_of_shift: '00:00',
    start_of_break: '00:00',
    end_of_break: '00:00',
    is_default: false,
    status: 'N'
  });
  const initialDummyData = {
    id: '',
    shift_code: 'test',
    description: 'test',
    start_of_shift: '00:00',
    end_of_shift: '00:00',
    start_of_break: '00:00',
    end_of_break: '00:00',
    is_default: false,
    status: 'N'
  };
  const [selectedOption, setSelectedOption] = useState('');
  const [shiftValidation, setshiftValidation] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [codeNumber, setCodeNumber] = useState(null);
  const [shiftId, setShiftId] = useState(null);
  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Blank spaces are not allowed at the beginning or end';

  const [hoursFormat, setHoursFormat] = useState({
    working_hours: '0',
    break_time: '0'
  });

  const calculateTimeDifference = () => {
    const keys = [
      'start_of_shift',
      'end_of_shift',
      'start_of_break',
      'end_of_break'
    ];
    const times = keys.map(key => moment(formData[key], 'HH:mm'));
    const newHours = times[1].isBefore(times[0])
      ? times[1].add(1, 'day').diff(times[0], 'hours', true)
      : (times[1] - times[0]) / (1000 * 60 * 60);
    const newMinutes = times[3].isBefore(times[2])
      ? times[3].add(1, 'day').diff(times[2], 'minutes', true)
      : times[3].diff(times[2], 'minutes');

    let minutes = Math.round((newHours % 1) * 60 - newMinutes);
    let hours = Math.floor(newHours);

    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }

    let formattedTime = moment
      .utc()
      .hours(hours)
      .minutes(minutes)
      .format('HH:mm');

    const momentTime = moment(hours, 'HH:mm');

    const totalMinutes = momentTime.hours() * 60;

    if (newMinutes > totalMinutes) {
      formattedTime = 0;
    }

    setHoursFormat({
      working_hours: formattedTime ?? '00:00',
      break_time: newMinutes ?? '0'
    });
  };

  useEffect(() => {
    calculateTimeDifference();
  }, [formData]);

  const handleDeleteModal = useCallback(
    (modal, editModalName, id = null) => {
      setShowDeleteModal(modal);
      setCodeNumber(modal ? id : null);
      setEditModal(editModalName);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setEditModal, setCodeNumber]
  );

  const fetchShift = async code => {
    const response = await getShiftByIDSevice(code, 'GET');
    if (response.data) {
      const responseData = response.data;
      Object.keys(response.data).forEach(formKey => {
        if (typeof formData[formKey] === 'undefined') {
          delete responseData[formKey];
        }
      });
      setFormData(response.data);
    }
  };

  const handleTrimSpaces = e => {
    const { name, value } = e.target;
    let trimEndValue = '';
    if (typeof value === 'string' && value.length > 1) {
      trimEndValue = value.trimEnd();
      setFormData(prevState => ({
        ...prevState,
        [name]: trimEndValue
      }));
    }
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;

    const fieldValue = type === 'checkbox' ? checked : value;

    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    setFieldValue(name, value);
    setFieldTouched(name, true);
    setFieldError(name, '');
    setshiftValidation('');
  };

  const handleSelectChange = value => {
    setFormData(prevState => ({
      ...prevState,
      status: value
    }));
  };

  const fetchUpdateShift = async () => {
    getShiftsServiceByPage(1)
      .then(() => {
        dispatch(getShifts());
      })
      .catch(err => {
        return err;
      });
  };

  const updateShift = async payload => {
    updateShiftByIDSevice(payload)
      .then(response => {
        if (response.success) {
          fetchUpdateShift();
          toast.success('Successfully Updated!', { icon: <CgCheckO /> });
          handleModal(null);
        } else if (response.response.data.statusCode === 406) {
          toast.error(response.response.data.message, {
            ...TOAST_OPTIONS,
            icon: <MdOutlineErrorOutline />
          });
        }
        setFormData(formData);
      })
      .catch(err => {
        toast.error(err, { icon: <MdOutlineErrorOutline /> });
      });
  };

  const addShift = async payload => {
    const response = await addShiftService(payload);
    if (response.success) {
      fetchUpdateShift();
      toast.success('Successfully Added!', { icon: <CgCheckO /> });
      handleModal(null);
    } else if (response.status === 409)
      setshiftValidation('Shift already exists');
  };

  const handleSubmit = useCallback(async () => {
    if (uniqueCode) {
      // EDIT
      setFormData(prevState => ({
        ...prevState,
        id: uniqueCode
      }));
      updateShift(formData);
    } else {
      // ADD
      addShift(formData);
    }
  });

  const validationSchema = Yup.object().shape({
    shift_code: Yup.string().required(),
    description: Yup.string().required(),
    start_of_shift: Yup.string().required('Required'),
    end_of_shift: Yup.string()
      .required('Required')
      .test(
        'is-greater',
        'Invalid time',
        // eslint-disable-next-line func-names
        function () {
          const moment1 = moment(formData.start_of_shift, 'HH:mm');
          const moment2 = moment(formData.end_of_shift, 'HH:mm');
          return moment2.isAfter(moment1);
        }
      ),
    start_of_break: Yup.string().required('Required'),
    end_of_break: Yup.string()
      .required('Required')
      .test(
        'is-greater',
        'Invalid time',
        // eslint-disable-next-line func-names
        function () {
          const moment1 = moment(formData.start_of_break, 'HH:mm');
          const moment2 = moment(formData.end_of_break, 'HH:mm');
          return moment2.isAfter(moment1);
        }
      ),
    status: Yup.string()
  });

  useEffect(() => {
    if (uniqueCode) {
      fetchShift(uniqueCode);
    }
  }, []);

  const handleTimeChange = (
    value,
    name,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    const isDeleteKeyPressed =
      value.nativeEvent.inputType === 'deleteContentBackward';
    const newValue = timeChecker(value.target.value, isDeleteKeyPressed);

    if (typeof newValue === 'string') {
      setFormData(prevState => ({
        ...prevState,
        [name]: newValue
      }));
      setFieldValue(name, newValue);
      setFieldTouched(name, true);
      setFieldError(name, '');
    }
  };

  return {
    formData,
    selectedOption,
    initialDummyData,
    handleChange,
    handleTrimSpaces,
    fetchShift,
    handleSelectChange,
    handleSubmit,
    handleDeleteModal,
    validationSchema,
    shiftValidation,
    showDeleteModal,
    editModal,
    handleTimeChange,
    hoursFormat,
    shiftId
  };
};
