import moment from 'moment';
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { filterData } from 'src/helpers/utils';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ANNOUNCEMENTS_STATUS_OPTIONS,
  TOAST_OPTIONS
} from 'src/helpers/constants';
import {
  getAnnouncementsService,
  deleteAnnouncementService,
  updateAnnouncementService
} from 'src/api/modules/announcements';

const useAnnouncements = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const mounted = useRef(true);
  const form = useForm({ defaultValues: undefined });
  const [formLoading, setFormLoading] = useState(false);
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });
  const [announcementId, setAnnouncementId] = useState(null);
  const [announcementList, setAnnouncementList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const announcementFlag = useSelector(
    state => state?.announcement?.announcementList
  );

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(ANNOUNCEMENTS_STATUS_OPTIONS, 'label', 'value'),
      multiOptions: ANNOUNCEMENTS_STATUS_OPTIONS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A', 'N']
    },
    {
      type: 'date-range',
      options: [
        {
          label: 'Posted From',
          name: 'post_date_from'
        },
        {
          label: 'To',
          name: 'post_date_to'
        }
      ]
    }
  ];

  const announcementColumns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'title',
      label: 'Title'
    },
    {
      key: 'date_posted',
      label: 'Date Posted'
    },
    {
      key: 'posted_by',
      label: 'Posted By'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'expire_on',
      label: 'Expire On'
    }
  ];

  useEffect(() => {
    mounted.current = true;
    const savedSearchParams = localStorage.getItem(
      'hris-announcements-search-params'
    );
    const savedPageIndex = localStorage.getItem('hris-announcements-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }

    if (savedSearchParams) {
      localStorage.setItem('hris-announcements-page-no', JSON.stringify(1));
    }

    fetchAnnouncements();
  }, []);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;

      // Check for null or empty values
      if (value === null || value === '') {
        delete searchParamsObject[key];
      } else if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: value });
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-announcements-search-params', searchParams);
  };

  const fetchAnnouncements = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-announcements-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-announcements-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAnnouncementsService(savedPageIndex, params);

      const modifiedResult = res.data?.items?.map(item => {
        const { id, title, post_date, created, status, expire_date } = item;
        const date_posted = moment(post_date).format('YYYY-DD-MM');
        const expire_on = moment(expire_date).format('YYYY-DD-MM');
        const posted_by = `${created.first_name} ${created.last_name}`;

        return {
          id,
          title,
          date_posted,
          posted_by,
          status,
          expire_on
        };
      });

      if (mounted.current) {
        const newList =
          savedPageIndex !== 1
            ? announcementList.concat(modifiedResult)
            : modifiedResult;
        setAnnouncementList(newList);
        setFormLoading(false);
        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(savedPageIndex + 1);
        }
      }
    } catch (error) {
      setFormLoading(false);
    }
  }, [
    page,
    announcementList,
    getAnnouncementsService,
    setAnnouncementList,
    setPage,
    setHasMore
  ]);

  const announcementListMemo = useMemo(() => {
    return announcementList ?? [];
  }, [announcementList]);

  const handleViewModal = useCallback(
    (modal, id = null) => {
      setShowViewModal(modal);
      setAnnouncementId(modal ? id : null);
      setIsOpen(!!modal);
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowViewModal, setAnnouncementId]
  );

  const handleRePostSubmit = async (id, title) => {
    const today = new Date();
    let expire_date;

    if (typeof today === 'object' && today !== null && 'getDate' in today) {
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const addDate = String(today.getDate() + 7).padStart(2, '0');
      expire_date = `${year}-${month}-${addDate}`;
    }

    const payload = {
      id,
      status: 'A',
      expire_date
    };

    try {
      const response = await updateAnnouncementService(payload);
      if (response?.success) {
        fetchAnnouncements();
        toast.success(`${title} has been reposted and will expire in 7 days!`, {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: id
        });
      } else {
        fetchAnnouncements();
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id
        });
      }
    } catch (error) {
      toast.error('Failed to Repost!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id
      });
    }

    setShowModal(null);
  };

  const handleModal = useCallback(
    ({ modal, id, title }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      handleRePostSubmit(id, title);
      setShowModal(modal);
    },
    [showModal]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteAnnouncementService(id);
      if (response?.success) {
        fetchAnnouncements();
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        fetchAnnouncements();
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }

    setShowDeleteModal(null);
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, title }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, title });
      setShowDeleteModal(modal);
    },

    [showDeleteModal]
  );

  const handleBulkDeleteModal = useCallback(
    modal => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setShowBulkDeleteModal(modal);
    },
    [showBulkDeleteModal]
  );

  useEffect(() => {
    if (!location.search && form.getValues('status')) {
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, [location]);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-announcements-page-no', JSON.stringify(1));
    setHasMore(true);
    setFormLoading(true);
    fetchAnnouncements();
  }, [location.search, announcementFlag]);

  useEffect(() => {
    localStorage.setItem('hris-announcements-page-no', JSON.stringify(page));
  }, [page]);

  return {
    inputs,
    form,
    announcementColumns,
    announcementList,
    announcementListMemo,
    announcementId,
    showViewModal,
    isOpen,
    showModal,
    showDeleteModal,
    showBulkDeleteModal,
    page,
    hasMore,
    deleteId,
    formLoading,
    fetchAnnouncements,
    handleModal,
    handleViewModal,
    handleDelete,
    handleDeleteModal,
    handleBulkDeleteModal,
    submitFilter
  };
};

export default useAnnouncements;
