import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  getLeaveTypeIDSevice,
  updateLeaveTypesByIDServices,
  addLeaveTypesService,
  getLeaveTypeDataItems
} from 'src/api/modules/leaveType';
import { getLeaveTypes } from 'src/redux/modules/LeaveTypes/LeaveTypesActions';
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';

const useAddEditModal = ({ handleModal, leaveTypeId, dataList }) => {
  const dispatch = useDispatch();
  const [daycodeOptions, setDaycodeOptions] = useState([]);
  const [isPayableCheckboxDisabled, setIsPayableCheckboxDisabled] =
    useState(false);
  const [isDocumentCheckboxDisabled, setIsDocumentCheckboxDisabled] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });
  const [formData, setFormData] = useState({
    id: '',
    leave_code: '',
    description: '',
    minimum_hours: '0',
    is_payable: false,
    is_document_required: false,
    status: 'N'
  });

  const initialDummyData = {
    id: '',
    leave_code: 'test',
    description: 'test',
    minimum_hours: '0',
    is_payable: false,
    is_document_required: false,
    status: 'A'
  };

  const handleTrimSpaces = e => {
    const { name, value } = e.target;
    let trimEndValue = '';
    if (typeof value === 'string' && value.length > 1) {
      trimEndValue = value.trimEnd();
      setFormData(prevState => ({
        ...prevState,
        [name]: trimEndValue
      }));
    }
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    // For checkboxes, directly set the checked value
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
      }));
    } else {
      const initialValue = value.match(/^\s/) !== null;

      if (name === 'description' && initialValue) {
        setFormData(prevState => ({
          ...prevState,
          [name]: value.trim()
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: fieldValue
        }));
      }
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };
  const codeRegex = /^[0-9]+$/;
  const codeMessage = 'Must be a Number';
  const validationSchema = Yup.object().shape({
    leave_code: Yup.string()
      .required('Required')
      .max(10, 'Maximum of 10 characters allowed'),
    description: Yup.string()
      .required('Required')
      .max(100, 'Maximum of 100 characters allowed'),
    minimum_hours: Yup.string()
      .required('Required')
      .typeError('Must be a number')
      .max(255, 'Maximum of 255 digits allowed')
      .test('is-code', codeMessage, value => {
        if (!value || value.match(codeRegex)) {
          return true;
        }
        return false;
      }),
    is_payable: Yup.boolean(),
    is_document_required: Yup.boolean(),
    status: Yup.string()
  });

  const fetchUpdateShift = async () => {
    getLeaveTypeDataItems(1)
      .then(() => {
        dispatch(getLeaveTypes());
      })
      .catch(err => {
        return err;
      });
  };

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const updateLeaveTypes = async payload => {
    updateLeaveTypesByIDServices(payload)
      .then(response => {
        if (response.success) {
          fetchUpdateShift();
          toast.success('Successfully Updated!', { icon: <CgCheckO /> });
          handleModal(null);
        }
        setFormData(formData);
      })

      .catch(err => {
        toast.error(err, { icon: <MdOutlineErrorOutline /> });
      });
  };

  const addLeaveTypes = async payload => {
    const response = await addLeaveTypesService(payload);
    if (response.success) {
      fetchUpdateShift();
      toast.success('Successfully Added!', { icon: <CgCheckO /> });
      handleModal(null);
    }
  };

  const handleSubmit = useCallback(async () => {
    // EDIT
    if (leaveTypeId) {
      updateLeaveTypes(formData);
    } else {
      addLeaveTypes(formData);
    }
  });

  const fetchLeaveType = async id => {
    const response = await getLeaveTypeIDSevice(id, 'GET');
    if (response.data) {
      const responseData = response.data;
      Object.keys(response.data).forEach(formKey => {
        if (typeof formData[formKey] === 'undefined') {
          delete responseData[formKey];
        }
      });
      setFormData(response.data);
    }
  };

  const handleSelectChange = value => {
    setFormData(prevState => ({
      ...prevState,
      status: value
    }));
  };

  useEffect(() => {
    if (leaveTypeId) {
      fetchLeaveType(leaveTypeId);
    }
  }, []);
  const handleFocus = e => {
    e.target.select();
  };
  return {
    isPayableCheckboxDisabled,
    isDocumentCheckboxDisabled,
    formData,
    initialDummyData,
    validationSchema,
    daycodeOptions,
    formLoading,
    showDeleteModal,
    deleteId,
    handleChange,
    handleTrimSpaces,
    handleSubmit,
    handleSelectChange,
    handleFocus
  };
};

export default useAddEditModal;
