import { getLeaveTypesService } from 'src/api/modules/leaveType';
import { GET_LEAVE_TYPES_LIST } from './LeaveTypes';

export function setLeaveTypes(data) {
  return { type: GET_LEAVE_TYPES_LIST, payload: data };
}

export function getLeaveTypes() {
  return async function (dispatch) {
    const response = await getLeaveTypesService();
    if (response?.success && response?.data)
      dispatch(setLeaveTypes(response.data));
  };
}
