import { useEmployees } from './useEmployees';
import { createDictionary } from './createDictionary';
import { useActiveTemplates } from './useActiveTemplates';
import { useRemoveSelectedItems } from './useRemoveSelectedItems';
import { removeDefaultEvaluators } from './removeDefaultEvaluators';

function merge(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2))
    throw new Error('Both arguments must be arrays');
  const uniqueIdsMap = new Map();
  arr1.forEach(item => uniqueIdsMap.set(item.id, item));
  const uniqueItemsFromArr2 = arr2.filter(item => !uniqueIdsMap.has(item.id));
  const mergedArray = [...uniqueIdsMap.values(), ...uniqueItemsFromArr2];

  return mergedArray;
}

function filterDuplicatesById(arr) {
  if (!Array.isArray(arr)) throw new Error('Argument must be an array');
  const uniqueMap = new Map();
  arr.forEach(item => {
    if (!uniqueMap.has(item.id)) {
      uniqueMap.set(item.id, item);
    }
  });
  return Array.from(uniqueMap.values());
}

function isObjectHasLength(object) {
  return !!Object.keys(object).length;
}

function isArrayHasLength(array) {
  return array.length >= 1;
}

function log(item, { name = '', color = 'none', enable = false }) {
  if (!enable) return;
  // eslint-disable-next-line no-console
  console.log(
    `%c ${name} `,
    `color: white; font-weight: bold; background-color: ${color}`,
    item
  );
}

function sortBySortedItems(list, sortedIDs) {
  const idIndexMap = {};

  sortedIDs.forEach((id, index) => {
    idIndexMap[id] = index;
  });

  list.sort((a, b) => {
    const indexA = idIndexMap[a.id];
    const indexB = idIndexMap[b.id];
    return indexA - indexB;
  });

  return list;
}

function employeeName(info) {
  const {
    first_name = '-',
    last_name = '',
    middle_name = '',
    suffix = ''
  } = info || {};

  const hasMiddleName = middle_name ? `${middle_name.charAt(0)}. ` : '';
  const hasSuffix = suffix ? ` ${suffix}` : '';

  const name =
    first_name || last_name
      ? `${first_name} ${hasMiddleName}${last_name}${hasSuffix}`
      : '-';

  return name;
}

export {
  log,
  merge,
  useEmployees,
  employeeName,
  createDictionary,
  isArrayHasLength,
  isObjectHasLength,
  sortBySortedItems,
  useActiveTemplates,
  filterDuplicatesById,
  useRemoveSelectedItems,
  removeDefaultEvaluators
};
