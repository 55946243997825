import Filter from 'src/components/Common/Filter/Filter';
import SlideModal from 'src/components/Common/SlideModal';
import Datatable from 'src/components/Common/Datatable';
import AddEditModal from 'src/components/Parts/Announcements/AddEditModal';
import ModalCenter from 'src/components/Common/ModalCenter';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import ModalUI from 'src/components/Common/ModalUI';
import Loading from 'src/components/Common/loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import useAnnouncements from 'src/hooks/Pages/Announcement/useAnnouncements';
import PropTypes from 'prop-types';
import { bulkDeleteAnnouncementService } from 'src/api/modules/announcements';
import { ANNOUNCEMENT_BULK_DELETE } from 'src/api/endpoints';
import BulkDeleteModal from './BulkDeleteModal';

const Announcements = ({ menuCode }) => {
  const {
    inputs,
    form,
    announcementColumns,
    announcementListMemo,
    showViewModal,
    isOpen,
    showModal,
    showDeleteModal,
    showBulkDeleteModal,
    hasMore,
    deleteId,
    formLoading,
    fetchAnnouncements,
    handleModal,
    handleViewModal,
    handleDelete,
    handleDeleteModal,
    handleBulkDeleteModal,
    submitFilter
  } = useAnnouncements();
  const { menus } = useUserAccess();

  return (
    <div className="announcements__container">
      <Breadcrumbs crumbs={[{ name: 'System' }, { name: 'Announcements' }]} />
      <div className="pt-2">
        {formLoading && <Loading />}
        <div className="filter__content--search capitalize">
          <Filter
            searchInputPlaceholder="Title, Posted By"
            buttonName="New Post"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleViewModal('View')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
          />
          <InfiniteScroll
            dataLength={announcementListMemo?.length ?? 0}
            next={fetchAnnouncements}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5 normal-case">
                {announcementListMemo?.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
            style={{ overflow: 'visible' }}
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={announcementListMemo || []}
              clickableRows={false}
              headingColumns={announcementColumns}
              title=""
              actions={['announcement_custom', 'view', 'delete']}
              actionLinks={{
                view: '/announcements',
                custom: '/announcements'
              }}
              actionLabels={{
                custom: 'Repost'
              }}
              isAnnouncement
              showModal={showModal}
              showViewModal={showViewModal}
              handleModal={handleModal}
              handleViewModal={handleViewModal}
              handleDeleteModal={handleDeleteModal}
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              access={menus[menuCode]?.user_group_access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <SlideModal
        showModal={showViewModal}
        modalName="View"
        handleModal={handleViewModal}
      >
        {showViewModal && (
          <AddEditModal
            handleModal={handleViewModal}
            modalTitle="Add New Post"
            action="add"
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <ModalUI
            header="Delete"
            type="delete"
            submit={() => handleDelete(deleteId.id)}
            cancel={() => handleDeleteModal({ modal: null })}
          >
            <div className="text-center font-stolzlRegular text-[14px] mt-7">
              Are you sure you want to delete
            </div>
            <div className="text-center font-stolzlBold mt-2 capitalize">
              {deleteId.title}?
            </div>
          </ModalUI>
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            apiService={bulkDeleteAnnouncementService}
            endpoint={ANNOUNCEMENT_BULK_DELETE}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Announcements.propTypes = {
  menuCode: PropTypes.string
};
export default Announcements;
