// eslint-disable-next-line import/no-extraneous-dependencies
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useState, useMemo, memo } from 'react';
import { cn } from 'src/helpers/utils';
import FormLabel from './FormLabel';

const ComboBox = ({
  id,
  name,
  error,
  required,
  datasource = [],
  label = '',
  errorMessage,
  defaultValue,
  listType = '',
  placeholder = '',
  isLoading = false,
  isDisabled = false,
  onChangeValue = () => {},
  submitFilter,
  menuCode
}) => {
  const notExist = defaultValue === '-‎';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = () => setIsMenuOpen(true);
  const handleMenuClose = () => setIsMenuOpen(false);
  const handleChange = selected => {
    const { value, label: title } = selected || {};
    const isListType = ['schedule'].includes(listType); // Add more listType in the array
    const update = isListType ? title : value;
    onChangeValue(update, name);
    if (menuCode === 'TK10') {
      submitFilter();
    }
  };

  const options = useMemo(
    () =>
      datasource.map(item => ({
        value: item.id || item.value,
        label: item.name || item.label
      })),
    [datasource]
  );

  const getIndex = options.findIndex(option => {
    const { value, label: title } = option;
    const isListType = ['schedule'].includes(listType); // Add more listType in the array
    const result = isListType ? title : value;
    return result === defaultValue;
  });

  const currentValue = useMemo(() => {
    const emptyValue = { value: '', label: placeholder };
    return options[getIndex] ?? emptyValue;
  }, [options, getIndex, placeholder]);

  const customDropdownIndicator = props => (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
      {...props.innerProps}
      style={{ cursor: 'pointer' }}
      className={cn(
        "font-stolzlBook h-10 p-[10px_20px_10px_10px] flex items-center border-none text-[#222222] text-[12px] bg-white border rounded capitalize bg-no-repeat bg-[center_right_18px] bg-[url('/src/assets/icons/dropdown.svg')] transition-transform duration-150 relative -right-[8px] -top-[1px] project-select",
        isMenuOpen && 'rotate-180 duration-150 right-[8px]'
      )}
    />
  );

  const customStyles = {
    singleValue: provided => {
      const errorValue = error ? '#E6A197' : '#BEBFC2';
      return {
        ...provided,
        marginTop: 0,
        color: defaultValue ? '#212121' : errorValue
      };
    },
    menu: provided => ({
      ...provided,
      border: '0px solid transparent',
      marginTop: 0,
      borderRadius: 0
    }),
    control: (provided, state) => {
      const errorBorder = error ? '1px solid #E43B26' : '1px solid #EAEAEA';
      return {
        ...provided,
        fontFamily: 'Stolzl Book, sans-serif',
        fontSize: '14px',
        color: '#212121',
        height: '40px',
        padding: 0,
        border: state.isFocused ? '1px solid black' : errorBorder,
        backgroundColor: state.isDisabled ? '#F7F7F7' : 'white'
      };
    },
    option: base => ({
      ...base,
      fontFamily: 'Stolzl Book, sans-serif',
      fontSize: '14px',
      color: '#212121'
    })
  };

  const customComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: customDropdownIndicator
  };

  return (
    <>
      {label && <FormLabel text={label} htmlFor={name} required={required} />}
      <Select
        id={id}
        name={name}
        className="basic-single"
        classNamePrefix="select"
        defaultValue={currentValue}
        onChange={handleChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isDisabled={notExist || isDisabled}
        isLoading={isLoading}
        options={options}
        placeholder={error ? errorMessage : placeholder}
        styles={customStyles}
        components={customComponents}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#EAF1FE',
            primary: '#EAF1FE'
          }
        })}
      />
    </>
  );
};

ComboBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  // datasource: PropTypes.instanceOf(Object),
  datasource: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  listType: PropTypes.string,
  onChangeValue: PropTypes.func,
  submitFilter: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string,
  menuCode: PropTypes.string
};

export default memo(ComboBox);
