/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-big-calendar';

import next_icon from 'src/assets/icons/next_icon.svg';
import prev_icon from 'src/assets/icons/prev_icon.svg';

const useDashboardIndex = () => {
  const convertDate = dateString => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  return {
    convertDate
  };
};

export default useDashboardIndex;
