import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getLeaveTypes } from 'src/redux/modules/LeaveTypes/LeaveTypesActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import {
  getLeaveTypeDataItems,
  getLeaveTypeIDSevice
} from 'src/api/modules/leaveType';

export const useDeleteModal = ({ handleDeleteModal, editModal, id, modal }) => {
  const dispatch = useDispatch();
  const [LeaveTypeDetail, setLeaveTypeDetail] = useState(null);

  const fetchLeaveTypeList = async () => {
    getLeaveTypeDataItems(1)
      .then(res => {
        dispatch(getLeaveTypes(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (id) {
      getLeaveTypeIDSevice(id, 'GET').then(res => {
        setLeaveTypeDetail(LeaveTypeDetail);
      });
    }
  }, []);

  const handleDelete = async codeId => {
    if (codeId) {
      const res = await getLeaveTypeIDSevice(codeId, 'DELETE');

      if (res.success) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: codeId
        });
        fetchLeaveTypeList();
      } else if (res.response.status === 405)
        toast.error('Unable to delete!', {
          icon: <CgCloseO />,
          toastId: codeId
        });
      handleDeleteModal(modal);
      if (editModal) {
        editModal(null);
      }
    }
  };
  return {
    LeaveTypeDetail,
    handleDelete
  };
};
