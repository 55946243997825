/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { getEmployeesService } from 'src/api/modules/employee';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getSalaryGrade,
  getGender,
  getCivilStatus,
  getEmploymentStatus,
  getTeams,
  getPositions
} from 'src/redux/modules/employee/employeeActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  filterData,
  updateObjectById,
  deleteObjectsById,
  employeeName as employeeFullName
} from 'src/helpers/utils';
import { STATUS_OPTIONS } from 'src/helpers/constants';
import { setUserListUpdate } from 'src/redux/modules/users/userActions';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';

const useEmployeeInformation = () => {
  const mounted = useRef(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [employeeName, setEmployeeName] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);

  const { isListUpdated } = useSelector(state => state.employees);
  const { deletedIds } = useSelector(state => state.datatable);

  const [employeeList, setEmployeeList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSalaryGrade());
    dispatch(getGender());
    dispatch(getCivilStatus());
    dispatch(getEmploymentStatus());
    dispatch(getPositions());
    dispatch(getTeams());
  }, []);

  const employeeColumns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'id_no',
      label: 'ID Number'
    },
    {
      key: 'name',
      label: 'Name',
      userIconKey: 'image'
    },
    {
      key: 'cost_center_code',
      label: 'Cost Center'
    },
    {
      key: 'project',
      label: 'Project'
    },
    {
      key: 'isUser',
      label: 'User'
    },
    {
      key: 'status',
      label: 'Status'
    }
  ];

  const filterOptions = [
    {
      value: 'is_user',
      label: 'User'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    },
    {
      type: 'checkbox',
      options: filterData(filterOptions, 'label'),
      name: 'is_user'
    }
  ];

  const employeeListMemo = useMemo(() => {
    return employeeList?.map(employee => {
      const name = employeeFullName(employee.employee_info);
      const project = employee?.projects[employee.projects.length - 1];
      const isUser = employee?.user?.id;

      employee.name = name;
      employee.project = project?.name;
      employee.isUser = isUser;

      return employee;
    });
    // return employeeList ?? [];
  }, [employeeList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;

      // Check for null or empty values
      if (value === null || value === '') {
        delete searchParamsObject[key];
      } else if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: value });
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-employee-search-params', searchParams);
  };

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setEmployeeId(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setEmployeeId]
  );

  const handleViewModal = useCallback(
    (modal, id = null, data = null) => {
      setShowViewModal(modal);
      setEmployeeId(modal ? id : null);
      setUserData(modal ? data : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setEmployeeId, setUserData]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, first_name, last_name, suffix }) => {
      const name = `${first_name || ''} ${last_name || ''} ${suffix || ''} `;
      setShowDeleteModal(modal);
      setEmployeeId(modal ? id : null);
      setEmployeeName(modal?.name);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setEmployeeId, setEmployeeName]
  );

  const handleCreateModal = useCallback(
    (modal, id = null, first_name = null, last_name = null, suffix = null) => {
      const name = `${first_name || ''} ${last_name || ''} ${suffix || ''} `;
      setShowCreateModal(modal);
      setEmployeeId(modal ? id : null);
      setEmployeeName(modal?.name);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowCreateModal, setEmployeeId]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const fetchEmployee = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-employee-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-employee-page-no'),
        10
      );
      const params = new URLSearchParams(savedSearchParams);
      const res = await getEmployeesService(savedPageIndex, params);

      if (mounted.current) {
        const newList =
          savedPageIndex !== 1
            ? employeeList.concat(res.data.items)
            : res.data.items;
        setEmployeeList(newList);
        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      if (mounted.current) {
        toast.error(`Error fetching data: ${error.message}`);
      }
    }
  }, [
    page,
    getEmployeesService,
    setEmployeeList,
    setPage,
    setHasMore,
    employeeList
  ]);

  useEffect(() => {
    mounted.current = true;
    const savedPageIndex = localStorage.getItem('hris-employee-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }

    pushQuery({ status: 'A' });
    return () => {
      // Set mounted to false when the component is unmounted
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isListUpdated) {
      const updatedObjects = updateObjectById(
        employeeList,
        isListUpdated.id,
        isListUpdated.payload
      );
      setEmployeeList(updatedObjects);
      dispatch(setUserListUpdate(false));
    }
  }, [isListUpdated]);

  useEffect(() => {
    if (deletedIds) {
      const updatedList = deleteObjectsById(employeeListMemo, deletedIds);
      setEmployeeList(updatedList);
      dispatch(setIdDeletion(null));
    }
  }, [deletedIds]);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-employee-page-no', JSON.stringify(1));
    fetchEmployee();
    setHasMore(true);
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('hris-employee-page-no', JSON.stringify(page));
  }, [page]);

  return {
    employeeListMemo,
    showModal,
    showDeleteModal,
    showBulkDeleteModal,
    showViewModal,
    showCreateModal,
    employeeId,
    userData,
    employeeName,
    inputs,
    form,
    isOpen,
    hasMore,
    employeeColumns,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    handleCreateModal,
    fetchEmployee,
    submitFilter
  };
};

export default useEmployeeInformation;
