import {
  SET_EDITOR,
  SET_ANNOUNCEMENT_LIST,
  SET_ANNOUNCEMENT_SINGLE
} from './announcementTypes';

const INITIAL_STATE = {
  editor: null,
  announcementList: {},
  announcementSingle: {}
};

export default function announcementReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case SET_EDITOR:
      return {
        ...state,
        editor: payload
      };
    case SET_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcementList: payload
      };
    case SET_ANNOUNCEMENT_SINGLE:
      return {
        ...state,
        announcementSingle: payload
      };
    default:
      return state;
  }
}
