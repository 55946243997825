/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  getDaycodes,
  getDayCodeByIDService,
  updateDayCodeByID,
  createDayCodeService,
  deleteDayCodeService
} from 'src/api/modules/daycode';
import {
  getDayCode,
  getAllDayCodes,
  setDayCodeListUpdate
} from 'src/redux/modules/dayCodes/dayCodeActions';
import { toast } from 'react-toastify';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import { TOAST_OPTIONS } from 'src/helpers/constants';
import { MdOutlineErrorOutline } from 'react-icons/md';
import moment from 'moment';

const useAddEditModal = ({ handleModal, dayCodeId, dataList }) => {
  const dispatch = useDispatch();
  const [daycodeOptions, setDaycodeOptions] = useState([]);
  const [isFixedCheckboxDisabled, setIsFixedCheckboxDisabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });

  const [formData, setFormData] = useState({
    day_code: '',
    description: '',
    hol_premium_rate: '',
    ot_rate: '',
    night_diff_rate: ''
  });

  const initialDummyData = {
    day_code: 'TEST',
    description: 'TEST',
    hol_premium_rate: '0',
    ot_rate: '0',
    night_diff_rate: '0'
  };

  const handleSelectChange = useCallback(
    (value, name, setFieldTouched, setFieldError) => {
      const modifiedName = name;
      const modifiedValue = value;

      setFormData(prevState => ({
        ...prevState,
        [modifiedName]: modifiedValue
      }));

      setFieldTouched(modifiedName, true);
      setFieldError(modifiedName, '');
    },
    [setFormData]
  );

  const fetchDayCodes = async dayCodeId => {
    try {
      const response = await getDayCodeByIDService(dayCodeId, 'GET');

      if (response.data) {
        const responseData = response.data;
        if (responseData.daycodes && Array.isArray(responseData.daycodes)) {
          const modifiedDayCodes = responseData.daycodes.map(daycode => ({
            ...daycode
          }));
          responseData.daycodes = modifiedDayCodes;
        }
        setFormData(response.data);
      }
    } catch (error) {
      console.error('Error fetching day code:', error);
    }
  };

  const handleColorChange = color => {
    setFormData(prevState => ({
      ...prevState,
      color
    }));
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, day_code }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      setDeleteId({ id, day_code });
      setShowDeleteModal(modal);
    },
    [showDeleteModal]
  );

  const handleDelete = async id => {
    try {
      const response = await deleteDayCodeService(id);
      if (response?.success) {
        fetchUpdateDayCode();
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          onClose: handleDeleteModal({ modal: null })
        });
      } else {
        fetchUpdateDayCode();
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id,
          onClose: handleDeleteModal({ modal: null })
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id,
        onClose: handleDeleteModal({ modal: null })
      });
    }
    setShowDeleteModal(null);
  };

  const handleTrimSpaces = e => {
    const { name, value } = e.target;
    let trimEndValue = '';
    if (typeof value === 'string' && value.length > 1) {
      trimEndValue = value.trimEnd();
      setFormData(prevState => ({
        ...prevState,
        [name]: trimEndValue
      }));
    }
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type, selectionStart, selectionEnd } =
      e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    const initialValue = value.match(/^\s/) !== null;
    const numRegex = /^\d*\.?\d*%?$/;
    const initialNumber = value.match(numRegex) !== null;

    if (name === 'day_code' || name === 'description') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.trim()
      }));
      setFieldValue(name, value);
    } else if (
      (name === 'hol_premium_rate' && initialNumber) ||
      (name === 'ot_rate' && initialNumber) ||
      (name === 'night_diff_rate' && initialNumber)
    ) {
      const numericValue = value.replace('%', '');
      setFormData(prevState => ({
        ...prevState,
        [name]: numericValue
      }));
      setFieldValue(name, numericValue);
    }

    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const validationSchema = Yup.object().shape({
    day_code: Yup.string().required('Day Code is required'),
    description: Yup.string().required('Day Code description is required'),
    hol_premium_rate: Yup.number().required('Holiday Premium rate is required'),
    ot_rate: Yup.number().required('Overtime rate is required'),
    night_diff_rate: Yup.number().required(
      'Night differential rate is required'
    )
  });

  const fetchUpdateDayCode = async () => {
    getDayCodeByIDService(1)
      .then(() => {
        dispatch(getDayCode());
        dispatch(getAllDayCodes());
      })
      .catch(err => {
        return err;
      });
  };

  const isMounted = useRef(true);

  const addDayCode = async body => {
    const payload = {
      day_code: body.day_code,
      description: body.description,
      hol_premium_rate: body.hol_premium_rate.replace('%', ''),
      ot_rate: body.ot_rate.replace('%', ''),
      night_diff_rate: body.night_diff_rate.replace('%', '')
    };
    try {
      const response = await createDayCodeService(payload);

      if (isMounted.current) {
        if (response.status === 201) {
          toast.success('Successfully Added!', { icon: <CgCheckO /> });
          handleModal(null);
          fetchUpdateDayCode();
        }
      }
    } catch (error) {
      console.error('Error occurred while adding day code:', error);
    }
  };

  const updateDayCode = async body => {
    const payload = {
      day_code: body.day_code,
      description: body.description,
      hol_premium_rate: body.hol_premium_rate.replace('%', ''),
      ot_rate: body.ot_rate.replace('%', ''),
      night_diff_rate: body.night_diff_rate.replace('%', '')
    };
    setFormLoading(true);
    const response = await updateDayCodeByID(dayCodeId, payload);
    if (response.statusCode === 200) {
      fetchUpdateDayCode();
      toast.success('Successfully Updated!', {
        icon: <CgCheckO />
      });
      handleModal(null);
    } else {
      toast.error('Update day code failed', { icon: <CgCloseO /> });
      setFormLoading(false);
    }
  };
  const handleSubmit = useCallback(async () => {
    // EDIT
    if (dayCodeId) {
      updateDayCode(formData);
    } else {
      // ADD
      addDayCode(formData);
    }
  });

  useEffect(() => {
    if (formData.is_fixed) {
      setIsFixedCheckboxDisabled(true);
    } else {
      setIsFixedCheckboxDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (dayCodeId) {
      fetchDayCodes(dayCodeId);
    }
  }, []);

  useEffect(() => {
    if (dayCodeId) {
      const dayCode = dataList.find(e => e.id === dayCodeId);
      if (dayCode) {
        setFormData({
          day_code: dayCode.day_code,
          description: dayCode.description,
          hol_premium_rate: dayCode.hol_premium_rate,
          ot_rate: dayCode.ot_rate,
          night_diff_rate: dayCode.night_diff_rate
        });
      }
    } else {
      setFormData({
        day_code: '',
        description: '',
        hol_premium_rate: '',
        ot_rate: '',
        night_diff_rate: ''
      });
    }
  }, [dayCodeId, dataList]);

  return {
    isFixedCheckboxDisabled,
    formData,
    initialDummyData,
    validationSchema,
    daycodeOptions,
    formLoading,
    showDeleteModal,
    deleteId,
    handleDeleteModal,
    handleDelete,
    handleColorChange,
    handleChange,
    handleTrimSpaces,
    handleSubmit,
    handleSelectChange
  };
};

export default useAddEditModal;
