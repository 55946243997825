/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  getLowerCasedValue,
  employeeName,
  filterData
} from 'src/helpers/utils';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getAllEvaluationsResultsEntryService } from 'src/api/modules/evaluation';

const useEvaluationEntryNew = () => {
  const [evaluationResultList, setEvaluationResultList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [resultId, setResultId] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const currentSearchParams = useRef({});
  const currentSaveParams = useRef(null);
  const [evaluationResultCount, setEvaluationResultCount] = useState(0);
  const itemsPerPage = 20;

  const evaluationResultColumns = [
    { key: 'schedule_name', label: 'Schedule Name' },
    { key: 'created_by', label: 'Created by' },
    { key: 'evaluation_date', label: 'Evaluation Date' }
  ];

  const access = {
    can_add: true,
    can_approve: true,
    can_delete: true,
    can_edit: true,
    can_generate: true,
    can_print: true,
    can_view: true
  };

  const EVALUATION_STATUS = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'F',
      label: 'Fullfilled'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(EVALUATION_STATUS, 'label', 'value'),
      multiOptions: EVALUATION_STATUS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A'],
      size: 'L'
    },
    {
      type: 'date-range',
      options: [
        {
          label: 'Start Date',
          name: 'start_date'
        },
        {
          label: 'End Date',
          name: 'end_date'
        }
      ]
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') delete searchParamsObject.search;

    if (params.status === '') delete searchParamsObject.status;

    if (params.start_date === '') delete searchParamsObject.start_date;

    if (params.end_date === '') delete searchParamsObject.end_date;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });
    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-evaluation-result-search-params', searchParams);

    if (searchParams) currentSaveParams.current = searchParams;
    else currentSaveParams.current = null;
  };

  const evaluationResultMemo = useMemo(() => {
    return evaluationResultList?.map(
      ({ name, user_createdBy, start_date, end_date, ...rest }) => {
        return {
          ...rest,
          schedule_name: name,
          created_by: employeeName(user_createdBy),
          evaluation_date: `${start_date} - ${end_date}`
        };
      }
    );
  }, [evaluationResultList]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-result-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-evaluation-result-page-no'),
        10
      );

      // const newSearchParams = savedSearchParams
      //   .replace('status=A&', '')
      //   .replace('status=A', '');

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAllEvaluationsResultsEntryService(
        newPageIndex || page,
        params
      );

      setEvaluationResultCount(res.data.total_items);

      const newList =
        savedPageIndex !== 1
          ? evaluationResultList.concat(res.data.items)
          : res.data.items;

      setTotalPage(res.data.total_pages);
      setEvaluationResultList(newList);

      if (
        res.data.items.length === 0 ||
        res.data.items.length <= itemsPerPage
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAllEvaluationsResultsEntryService,
    setEvaluationResultList,
    setPage,
    setHasMore,
    evaluationResultList
  ]);

  const handleViewPage = useCallback((modal, id = null) => {
    navigate(`/evaluation-entry/schedule/${id}`);
  }, []);

  const handleDateOnChange = e => {
    const searchParams = new URLSearchParams(location.search);
    const { name, value } = e.target;
    if (Object.keys(currentSearchParams.current).length === 1 && value === '') {
      const paramsList = currentSaveParams.current.split('&');
      const updatedParamsList = paramsList.filter(
        param => !param.startsWith(name.toString())
      );

      const updatedParams = updatedParamsList.join('&');

      navigate({
        pathname: location.pathname,
        search: updatedParams.toString()
      });

      localStorage.setItem(
        'hris-evaluation-result-search-params',
        updatedParams
      );

      searchParams.delete(name);
    }
  };

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-result-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-result-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-evaluation-result-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchData();
    } else {
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
    return () => {
      localStorage.setItem('hris-evaluation-result-search-params', '');
    };
  }, []);

  useEffect(() => {
    const newLocation = { ...location, search: '' };
    navigate(newLocation);
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-evaluation-result-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem(
      'hris-evaluation-result-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  useEffect(() => {
    return () => {
      localStorage.setItem('hris-evaluation-result-search-params', '');
    };
  }, [navigate]);

  return {
    page,
    form,
    inputs,
    hasMore,
    access,
    evaluationResultColumns,
    evaluationResultList,
    evaluationResultMemo,
    evaluationResultCount,
    fetchData,
    submitFilter,
    handleViewPage,
    handleDateOnChange
  };
};

export default useEvaluationEntryNew;
