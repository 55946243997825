import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import {
  HOLIDAYS_ALL,
  HOLIDAYS,
  HOLIDAY_BY_ID,
  HOLIDAY_DELETE
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getHolidayService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: HOLIDAYS
    });
  } catch (error) {
    return error;
  }
};

export const getHolidayItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${HOLIDAYS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAllHolidaysService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${HOLIDAYS_ALL}`
    });
  } catch (error) {
    return error;
  }
};

export const getHolidayByIDService = async (id, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${HOLIDAY_BY_ID}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const getHolidayByIDService2 = async (id, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${HOLIDAYS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateHolidayById = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${HOLIDAYS}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getHolidaysService = async (page, searchParams) => {
  try {
    let endpoint = `${HOLIDAYS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const updateHolidayService = async (id, data) => {
  try {
    const response = await axios.put(
      `${ENV.test}${HOLIDAYS}/${id}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createHolidayService = async data => {
  try {
    const response = await axios.post(`${ENV.test}${HOLIDAYS}`, data, auth());
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteHolidayService = async holidayId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${HOLIDAY_DELETE}/${holidayId}`
    });
  } catch (error) {
    return error;
  }
};

export const addHolidayService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${HOLIDAYS}`,
      data,
      auth({
        'menu-code': MENU_CODES.calendarHolidays,
        'menu-action': MENU_ACTIONS.CRUD
      })
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteBulkHoliday = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });

    return response.data;
  } catch (error) {
    return error.response;
  }
};
