/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import useAddEditModal from 'src/hooks/components/Parts/CalendarSettings/DayCodes/useAddEditModal';
import ColorPicker from 'src/components/Common/ColorPicker';
import {
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard
} from 'src/components/Common';
import { useUserAccess } from 'src/hooks/useUserAccess';
import Loading from 'src/components/Common/loading';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, dayCodeId, dataList, menuCode }) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    handleDeleteModal,
    handleTrimSpaces,
    handleSelectChange,
    handleDelete,
    initialDummyData,
    validationSchema,
    showDeleteModal,
    isFixedCheckboxDisabled,
    daycodeOptions,
    formLoading,
    deleteId
  } = useAddEditModal({ handleModal, dayCodeId, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <>
      <Formik
        enableReinitialize={!!dayCodeId}
        validateOnMount={!!dayCodeId}
        initialValues={dayCodeId ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {formData.id ? 'Edit' : 'Add'} Day Code
              </h4>
              <div className="text-right">
                <button
                  type="submit"
                  className="text-[12px] text-white leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                  disabled={isSubmitting}
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Save
                  </span>
                </button>
              </div>
            </div>
            {formLoading && <Loading />}
            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!formData.id ? (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Day Code"
                          required
                          name="day_code"
                          type="text"
                          placeholder=""
                          value={formData.day_code ?? ''}
                          error={errors.day_code && touched.day_code}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.day_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.day_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          name="description"
                          type="text"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              'description'
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <label
                          htmlFor="hol_premium_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Holiday Premium Rate
                            {errors.hol_premium_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.hol_premium_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="hol_premium_rate"
                            type="text"
                            placeholder=""
                            value={
                              (
                                formData.hol_premium_rate + '%'.toString()
                              ).replace('%%', '%') ?? ''
                            }
                            error={
                              errors.hol_premium_rate &&
                              touched.hol_premium_rate
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                      <FormCard>
                        <label
                          htmlFor="ot_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Overtime Rate
                            {errors.ot_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.ot_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="ot_rate"
                            type="text"
                            placeholder=""
                            value={
                              (formData.ot_rate + '%'.toString()).replace(
                                '%%',
                                '%'
                              ) ?? ''
                            }
                            error={errors.ot_rate && touched.ot_rate}
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <label
                          htmlFor="night_diff_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Night Differential Rate
                            {errors.night_diff_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.night_diff_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="night_diff_rate"
                            type="text"
                            placeholder=""
                            value={
                              (
                                formData.night_diff_rate + '%'.toString()
                              ).replace('%%', '%') ?? ''
                            }
                            error={
                              errors.night_diff_rate && touched.night_diff_rate
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Day Code"
                          required
                          name="day_code"
                          type="text"
                          placeholder=""
                          value={formData.day_code ?? ''}
                          error={errors.day_code && touched.day_code}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.day_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.day_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Description"
                          name="description"
                          type="text"
                          placeholder=""
                          value={formData.description ?? ''}
                          error={errors.description && touched.description}
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              'description'
                            )
                          }
                        />
                        {errors.description !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.description}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <label
                          htmlFor="hol_premium_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Holiday Premium Rate
                            {errors.hol_premium_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.hol_premium_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="hol_premium_rate"
                            type="text"
                            placeholder=""
                            value={
                              (
                                formData.hol_premium_rate + '%'.toString()
                              ).replace('%%', '%') ?? ''
                            }
                            error={
                              errors.hol_premium_rate &&
                              touched.hol_premium_rate
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                      <FormCard>
                        <label
                          htmlFor="ot_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Overtime Rate
                            {errors.ot_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.ot_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="ot_rate"
                            type="text"
                            placeholder=""
                            value={
                              (formData.ot_rate + '%'.toString()).replace(
                                '%%',
                                '%'
                              ) ?? ''
                            }
                            error={errors.ot_rate && touched.ot_rate}
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <label
                          htmlFor="night_diff_rate"
                          className="custom__checkbox inline-block align-middle text-[0] cursor-pointer flex items-center"
                        >
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer not-italic">
                            Night Differential Rate
                            {errors.night_diff_rate !== 'Required' && (
                              <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                                {errors.night_diff_rate}
                              </div>
                            )}
                          </i>
                          <FormField
                            required
                            name="night_diff_rate"
                            type="text"
                            placeholder=""
                            value={
                              (
                                formData.night_diff_rate + '%'.toString()
                              ).replace('%%', '%') ?? ''
                            }
                            error={
                              errors.night_diff_rate && touched.night_diff_rate
                            }
                            onBlur={handleTrimSpaces}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            className="w-[70px] ml-2.5 "
                          />
                        </label>
                      </FormCard>
                    </FormGridCard>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            id={formData.day_code}
          />
        )}
      </ModalCenter>
    </>
  );
};

export default AddEditModal;
